<template>
    <v-app-bar
        color="headerblue"
        height="74"
        style="padding-left: 2.5%; padding-right: 2.5%"
    >
        <BBBDropdown />

        <v-spacer />

        <div
            class="d-flex justify-center"
            style="gap: 16px"
        >
            <div class="d-flex justify-center align-center">
                <p style="color: white">
                    Verwaltung
                </p>
            </div>

            <v-tooltip
                bottom
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-badge
                        :value="showCalendarBadge"
                        left
                        offset-x="10"
                        offset-y="15"
                        color="#ff0000"
                        :content="eventsNotSeen"
                    >
                        <v-btn
                            v-if="!calendarDeactivated"
                            x-small
                            style="width: 50px; height: 50px; border-radius: 8px; background-color: #FFFFFF"
                            elevation="0"
                            v-bind="attrs"
                            v-on="on"
                            @click="openCalendar"
                        >
                            <img
                                :src="kalenderIcon"
                                alt="Kalender"
                                class="iconToHeaderblue"
                                style="max-width: 25px; max-height: 25px;"
                            >
                        </v-btn>
                    </v-badge>
                </template>
                <span>Kalender</span>
            </v-tooltip>

            <!-- <v-tooltip
                bottom
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-if="!diaryDeactivated"
                        x-small
                        elevation="0"
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        v-bind="attrs"
                        v-on="on"
                        @click="openDiary()"
                    >
                        <img
                            :src="bookIcon"
                            alt="Klassenbuch"
                            class="iconToHeaderblue"
                            style="max-width: 30px; max-height: 30px;"
                        >
                    </v-btn>
                </template>
                <span>Klassenbuch</span>
            </v-tooltip> -->

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small
                        elevation="0"
                        class="animateIcon"
                        v-bind="attrs"
                        @click="reloadPage()"
                        v-on="on"
                    >
                        <img
                            class="iconToHeaderblue"
                            :src="reloadIcon"
                            style="max-width: 25px; max-height: 25px;"
                            alt=""
                        >
                    </v-btn>
                </template>
                <span>Aktualisieren</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                        x-small
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => { setSupportPageOpen(true) }"
                    >
                        <img
                            class="iconToHeaderblue"
                            :src="supportIcon"
                            height="25"
                            alt=""
                        >
                    </v-btn>
                </template>
                <span>Hilfe</span>
            </v-tooltip>

            <v-badge
                v-if="showEmailBadge"
                content="1"
                overlap
                left
                offset-x="25"
                color="#3ba934"
                style="z-index: 9; align-base; margin-left: -16px;"
                class="align-self-start insetBadge"
            />
            <v-tooltip
                bottom
            >
                <template v-slot:activator="{ on, attrs }">
                    <div
                        class="d-flex align-center pointer"
                        v-bind="attrs"
                        v-on="on"
                        @click="openSidebar"
                    >
                        <Avatar
                            :profile="accountId"
                            :show-burger="true"
                            :alt="'Profil öffnen'"
                        />
                    </div>
                </template>
                <span>Profil</span>
            </v-tooltip>
        </div>
        <calendar-app
            v-if="showCalendarWidget"
            @close="() => {showCalendarWidget = false; this.hasUserNewEvents();}"
        />
        <!-- <Diary
            v-if="diaryDialog"
            :diary-dialog="diaryDialog"
            @close="diaryDialog = false"
        /> -->
    </v-app-bar>
</template>

<script>
import {mapMutations, mapActions, mapGetters, mapState} from "vuex";

import reloadIcon from '@/assets/Icons/FaceliftIcons/aktualisieren.svg';
import supportIcon from "@/assets/Icons/FaceliftIcons/hilfe_support_telefon_eklara.svg";
import kalenderIcon from "../../assets/Icons/FaceliftIcons/kalender_datum_zeit.svg";
import bookIcon from "../../assets/Icons/FaceliftIcons/klassenbuch.svg";

import Avatar from "@/components/SharedViews/Profile/Avatar";
import BBBDropdown from "@/components/BBBDropdown";
import CalendarApp from "../Calendar/CalendarApp.vue";
import Diary from "../Lehrer/Diary";

export default {
    name: "Header",
    components: {
        Avatar,
        BBBDropdown,
        CalendarApp,
        // Diary
    },
    props: {
        showEmailBadge: { required: false, type: Boolean, default: false, },
    },
    data() {
        return {
            reloadIcon,
            supportIcon,
            kalenderIcon,
            bookIcon,

            showCalendarWidget: false,
            showCalendarBadge: false,
            eventsNotSeen: '',
            diaryDialog: false,
        };
    },
    computed: {
        ...mapGetters("support",["supportPageOpen"]),
        ...mapState("profileManagement", ["profileManagement"]),
        ...mapGetters('auth', ['accountId']),
        ...mapGetters('deactivatedFeatures',['deactivatedFeatures', 'appsDeactivated','chatDeactivated','brettDeactivated','planDeactivated',
                                                'messagesDeactivated','filesDeactivated','calendarDeactivated','diaryDeactivated','pinboardDeactivated',]),
    },
    async mounted() {
        await this.hasUserNewEvents();   
    },
    methods: {
        ...mapActions("auth", ["logoutUser"]),
        ...mapActions("profileManagement", ["toggleProfileManagement", "closeProfileManagement"]),
        ...mapMutations("maintainer", ["setShowDialogGDPR"]),
        ...mapMutations("support", ["setSupportPageOpen"]),
        ...mapActions("calendar", ['getUnseenEventsForUser']),

        reloadPage() {
            this.$router.go();
        },

        openSidebar() {
            this.$emit('openSidebar');
        },

        openCalendar() {
            this.showCalendarWidget = true;
        },

        async hasUserNewEvents() {
            this.showCalendarBadge = false;
            const eventsNotSeen = await this.getUnseenEventsForUser();
            this.eventsNotSeen = eventsNotSeen.length;

            if (this.eventsNotSeen > 0) {
                this.showCalendarBadge = true;
            }
        },

        openDiary() {
            this.diaryDialog = true;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar__content {
    padding: 12px;
}

.pointer {
    cursor: pointer;
}

.activeLink {
    background-color: rgba(105, 149, 205, 0.8) !important;

    & img {
        filter: brightness(1000%);
    }

    & div {
        color: white !important;
        filter: brightness(1000%);
    }
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0);
    background: linear-gradient(
        to right,
        rgba(112, 112, 112, 0.2) 0%,
        rgba(112, 112, 112, 0.2) 77%,
        rgba(112, 112, 112, 0.5) 92%,
        rgba(112, 112, 112, 0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

.iconToHeaderblue {
    filter: brightness(0) saturate(100%) invert(21%) sepia(43%) saturate(391%) hue-rotate(183deg) brightness(94%) contrast(92%);
}

@keyframes shine {
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}
</style>
