<template>
    <div class="widgetAlign">
        <div
            class="widgetContainer"
            style="background-color: white;"
        >
            <div style="z-index: 3;">
                <ClickableBadge
                    :event-list="totalLockedAccounts"
                    :delete-button="true"
                    usage="users"
                    @badgeClicked="lockedBadgeClicked"
                />
            </div>
            <!--#region Header -->
            <div class="header">
                <div
                    v-if="editObject || createType"
                    class="headerRow mx-2 d-flex align-center justify-space-between"
                >
                    <div class="d-flex justify-center align-center">
                        <v-btn
                            class="optionBtn"
                            x-small
                            icon
                            dark
                            @click="()=>{ createType ? showAreYouSure = true : stop() }"
                        >
                            <img
                                :src="backIcon"
                                class="icon20 iconToWhite"
                                alt="zurück"
                            >
                        </v-btn>

                        <p v-if="editObjectRole === 'group'">
                            {{ editObject.name }}
                        </p>
                        <p v-else-if="editObject">
                            {{ editObject.name + ' ' + editObject.lastName.slice(0, 1) }}
                        </p>
                        <p v-else>
                            {{ createType === 'pupil' ? 'Neuen Schüler anlegen' : createType === 'teacher' ? 'Neuen Lehrer anlegen' : 'Neue Gruppe anlegen' }}
                        </p>
                    </div>


                    <div class="d-flex justify-center align-center">
                        <v-btn
                            v-if="editObject"
                            class="optionBtn ml-2"
                            x-small
                            icon
                            dark
                            @click="deleteDialog = true"
                        >
                            <img
                                :src="deleteIcon"
                                class="icon20 iconToWhite"
                                alt="löschen"
                            >
                        </v-btn>
                        <div
                            class="d-flex justify-space-around align-center ml-2"
                            style="cursor: pointer"
                        >
                            <v-tooltip
                                v-if="createType"
                                bottom
                                :disabled="valid"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        id="saveBtn"
                                        class="text-none"
                                        elevation="0"
                                        :disabled="!valid"
                                        color="success"
                                        v-on="on"
                                        @click="saveElement()"
                                    >
                                        <img
                                            :src="saveIcon"
                                            class="ml-0 icon20 iconToWhite"
                                            alt="💾"
                                        >
                                    </v-btn>
                                </template>
                                <span>Bitte füllen Sie zuerst alle Pflichtfelder aus</span>
                            </v-tooltip>
                            <div
                                v-else-if="editObject"
                                class="d-flex justify-space-around align-center ml-2"
                                style="cursor: pointer"
                            >
                                <v-btn
                                    id="saveBtn"
                                    class="text-none"
                                    :disabled="!valid"
                                    color="success"
                                    elevation="0"
                                    @click="patchElement()"
                                >
                                    <img
                                        :src="saveIcon"
                                        class="icon20 iconToWhite ml-0"
                                        alt="💾"
                                    >
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-else
                    class="headerRow mx-4 d-flex align-center justify-space-between"
                >
                    <div
                        class="pa-0 d-flex align-center justify-center"
                        style="font-size: larger; flex-grow: 4"
                    >
                        <v-tooltip
                            top
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <img
                                    :src="pupilIcon"
                                    class="icon30 iconToWhite mr-2"
                                    alt="Nutzerverwaltung"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                            </template>
                            <span>Hier können Sie die Nutzer ihrer Schule verwalten.</span>
                        </v-tooltip>
                        <!-- text field needs to be present 3x because there are 3 different search models -->
                        <v-text-field
                            v-if="showGroups"
                            v-model="searchGroupNames"
                            dark
                            :label="'Gruppe suchen'"
                            single-line
                            hide-details
                        >
                            <template v-slot:prepend-inner>
                                <img
                                    :src="magnifierIcon"
                                    class="mt-1 icon20 iconToWhite"
                                    alt="Suche"
                                >
                            </template>
                        </v-text-field>
                        <v-text-field
                            v-if="showTeacher"
                            v-model="searchLeaders"
                            dark
                            :label="'Lehrer suchen'"
                            single-line
                            hide-details
                        >
                            <template v-slot:prepend-inner>
                                <img
                                    :src="magnifierIcon"
                                    class="mt-1 icon20 iconToWhite"
                                    alt="Suche"
                                >
                            </template>
                        </v-text-field>
                        <v-text-field
                            v-if="showPupil"
                            v-model="searchMembers"
                            dark
                            :label="'Schüler suchen'"
                            single-line
                            hide-details
                        >
                            <template v-slot:prepend-inner>
                                <img
                                    :src="magnifierIcon"
                                    class="mt-1 icon20 iconToWhite"
                                    alt="Suche"
                                >
                            </template>
                        </v-text-field>
                    </div>

                    <div class="d-flex justify-end flex-row ml-3">
                        <!--#region Options menu (Erneuern, gruppieren, löschen)-->
                        <v-menu
                            v-if="selected.length > 0"
                            offset-y
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="optionBtn ml-2"
                                    x-small
                                    icon
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <img
                                        :src="editIcon"
                                        class="icon20 iconToWhite"
                                        alt="bearbeiten"
                                    >
                                </v-btn>
                            </template>

                            <v-list width="400">
                                <v-list-item
                                    v-if="!showGroups"
                                    @click="confirmDialog = true"
                                >
                                    <v-list-item-icon>
                                        <img
                                            :src="updateIcon"
                                            class="icon20"
                                            alt="erneuern"
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Erneuern
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Für die in der Tabelle ausgewählten Nutzer <br>
                                            neue Zugangsdaten (inkl. PDF) erstellen
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="!showGroups"
                                    @click="prefillGroup"
                                >
                                    <v-list-item-icon>
                                        <img
                                            :src="groupIcon"
                                            class="icon20"
                                            style="filter: invert(44%) sepia(1%) saturate(0%) hue-rotate(3deg) brightness(97%) contrast(85%); width: 20px"
                                            alt="gruppieren"
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Gruppieren
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Erstellen Sie eine neue Gruppe <br>
                                            mit den in der Tabelle ausgewählten Nutzern
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="deleteDialog = true">
                                    <v-list-item-icon>
                                        <img
                                            :src="deleteIcon"
                                            class="icon20 iconToRed"
                                            alt="löschen"
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title style="color: red">
                                            Löschen
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Die in der Tabelle ausgewählten {{ this.showGroups ? 'Gruppen' : 'Nutzer' }} löschen
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <!--#endregion -->

                        <!--#region Create menu -->
                        <v-menu
                            v-else
                            offset-y
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="optionBtn ml-2"
                                    x-small
                                    icon
                                    elevation="0"
                                    :disabled="usePin && !accountPinValid"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <img
                                        :src="plusIcon"
                                        class="icon20 iconToWhite"
                                        alt="Erstellen-Menü"
                                    >
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item @click="createElement('pupil')">
                                    <v-list-item-icon>
                                        <img
                                            :src="pupilIcon"
                                            class="icon20 iconToDunkelgrau"
                                            alt="Schüler"
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Neuer Schüler
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="createElement('teacher')">
                                    <v-list-item-icon>
                                        <img
                                            :src="teacherIcon"
                                            class="icon20 iconToDunkelgrau"
                                            alt="Lehrer"
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Neuer Lehrer
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="createElement('group')">
                                    <v-list-item-icon>
                                        <img
                                            :src="groupIcon"
                                            class="icon20 iconToDunkelgrau"
                                            alt="Gruppen"
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Neue Gruppe
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="oldExcelDialog = true">
                                    <v-list-item-icon>
                                        <v-icon>mdi-microsoft-excel</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Neue Schüler per Excel Upload
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="excelDialog = true">
                                    <v-list-item-icon>
                                        <v-icon>mdi-microsoft-excel</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Erweiterter Excel-Import
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <!--#endregion -->
                    </div>
                </div>
            </div>
            <!--#endregion -->
            <!--#region Ersteinrichtung -->
            <!-- <div
                v-if="!editObject && !createType"
                class="d-flex pa-0 ma-0 fontSizeChange"
                style="height: 64px; min-width: 100%; max-width: 100%"
            >
                <div class="pinkTextBlock textBlock text-center">
                    <div class="d-flex justify-center">
                        <span style="white-space: pre-line; word-break: break-word">Ersteinrichtung</span>
                    </div>
                </div>
                <div class="separatorLine" />
                <div
                    class="blueTextBlock textBlock"
                    @click="createElement('pupil')"
                >
                    <div class="d-flex justify-center">
                        <div class="numberBadge d-flex justify-center align-center">
                            1
                        </div>
                        <span style="white-space: pre-line">Schüler anlegen</span>
                    </div>
                </div>
                <div class="separatorLine" />
                <div
                    class="blueTextBlock textBlock"
                    @click="createElement('teacher')"
                >
                    <div class="d-flex justify-center">
                        <div class="numberBadge d-flex justify-center align-center">
                            2
                        </div>
                        <span style="white-space: pre-line">Lehrer anlegen</span>
                    </div>
                </div>
                <div class="separatorLine" />
                <div
                    class="blueTextBlock textBlock"
                    @click="() => { showGroups = true; createElement('group')}"
                >
                    <div class="d-flex justify-center">
                        <div class="numberBadge d-flex justify-center align-center">
                            3
                        </div>
                        <span style="white-space: pre-line">Gruppen anlegen</span>
                    </div>
                </div>
            </div> -->
            <!--#endregion -->
            <v-overlay
                v-if="usePin && !accountPinValid"
                :color="'white'"
                opacity="1.0"
                class="overlaySize"
                @click="pinDialog = true"
            >
                <div
                    class="d-flex flex-column justify-space-around align-center"
                >
                    <img
                        class="icon30 iconToRed"
                        style="position: relative; margin: auto;"
                        alt="gesperrt"
                        :src="lockIcon"
                    >
                    <p style="color:black">
                        Klicken um Nutzerverwaltung anzuzeigen
                    </p>
                </div>
            </v-overlay>
            <v-tooltip
                v-if="!(editObject || createType || usePin && !accountPinValid)"
                top
            >
                <template v-slot:activator="{ on, attrs }">
                    <div
                        class="px-4 pt-3 d-flex justify-center pb-2"
                        style="background-color: white;"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-btn-toggle
                            v-model="messageCategory"
                            dense
                            mandatory
                            borderless
                            style="width: 99% !important; border-radius: 8px;"
                        >
                            <v-badge
                                content="1"
                                color="green"
                                left
                                overlap
                                style="width: 33% !important"
                                v-bind="attrs"
                                class="insetBadge"
                                v-on="on"
                            >
                                <v-btn
                                    ref="btn1"
                                    class="text-none"
                                    style="width: 100% !important; border-top-right-radius: 0px; border-bottom-right-radius: 0px;"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="handleClickCategory(0)"
                                >
                                    <div class="d-flex justify-space-between align-center">
                                        <img
                                            :src="schuelerIcon"
                                            class="icon20 "
                                            alt=""
                                        >
                                        <div class="mx-1">
                                            Schüler
                                        </div>
                                        <img
                                            :src="plusIconSchw"
                                            class="icon20 "
                                            alt="Neuer Schüler"
                                            @click="createElement('pupil')"
                                        >
                                    </div>
                                </v-btn>
                            </v-badge>
                            <v-badge
                                content="2"
                                color="green"
                                left
                                overlap
                                style="width: 33% !important"
                                v-bind="attrs"
                                class="insetBadge"
                                v-on="on"
                            >
                                <v-btn
                                    ref="btn2"
                                    class="text-none"
                                    style="width: 100% !important; border-top-right-radius: 0px; border-bottom-right-radius: 0px;  border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important;"
                                    @click="handleClickCategory(1)"
                                >
                                    <div class="d-flex justify-space-between align-center">
                                        <img
                                            :src="lehrerIcon"
                                            class="icon20 "
                                            alt=""
                                        >
                                        <div class="mx-1">
                                            Lehrer
                                        </div>
                                        <img
                                            :src="plusIconSchw"
                                            class="icon20 "
                                            alt="Neuer Lehrer"
                                            @click="createElement('teacher')"
                                        >
                                    </div>
                                </v-btn>
                            </v-badge>
                            <v-badge
                                content="3"
                                color="green"
                                left
                                overlap
                                style="width: 33% !important;"
                                v-bind="attrs"
                                class="insetBadge"
                                v-on="on"
                            >
                                <v-btn
                                    class="text-none"
                                    style="width: 100% !important;  border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important;"
                                    @click="handleClickCategory(2)"
                                >
                                    <div class="d-flex justify-space-between align-center">
                                        <img
                                            :src="gruppenIcon"
                                            class="icon20 "
                                            alt=""
                                        >
                                        <div class="mx-1">
                                            Gruppen
                                        </div>
                                        <img
                                            :src="plusIconSchw"
                                            class="icon20 "
                                            alt="Neue Gruppe"
                                            @click="createElement('group')"
                                        >
                                    </div>
                                </v-btn>
                            </v-badge>
                        </v-btn-toggle>
                    </div>
                </template>
                Ersteinrichtung in drei Schritten
            </v-tooltip>
            <vue-custom-scrollbar
                id="hehe"
                class="scroll-area"
                :style="(editObject||createType) ? 'max-height: calc(80vh - 56px );':''"
                :settings="settings"
            >
                <!--#region Create/Edit pupil -->
                <div
                    v-if="(editObject && editObjectRole === 'pupil') || createType === 'pupil'"
                    class="mx-2 mt-2"
                >
                    <!--#region Pupil -->
                    <div class="d-flex justify-space-between align-center">
                        <p class="mb-1">
                            <b>Schülerdaten</b>
                        </p>
                        <v-btn
                            v-if="createType !== 'pupil'"
                            text
                            class="text-none"
                            @click="showMorePupil = !showMorePupil;"
                        >
                            <img
                                :src="downIcon"
                                class="icon10 IconToDunkelgrau"
                                :style="showMorePupil ? 'transform: rotate(180deg)':''"
                            >
                            {{ showMorePupil ? 'Weniger Daten anzeigen' : 'Weitere Daten anzeigen' }}
                        </v-btn>
                    </div>

                    <div v-if="showMorePupil">
                        <v-text-field
                            v-model="firstName"
                            outlined
                            dense
                            hide-details
                            label="Vorname"
                            placeholder="Vorname eingeben"
                            class="inputField mb-2"
                            :class="{ errorInput: fields.firstName }"
                            @input="generateUsername(); validateInput()"
                        />

                        <v-text-field
                            v-model="lastName"
                            outlined
                            dense
                            hide-details
                            label="Nachname"
                            placeholder="Nachname eingeben"
                            class="inputField mb-2"
                            :class="{ errorInput: fields.lastName }"
                            @input="() => { generateUsername(); generateParentLoginId(); validateInput(); }"
                        />
                        <v-select
                            v-if="editObject"
                            v-model="editGroups"
                            outlined
                            dense
                            hide-details
                            multiple
                            :items="filteredGroups"
                            item-text="name"
                            :item-value="item => item._id"
                            label="Gruppen"
                            class="inputField mb-2"
                            return-object
                            @change="() => { searchGroupNames = ''}"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item
                                    class="px-2"
                                    @mousedown.prevent
                                >
                                    <v-text-field
                                        v-model="searchGroupNames"
                                        style="background-color: white;"
                                        solo
                                        dense
                                        flat
                                        placeholder="suchen"
                                        hide-details
                                    >
                                        <template v-slot:prepend-inner>
                                            <img
                                                :src="searchIcon"
                                                class="icon20"
                                                alt="suchen"
                                            >
                                        </template>
                                    </v-text-field>
                                </v-list-item>
                            </template>
                        </v-select>
                        <v-select
                            v-if="editObject"
                            v-model="pupilTeachers"
                            outlined
                            dense
                            readonly
                            hide-details
                            multiple
                            :items="teachers"
                            :item-text="item => item.name + ' ' + item.lastName"
                            :item-value="item => item._id"
                            label="Klassenlehrer"
                            class="inputField mb-2"
                        />
                    </div>

                    <v-textarea
                        v-model="note"
                        outlined
                        dense
                        hide-details
                        auto-grow
                        label="Notiz"
                        placeholder="Notiz eingeben"
                        class="inputField "
                    />
                    <div
                        v-if="editObject && editObject.accountLocked"
                        class="d-flex flex-column"
                    >
                        <div
                            class="d-flex flex-row flex-wrap justify-space-between align-center mt-4"
                        >
                            <v-badge
                                :value="editObject.accountLocked"
                                right
                                color="#ff0000"
                                content="1"
                            >
                                <b>Nutzer freischalten</b>
                            </v-badge>
                            <v-switch
                                v-model="resetLockedUser"
                                class=" ml-2 pa-0"
                                inset
                                hide-details
                                color="success"
                                @change="unlockUser"
                            />
                        </div>
                        <div class="d-flex flex-row justify-flex-start">
                            <span style="font-size: 14px; color:red">Schalter betätigen um gesperrten Nutzer freizuschalten</span>
                        </div>
                    </div>
                    <div
                        v-if="editObject"
                        style="width: 100%"
                        :class="!editObject.usageAgreement.isSigned ? 'my-4':''"
                    >
                        <div class="d-flex flex-row flex-wrap justify-space-between align-center pt-2">
                            <div class="d-flex align-center">
                                <span><b>Nutzungserklärung</b></span>
                            </div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        :style="editObject.usageAgreement.isSigned ? 'color: green':'color: red'"
                                        class="d-flex flex-row justify-space-around"
                                        style="cursor: pointer"
                                        v-on="on"
                                    >
                                        {{ editObject.usageAgreement.isSigned ? `zugestimmt`
                                            : (!editObject.usageAgreement.isSigned && editObject.usageAgreement.dateSigned) ? 'abgelehnt' : 'nicht zugestimmt' }}
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1 mt-1"
                                            alt="Erklärung"
                                        >
                                    </div>
                                </template>
                                <p style="max-width: 200px;">
                                    {{
                                        editObject.usageAgreement.isSigned ? `Zugestimmt am ${convertToDate(editObject.usageAgreement.dateSigned)}`
                                        : (!editObject.usageAgreement.isSigned && editObject.usageAgreement.dateSigned ) ? `Abgelehnt am ${convertToDate(editObject.usageAgreement.dateSigned)}`
                                            : 'Nutzungserklärung wurde nicht zugestimmt'
                                    }}
                                </p>
                            </v-tooltip>

                            <div
                                v-if="editObject.usageAgreement.isSigned"
                                class="d-flex flex-row justify-space-around mr-2 align-baseline"
                            >
                                <v-checkbox
                                    v-model="revokeUsageAgreement"
                                    class="mb-0 mt-0 pa-0 usersCheckbox"
                                    label="widerrufen"
                                    dense
                                />
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1 mt-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <p style="max-width: 200px;">
                                        Wenn ausgewählt, wird die Zustimmung zur Nutzungs- und Datenschutzerklärung widerrufen
                                        und muss vom Nutzer erneut bestätigt werden
                                    </p>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                    <!--#region Help -->
                    <div class="d-flex justify-space-between">
                        <p class="mb-1">
                            <b>Hilfen</b>
                        </p>
                        <v-spacer />
                        <p class="mb-1">
                            <b>aktivieren</b>
                        </p>
                    </div>
                    <div style="width: 100%">
                        <div
                            v-for="(accessibility, _id) in accessibilities"
                            :key="_id"
                            class="d-flex justify-space-between align-center mb-1"
                        >
                            <div class="d-flex align-center mb-1">
                                <span class="text-capitalize">{{ accessibility.text }}</span>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            v-if="accessibility.explanation"
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                    <!-- eslint-disable vue/no-v-html -->
                                    <p
                                        style="max-width: 250px"
                                        v-html="accessibility.explanation"
                                    />
                                </v-tooltip>
                            </div>

                            <v-switch
                                v-model="accessibility.value"
                                inset
                                hide-details
                                :disabled="accessibility.text === 'Vorlesen' && !accessibilities[0].value"
                                color="success"
                                class="mt-0"
                                @change="toggleA11y(accessibility)"
                            />
                        </div>
                        <!-- <div
                            class="d-flex justify-space-between align-center mb-1"
                        >
                            <div class="d-flex align-center mb-1">
                                <span class="text-capitalize">Google-Übersetzer</span>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <p
                                        style="max-width: 250px"
                                    >
                                        Manche Sprachen (beim Nutzer markiert mit Logo) erfordern die Verwendung des Google-Übersetzers. Sie können dies aus datenschutzrechtlichen Gründen hier ausstellen.
                                    </p>
                                </v-tooltip>
                            </div>

                            <v-switch
                                v-model="allowGoogleTranslate"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div> -->
                    </div>
                    <!--#endregion -->

                    <div v-if="showMorePupil">
                        <div class="d-flex justify-space-between align-center">
                            <p class="mb-1">
                                <b>Login</b>
                            </p>
                        </div>

                        <v-text-field
                            v-model="username"
                            outlined
                            dense
                            hide-details
                            label="Nutzername"
                            placeholder="Nutzername eingeben"
                            :disabled="editObjectRole === 'pupil'"
                            class="inputField mb-2"
                            :class="{ errorInput: fields.username }"
                            @input="() => {this.fields.usernameGenerated = false; validateInput()}"
                        />
                        <span
                            v-if="fields.usernameStrangeLetters"
                            style="color: var(--v-frot-base)"
                        >
                            Es werden nur die Buchstaben A-Z (ohne Umlaute) und Zahlen unterstützt.
                        </span>
                        <span
                            v-if="fields.usernameDuplicate"
                            style="color: var(--v-frot-base)"
                        >
                            Der Nutzername existiert bereits, passen Sie den Nutzernamen an.
                        </span>
                        <span
                            v-if="fields.usernameGenerated"
                            style="color: var(--v-forange-base)"
                        >
                            Der Nutzername existiert bereits, der Nutzername wurde automatisch angepasst.
                        </span>

                        <v-text-field
                            v-model="password"
                            outlined
                            dense
                            hide-details
                            label="Passwort"
                            placeholder="Passwort eingeben"
                            :type="showPwPupil ? 'text' : 'password'"
                            class="inputField mb-4"
                            :class="{ errorInput: fields.password }"
                            @input="validateInput"
                        >
                            <template v-slot:append>
                                <img
                                    tabindex="0"
                                    role="button"
                                    :alt="showPwPupil ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                                    :src="showPwPupil ? eyeIcon : eyeOffIcon"
                                    style="display: block; height: 25px; margin-top: -5px; color: #747474; margin-right: 5px"
                                    @click="showPwPupil = !showPwPupil"
                                >
                            </template>
                        </v-text-field>
                        <span
                            v-if="fields.password"
                            style="color: var(--v-frot-base)"
                        >
                            Mind. 10 Zeichen, bestehend aus Buchstaben, Zahlen und Sonderzeichen
                        </span>
                        <v-tooltip
                            top
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    style="display: flex; flex-direction: row; justify-content:space-between; width: fit-content mb-1"
                                    v-on="on"
                                >
                                    <span>QR-Anmeldung</span>
                                    <v-switch
                                        v-model="invertedQrLoginDeactivated"
                                        inset
                                        hide-details
                                        color="success"
                                        class="mt-0"
                                    />
                                </div>
                            </template>
                            <span>Aktivieren Sie dieses Feld, um die Qr-Anmeldung für diesen Nutzer zu aktivieren.</span>
                        </v-tooltip>
                        
                        <div class="d-flex flex-row align-center mr-2">
                            <v-tooltip
                                v-if="editObject"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        style="display: flex; flex-direction: row; width: fit-content"
                                        class="mr-2"
                                        v-on="on"
                                    >
                                        <v-checkbox
                                            v-model="regenerateQr"
                                            :disabled="regenerateQrDisabled"
                                            class="usersCheckbox"
                                        />
                                        <span>QR-Ausweis neu generieren</span>
                                    </div>
                                </template>
                                <span>Aktivieren Sie dieses Feld, um einen neuen QR-Ausweis für diese/n Nutzer/in zu erstellen.<br>
                                    Alte QR-Ausweise werden dadurch ungültig.</span>
                            </v-tooltip>
                            <v-tooltip
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        style="display: flex; flex-direction: row; width: fit-content"
                                        v-on="on"
                                    >
                                        <v-checkbox
                                            v-model="canChangePassword"
                                            class="usersCheckbox"
                                        />
                                        <span>Darf Passwort ändern</span>
                                    </div>
                                </template>
                                <span>Wenn ausgewählt, darf dieser Nutzer sein Passwort selbst ändern.</span>
                            </v-tooltip>
                        </div>
                    </div>

                    <v-btn
                        v-if="editObjectRole === 'pupil'"
                        dark
                        elevation="0"
                        :color="'gruen'"
                        class="text-none my-4"
                        style="width: 100%"
                        @click="regeneratePdf()"
                    >
                        Zugangsdaten neu generieren
                    </v-btn>
                    <!--#endregion -->

                    <!--#region Parent -->
                    <div
                        id="parentContent"
                        class="d-flex justify-space-between align-center"
                    >
                        <div
                            v-if="createType === 'pupil'"
                            class="d-flex justify-space-between align-center"
                            style="width: 100%"
                        >
                            <div class="d-flex justify-space-between align-center mb-1">
                                <p><b>Elternzugang von {{ firstName }}</b></p> <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <p
                                        style="max-width: 250px"
                                    >
                                        Um einen neuen Eltern-Zugang zu erstellen wählen Sie die leere Option.
                                    </p>
                                </v-tooltip>
                            </div>

                            <v-btn
                                v-if="!selectedParent"
                                text
                                :color="createNewParent ? 'rot' : 'gruen'"
                                class="text-none"
                                @click="()=>{createNewParent = !createNewParent; deactivateParent = !deactivateParent}"
                            >
                                {{ createNewParent ? 'entfernen' : 'hinzufügen' }}
                            </v-btn>
                        </div>
                        <p
                            v-else
                            class="mb-1"
                        >
                            <b>Elternzugang</b>
                        </p>

                        <v-btn
                            v-if="editObject && !selectedParent"
                            text
                            :color="deactivateParent ? 'gruen' : 'rot'"
                            class="text-none"
                            @click="clickDeactivate()"
                        >
                            {{ deactivateParent ? 'hinzufügen' : 'entfernen' }}
                        </v-btn>
                    </div>
                    <div v-if="!deactivateParent">
                        <v-select
                            v-if="(createType === 'pupil' && showMoreParent) || (!editObject.parent && showMoreParent)"
                            v-model="selectedParent"
                            :items="parentAccounts"
                            :item-value="item => item._id"
                            item-text="displayName"
                            placeholder="Andere Eltern zuweisen"
                            no-data-text="Keine Eltern vorhanden"
                            hide-details
                            outlined
                            dense
                            class="inputField"
                            :class="selectedParent ? '' : 'mb-4'"
                            :menu-props="{ closeOnContentClick: true, offsetY: true }"
                        >
                            <template v-slot:prepend-item>
                                <v-list-item @click="selectedParent = ''">
                                    <v-list-item-content>
                                        <v-list-item-title />
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>

                        <p
                            v-if="selectedParent"
                            class="text-center mt-1 mb-4"
                        >
                            Um einen neuen Eltern-Account zu erstellen wählen Sie die leere Option.
                        </p>

                        <v-textarea
                            v-if="!selectedParent"
                            v-model="noteParent"
                            :disabled="deactivateParent || (!editObject && !createNewParent)"
                            outlined
                            dense
                            hide-details
                            auto-grow
                            label="Kontaktdaten"
                            placeholder="Kontaktdaten eingeben"
                            class="inputField"
                        />
                        <b>Persönliche PIN</b>
                        <div class="d-flex justify-space-between align-center my-1 ml-2">
                            <div class="d-flex align-center mb-1 ">
                                <span>Anmeldung</span>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                    <!-- eslint-disable vue/no-v-html -->
                                    <p style="max-width: 250px">
                                        Aktiviert die Verwendung einer zusätzlichen PIN für die Anmeldung.
                                    </p>
                                </v-tooltip>
                            </div>

                            <v-switch
                                v-model="loginPinActivated"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                        <div
                            v-if="!messagesDeactivated"
                            class="d-flex justify-space-between align-center my-1 ml-2"
                        >
                            <div class="d-flex align-center mb-1 ">
                                <span>Elternbrief</span>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                    <!-- eslint-disable vue/no-v-html -->
                                    <p style="max-width: 250px">
                                        Aktiviert die Verwendung einer zusätzlichen PIN für den Elternbrief.
                                    </p>
                                </v-tooltip>
                            </div>

                            <v-switch
                                v-model="parentPinActivated"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                        <div
                            v-if="editObject && editObject.parentAccountLocked"
                            class="d-flex flex-row flex-wrap justify-space-between align-center mt-4"
                        >
                            <v-badge
                                :value="editObject.parentAccountLocked"
                                right
                                color="#ff0000"
                                content="1"
                            >
                                <div class="d-flex flex-row  justify-space-around align-center ">
                                    <b>Gesperrte Eltern freischalten</b>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                :src="infoIcon"
                                                class="icon20 pl-1"
                                                alt="Erklärung"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                        </template>
                                        <span>Nutzer kann sich nach Freischalten bis zu 5 mal mit den ursprünglichen Anmeldedaten anmelden. Falls er diese nicht mehr hat, können Sie diesem neue Anmeldedaten generieren und zusenden.</span>
                                    </v-tooltip>
                                </div>
                            </v-badge>
                            <v-switch
                                v-model="resetLockedParent"
                                class=" ml-2 pa-0"
                                inset
                                hide-details
                                color="success"
                                @change="unlockParent"
                            />
                        </div>
                        <div
                            v-if="editObject && editObject.parentAccountHasPin"
                        >
                            <div
                                class="d-flex flex-row flex-wrap justify-space-between align-center mt-4"
                            >
                                <v-badge
                                    :value="editObject.parentPinLocked"
                                    right
                                    color="#ff0000"
                                    content="1"
                                >
                                    <b>PIN zurücksetzen</b>
                                </v-badge>
                                <v-switch
                                    v-model="resetParentPin"
                                    class="ml-2 pa-0"
                                    inset
                                    hide-details
                                    color="success"
                                    @change="confirmResetPin"
                                />
                            </div>
                            <div class="d-flex flex-row justify-end mt-1 ">
                                <p style="font-size:10px; color: red">
                                    Bitte stellen Sie sicher, dass diese Anfrage zur Zurücksetzung der PIN tatsächlich vom Nutzer stammt(telefonisch/per Mail), um Missverständise zu vermeiden.
                                </p>
                            </div>
                        </div>

                        <div v-if="showMoreParent && !selectedParent">
                            <div class="d-flex justify-start align-center my-1">
                                <p><b>Anmeldedaten</b></p>
                                <v-tooltip
                                    v-if="editObjectRole === 'pupil'"
                                    top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                    <!-- eslint-disable vue/no-v-html -->
                                    <p style="max-width: 250px">
                                        {{ `Das Passwort wurde ${editObject.passwordAlteredUser}x vom Nutzer und ${editObject.passwordAlteredMaintainer}x von der Verwaltung geändert.` }}
                                    </p>
                                </v-tooltip>
                            </div>

                            <v-text-field
                                v-model="usernameParent"
                                outlined
                                dense
                                hide-details
                                label="Nutzername"
                                placeholder="Nutzername eingeben"
                                class="inputField mb-2"
                                :class="{ errorInput: fields.parentLoginId }"
                                @input="() => {this.fields.parentLoginIdGenerated = false; validateInput()}"
                            />
                            <span
                                v-if="fields.parentLoginIdStrangeLetters"
                                style="color: var(--v-frot-base)"
                            >
                                Es werden nur die Buchstaben A-Z (ohne Umlaute) und Zahlen unterstützt.
                            </span>
                            <span
                                v-if="fields.parentLoginIdDuplicate"
                                style="color: var(--v-frot-base)"
                            >
                                Der Nutzername existiert bereits, passen Sie den Nutzernamen an.
                            </span>
                            <span
                                v-if="fields.parentLoginIdGenerated"
                                style="color: var(--v-forange-base)"
                            >
                                Der Nutzername existiert bereits, der Nutzername wurde automatisch angepasst.
                            </span>

                            <v-text-field
                                v-model="passwordParent"
                                :disabled="deactivateParent || (!editObject && !createNewParent)"
                                outlined
                                dense
                                hide-details
                                label="Passwort"
                                placeholder="Passwort eingeben"
                                class="inputField mb-2"
                                :type="showPwParent ? 'text' : 'password'"
                                :class="{ errorInput: fields.parentPassword }"
                                @input="validateInput"
                            >
                                <template v-slot:append>
                                    <img
                                        tabindex="0"
                                        role="button"
                                        :alt="showPwParent ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                                        :src="showPwParent ? eyeIcon : eyeOffIcon"
                                        style="display: block; height: 25px; margin-top: -5px; color: #747474; margin-right: 5px"
                                        @click="showPwParent = !showPwParent"
                                    >
                                </template>
                            </v-text-field>
                            <span
                                v-if="fields.parentPassword"
                                style="color: var(--v-frot-base)"
                            >Mind. 10 Zeichen, bestehend aus Buchstaben, Zahlen und Sonderzeichen</span>
                            <v-tooltip
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        style="display: flex; flex-direction: row; justify-content:space-between; width: fit-content mb-1"
                                        v-on="on"
                                    >
                                        <span>QR-Anmeldung</span>
                                        <v-switch
                                            v-model="invertedParentQrLoginDeactivated"
                                            inset
                                            hide-details
                                            color="success"
                                            class="mt-0"
                                        />
                                    </div>
                                </template>
                                <span>Aktivieren Sie dieses Feld, um die Qr-Anmeldung für diesen Nutzer zu aktivieren.</span>
                            </v-tooltip>
                            <div v-if="!parentQrLoginDeactivated">
                                <span
                                    style="color: var(--v-frot-base); font-size: 14px;"
                                >
                                    Bei der QR-Code Anmeldung ist ein weiterer Faktor zur Authentifizierung zu verwenden.
                                </span>
                            </div>
                            <div
                                v-if="editObject"
                                style="width: 100%"
                                :class="!parentUsageAgreement.isSigned ? 'my-4':''"
                            >
                                <div class="d-flex justify-space-between align-center pt-2">
                                    <div class="d-flex align-center ">
                                        <span><b>Nutzungserklärung</b> </span>
                                    </div>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div
                                                v-bind="attrs"
                                                :style="parentUsageAgreement.isSigned ? 'color: green':'color: red'"
                                                class="d-flex flex-row justify-space-around"
                                                style="cursor: pointer"
                                                v-on="on"
                                            >
                                                {{ parentUsageAgreement.isSigned ? `zugestimmt`
                                                    : (!parentUsageAgreement.isSigned && parentUsageAgreement.dateSigned) ? 'abgelehnt' : 'nicht zugestimmt' }}
                                                <img
                                                    :src="questionMarkIcon"
                                                    class="icon20 pl-1 mt-1"
                                                    alt="Erklärung"
                                                >
                                            </div>
                                        </template>
                                        <p style="max-width: 200px;">
                                            {{
                                                parentUsageAgreement.isSigned ? `Zugestimmt am ${convertToDate(parentUsageAgreement.dateSigned)}`
                                                : (!parentUsageAgreement.isSigned && parentUsageAgreement.dateSigned ) ? `Abgelehnt am ${convertToDate(parentUsageAgreement.dateSigned)}` : 'Nutzungserklärung wurde nicht zugestimmt'
                                            }}
                                        </p>
                                    </v-tooltip>
                                    <div
                                        v-if="parentUsageAgreement.isSigned"
                                        class="d-flex flex-row justify-space-around mr-2 align-baseline "
                                    >
                                        <v-checkbox
                                            v-model="revokeParentAgreement"
                                            class="mb-0 mt-0 pa-0 usersCheckbox"
                                            label="widerrufen"
                                            dense
                                        />
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <img
                                                    :src="questionMarkIcon"
                                                    class="icon20 pl-1 mt-1"
                                                    alt="Erklärung"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                            </template>
                                            <p style="max-width: 200px;">
                                                Wenn ausgewählt, wird die Zustimmung zur Nutzungs- und Datenschutzerklärung widerrufen
                                                und muss vom Nutzer erneut bestätigt werden
                                            </p>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </div>
                            <v-tooltip
                                v-if="!deactivateParent && editObject && !createNewParent"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        style="display: flex; flex-direction: row; width: fit-content"
                                        v-on="on"
                                    >
                                        <v-checkbox
                                            v-model="regenerateQrParent"
                                            :disabled="regenerateQrDisabledParent"
                                            class="usersCheckbox"
                                        />
                                        <span>QR-Ausweis neu generieren</span>
                                    </div>
                                </template>
                                <span>Aktivieren Sie dieses Feld, um einen neuen QR-Ausweis für diese/n NutzerIn zu erstellen.<br>
                                    Alte QR-Ausweise werden dadurch ungültig.</span>
                            </v-tooltip>
                        </div>
                        <div
                            v-if="createType !== 'pupil'"
                            class="d-flex justify-space-between"
                        >
                            <v-spacer />
                            <v-btn
                                text
                                class="text-none"
                                @click="showMoreParent = !showMoreParent; scrollToParent()"
                            >
                                <img
                                    :src="downIcon"
                                    class="icon10 IconToDunkelgrau"
                                    :style="showMoreParent ? 'transform: rotate(180deg)':''"
                                >
                                {{ showMoreParent ? 'Weniger Daten anzeigen' : 'Weitere Daten anzeigen' }}
                            </v-btn>
                        </div>
                    </div>
                    <v-btn
                        v-if="editObjectRole === 'pupil' && pupilHasParent && !deactivateParent"
                        dark
                        elevation="0"
                        :color="'gruen'"
                        class="text-none my-4"
                        style="width: 100%"
                        @click="regeneratePdf(true)"
                    >
                        Zugangsdaten neu generieren
                    </v-btn>
                    <v-btn
                        v-if="editObjectRole === 'pupil' && pupilHasParent && !deactivateParent"
                        dark
                        elevation="0"
                        color="gruen"
                        class="text-none mb-4"
                        style="width: 100%"
                        @click="clickChangeParent"
                    >
                        Eltern zuweisen
                    </v-btn>
                    <v-btn
                        v-if="editObjectRole === 'pupil' && pupilHasParent && currentParentPupils && currentParentPupils > 1 && !deactivateParent"
                        dark
                        elevation="0"
                        color="warning"
                        class="text-none mb-4"
                        style="width: 100%"
                        @click="removeParent"
                    >
                        Eltern entfernen
                    </v-btn>
                    <v-btn
                        v-if="editObjectRole === 'pupil' && pupilHasParent && !deactivateParent"
                        dark
                        elevation="0"
                        color="rot"
                        class="text-none mb-4"
                        style="width: 100%"
                        @click="clickDelete"
                    >
                        Eltern löschen
                    </v-btn>
                    <!--#endregion -->
                </div>
                <!--#endregion-->

                <!--#region Create/Edit teacher -->
                <div
                    v-else-if="(editObject && editObjectRole === 'teacher') || createType === 'teacher'"
                    class="mx-2 mt-2"
                >
                    <p class="mb-1">
                        <b>Lehrerdaten</b>
                    </p>

                    <v-text-field
                        v-model="firstName"
                        outlined
                        dense
                        hide-details
                        label="Vorname"
                        placeholder="Vorname eingeben"
                        class="inputField mb-2"
                        :class="{ errorInput: fields.firstName }"
                        @input="() => { generateUsername(true); validateInput(); }"
                    />

                    <v-text-field
                        v-model="lastName"
                        outlined
                        dense
                        hide-details
                        label="Nachname"
                        placeholder="Nachname eingeben"
                        class="inputField mb-2"
                        :class="{ errorInput: fields.lastName }"
                        @input="() => { generateUsername(true); validateInput(); }"
                    />

                    <v-textarea
                        v-model="note"
                        outlined
                        dense
                        hide-details
                        auto-grow
                        label="Notiz"
                        placeholder="Notiz eingeben"
                        class="inputField"
                    />
                    <div
                        v-if="editObject && editObject.accountLocked"
                        class="d-flex flex-column"
                    >
                        <div
                            class="d-flex flex-row flex-wrap justify-space-between align-center mt-4"
                        >
                            <v-badge
                                :value="editObject.accountLocked"
                                right
                                color="#ff0000"
                                content="1"
                            >
                                <div class="d-flex flex-row justify-space-around align-center">
                                    <b>Nutzer freischalten</b>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                :src="infoIcon"
                                                class="icon20 pl-1"
                                                alt="Erklärung"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                        </template>
                                        <span>Nutzer kann sich nach Freischalten bis zu 5 mal mit den ursprünglichen Anmeldedaten anmelden. Falls er diese nicht mehr hat, können Sie diesem neue Anmeldedaten generieren und zusenden.</span>
                                    </v-tooltip>
                                </div>
                            </v-badge>
                            <v-switch
                                v-model="resetLockedUser"
                                class=" ml-2 pa-0"
                                inset
                                hide-details
                                color="success"
                                @change="unlockUser"
                            />
                        </div>
                        <div class="d-flex flex-row justify-flex-start">
                            <span style="font-size: 14px; color:red">Schalter betätigen um gesperrten Nutzer freizuschalten</span>
                        </div>
                    </div>
                    <div
                        v-if="editObject && editObject.accountHasPin"
                    >
                        <div
                            class="d-flex flex-row flex-wrap justify-space-between align-center mt-4"
                        >
                            <v-badge
                                :value="editObject.parentPinLocked"
                                right
                                color="#ff0000"
                                content="1"
                            >      
                                <b>PIN zurücksetzen</b>
                            </v-badge>
                            <v-switch
                                v-model="resetPin"
                                class="ml-2 pa-0"
                                inset
                                hide-details
                                color="success"
                                @change="confirmResetPin"
                            />
                        </div>
                        <div class="d-flex flex-row justify-end mt-1 ">
                            <p style="font-size:10px; color: red">
                                Bitte stellen Sie sicher, dass diese Anfrage zur Zurücksetzung der PIN tatsächlich vom Nutzer stammt(telefonisch/per Mail), um Missverständise zu vermeiden.
                            </p>
                        </div>
                    </div>
                    <div
                        v-if="editObject"
                        style="width: 100%"
                        :class="editObject.isSigned ? 'my-4' : ''"
                    >
                        <div class="d-flex flex-row flex-wrap justify-space-between align-center pt-2">
                            <div class="d-flex align-center ">
                                <span><b>Nutzungserklärung</b></span>
                            </div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        :style="editObject.usageAgreement.isSigned ? 'color: green':'color: red'"
                                        class="d-flex flex-row justify-space-around"
                                        style="cursor: pointer"
                                        v-on="on"
                                    >
                                        {{ editObject.usageAgreement.isSigned ? `zugestimmt`
                                            : (!editObject.usageAgreement.isSigned && editObject.usageAgreement.dateSigned) ? 'abgelehnt' : 'nicht zugestimmt' }}
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1 mt-1"
                                            alt="Erklärung"
                                        >
                                    </div>
                                </template>
                                <p style="max-width: 200px;">
                                    {{
                                        editObject.usageAgreement.isSigned ? `Zugestimmt am ${convertToDate(editObject.usageAgreement.dateSigned)}`
                                        : (!editObject.usageAgreement.isSigned && editObject.usageAgreement.dateSigned ) ? `Abgelehnt am ${convertToDate(editObject.usageAgreement.dateSigned)}` : 'Nutzungserklärung wurde nicht zugestimmt'
                                    }}
                                </p>
                            </v-tooltip>

                            <div
                                v-if="editObject.usageAgreement.isSigned"
                                class="d-flex flex-row justify-space-around mr-2 align-baseline"
                            >
                                <v-checkbox
                                    v-model="revokeUsageAgreement"
                                    class="mb-0 mt-0 pa-0 usersCheckbox"
                                    label="widerrufen"
                                    dense
                                />
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1 mt-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <p style="max-width: 200px;">
                                        Wenn ausgewählt, wird die Zustimmung zur Nutzungs- und Datenschutzerklärung widerrufen
                                        und muss vom Nutzer erneut bestätigt werden
                                    </p>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-start align-center mb-1">
                        <p><b>Login</b></p>
                        <v-tooltip
                            v-if="editObjectRole === 'teacher'"
                            top
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <img
                                    :src="questionMarkIcon"
                                    class="icon20 pl-1"
                                    alt="Erklärung"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                            </template>
                            <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                            <!-- eslint-disable vue/no-v-html -->
                            <p style="max-width: 250px">
                                {{ `Das Passwort wurde ${editObject.passwordAlteredUser}x vom Nutzer und ${editObject.passwordAlteredMaintainer}x von der Verwaltung geändert.` }}
                            </p>
                        </v-tooltip>
                    </div>

                    <v-text-field
                        v-model="username"
                        outlined
                        dense
                        hide-details
                        label="Nutzername"
                        placeholder="Nutzername eingeben"
                        :disabled="editObjectRole === 'teacher'"
                        class="inputField mb-2"
                        :class="{ errorInput: fields.username }"
                        @input="() => {this.fields.usernameGenerated = false; validateInput()}"
                    />
                    <span
                        v-if="fields.usernameStrangeLetters"
                        style="color: var(--v-frot-base)"
                    >
                        Es werden nur die Buchstaben A-Z (ohne Umlaute) und Zahlen unterstüzt.
                    </span>
                    <span
                        v-if="fields.usernameDuplicate"
                        style="color: var(--v-frot-base)"
                    >
                        Der Nutzername existiert bereits, passen Sie den Nutzernamen an.
                    </span>
                    <span
                        v-if="fields.usernameGenerated"
                        style="color: var(--v-forange-base)"
                    >
                        Der Nutzername existiert bereits, der Nutzername wurde automatisch angepasst.
                    </span>

                    <v-text-field
                        v-model="password"
                        outlined
                        dense
                        hide-details
                        label="Passwort"
                        placeholder="Passwort eingeben"
                        class="inputField mb-4"
                        :type="showPwTeacher ? 'text' : 'password'"
                        :class="{ errorInput: fields.password }"
                        @input="validateInput"
                    >
                        <template v-slot:append>
                            <img
                                tabindex="0"
                                role="button"
                                :alt="showPwTeacher ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                                :src="showPwTeacher ? eyeIcon : eyeOffIcon"
                                style="display: block; height: 25px; margin-top: -5px; color: #747474; margin-right: 5px"
                                @click="showPwTeacher = !showPwTeacher"
                            >
                        </template>
                    </v-text-field>

                    <span
                        v-if="fields.password"
                        style="color: var(--v-frot-base)"
                    >
                        Mind. 10 Zeichen, bestehend aus Buchstaben, Zahlen und Sonderzeichen
                    </span>

                    <v-tooltip
                        v-if="editObject"
                        top
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                v-bind="attrs"
                                style="display: flex; flex-direction: row; width: fit-content"
                                v-on="on"
                            >
                                <v-checkbox
                                    v-model="regenerateQr"
                                    :disabled="regenerateQrDisabled"
                                    class="usersCheckbox"
                                />
                                <span>QR-Ausweis neu generieren</span>
                            </div>
                        </template>
                        <span>Aktivieren Sie dieses Feld, um einen neuen QR-Ausweis für diese/n NutzerIn zu erstellen.<br>
                            Alte QR-Ausweise werden dadurch ungültig.</span>
                    </v-tooltip>

                    <!--div class="d-flex justify-space-between align-center">
                        <p class="mb-1">
                            <b>Optionen</b>
                        </p>
                    </div>

                    <div style="width: 100%">
                        <div class="d-flex justify-space-between align-center mb-1">
                            <div class="d-flex align-center mb-1">
                                <span class="text-capitalize">Einfacher Editor-Modus</span>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template-->
                    <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                    <!-- eslint-disable vue/no-v-html -->
                    <!--p style="max-width: 250px">
                                        Eine vereinfachte Ansicht für den Editor
                                    </p>
                                </v-tooltip>
                            </div>

                            <v-switch
                                v-model="simpleEditor"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                    </div-->

                    <div class="d-flex justify-space-between align-center">
                        <p class="mb-1">
                            <b>Schulisches</b>
                        </p>
                    </div>
                    <v-tooltip
                        top
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                v-bind="attrs"
                                style="display: flex; flex-direction: row; justify-content:space-between; width: fit-content mb-1"
                                v-on="on"
                            >
                                <span>QR-Anmeldung</span>
                                <v-switch
                                    v-model="invertedQrLoginDeactivated"
                                    inset
                                    hide-details
                                    color="success"
                                    class="mt-0"
                                />
                            </div>
                        </template>
                        <span>Aktivieren Sie dieses Feld, um die Qr-Anmeldung für diesen Nutzer zu aktivieren.</span>
                    </v-tooltip>
                    <div v-if="!qrLoginDeactivated">
                        <span
                            style="color: var(--v-frot-base); font-size: 14px;"
                        >
                            Bei der QR-Code Anmeldung ist ein weiterer Faktor zur Authentifizierung zu verwenden.
                        </span>
                    </div>
                    <div style="width: 100%">
                        <div class="d-flex justify-space-between align-center mb-1">
                            <div class="d-flex align-center mb-1">
                                <span>Persönliche PIN </span>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                    <!-- eslint-disable vue/no-v-html -->
                                    <p style="max-width: 250px">
                                        Aktiviert die Verwendung einer zusätzlichen PIN für die Anmeldung, das Klassenbuch und/oder den Elternbrief.
                                    </p>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%">
                        <div class="d-flex justify-space-between align-center mb-1 ml-3">
                            <div class="d-flex align-center mb-1">
                                <span>Anmeldung</span>
                            </div>

                            <v-switch
                                v-model="loginPinActivated"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                    </div>
                    <!-- <div
                        v-if="!diaryDeactivated"
                        style="width: 100%"
                    >
                        <div class="d-flex justify-space-between align-center mb-1 ml-3">
                            <div class="d-flex align-center mb-1">
                                <span>Klassenbuch </span>
                            </div>

                            <v-switch
                                v-model="pinActivated"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                    </div> -->
                    <div
                        v-if="!messagesDeactivated"
                        style="width: 100%"
                    >
                        <div class="d-flex justify-space-between align-center mb-1 ml-3">
                            <div class="d-flex align-center mb-1">
                                <span>Elternbrief</span>
                            </div>

                            <v-switch
                                v-model="messagesPinActivated"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                    </div>
                    <div style="width: 100%">
                        <div class="d-flex justify-space-between align-center mb-1">
                            <div class="d-flex align-center mb-1">
                                <span>Vertretung deaktivieren</span>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                    <!-- eslint-disable vue/no-v-html -->
                                    <p style="max-width: 250px">
                                        Wenn ausgewählt, wird die Lehrkraft für den Vertretungsplan nicht in Betracht gezogen.
                                    </p>
                                </v-tooltip>
                            </div>

                            <v-switch
                                v-model="substitutionBlocked"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                    </div>
                    <div style="width: 100%">
                        <div class="d-flex justify-space-between align-center mb-1">
                            <div class="d-flex align-center mb-1">
                                <span>Stundenplanzeiten bearbeiten</span>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                    <!-- eslint-disable vue/no-v-html -->
                                    <p style="max-width: 250px">
                                        Nur wenn ausgewählt darf Gruppenleiter die Zeiten(Zeitslots) im Stundenplan eigenständig bearbeiten.
                                    </p>
                                </v-tooltip>
                            </div>

                            <v-switch
                                v-model="canEditTimeslot"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                    </div>
                    <div style="width: 100%">
                        <div class="d-flex justify-space-between align-center mb-1">
                            <div class="d-flex align-center mb-1">
                                <span>Verleih-App verwalten</span>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkIcon"
                                            class="icon20 pl-1"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                                    <!-- eslint-disable vue/no-v-html -->
                                    <p style="max-width: 250px">
                                        Nur wenn ausgewählt darf der Lehrer in der Verleih-App Qr-Codes für Geräte erstellen und löschen.
                                    </p>
                                </v-tooltip>
                            </div>

                            <v-switch
                                v-model="canEditQrCodes"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                    </div>

                    <v-text-field
                        v-model="hoursPerWeek"
                        label="Stunden pro Woche"
                        placeholder="Anzahl der Stunden"
                        dense
                        outlined
                        hide-details
                        class="inputField mb-2"
                    />
                    <v-select
                        v-model="subjectsTeacher"
                        :items="filteredSubjects"
                        :item-value="item => item._id"
                        item-text="name"
                        :menu-props="{ offsetY: true }"
                        label="Fächer"
                        placeholder="Fächer auswählen"
                        no-data-text="Keine Fächer vorhanden"
                        class="inputField mb-2"
                        hide-details
                        dense
                        outlined
                        multiple
                        return-object
                        @change="()=>{ searchSubjects= '';}"
                    >
                        <template v-slot:prepend-item>
                            <v-list-item
                                class="px-2"
                                @mousedown.prevent
                            >
                                <v-text-field
                                    v-model="searchSubjects"
                                    style="background-color: white;"
                                    solo
                                    dense
                                    flat
                                    placeholder="suchen"
                                    hide-details
                                >
                                    <template v-slot:prepend-inner>
                                        <img
                                            :src="searchIcon"
                                            class="icon20"
                                            alt="suchen"
                                        >
                                    </template>
                                </v-text-field>
                            </v-list-item>
                            <v-list-item
                                hide-details
                                @click="createSubjectDialog = true"
                            >
                                <v-list-item-action>
                                    <v-icon>
                                        mdi-plus-circle
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Neues Fach
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>
                    </v-select>

                    <v-select
                        v-model="groupsTeacher"
                        :items="groupsWithPlaceholder.length > 0 ? groupsWithPlaceholder : groups "
                        :item-value="item => item._id"
                        item-text="name"
                        :menu-props="{ offsetY: true }"
                        label="Gruppen-,Klassenleiter"
                        placeholder="Gruppen auswählen"
                        no-data-text="Keine Gruppen vorhanden"
                        hide-details
                        dense
                        outlined
                        multiple
                        return-object
                        class="inputField"
                        :class="{ errorInput: fields.groupsTeacher }"
                        @change="validateInput()"
                    >
                        <template
                            v-slot:prepend-item
                        >
                            <v-list-item
                                hide-details
                                @click="fillCreateGroupDialog"
                            >
                                <v-list-item-action>
                                    <v-icon>
                                        mdi-plus-circle
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Neue Gruppe
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>
                    </v-select>
                    <span
                        v-if="groupsTeacher.length > 3"
                        style="color: var(--v-frot-base)"
                    >
                        Sie können maximal 3 Klassen als Klassenlehrer zugewiesen sein! Um Fachlehrer in der Klasse zu sein, müssen Sie lediglich im Stundenplan der Klasse eingetragen sein.
                    </span>
                    <span
                        v-if="groupsTeacher.length < 1"
                        style="color: var(--v-forange-base); font-size: 12px;"
                    >
                        Falls keiner Gruppe/ Klasse zugeordnet, handelt es sich um einen Fachlehrer mit eingeschränkten Rechten (Klassenbuch, Chat, …).
                    </span>
                    <v-select
                        v-model="locationsTeacher"
                        :items="filteredLocations"
                        :item-value="item => item._id"
                        item-text="name"
                        :menu-props="{ offsetY: true }"
                        label="Standort"
                        placeholder="Standort auswählen"
                        no-data-text="Keine Standorte vorhanden"
                        class="inputField mt-2"
                        hide-details
                        dense
                        outlined
                        multiple
                        return-object
                        @change="()=>{ searchLocations= '';}"
                    >
                        <template v-slot:prepend-item>
                            <v-list-item
                                class="px-2"
                                @mousedown.prevent
                            >
                                <v-text-field
                                    v-model="searchLocations"
                                    style="background-color: white;"
                                    solo
                                    dense
                                    flat
                                    placeholder="suchen"
                                    hide-details
                                >
                                    <template v-slot:prepend-inner>
                                        <img
                                            :src="searchIcon"
                                            class="icon20"
                                            alt="suchen"
                                        >
                                    </template>
                                </v-text-field>
                            </v-list-item>
                            <v-list-item
                                hide-details
                                @click="createLocationDialog = true"
                            >
                                <v-list-item-action>
                                    <v-icon>
                                        mdi-plus-circle
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Neuer Standort
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>
                    </v-select>
                </div>
                <!--#endregion-->

                <!--#region Create/Edit group -->
                <div
                    v-else-if="(editObject && editObjectRole === 'group') || createType === 'group'"
                    class="ma-2"
                >
                    <p class="mb-1">
                        <b>Gruppendaten</b>
                    </p>

                    <!-- groupName has to be unique
                    and can't be changed later as matrix room name isn't changed in backend -->
                    <v-text-field
                        v-model="groupName"
                        outlined
                        dense
                        hide-details
                        :disabled="editObjectRole === 'group'"
                        label="Gruppen-, Klassenname"
                        placeholder="Gruppenname eingeben"
                        class="inputField mb-2"
                        :class="{ errorInput: fields.groupName || fields.groupNameDuplicate }"
                        @change="validateInputGroup()"
                    />
                    <span
                        v-if="fields.groupName"
                        style="color: var(--v-frot-base)"
                    >
                        Geben Sie einen Gruppenname an
                    </span>
                    <span
                        v-else-if="fields.groupNameDuplicate"
                        style="color: var(--v-frot-base)"
                    >
                        Dieser Gruppenname wird bereits verwendet
                    </span>

                    <v-select
                        v-model="groupLeaders"
                        :items="filteredLeaders"
                        :item-text="item => item.name + ' ' + item.lastName"
                        :item-value="item => item._id"
                        return-object
                        outlined
                        dense
                        hide-details
                        multiple
                        label="Gruppen-,Klassenleiter"
                        :menu-props="{
                            maxHeight: '50vh',
                            allowOverflow: true,
                            overflowY: true,
                        }"
                        placeholder="Gruppenleiter auswählen/suchen"
                        no-data-text="Keine Lehrer vorhanden"
                        class="inputField mb-2"
                        :class="{ errorInput: fields.groupLeaders }"
                        @change="() => { validateInputGroup(); setGroupLeaders(groupLeaders); searchLeaders = '' }"
                    >
                        <template v-slot:prepend-item>
                            <v-list-item
                                class="px-2"
                                @mousedown.prevent
                            >
                                <v-text-field
                                    v-model="searchLeaders"
                                    style="background-color: white;"
                                    solo
                                    dense
                                    flat
                                    placeholder="suchen"
                                    hide-details
                                >
                                    <template v-slot:prepend-inner>
                                        <img
                                            :src="searchIcon"
                                            class="icon20"
                                            alt="suchen"
                                        >
                                    </template>
                                </v-text-field>
                            </v-list-item>
                            <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="createTeacherDialog = true"
                            >
                                <v-list-item-action>
                                    <v-icon>
                                        mdi-plus-circle
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Neuer Lehrer erstellen
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="toggle(true)"
                            >
                                <v-list-item-action>
                                    <v-icon>{{ iconLeader }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ selectedAllLeaders ? 'Alle abwählen' : 'Alle auswählen' }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>
                    </v-select>
                    <span
                        v-if="fields.groupLeaders"
                        style="color: var(--v-frot-base)"
                    >
                        Wählen Sie mindestens einen Gruppenleiter aus
                    </span>
                    <!-- Todo compute full possible height for v-selects -->
                    <v-select
                        v-model="groupMembers"
                        :items="filteredMembers"
                        :item-text="item => item.name + ' ' + item.lastName"
                        :item-value="item => item._id"
                        return-object
                        outlined
                        dense
                        hide-details
                        multiple
                        :menu-props="{
                            maxHeight: '50vh',
                            allowOverflow: true,
                            overflowY: true,
                        }"
                        label="Schüler"
                        placeholder="Schüler auswählen/suchen"
                        no-data-text="Keine Schüler vorhanden"
                        class="inputField mb-2"
                        @change="() => { validateInputGroup(); setGroupMembers(groupMembers); searchMembers = ''}"
                    >
                        <template v-slot:prepend-item>
                            <v-list-item
                                class="px-2"
                                @mousedown.prevent
                            >
                                <v-text-field
                                    v-model="searchMembers"
                                    style="background-color: white; "
                                    solo
                                    dense
                                    flat
                                    placeholder="suchen"
                                    hide-details
                                >
                                    <template v-slot:prepend-inner>
                                        <img
                                            :src="searchIcon"
                                            class="icon20"
                                            alt="suchen"
                                        >
                                    </template>
                                </v-text-field>
                            </v-list-item>
                            <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="createPupilDialog = true"
                            >
                                <v-list-item-action>
                                    <v-icon>
                                        mdi-plus-circle
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Neuer Schüler erstellen
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="toggle()"
                            >
                                <v-list-item-action>
                                    <v-icon>{{ iconMember }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ selectedAllMembers ? 'Alle abwählen' : 'Alle auswählen' }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>
                    </v-select>
                </div>
                <!--#endregion -->

                <!--#region List of groups -->
                <div v-else-if="showGroups">
                    <v-expansion-panels
                        v-model="expandedPanels"
                        accordion
                    >
                        <v-expansion-panel
                            v-for="(group, _id) in filteredGroups"
                            :key="_id"
                        >
                            <v-expansion-panel-header
                                id="groupPanel"
                            >
                                <div class="d-flex align-center justify-space-between">
                                    <div class="d-flex align-center">
                                        <v-checkbox
                                            hide-details
                                            @click.native.stop="handleCheckbox(group._id)"
                                        />
                                        <p class="ml-7">
                                            {{ group.name }}
                                        </p>
                                    </div>

                                    <v-btn
                                        x-small
                                        icon
                                        class="optionBtn"
                                        @click.native.stop="setEditGroup(group)"
                                    >
                                        <img
                                            :src="editIcon"
                                            class="icon20"
                                            alt="bearbeiten"
                                        >
                                    </v-btn>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-data-table
                                    v-model="selected"
                                    :headers="groupsHeaders"
                                    :items="getAllUsers(group)"
                                    item-key="selectKey"
                                    sort-by="name"
                                    :mobile-breakpoint="0"
                                    disable-pagination
                                    hide-default-footer
                                    fixed-header
                                    locale="de-DE"
                                    no-data-text="Keine Gruppenmitglieder verfügbar"
                                    no-results-text="Keinen passenden Nutzer gefunden"
                                    style="cursor:pointer; height: 100%;"
                                    class="pl-6"
                                    @click:row="editRow"
                                >
                                    <template v-slot:[`item.name`]="{ item }">
                                        <div class="d-flex justify-flex-start align-center">
                                            <Avatar
                                                :profile="item.account"
                                                :size="30"
                                                :alt="'Profil öffnen'"
                                                class="mr-2"
                                            />

                                            {{ item.name + ' ' + item.lastName }}
                                        </div>
                                    </template>
                                    <template v-slot:[`item.lastName`] />
                                    <template v-slot:[`item.accountRole`]="{ item }">
                                        <span v-if="item && item.accountRole === 'pupil'">Schüler</span>
                                        <span
                                            v-else-if="item && item.accountRole === 'teacher'"
                                            style="font-weight: bold"
                                        >Lehrer (KL)</span>
                                    </template>
                                </v-data-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                <!--#endregion -->

                <!--#region Table for teacher -->
                <div
                    v-else-if="showTeacher"
                    style="height: 100%;"
                >
                    <v-data-table
                        v-model="selected"
                        :headers="windowWidth < 900 ? teacherHeadersMobile : teacherHeaders"
                        :header-props="headerProps"
                        :items="filteredLeaders"
                        :search="search"
                        item-key="_id"
                        sort-by="name"
                        :mobile-breakpoint="0"
                        disable-pagination
                        hide-default-footer
                        fixed-header
                        show-select
                        locale="de-DE"
                        no-data-text="Keine Daten verfügbar"
                        no-results-text="Keinen passenden Nutzer gefunden"
                        style="cursor:pointer; height: 100%;"
                        height="100%;"
                        @click:row="editRow"
                    >
                        <template v-slot:[`item.name`]="{ item }">
                            <div
                                class="d-flex flex-row align-center"
                            >
                                <div
                                    class="d-flex justify-flex-start align-center"
                                    :style="item.accountLocked || item.pinLocked ? 'color:red': ''"
                                >
                                    <Avatar
                                        :profile="item.account"
                                        :size="30"
                                        :alt="'Profil öffnen'"
                                        class="mr-2"
                                    />

                                    {{ item.account ? item.name + ' ' + item.lastName : item.name }}
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`header.usageAgreement`]>
                            <v-tooltip
                                top
                            >
                                <template v-slot:activator="{ on }">
                                    <img

                                        :src="lehrerIcon"
                                        class="icon20"
                                        v-on="on"
                                    >
                                </template>
                                <span>Datenschutz</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.usageAgreement`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div
                                        v-on="on"
                                    >
                                        <v-icon
                                            :color="item.usageAgreement.isSigned ? 'success':'error'"
                                        >
                                            {{ item.usageAgreement.isSigned ? 'mdi-check' : 'mdi-close' }}
                                        </v-icon>
                                    </div>
                                </template>
                                <span>{{ item.usageAgreement.isSigned ? `Nutzungserklärung zugestimmt am ${convertToDate(item.usageAgreement.dateSigned)}`
                                    :(!item.usageAgreement.isSigned && item.usageAgreement.dateSigned) ? `Nutzungserklärung abgelehnt am ${convertToDate(item.usageAgreement.dateSigned)}`
                                        : 'Nutzungserklärung nicht zugestimmt' }}</span>
                            </v-tooltip>
                        </template>
                        <!-- This is a hidden column so lastName is searchable -->
                        <!-- <template v-slot:[`item.lastName`] /> -->
                        <template v-slot:[`item.teacherGroups[0]`]="{ item }">
                            <span style="color: green">{{ item.teacherGroups[0].name }}</span>
                        </template>
                    </v-data-table>
                </div>
                <!--#endregion-->
                <!--#region table for pupil -->
                <div
                    v-else-if="showPupil"
                    style="height: 100%;"
                >
                    <v-data-table
                        v-model="selected"
                        :headers="windowWidth < 900 ? headersMobile : headers"
                        :header-props="headerProps"
                        :items="filteredMembers"
                        :search="search"
                        item-key="_id"
                        sort-by="lastName"
                        :mobile-breakpoint="0"
                        disable-pagination
                        hide-default-footer
                        fixed-header
                        show-select
                        locale="de-DE"
                        no-data-text="Keine Daten verfügbar"
                        no-results-text="Keinen passenden Nutzer gefunden"
                        style="cursor:pointer; height: 100%;"
                        height="100%"
                        @click:row="editRow"
                    >
                        <template v-slot:[`item.name`]="{ item }">
                            <div
                                class="d-flex flex-row align-center"
                            >
                                <div
                                    class="d-flex justify-flex-start align-center"
                                    :style="item.accountLocked || item.parentAccountLocked || item.pinLocked || item.parentPinLocked ? 'color:red': ''"
                                >
                                    <Avatar
                                        :profile="item.account"
                                        :size="30"
                                        :alt="'Profil öffnen'"
                                        class="mr-2"
                                    />

                                    {{ item.account ? item.lastName + ' ' + item.name : item.name }}
                                </div>
                            </div>
                        </template>
                        <!-- This is a hidden column so lastName is searchable -->

                        <!-- <template
                            v-slot:[`item.lastName`]="{ item }"
                        >
                            <div
                                v-if="item"
                                style="display: none"
                            >
                                {{ item.lastName }}
                            </div>
                        </template> -->
                        <template v-slot:[`header.usageAgreement`]>
                            <v-tooltip
                                top
                            >
                                <template v-slot:activator="{ on, }">
                                    <img
                                        :src="schuelerIcon"
                                        class="icon20"
                                        v-on="on"
                                    >
                                </template>
                                <span>Datenschutz Schüler</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.usageAgreement`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div

                                        v-on="on"
                                    >
                                        <v-icon
                                            :color="item.usageAgreement.isSigned ? 'success':'error'"
                                        >
                                            {{ item.usageAgreement.isSigned ? 'mdi-check' : 'mdi-close' }}
                                        </v-icon>
                                    </div>
                                </template>
                                <span>{{ item.usageAgreement.isSigned ? `Nutzungserklärung zugestimmt am ${convertToDate(item.usageAgreement.dateSigned)}`
                                    :(!item.usageAgreement.isSigned && item.usageAgreement.dateSigned) ? `Nutzungserklärung abgelehnt am ${convertToDate(item.usageAgreement.dateSigned)}`
                                        : 'Nutzungserklärung nicht zugestimmt' }}</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`header.usageAgreementParent`]>
                            <v-tooltip
                                top
                            >
                                <template v-slot:activator="{ on }">
                                    <img

                                        :src="gruppenIcon"
                                        class="icon20"
                                        v-on="on"
                                    >
                                </template>
                                <span>Datenschutz Eltern</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.usageAgreementParent`]="{ item }">
                            <!-- <div v-if="item.usageAgreementParent"> -->
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div

                                        v-on="on"
                                    >
                                        <v-icon
                                            :color="item.usageAgreementParent.isSigned ? 'success':'error'"
                                        >
                                            {{ item.usageAgreementParent.isSigned ? 'mdi-check' : 'mdi-close' }}
                                        </v-icon>
                                    </div>
                                </template>
                                <span>Eltern: {{ item.usageAgreementParent.isSigned ? `Nutzungserklärung zugestimmt am ${convertToDate(item.usageAgreementParent.dateSigned)}`
                                    :(!item.usageAgreementParent.isSigned && item.usageAgreementParent.dateSigned) ? `Nutzungserklärung abgelehnt am ${convertToDate(item.usageAgreementParent.dateSigned)}`
                                        : 'Nutzungserklärung nicht zugestimmt' }}</span>
                            </v-tooltip>
                            <!-- </div> -->
                        </template>
                        <template v-slot:[`item.pupilGroups[0]`]="{ item }">
                            <span
                                v-if="item && item.pupilGroups && item.pupilGroups.length"
                                style="color: green"
                            >{{ item.pupilGroups[0].name }}</span>
                        </template>
                        <!-- <template v-slot:[`item.pupilTeachers[0].lastName`]="{ item }">
                            <div
                                v-if="item && item.pupilTeachers && item.pupilTeachers.length"
                                class="d-flex justify-flex-start align-center"
                            >
                                {{ item.pupilTeachers.length && item.pupilTeachers[0].account ? item.pupilTeachers[0].name + ' ' + item.pupilTeachers[0].lastName : item.pupilTeachers[0].name }}
                            </div>
                        </template> -->
                        <template v-slot:[`item.pupilTeachers[0].name`]="{ item }">
                            <div
                                v-if="item && item.pupilTeachers && item.pupilTeachers.length && item.pupilTeachers[0]"
                                style="display: none"
                            >
                                {{ item && item.pupilTeachers.length ? item.pupilTeachers[0].name : '' }}
                            </div>
                        </template>
                    </v-data-table>
                </div>
                <!-- #endregion -->
            </vue-custom-scrollbar>
        </div>

        <!--#region Dialogs, Overlays, Popups -->
        <v-dialog
            v-model="saveDialog"
            persistent
            max-width="400"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-overlay
                    :value="showPdfOverlay"
                    class="d-flex flex-column justify-space-around align-center"
                >
                    <v-progress-circular
                        v-if="showPdfOverlay"
                        indeterminate
                        size="100"
                    />
                    <p>Die Pdf wird erstellt, bitte gedulden Sie sich einen Moment</p>
                </v-overlay>
                <v-card-title
                    class="headline d-flex justify-space-between mb-1"
                    style="color:white;background-color:var(--v-rot-base)"
                >
                    <div>
                        Accounterstellung
                    </div>
                    <v-btn
                        icon
                        dark
                        @click="()=>{ saveDialog=false; stop(); }"
                    >
                        <img
                            class="iconToWhite icon30"
                            :src="schliessenIcon"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <b>WICHTIG:</b> Laden Sie direkt die Zugangsdaten für die Nutzer herunter. Dies ist nachträglich aus Datenschutzgründen nicht mehr möglich.
                    <br> <br>
                    Insofern der Nutzer die Zugangsdaten vergessen oder verloren hat, klicken Sie auf "Zugangsdaten neu generieren", um die neuen Zugangsdaten zu erhalten.

                    <br> <br>
                    <div>
                        <v-row
                            class="mt-5"
                            align="center"
                            justify="center"
                        >
                            <div style="width: 200px">
                                <div
                                    style="width: 200px; gap: 4px"
                                    class="d-flex flex-column align-center ma-0"
                                >
                                    <v-btn
                                        v-if="createType === 'teacher'"
                                        class="text-none"
                                        color="gruen"
                                        elevation="0"
                                        dark
                                        @click="pdf(false, false, groupsTeacher)"
                                    >
                                        Download Zugangsdaten Lehrer
                                    </v-btn>

                                    <v-btn
                                        v-if="pupilAndParentPdf"
                                        class="text-none"
                                        color="gruen"
                                        elevation="0"
                                        dark
                                        @click="downloadPupilAndParent"
                                    >
                                        Download Zugangsdaten Schüler & Eltern
                                    </v-btn>

                                    <v-btn
                                        v-if="createType === 'pupil' && !pupilAndParentPdf"
                                        class="text-none"
                                        color="gruen"
                                        elevation="0"
                                        dark
                                        @click="pdf(false, false, editGroups)"
                                    >
                                        Download Zugangsdaten Schüler
                                    </v-btn>

                                    <v-btn
                                        v-if="!deactivateParent && createNewParent && !selectedParent && createType !== 'teacher' && !pupilAndParentPdf"
                                        class="text-none"
                                        color="gruen"
                                        elevation="0"
                                        dark
                                        @click="pdf(true, false, null)"
                                    >
                                        Download Zugangsdaten Eltern
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        v-if="false"
                        class="text-none"
                        elevation="0"
                        color="gruen"
                        dark
                        @click="stop"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="patchDialog"
            persistent
            max-width="400"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    class="headline d-flex justify-space-between mb-1"
                    style="color:white;background-color:var(--v-rot-base)"
                >
                    <div>
                        Accountänderung
                    </div>
                    <v-btn
                        icon
                        dark
                        @click="()=>{ patchDialog=false;stop(); }"
                    >
                        <img
                            class="iconToWhite icon30"
                            :src="schliessenIcon"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <b>WICHTIG:</b> Wenn Sie die Zugangsdaten geändert haben, laden Sie hier direkt die Zugangsdaten für die Nutzer herunter. Dies ist nachträglich aus Datenschutzgründen nicht mehr möglich.
                    <br> <br>
                    Insofern der Nutzer die Zugangsdaten vergessen oder verloren hat, klicken Sie auf "Zugangsdaten neu generieren", um die neuen Zugangsdaten zu erhalten.

                    <br> <br>
                    Aus Sicherheitsgründen werden Nutzer bei Änderung des Passworts ausgeloggt.
                    <br> <br>
                    <div>
                        <v-row
                            class="mt-5"
                            align="center"
                            justify="center"
                        >
                            <div style="width: 200px">
                                <div
                                    style="width: 200px; gap: 4px"
                                    class="d-flex flex-column align-center ma-0"
                                >
                                    <v-btn
                                        v-if="regenerateQr && editObjectRole === 'teacher'"
                                        class="text-none"
                                        color="gruen"
                                        elevation="0"
                                        dark
                                        @click="pdf(false, false, groupsTeacher)"
                                    >
                                        Download Zugangsdaten Lehrer
                                    </v-btn>
                                    <v-btn
                                        v-if="regenerateQr && editObjectRole === 'pupil'"
                                        class="text-none"
                                        color="gruen"
                                        elevation="0"
                                        dark
                                        @click="pdf(false, false, editGroups)"
                                    >
                                        Download Zugangsdaten Schüler
                                    </v-btn>

                                    <v-btn
                                        v-if="editObjectRole !== 'teacher' && (createNewParent || selectedParent || regenerateQrParent) && !noParents"
                                        class="text-none"
                                        color="gruen"
                                        elevation="0"
                                        dark
                                        @click="pdf(true, false, null)"
                                    >
                                        Download Zugangsdaten Eltern
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        v-if="false"
                        class="text-none"
                        elevation="0"
                        color="gruen"
                        dark
                        @click="stop"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog for deleting one or multiple elements -->
        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="400"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    class="text-h5 mb-1 d-flex align-center justify-space-between"
                    style="color:white;background-color:var(--v-rot-base)"
                >
                    <p>Löschen bestätigen?</p>
                    <v-btn
                        icon
                        color="transparent"
                        elevation="0"
                        style="margin-right: -8px"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="schliessenIcon"
                            class="icon30 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text class="pa-4">
                    <div
                        v-if="deleteProgress"
                    >
                        <p>{{ `${deleteProgress.length} von ${selected.length} ${showGroups ? 'Gruppen' : 'Nutzern'} entfernt.` }}</p>
                    </div>
                    <p v-else>
                        {{ editObject ? 'Sind Sie sicher, dass Sie diesen Nutzer löschen möchten?'
                            + (editObjectRole === 'teacher' ? ' Die vom Lehrer privat genutzten Pinnwände werden ebenfalls gelöscht.' : '')
                            : showGroups ? 'Sind Sie sicher, dass Sie die ausgewählten Gruppen löschen möchten?'
                                : 'Sind Sie sicher, dass Sie die ausgewählten Nutzer löschen möchten?' }}
                    </p>
                </v-card-text>
                <v-card-actions class="d-flex justify-center mb-2">
                    <v-btn
                        class="option text-none"
                        color="#F5F5F5"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="schliessenIcon"
                            class="icon20 mr-2"
                            alt="abbrechen"
                        >
                        Abbrechen
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        v-if="editObject"
                        :disabled="!!deleteProgress"
                        :loading="deleteLoading"
                        class="option text-none"
                        color="rot"
                        dark
                        @click="deleteElement()"
                    >
                        <img
                            :src="deleteIcon"
                            class="icon20 mr-2 iconToWhite"
                        >
                        Löschen
                    </v-btn>

                    <v-btn
                        v-else
                        :disabled="!!deleteProgress"
                        class="option text-none ml-2"
                        color="rot"
                        dark
                        :style="`${deleteProgress ? 'background-color: #FF000050 !important; color: #FFF !important;':''}`"
                        @click="bulkDelete()"
                    >
                        <img
                            :src="deleteIcon"
                            class="icon20 mr-2 iconToWhite"
                        >
                        Auswahl löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="confirmDialog"
            persistent
            max-width="400"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title class="headline">
                    Erneuern bestätigen
                </v-card-title>
                <v-card-text>
                    <div
                        v-if="regenerateProgress"
                    >
                        PDFs werden generiert und zu einer Zip-Datei hinzugefügt... <br>
                        {{ `${regenerateProgress.length} von ${selected.length} Accounts wurden neu generiert.` }}
                    </div>
                    <p v-else>
                        Wollen Sie wirklich für die ausgewählten Nutzer neue Zugangsdaten (Passwörter) generieren und herunterladen?
                        Für Schüler-Accounts werden auch die Zugangsdaten der Eltern neu generiert.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        elevation="0"
                        @click="confirmDialog = false"
                    >
                        Abbrechen
                    </v-btn>

                    <v-btn
                        :disabled="!!regenerateProgress"
                        class="text-none"
                        elevation="0"
                        color="gruen"
                        :style="`${regenerateProgress ? 'background-color: #8cbe4650 !important; color: #FFF !important;':''}`"
                        dark
                        @click="bulkRegenerate()"
                    >
                        Zugangsdaten generieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Excel Upload -->
        <ExtendedExcelImport
            v-if="excelDialog"
            @close="() => { excelDialog = false; }"
        />

        <v-dialog
            v-model="oldExcelDialog"
            persistent
            fullscreen
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    class="headline"
                    style="padding-bottom: 16px; background-color: var(--v-gelb-base); margin-bottom: 24px"
                >
                    <img
                        :src="hochladen"
                        style="height: 35px; margin-right: 8px; color: white"
                        class="iconToWhite"
                        alt="Excel hochladen"
                    >
                    <span style="color: white">Excel-Upload</span>
                    <v-spacer />
                    <v-btn
                        x-small
                        class="d-flex justify-end align-center"
                        style="background-color: #f8f8f880; width: 35px; height: 35px;"
                        @click="oldExcelDialog = false"
                    >
                        <img
                            :src="schliessenIcon"
                            class="closeIcon"
                            alt="Exceldialog schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text style="font-size: 16px">
                    Hier können Sie Schüler aus einer Excel-Datei (.xlsx) hochladen. Dafür laden Sie sich die
                    <a
                        id="downloadVorlageBtn"
                        :href="excelSheet"
                        download
                    >
                        Vorlage
                    </a> herunter und füllen diese anhand des Beispiels aus.
                    <br>
                    Dabei müssen Sie einige Regeln beachten:
                    <ul>
                        <li>Ändern Sie nicht die Namen der Spalten (Benutzername, Vorname, ...) in der Beispieldatei.</li>
                        <li>Die Login-ID darf keine Umlaute oder Sonderzeichen enthalten. Lassen Sie das Feld frei um automatisch aus dem Vornamen und dem ersten Buchstaben des Nachnamens die Login-ID zu generieren</li>
                        <li>Vor- und Nachname sind Pflichtfelder.</li>
                    </ul>
                    Anschließend laden Sie die ausgefüllte Vorlage hoch. Der Import startet dann automatisch. Ist der Import abgeschlossen startet automatisch der Download eine ZIP Datei in der sich die PDFs mit allen Accountdaten zum Ausdrucken befinden.
                    <br>
                    <!--                    <v-checkbox -->
                    <!--                        :value="excelAlsoCreateParents"-->
                    <!--                        label="Accounts für Eltern automatisch mitgenerieren"-->
                    <!--                    /> Wenn Sie dieses Kästchen anklicken werden beim Import auch Elternaccounts generiert, damit sich die Eltern auch einloggen können. Die PDFs mit den Zugangsdaten werden auch mit heruntergeladen.-->
                    <!--                    <br>-->
                    <v-btn
                        class="ma-2"
                        @click="clickDownloadVorlage"
                    >
                        Vorlage herunterladen
                    </v-btn>
                    <v-btn
                        class="ma-2"
                        @click="clickExelUploadButton"
                    >
                        Ausgefüllte Vorlage hochladen
                    </v-btn>
                    <br>
                    Um möglichen Fehlern beim Import vorzubeugen befindet sich im Folgenden eine Konsole auf der mögliche Fehler (rot) und Warnungen (orange) ausgegeben werden. Bei einer roten Meldung wird der/die Nutzer/in nicht erstellt. Bei einer orangenen Meldung wird der Nutzer trotzdem erstellt. Die Fehlermeldungen beinhalten immer eine Zeilenangabe, sodass Sie wissen in welcher Zeile ihrer Excel-Datei ein Fehler aufgetreten ist.
                    <br>
                    <div
                        class="loggerDiv"
                        style="display: flex; flex-direction: column"
                    >
                        <p
                            v-for="(log, index) in excelImportLoggerFilterLogLevel"
                            :key="index"
                            :class="'loggerLevel' + log.level"
                        >
                            {{ log.message }}
                        </p>
                        <p
                            v-if="excelImportLogger.length === 0"
                            class="ml-2 mt-2"
                            style="font-size: x-large; opacity: 50%"
                        >
                            Konsole
                        </p>
                    </div>
                    <v-checkbox
                        v-model="excelImportLoggerShowErrors"
                        label="Fehler anzeigen"
                        hide-details
                        class="mt-0"
                    />
                    <v-checkbox
                        v-model="excelImportLoggerShowWarnings"
                        label="Warnungen anzeigen"
                        hide-details
                        class="mt-0"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="mr-4"
                        color="green darken-1"
                        dark
                        @click="oldExcelDialog = false; excelAlsoCreateParents = false; excelImportLogger = []"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-overlay
                absolute
                :value="showLoadingOverlay"
                style="width: 100% !important;"
            >
                <div class="d-flex flex-column justify-center align-center">
                    <v-progress-linear
                        v-if="showLoadingOverlay"
                        v-model="excelProgressbarPercentage"
                        height="25"
                        style="width: 200px;"
                    >
                        <strong>{{ excelCurrentStatusOfImports + ' / ' + excelAmountOfImports }}</strong>
                    </v-progress-linear>
                    <p style="color: white; font-weight: bold; width: 500px; text-align: center">
                        Bitte warten. Dieser Vorgang kann bis zu 15 Minuten dauern.
                        Am Ende werden die Schülerausweise zum Herunterladen bereit gestellt.
                    </p>
                    <p style="color: white; font-weight: bold; width: 500px; text-align: center">
                        {{ excelExtraStatusMessage }}
                    </p>
                </div>
            </v-overlay>
        </v-dialog>


        <input
            id="exelUploadInput"
            ref="exelUploadInput"
            type="file"
            hidden
            @change="() => exelUploadInputChange()"
        >

        <v-dialog
            v-model="createSubjectDialog"
            max-height="1000px"
            max-width="400"
        >
            <FachErstellen
                v-if="createSubjectDialog"
                :teacher-creation="true"
                @addSubject="handleAddSubject"
                @stopEvent="createSubjectDialog = false"
            />
        </v-dialog>

        <v-dialog
            v-model="createLocationDialog"
            max-height="1000px"
            max-width="400"
        >
            <CreateLocation
                v-if="createLocationDialog"
                :teacher-creation="true"
                @addLocation="handleAddLocation"
                @stopEvent="createLocationDialog = false"
            />
        </v-dialog>
        <v-dialog
            v-model="createGroupDialog"
            max-height="1000"
            max-width="400"
            style="border-radius: 16px; height: 100%;"
        >
            <CreateGroup
                style="height: 100%;"
                :create-teacher-first="editObjectRole === 'teacher' ? false : true"
                :teacher-name="placeholderName"
                @stopEvent="createGroupCallback"
                @newGroup="createGroupCallback"
            />
        </v-dialog>

        <v-dialog
            v-model="createTeacherDialog"
            max-height="1000px"
            max-width="400"
            style="border-radius: 16px"
        >
            <CreateTeacher
                :header-color="'var(--v-rot-base)'"
                :add-new-teacher="addNewTeacher"
                :subjects="subjects"
                :group-name="groupName"
                @stopEvent="createTeacherCallback"
                @addedSubject="handleAddSubject"
                @finishTeacher="createTeacherCallback"
            />
        </v-dialog>
        <v-dialog
            v-model="showAreYouSureDelete"
            max-width="337"
            content-class="rounded-4"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    class="text-h5 mb-1 d-flex align-center justify-space-between"
                    style="background-color: var(--v-rot-base)"
                >
                    <p
                        style="color: white"
                    >
                        Sind Sie Sicher?
                    </p>
                    <v-btn
                        icon
                        color="transparent"
                        elevation="0"
                        style="margin-right: -8px"
                        @click="showAreYouSureDelete = false;"
                    >
                        <img
                            :src="schliessenIcon"
                            class="icon30 iconToWhite"
                            alt="Abbrechen"
                        >
                    </v-btn>
                </v-card-title>

                <v-card-text class="mt-2">
                    {{ areYouSureDeleteText }}
                </v-card-text>



                <v-card-actions class="d-flex justify-center mb-2">
                    <v-btn
                        class="text-none ml-2"
                        color="#F5F5F5"
                        @click="showAreYouSureDelete = false;"
                    >
                        <img
                            :src="schliessenIcon"
                            class="mr-2 icon20"
                            alt="abbrechen"
                        >
                        <p>{{ windowWidth >= 350 ? 'Abbrechen' : '' }}</p>
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        class="text-none ml-2"
                        color="red"
                        dark
                        @click="deleteParentAccount()"
                    >
                        <img
                            :src="deleteIcon"
                            class="iconToWhite mr-2 icon20"
                            alt="bestätigen"
                        >
                        <p>{{ 'Löschen' }}</p>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="createPupilDialog"
            max-height="1000px"
            max-width="400"
            style="border-radius: 16px"
        >
            <CreatePupil
                :add-new-pupil="addNewPupil"
                :group-name="groupName"
                @stopEvent="createPupilCallback"
            />
        </v-dialog>
        <AreYouSurePopup
            v-if="showAreYouSure"
            :show-dialog="showAreYouSure"
            :content-text-prop="areYouSureText"
            :header-color="'#e6231e'"
            :cancel-button-text="'Zurück'"
            :ok-button-text="'Verlassen'"
            :ok-btn-color="'#e6231e'"
            :ok-button-icon="''"
            :usage="'userWidget'"
            @close="showAreYouSure = false"
            @ok="stop"
        />

        <v-overlay
            absolute
            :value="showLoadingOverlay"
            style="width: 100% !important;"
        >
            <v-progress-circular
                v-if="showLoadingOverlay"
                indeterminate
                size="100"
            />
        </v-overlay>
        <!--#endregion -->
        <!-- <BadgeExplanations
            v-if="windowWidth < 900"
            :small="true"
        /> -->
        <PinDialog
            :show-pin-dialog="pinDialog"
            :account="accountsById[me._id]"
            :usage="'users'"
            @close="pinDialog = false"
            @accountPinValid="()=>{pinDialog = false}"
        />
    </div>
</template>

<script>
import CreateGroup from "@/components/Verwaltung/CreateGroup";
import CreateTeacher from "@/components/Verwaltung/CreateTeacher";
import CreatePupil from "@/components/Verwaltung/CreatePupil";
import FachErstellen from "@/components/Verwaltung/FachErstellen";
import CreateLocation from "@/components/Verwaltung/CreateLocation"
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";
import vueCustomScrollbar from "vue-custom-scrollbar";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as backend from "@/api/backend";
import {compress} from "shrink-string";
import * as xlsx from "xlsx";
import generateRandomPassword from "@/util/generateRandomPassword";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import excelSheet from "@/assets/excel_import_template.xlsx";
import Avatar from "@/components/SharedViews/Profile/Avatar";

import editIcon from '@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg';
import backIcon from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import groupIcon from '@/assets/Icons/group.svg';
import pupilIcon from '@/assets/Icons/pupil.svg';
import teacherIcon from '@/assets/Icons/teacher-white.svg';
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';
import updateIcon from '@/assets/Icons/aktualisieren-verwaltung-107.svg';
import magnifierIcon from '@/assets/Icons/redesignV3/magnifier.svg';
import plusIcon from '@/assets/Icons/plus.svg';
import plusIconSchw from '@/assets/Icons/FaceliftIcons/plus_hinzufuegen_weiteres.svg';
import questionMarkIcon from '@/assets/Icons/redesignV3/questionmark.svg';
import hochladen from '@/assets/Icons/hochladen-15.svg';
import schliessenIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import downIcon from '@/assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg';
import LoginPdf from "@/util/loginPdf";
import ExtendedExcelImport from "@/components/Verwaltung/Excel/ExtendedExcelImport";
import searchIcon from '@/assets/Icons/suche-26.svg';
import gruppenIcon from '@/assets/Icons/FaceliftIcons/nutzer_gruppen_default.svg';
import lehrerIcon from '@/assets/Icons/FaceliftIcons/Nutzer_Lehrer_default.svg';
import schuelerIcon from '@/assets/Icons/FaceliftIcons/nutzer_schueler_default.svg';
import defaultProfilePicture from "/src/assets/Icons/Avatar3.svg";
import zahnrad from '@/assets/Icons/FaceliftIcons/einstellungen_zahnrad.svg';
import lockIcon from '@/assets/Icons/lock-closed.svg';
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import eyeIcon from "@/assets/Icons/eye.svg";
import eyeOffIcon from "@/assets/Icons/eye-off.svg";

import einfacheAnsicht from "@/assets/einfache-Ansicht.svg";
import normaleAnsicht from "@/assets/normale-Ansicht.svg";
import BadgeExplanations from '../Utils/BadgeExplanations.vue';
import PinDialog from '@/components/PinDialog.vue';
import ClickableBadge from "@/components/Utils/ClickableBadge";



export default {
    name: "Users",
    components: {
        ExtendedExcelImport,
        vueCustomScrollbar,
        CreateGroup,
        CreateTeacher,
        CreatePupil,
        FachErstellen,
        AreYouSurePopup,
        Avatar,
        // BadgeExplanations,
        PinDialog,
        ClickableBadge,
        CreateLocation,
    },
    props: {
        reset: {required: false, type: Boolean}
    },
    data: () => ({
        //#region Icons
        me: null,
        eyeIcon, 
        eyeOffIcon,
        infoIcon,
        lockIcon,
        zahnrad,
        editIcon,
        backIcon,
        groupIcon,
        pupilIcon,
        teacherIcon,
        deleteIcon,
        saveIcon,
        updateIcon,
        magnifierIcon,
        plusIcon,
        plusIconSchw,
        questionMarkIcon,
        hochladen,
        schliessenIcon,
        downIcon,
        searchIcon,
        gruppenIcon,
        lehrerIcon,
        schuelerIcon,
        defaultProfilePicture,
        // eslint-disable-next-line
        einfacheAnsicht, normaleAnsicht,
        //#endregion

        pinDialog:false,
        accountPinIsValid: false,
        accountHasPin: false,
        showAreYouSureDelete: false,
        areYouSureDeleteText: '',
        showAreYouSure: false,
        showLoadingOverlay: false,
        showPupil: false,
        showGroups: false,
        showTeacher: false,
        saveDialog: false,
        patchDialog: false,
        deleteDialog: false,
        confirmDialog: false,
        createSubjectDialog: false,
        createLocationDialog: false,
        createGroupDialog: false,
        createTeacherDialog: false,
        createPupilDialog: false,
        excelDialog: false,
        oldExcelDialog: false,
        excelAlsoCreateParents: false,
        excelSheet,
        excelImportLogger: [],
        excelImportLoggerShowErrors: true,
        excelImportLoggerShowWarnings: true,
        excelAmountOfImports: 0,
        excelCurrentStatusOfImports: 0,
        excelExtraStatusMessage: '',
        revokeUsageAgreement: false,
        revokeParentAgreement: false,
        resetLockedUser: false,
        resetLockedParent: false,
        qrLoginDeactivated: false,
        parentQrLoginDeactivated: false,
        showPdfOverlay: false,


        expandedPanels: [],
        headers: [
            { text: 'Nutzer', value: 'name' },
            // This adds a hidden column through it's v-slot to fix searching lastName
            // { text: '', value: 'lastName' },
            { text: 'Datenschutz SchÜler', value: 'usageAgreement'},
            { text: 'Datenschutz Eltern', value: 'usageAgreementParent'},
            { text: 'Klasse', value: 'pupilGroups[0].name' },
            { text: 'Klassenlehrer', value: 'pupilTeachers[0].lastName' },
            // This adds a hidden column through it's v-slot to fix searching name
            // { text: '', value: 'pupilTeachers[0].name' },

        ],
        groupsHeaders: [
            { text: 'Nutzer', value: 'name' },
            { text: '', value: 'lastName' },
            { text: 'Rolle', value: 'accountRole' },
        ],
        headersMobile: [
            { text: 'Nutzer', value: 'name' },
            // This adds a hidden column through it's v-slot to fix searching lastName
            // { text: '', value: 'lastName' },
            { text: 'Datenschutz SchÜler', value: 'usageAgreement'},
            { text: 'Datenschutz Eltern', value: 'usageAgreementParent'},
            { text: 'Klasse', value: 'pupilGroups[0].name' },
            { text: 'Klassenlehrer', value: 'pupilTeachers[0].lastName' },
            // This adds a hidden column through it's v-slot to fix searching name
            // { text: '', value: 'pupilTeachers[0].name' },
        ],
        teacherHeaders: [
            { text: 'Nutzer', value: 'name' },
            // This adds a hidden column through it's v-slot to fix searching lastName
            // { text: '', value: 'lastName' },
            { text: 'Datenschutz', value: 'usageAgreement'},
            { text: 'Klasse', value: 'teacherGroups[0].name' },
        ],
        teacherHeadersMobile: [
            { text: 'Nutzer', value: 'name' },
            // This adds a hidden column through it's v-slot to fix searching lastName
            // { text: '', value: 'lastName' },
            { text: 'Datenschutz', value: 'usageAgreement'},
            { text: 'Klasse', value: 'teacherGroups[0].name' },
        ],
        headerProps: { sortByText: 'Sortieren nach' },
        selected: [],           // Users or groups (when showGroups is true) selected in table
        deleteLoading: false,
        deleteProgress: null,
        regenerateProgress: null,
        search: '',

        editObject: null,       // Which pupil/teacher/group is being edited currently
        editObjectRole: '',     // 'pupil', 'teacher' or 'group'
        showMorePupil: true,
        showMoreParent: true,
        createType: null,     // Set when creating new pupil, teacher or group, either 'pupil', 'teacher' or 'group'

        regenerateQr: false,
        regenerateQrDisabled: false,
        regenerateQrParent: false,
        regenerateQrDisabledParent: false,

        // Pupil data
        firstName: '',
        lastName: '',
        note: '',
        username: '',
        password: '',
        qrPassword: '',
        pupilHasParent: false,
        editGroups: [],
        pupilTeachers: [],
        canChangePassword: false,

        babyView: false,
        translationAllowed: false,
        sehen: false,
        hoeren: false,
        motorik: false,
        screenreader: false,
        // Leave sehen and vorlesen next to each other.
        // Only when sehen is true you can interact with vorlesen/screenreader in UI
        // name is what the value is called in the DB and used for patch request
        accessibilities: [
              { text: 'Einfach', name: 'babyView', value: true, explanation: `<div class="d-flex-column align-center justify-center">
                    <p>Einfache Ansicht:</p>
                    <img style="height: 100px" src="`+einfacheAnsicht+`" alt="Vorschau einfache Ansicht">
                    <p>Normale Ansicht:</p>
                    <img style="height: 100px" src="`+normaleAnsicht+`" alt="Vorschau normale Ansicht">
                    </div>` },
            { text: 'Sehen', name: 'sehen', value: false },
            { text: 'Vorlesen', name: 'screenreader', value: false, explanation: 'Diese Hilfe kann nur aktiviert werden, wenn Sehen auch aktiviert wurde. Es werden Funktionen deaktiviert, welche für Screenreader-Nutzer nutzlos sind (Vorlesefunktion, Stundenplan im Vollbild)' },
            { text: 'Hören', name: 'hoeren', value: false },
            { text: 'Motorik', name: 'motorik', value: false },
            { text: 'Übersetzung', name: 'translationAllowed', value: false, explanation: 'Sie entscheiden ob diese/r Schüler/in die Übersetzungsfunktion nutzen kann. Aus datenschutzrechtlichen Gründen muss diese Entscheidung von einem Erwachsenen getroffen werden.' },
        ],
        allowGoogleTranslate: false,
        // Parent data
        selectedParent: '',
        deactivateParent: false,
        createNewParent: true,
        noteParent: '',
        usernameParent: '',
        passwordParent: '',
        qrPasswordParent: '',
        noParents: false,
        parentUsageAgreement: false,
        parentPinActivated: false,
        resetParentPin: false,
        resetPin: false,

        // Teacher data (+ Subject data)
        subjectsTeacher: [],    // subjects selected for current teacher
        subjectDiff: [],
        searchSubjects: '',
        groupsTeacher: [],      // groups selected for current teacher
        simpleEditor: false,
        hoursPerWeek: '',
        substitutionBlocked: false,
        pinActivated: false,
        messagesPinActivated: false,
        loginPinActivated: false,
        groupsWithPlaceholder: [],
        placeholderName: '',
        canEditTimeslot: false,
        canEditQrCodes: false,
        locationsTeacher: [],
        searchLocations: '',

        // Group data
        groupName: '',
        groupLeaders: [],
        groupMembers: [],
        searchLeaders: '',
        searchMembers: '',
        searchGroupNames: '',
        groupToBeCreated: null,

        // Validate input
        fields: {
            firstName: false,
            lastName: false,
            username: false,
            usernameStrangeLetters: false,
            usernameDuplicate: false,
            usernameGenerated: false,
            password: false,

            parent: false,
            parentLoginId: false,
            parentLoginIdStrangeLetters: false,
            parentLoginIdDuplicate: false,
            parentLoginIdGenerated: false,
            parentPassword: false,

            groupName: false,
            groupNameDuplicate: false,
            groupLeaders: false,

            groupsTeacher:false,
        },
        valid: true, // Needs to be true by default

        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
        },
        messageCategory: 0, // 0 Schüler, 1 Lehrer, 2 Gruppen+

        showPwPupil: false,
        showPwParent: false,
        showPwTeacher: false,
    }),
    computed: {
        ...mapGetters('accounts', ['accounts', 'accountsById']),
        ...mapGetters('deactivatedFeatures', ['diaryDeactivated', 'messagesDeactivated',]),
        ...mapGetters('groups', ['getGroupStatus', 'groupsById']),
        ...mapGetters('groups', {'vuexGroups': 'groups' }),
        ...mapGetters('parents', {'vuexParents': 'parents', 'parentsByAccountId': 'parentsByAccountId', 'parentsById': 'parentsById'}),
        ...mapGetters('teachers', ['teachersByAccountId', 'teachersById']),
        ...mapGetters('teachers', {'vuexTeachers': 'teachers' }),
        ...mapGetters('pupils', ['pupilsById', 'vuexPupils']),
        ...mapGetters('subjects', ['getSubjectInvalidStatus']),
        ...mapGetters('subjects', {'vuexSubjects': 'subjects' }),
        ...mapGetters('auth', ['accountPinValid','diaryPinActivated']),
        ...mapGetters('location',['locations']),
        ...mapState('util', ['windowWidth']),
        invertedParentQrLoginDeactivated:{
            get() {
                return !this.parentQrLoginDeactivated;
            },
            // The setter updates the actual data model based on the inverse of the input
            set(value) {
                this.parentQrLoginDeactivated = !value;
            }
        },
        invertedQrLoginDeactivated: {
            get() {
                return !this.qrLoginDeactivated;
            },
            // The setter updates the actual data model based on the inverse of the input
            set(value) {
                this.qrLoginDeactivated = !value;
            }
        },
        usePin(){
            return this.diaryPinActivated;
        },
        selectedAllLeaders() {
            return this.groupLeaders.length === this.teachers.length;
        },
        selectedSomeLeaders() {
            return this.groupLeaders.length > 0 && !this.selectedAllLeaders;
        },
        selectedAllMembers() {
            return this.groupMembers.length === this.pupils.length;
        },
        selectedSomeMembers() {
            return this.groupMembers.length > 0 && !this.selectedAllMembers;
        },
        iconLeader() {
            if (this.selectedAllLeaders) return 'mdi-close-box';
            if (this.selectedSomeLeaders) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
        iconMember() {
            if (this.selectedAllMembers) return 'mdi-close-box';
            if (this.selectedSomeMembers) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
        excelProgressbarPercentage() {
            return (this.excelCurrentStatusOfImports / parseFloat(this.excelAmountOfImports)) * 100;
        },
        excelImportLoggerFilterLogLevel() {
            if(this.excelImportLoggerShowErrors && this.excelImportLoggerShowWarnings) {
                return this.excelImportLogger;
            } else if(this.excelImportLoggerShowErrors && !this.excelImportLoggerShowWarnings){
                return this.excelImportLogger.filter(log => log.level === "error");
            } else if(!this.excelImportLoggerShowErrors && this.excelImportLoggerShowWarnings) {
                return this.excelImportLogger.filter(log => log.level === "warning");
            } else {
                return [];
            }
        },
        filteredLeaders() {
            return this.teachers.filter((teacher) => (teacher.name + ' ' +  teacher.lastName).toLowerCase().includes(this.searchLeaders.toLowerCase()));
        },
        filteredMembers() {
            return this.pupils.filter((pupil) => (pupil.name + ' ' +  pupil.lastName).toLowerCase().includes(this.searchMembers.toLowerCase()));
        },
        filteredSubjects() {
            return this.subjects.filter((subject) => subject.name.includes(this.searchSubjects));
        },
        filteredGroups() {
            return this.groups.filter((group) => group.name.toLowerCase().includes(this.searchGroupNames.toLowerCase()));
        },
        filteredLocations(){
            return this.locations.filter((location) => location.name.includes(this.searchLocations));

        },
        parents() {
            return this.vuexParents
                .map((parent) => ({
                    ...parent,
                    pupils: parent.pupils.map(child => this.pupilsById[child]),
                }))
                .sort((a, b) => this.alphabetically(a.displayName, b.displayName));
        },
        parentAccounts() {
            const result = [];
            this.parents.forEach(parent => {
                const acc = this.accountsById[parent.account];
                if (acc) {
                    result.push(acc);
                }
            });
            result.sort((a, b) => this.alphabetically(a.displayName, b.displayName));
            return result;
        },
        groups() {
            return this.vuexGroups
                .filter(el => el.name !== 'Alle')
                .sort((a, b) => this.alphabetically(a.name, b.name));
        },
        groupsList() {
            return this.groups.map((group) => {
                let participants = [];
                if(group.participants && group.participants.length){

                    participants = group.participants
                        .map(participant => {
                            const corresPupil = this.pupils.find(pupil => pupil._id === participant._id);
                            if (corresPupil) {
                                return {
                                    ...corresPupil,
                                    group: group.name,
                                    groupId: group._id,
                                    selectKey: `${group._id}${corresPupil._id}`
                                }
                            }
                        });
               }

                return {
                    ...group,
                    participants
                };
                })
                .sort((a, b) => this.alphabetically(a.name, b.name));
        },
        pupils() {
            return this.vuexPupils
            .map(pupil => {
                let corresAcc;
                if (typeof pupil.account === 'string') {
                    corresAcc = this.accountsById[pupil.account];
                } else {
                    corresAcc = pupil.account;
                }

                if (!corresAcc) {
                    //for some reason after deleting someimes there is no corresAcc i nthis.accountsById, therefore we return this default pupil
                    console.error(`Account not found for pupil: ${pupil.name}`);
                    return {
                        ...pupil,
                        accountRole: 'pupil',
                        pupilGroups: [],
                        pupilTeachers: [],
                        usageAgreement: null,
                        usageAgreementParent: {},
                        accountLocked: false,
                        pinLocked: false,
                        parentAccountLocked: false,
                        parentPinLocked: false,
                        passwordAlteredUser: 0,
                        passwordAlteredMaintainer: 0,
                    };
                }

                const pupilGroups = corresAcc.groups.map((group) => {
                    let foundGroup = this.groupsById[group];
                    if (!foundGroup) {
                        return 'notfound';
                    }
                    return foundGroup;
                });

                const parent = this.parentsById[pupil.parent];
                const parentAcc = pupil.parent && parent ? this.accountsById[parent.account] : null;

                let leaderList = [];
                leaderList = pupilGroups.filter(group => group !== 'notfound').map((group) => {
                    return group.leaders ? group.leaders : [];
                });

                leaderList = leaderList.reduce((list1, list2) => {
                    return [...new Set(list1.concat(list2))];
                }, []);

                return {
                    ...pupil,
                    accountRole: 'pupil',
                    pupilGroups: pupilGroups,
                    pupilTeachers: leaderList ? leaderList.map((leader) => this.teachersById[leader]) : [],
                    usageAgreement: corresAcc.signedUsageAgreement,
                    usageAgreementParent: parentAcc ? parentAcc.signedUsageAgreement : {},
                    accountLocked: corresAcc.wrongLogins && corresAcc.wrongLogins >= 5 ? true : false,
                    pinLocked: corresAcc.wrongPins && corresAcc.wrongPins >= 5 ? true : false,
                    parentAccountLocked: parentAcc && parentAcc.wrongLogins && parentAcc.wrongLogins >= 5 ? true : false,
                    parentPinLocked: parentAcc && parentAcc.wrongPins && parentAcc.wrongPins >= 5 ? true : false,
                    passwordAlteredUser: parentAcc && parentAcc.passwordAlteredUser ? parentAcc.passwordAlteredUser : 0,
                    passwordAlteredMaintainer: parentAcc && parentAcc.passwordAlteredMaintainer ? parentAcc.passwordAlteredMaintainer : 0,
                };
            })
            .sort((a, b) => this.alphabetically(a.name, b.name));

        },
        teachers() {
            return this.vuexTeachers
                .map(teacher => {
                    let corresAcc;
                     if(typeof teacher.account === 'string'){
                         corresAcc = this.accountsById[teacher.account];
                     }else{
                        corresAcc = teacher.account;
                     }
                    return {
                        ...teacher,
                        accountRole: 'teacher',
                        teacherGroups: this.setTeacherGroups(teacher._id),
                        usageAgreement: corresAcc.signedUsageAgreement,
                        accountLocked: corresAcc.wrongLogins && corresAcc.wrongLogins >= 5 ? true : false,
                        pinLocked: corresAcc.wrongPins && corresAcc.wrongPins >= 5 ? true : false,
                        passwordAlteredUser: corresAcc.passwordAlteredUser ? corresAcc.passwordAlteredUser : 0,
                        passwordAlteredMaintainer: corresAcc.passwordAlteredMaintainer ? corresAcc.passwordAlteredMaintainer : 0,
                    }
                })
                .sort((a, b) => this.alphabetically(a.name, b.name));
        },
        subjects() {
            //map is needed for eslint error
          return this.vuexSubjects
              .map(sub => sub)
              .sort((a, b) => this.alphabetically(a.name, b.name));
        },
        pupilAndParentPdf() {
            if (this.createType === 'pupil' && this.createNewParent && !this.selectedParent && this.createType !== 'teacher' && !this.deactivateParent) {
                return true;
            } else {
                return false;
            }
        },
        areYouSureText(){
            if(this.createType === 'group'){
                return 'Wenn Sie die Gruppenerstellung ohne zu speichern verlassen, werden alle Eingabefelder zurückgesetzt und die Gruppe nicht angelegt';
            }else{
                return 'Wenn Sie die Nutzererstellung ohne zu speichern verlassen, werden alle Eingabefelder zurückgesetzt und der Nutzer nicht angelegt';
            }
        },
        currentParentPupils(){
            if(this.editObjectRole==='pupil' && this.editObject.parent ){
                return this.parentsById[this.editObject.parent].pupils.length;
            }
            return false;
        },
        pupilAccountsLocked(){
            const lockedParents = [];
            const returnPupilAccounts = this.pupils.map((pupil)=>{
                if(pupil.accountLocked || (pupil.parentAccountLocked && !lockedParents.includes(pupil.parent)) || (pupil.parentPinLocked && !lockedParents.includes(pupil.parent))){
                    lockedParents.push(pupil.parent);
                    return pupil;
                }else{
                    return 'error';
                }
            });
            return returnPupilAccounts.filter(acc => acc !== 'error');
        },
        teacherAccountsLocked(){
            return this.teachers.filter(teacher => teacher.accountLocked || teacher.pinLocked);
        },
        totalLockedAccounts(){
            return this.pupilAccountsLocked.concat(this.teacherAccountsLocked);
        }
    },
    watch: {
        async reset(newVal){
         if(newVal && newVal === true ){
              await this.stop()
              this.$emit('usersRemounted');
         }
        },
        getSubjectInvalidStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestSubjects();
            }
        },
        getGroupStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestGroups();
            }
        },
        password() {
            this.regenerateQrDisabled = this.password !== '';
            this.regenerateQr = this.password !== '';
        },
        passwordParent() {
            this.regenerateQrDisabledParent = this.passwordParent !== '';
            this.regenerateQrParent = this.passwordParent !== '';
        },
        selectedParent(newVal){
            if(newVal !== ''){
                this.createNewParent = false;
            }
        },
        invertedQrLoginDeactivated(newVal){
            if(newVal === true &&  (!this.editObject && this.createType !== 'pupil')){
                this.loginPinActivated = true;
            }
        },
        invertedParentQrLoginDeactivated(newVal){
            if(newVal === true){
                this.loginPinActivated = true;
            }
        }
    },
    async created() {
        // performance.mark('start');
        await this.getGroups();
        await this.getTeachers();
        await this.getSubjects(true);
        this.showPupil = true;
    },
    async beforeMount () {
        this.me = await this.getAccount();

    },
    async mounted() {
        await this.requestList();
        await this.requestSubjects();
        // performance.mark('after');
        // performance.measure('measure1', 'start', 'after');
        // const entries = performance.getEntriesByType("measure");
        // entries.forEach((entry, i) => {
        //     console.log('measure', i, entry);
        // });
        this.showLoadingOverlay = false;
        this.urlData = `https://${window.location.hostname}`;

        this.$emit('usersMounted');
    },
    methods: {
        ...mapActions('auth', ['isAccountNameAvailable', 'getAccount']),
        ...mapActions('groups', ['getGroups', 'createGroup', 'editGroup', 'deleteGroup', 'setGroupInvalidStatus']),
        ...mapActions('parents', ['getParent','getParents', 'createParent', 'editParent', 'deleteParent']),
        ...mapActions('pupils', ['getPupils', 'deletePupile', 'createPupil', 'editPupil', 'removeParentFromPupil']),
        ...mapActions('teachers', ['getTeachers', 'createTeacher', 'deleteTeacher', 'editTeacher']),
        ...mapActions('subjects', ['getSubjects', 'editSubject', 'setSubjectListStatus']),
        ...mapActions('parentalLetter', ['toggleUpdatePossible']),
        ...mapActions('imageRights', ['postImageRights']),
        ...mapActions("accounts", ["toggleUsageAgreement"]),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapMutations('accounts', ['setAccount','pushAccount']),

        lockedBadgeClicked(item){
            const role = this.accountsById[item.account].role;
            if(role === 'teacher'){
                // this.handleClickCategory('Lehrer');
                if(this.messageCategory !== 1 ){
                    this.$refs.btn2.$el.click();
                }
                this.searchLeaders = item.name;
            }else{
                 if(this.messageCategory !== 0){
                    this.$refs.btn1.$el.click();
                }
                this.searchMembers = item.name;
            }
        },
        async confirmResetPin() {
        if (this.resetPin) {
            try {
            const res = await backend.resetPin(this.editObject.account);
            const updatedAccount = await res.json();
            const logoutRes = await backend.forceLogout(updatedAccount._id);
            const logout = await logoutRes.json();            
                this.showSnackbar({message:'PIN erfolgreich zurückgesetzt', color: 'success'})
                   setTimeout(async () => {
                    this.resetPin = false;
                    this.editObject.accountHasPin = false;
                    }, 1000);
                await this.setAccount(updatedAccount);    

            } catch (error) {
                 this.showSnackbar({message:'Fehler beim zurücksetzen der PIN. Laden Sie die Seite Neu und versuchen es erneut.', color: 'error'})
                console.error('Fehler beim Entsperren des Nutzers', error);
            }
        }
        if (this.resetParentPin) {
            try {
                const parent = await this.getParent(this.editObject.parent)
            const res = await backend.resetPin(parent.account);
            const updatedAccount = await res.json();
            const logoutRes = await backend.forceLogout(updatedAccount._id);
                const logout = await logoutRes.json();
                this.showSnackbar({message:'PIN erfolgreich zurückgesetzt', color: 'success'})
                   setTimeout(async () => {
                    this.resetParentPin = false;
                    this.editObject.accountHasPin = false;
                    }, 1000);
                    
                await this.setAccount(updatedAccount);    
            } catch (error) {
                 this.showSnackbar({message:'Fehler beim zurücksetzen der PIN. Laden Sie die Seite Neu und versuchen es erneut.', color: 'error'})
                console.error('Fehler beim Entsperren des Nutzers', error);
            }
        }
        },
        async unlockUser() {
        if (this.resetLockedUser) {
            try {
            // Your asynchronous code here
            const res = await backend.resetLoginTries(this.editObject.account);
            const updatedAccount = await res.json();
            if(updatedAccount.wrongLogins === 0){
                this.showSnackbar({message:'Nutzer erfolgreich freigeschalten', color: 'success'})
                   setTimeout(async () => {
                    this.resetLockedUser = false;
                    this.editObject.accountLocked = false;
                    await this.setAccount(updatedAccount);
                    }, 1000);
            }
            } catch (error) {
                 this.showSnackbar({message:'Fehler beim Freischalten des Nutzers. Laden Sie die Seite Neu und versuchen es erneut.', color: 'error'})
                console.error('Fehler beim Entsperren des Nutzers', error);
            }
        }
        },
        async unlockParent() {
        if (this.resetLockedParent) {
            try {
            const res = await backend.resetLoginTries(this.parentsById[this.editObject.parent].account);
            const updatedAccount = await res.json();
            if(updatedAccount.wrongLogins === 0){
                this.showSnackbar({message:'Nutzer erfolgreich freigeschalten', color: 'success'})
                   setTimeout(async () => {
                    this.resetLockedParent = false;
                    this.editObject.parentAccountLocked = false;
                    await this.setAccount(updatedAccount);
                    }, 1000);
            }
            } catch (error) {
                 this.showSnackbar({message:'Fehler beim Freischalten des Nutzers. Laden Sie die Seite Neu und versuchen es erneut.', color: 'error'})
                console.error('Fehler beim Entsperren des Nutzers', error);
            }
        }
        },
        clickDeactivate() {
            if(!this.deactivateParent){
                this.deactivateParent = !this.deactivateParent;
                if(this.deactivateParent && this.currentParentPupils && this.currentParentPupils > 1){
                this.showSnackbar({message:`Achtung, dieser Eltern-Account wird aktuell von ${this.currentParentPupils - 1}
                weiteren Schülern als Eltern-Account verwendet und somit auch bei diesen deaktiviert. Um den Eltern-Account nur bei diesem Nutzer zu
                entfernen verwenden sie bitte die Option 'Eltern-Account entfernen'`, color:'warning', timeout: 10000});
                }
            } else{
                this.deactivateParent = !this.deactivateParent;
                this.scrollToParent()
            }
        },
        clickDelete(){
            if(this.currentParentPupils > 1){
                this.areYouSureDeleteText = `Achtung, der Eltern-Account wird aktuell bei ${this.currentParentPupils - 1} weiteren Schülern als Elternteil verwendet und somit auch bei diesem gelöscht.
                 Um den Account nur bei diesem Schüler zu entfernen, wählen Sie die Option 'Eltern-Account' entfernen`
            }else{
                this.areYouSureDeleteText = "Achtung der Eltern-Account sowie dessen Daten werden entgültig gelöscht. Um ihn nur vorübergehend zu deaktivieren wählen sie die option 'deaktivieren'."
            }
                this.showAreYouSureDelete = true;

        },
        async clickChangeParent(){
            await this.showSnackbar({message:'Um den Eltern-Account zu ändern, müssen sie diesen zunächst entfernen oder löschen um anschließend einen Neuen Eltern-Account anzulegen oder einen bestehenden zuweisen zu können.', color: 'warning', timeout: 10000});
        },
        async removeParent(){
            this.showLoadingOverlay = true;
            let response = await this.removeParentFromPupil({
                pupilId:this.editObject._id, parentId: this.editObject.parent});
            if (response) {
                this.showSnackbar({message: 'Eltern erfolgreich entfernt'});
            } else {
                this.showSnackbar({
                    message: 'Beim Entfernen ist ein Fehler aufgetreten',
                    color: 'error'
                });
            }
            await this.getPupils(true);
                    await this.stop();
            await this.toggleUpdatePossible(true);
            this.showLoadingOverlay = false;
        },
        convertToDate(datetime){
                const date = new Date(datetime);
                return date.toLocaleDateString('de-DE', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: '2-digit'
                                                        }) + ' um ' +
                        date.toLocaleTimeString('de-DE', {
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        })
        },
        async addNewTeacher(teacher) {
            this.teachers.push(teacher);
            this.groupLeaders.push(teacher);
            this.setGroupLeaders(this.groupLeaders);
        },

        addNewPupil(createdPupil) {
            this.pupils.push(createdPupil);
            this.groupMembers.push(createdPupil);
            this.setGroupMembers(this.groupMembers);
        },

        handleClickCategory(category){
            this.selected = [];
            this.search = ''
            switch (category) {
                case 2:
                    this.showTeacher = false;
                    this.showPupil = false;
                    this.showGroups = true;
                    break;
                case 1:
                    this.showPupil = false;
                    this.showGroups = false;
                    this.showTeacher = true;
                    break;
                case 0:
                    this.showTeacher = false;
                    this.showGroups = false;
                    this.showPupil = true;
                    break;
            }

            this.messageCategory = category;
        },

        setGroupLeaders(leaders) {
            this.groupLeaders = [...leaders].sort((a,b) => this.sortForName(a, b));
            this.validateInputGroup();
        },

        setGroupMembers(members) {
            this.groupMembers = [...members].sort((a,b) => this.sortForName(a, b));
        },

        //#region Helpers
        // a11y is abbreviation for accessibility
        async toggleA11y(a11y) {
            // Deep copy, so that the Einfach toggle doesn't vanish in the UI
            let a11ys = JSON.parse(JSON.stringify(this.accessibilities));

            if (a11y.text === 'babyView') {
                let i = a11ys.findIndex((el) => el.text === 'babyView');
                let babyView = a11ys.splice(i, 1);

                // babyView could become null sometimes, so we fix it with this
                if (babyView[0].value !== true) {
                    babyView[0].value = false;
                }
            }
            else {
                // Making sure value is either true or false, became null sometimes
                if (a11y.value !== true) {
                    a11y.value = false;
                }

                // Toggling off sehen while screen reader is true should turn both to off/false
                if (a11y.name === 'sehen' && a11y.value === false) {
                    // Toggling off in UI
                    let i = this.accessibilities.findIndex((el) => el.name === 'screenreader');
                    this.accessibilities[i].value = false;
                }
            }
            if(a11y.name === 'translationAllowed' && a11y.value === false){
                this.allowGoogleTranslate = false;
            }
        },

        setA11y(array) {
            let index = this.accessibilities.findIndex((el) => el.name === "hoeren");
            this.accessibilities[index].value = array.accessibility.hoeren;
            index = this.accessibilities.findIndex((el) => el.name === "sehen");
            this.accessibilities[index].value = array.accessibility.sehen;
            index = this.accessibilities.findIndex((el) => el.name === "screenreader");
            this.accessibilities[index].value = array.accessibility.screenreader;
            index = this.accessibilities.findIndex((el) => el.name === "motorik");
            this.accessibilities[index].value = array.accessibility.motorik;
            index = this.accessibilities.findIndex((el) => el.name === "translationAllowed");
            this.accessibilities[index].value = array.translationAllowed;
            index = this.accessibilities.findIndex((el) => el.name === "babyView");
            this.accessibilities[index].value = array.babyView;
        },

        // Gets the a11y values from the a11ys array and sets them to local ones (e.g. sehen, hoeren)
        getA11y() {
            let index = this.accessibilities.findIndex((el) => el.name === "sehen");
            this.sehen = this.accessibilities[index].value;
            index = this.accessibilities.findIndex((el) => el.name === "screenreader");
            this.screenreader = this.accessibilities[index].value;
            index = this.accessibilities.findIndex((el) => el.name === "hoeren");
            this.hoeren = this.accessibilities[index].value;
            index = this.accessibilities.findIndex((el) => el.name === "motorik");
            this.motorik = this.accessibilities[index].value;
            index = this.accessibilities.findIndex((el) => el.name === "translationAllowed");
            this.translationAllowed = this.accessibilities[index].value;
             if(this.accessibilities[index].value === false){
                this.allowGoogleTranslate = false;
            }
            index = this.accessibilities.findIndex((el) => el.name === "babyView");
            this.babyView = this.accessibilities[index].value;
        },

        // true = leader, false = members
        toggle(leader) {
            this.$nextTick(() => {
                if (leader) {
                    if (this.selectedAllLeaders) {
                        this.groupLeaders = [];
                    } else {
                        this.setGroupLeaders(this.teachers.slice());
                    }
                } else {
                    if (this.selectedAllMembers) {
                        this.groupMembers = [];
                    } else {
                        this.setGroupMembers(this.pupils.slice());
                    }
                }
            })
        },

        handleCheckbox(id) {
            if (this.selected.includes(id)) {
                this.selected.splice(this.selected.indexOf(id), 1);
            } else {
                this.selected.push(id);
            }
        },

        // Pass in true to generate loginId for teacher
        async generateUsername(teacher) {
            // When editing you can't change username
            if (this.editObject) {
                return;
            }

            this.fields.usernameGenerated = false;
            let id;
            // As teacher Peter Parker would be pparker
            if (teacher) {
                id = this.firstName.slice(0, 1).toLowerCase() + this.lastName.toLowerCase();
            }
            // As pupil Peter Parker would be peterp
            else {
                id = this.firstName.toLowerCase() + this.lastName.slice(0, 1).toLowerCase();
            }

            let available = await this.isAccountNameAvailable(id);

            if (available) {
                this.username = id;
            } else {
                this.fields.usernameGenerated = true;
                let i = 1;
                this.username = id + i;
                for (let i = 1; !available; i++) {
                    if (teacher) {
                        id = this.firstName.slice(0, 1).toLowerCase() + this.lastName.toLowerCase() + i;
                    } else {
                        id = this.firstName.toLowerCase() + this.lastName.slice(0, 1).toLowerCase() + i;
                    }

                    available = await this.isAccountNameAvailable(id);
                    this.username = id;
                }
            }
            this.username = this.username.replaceAll('ü', 'ue');
            this.username = this.username.replaceAll('Ü', 'UE');
            this.username = this.username.replaceAll('ö', 'oe');
            this.username = this.username.replaceAll('Ö', 'OE');
            this.username = this.username.replaceAll('ä', 'ae');
            this.username = this.username.replaceAll('Ä', 'AE');
        },

        async generateParentLoginId() {
            // When editing you can't change loginId
            if (this.pupilHasParent) {
                return;
            }

            this.fields.parentLoginIdGenerated = false;
            let id = this.lastName.toLowerCase();
            id = id.replaceAll('ü', 'ue');
            id = id.replaceAll('Ü', 'UE');
            id = id.replaceAll('ö', 'oe');
            id = id.replaceAll('Ö', 'OE');
            id = id.replaceAll('ä', 'ae');
            id = id.replaceAll('Ä', 'AE');

            let available = await this.isAccountNameAvailable(id);

            if (available) {
                this.usernameParent = id;
            } else {
                this.fields.parentLoginIdGenerated = true;
                let i = 1;
                this.usernameParent = id + i;
                for (let i = 1; !available; i++) {
                    id = this.lastName.toLowerCase() + i;
                    available = await this.isAccountNameAvailable(id);
                    this.usernameParent = id;
                }
            }

            await this.validateInput();
        },

        /**
         Generate and set password
         @params parent: generate it for parent only, otherwise generate for pupil/teacher only
         */
        generatePassword(parent) {
            let result = "";
            let qrResult = "";
            let allCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#$%()*+,-./:;=?@[]_";
            let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            let numbers = "0123456789";
            let additional = "!#$%()*+,-./:;=?@[]_";
            result += characters.charAt(Math.floor(Math.random() * characters.length)); // Add an uppercase letter
            result += characters.charAt(Math.floor(Math.random() * characters.length)).toLowerCase(); // Ensure lowercase
            result += numbers.charAt(Math.floor(Math.random() * numbers.length)); // Add a number
            result += additional.charAt(Math.floor(Math.random() * additional.length)); // Add a special character
                

            // Make the rest of the password, up to 10 characters or more if needed
            for (let i =  result.length; i < 10; i++) {
                    result += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
            }
            result = this.shuffle(result);
            for (let i = 0; i < 16; i++) {
                qrResult += allCharacters.charAt(
                    Math.floor(Math.random() * allCharacters.length)
                );
            }
            if (parent) {
                this.passwordParent = result;
                this.qrPasswordParent = qrResult;
            } else {
                this.password = result;
                this.qrPassword = qrResult;
            }
        },
       shuffle(string) {
            let array = string.split('');
            for (let i = array.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]]; // Swap elements
            }
            return array.join('');
        },
        generateQrPassword(parent) {
            let qrResult = "";
            let allCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#$%()*+,-./:;=?@[]^_{|}~";

            for (let i = 0; i < 16; i++) {
                qrResult += allCharacters.charAt(
                    Math.floor(Math.random() * allCharacters.length)
                );
            }

            if (parent) {
                this.qrPasswordParent = qrResult;
            } else {
                this.qrPassword = qrResult;
            }
        },

        sortForName(a, b) {
            a = a.name + " " + a.lastName;
            b = b.name + " " + b.lastName;
            return (a > b) ? 1 : ((b > a) ? -1 : 0);
        },

        alphabetically(a, b) {
            let x = (a || '').toLowerCase();
            let y = (b || '').toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        },

        setEditGroup(group) {
            this.editObjectRole = 'group';
            this.editRow(group);
        },

        scrollToParent() {
            const element = document.getElementById("parentContent");
            element.scrollIntoView();
        },

        createTeacherCallback() {
            this.createTeacherDialog = false;
            // update teachers
            this.getTeachers(true);
        },

        createGroupCallback(data) {
            if(data){
                this.groupToBeCreated = data;
                //clone teachers to not mess with teachers in store
                this.groupsWithPlaceholder = this.groups.map((x) => x);
                this.groupsWithPlaceholder.push(data);
                this.groupsTeacher.push(data);
            }
            this.createGroupDialog = false;
            this.getGroups(true);
        },

        createPupilCallback() {
            this.createPupilDialog = false;
            // todo update pupils better (to not need extra events)
            this.getPupils(true);
        },

        async handleAddSubject() {
            await this.getSubjects(true);
            this.createSubjectDialog = false;
        },
        async handleAddLocation() {
            // await this.getLocations(true);
            this.createLocationDialog = false;
        },
        //#endregion

        //#region Validation
        // result = false indicates a problem, if a field is true it indicates a problem with this specific field
        async validateInput() {
            let result = true;

            this.fields.firstName = false;
            this.fields.lastName = false;
            this.fields.username = false;
            this.fields.usernameStrangeLetters = false;
            this.fields.usernameDuplicate = false;
            this.fields.password = false;
            this.fields.groupsTeacher = false;

            // Info: parent is not required
            this.fields.parent = false;
            this.fields.parentLoginId = false;
            this.fields.parentLoginIdStrangeLetters = false;
            this.fields.parentLoginIdDuplicate = false;
            this.fields.parentPassword = false;

            if (this.firstName.replace(/\s/g, "") === "")  {
                this.fields.firstName = true;
                result = false;
            }
            if (this.lastName.replace(/\s/g, "") === "") {
                this.fields.lastName = true;
                result = false;
            }
            if(this.groupsTeacher.length > 3 ){
                this.fields.groupsTeacher = true;
                result = false;
            }
            if (this.editObjectRole === 'pupil' || this.editObjectRole === 'teacher') {
                // We don't want to check username, can't be changed in UI because it can't be changed in backend
                // (Because of matrix stuff)
            } else {
                // With strange letters we highlight the field and also show a warning about naming convention
                if (this.username.replace(/\s/g, "") === ""
                    || this.username.trim().indexOf(" ") > -1
                    || this.containsUsernameStrangeLetters(this.username)
                ) {
                    this.fields.username = true;
                    this.fields.usernameStrangeLetters = true;
                    result = false;
                }
                let available = await this.checkIdAvailability(this.username);
                if (!available) {
                    this.fields.usernameDuplicate = true;
                    result = false;
                }
            }

            // If we edit an account we only check the pw if the pw field is not empty
            // So we skip password check if we e.g. edit a pupil but don't input a new password
            if (!((this.editObjectRole === 'teacher' || this.editObjectRole === 'pupil') && this.password.trim() === '')) {
                if (!this.checkPw()) {
                    this.fields.password = true;
                    result = false;
                }
            }

            if (this.createNewParent && !this.deactivateParent) {
                // With strange letters we highlight the field and also show a warning about naming convention
                if (this.usernameParent.replace(/\s/g, "") === ""
                    || this.usernameParent.trim().indexOf(" ") > -1
                    || this.containsUsernameStrangeLetters(this.usernameParent)
                ) {
                    this.fields.parentLoginId = true;
                    this.fields.parentLoginIdStrangeLetters = true;
                    result = false;
                }

                let available = await this.checkIdAvailability(this.usernameParent);
                if (!available) {
                    this.fields.parentLoginIdDuplicate = true;
                    result = false;
                }

                if (!this.checkParentPw()) {
                    this.fields.parentPassword = true;
                    result = false;
                }
            }

            // If we already have a parent and change the password also check it
            if (this.passwordParent.trim() !== '') {
                if (!this.checkParentPw()) {
                    this.fields.parentPassword = true;
                    result = false;
                }
            }

            this.valid = result;
            return result;
        },

        // result = false indicates a problem, if a field is true it indicates a problem with this specific field
        validateInputGroup() {
            let result = true;

            this.fields.groupName = false;
            this.fields.groupNameDuplicate = false;
            this.fields.groupLeaders = false;

            if (this.editObjectRole === 'group') {
                // We don't want to check group name, can't be changed in UI 'cause it can't be changed in backend
                // (Because of matrix stuff)
            } else {
                if (this.groupName.replace(/\s/g, '') === '' || this.groupName.trim() === '') {
                    this.fields.groupName = true;
                    result = false;
                }
                if (this.groupsList.filter(el => el.name === this.groupName.trim()).length > 0) {
                    this.fields.groupNameDuplicate = true;
                    result = false;
                }
            }

            if (this.groupLeaders.length < 1) {
                this.fields.groupLeaders = true;
                result = false;
            }

            this.valid = result;
            return result;
        },

        async checkIdAvailability(loginId) {
            return await this.isAccountNameAvailable(loginId);
        },

        //checks if password is at least of length 10 and contains one letter and one number
        checkPw() {
            const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{10,})");
            return pwCheck.test(this.password);
        },

        checkParentPw() {
            const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{10,})");
            return pwCheck.test(this.passwordParent);
        },

        containsUsernameStrangeLetters(username) {
            // eslint-disable-next-line no-useless-escape
            const format = /[!@#$äüö%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            return format.test(username);
        },
        //#endregion

        //#region PDF
        async regeneratePdf(parent) {
            if (parent) {
                if (this.passwordParent) { // Parent password was set manually
                    await this.patchElement();
                } else { // Parent password gets randomly generated
                    this.showMoreParent = true;
                    // TODO fix wording for regenerate
                    this.regenerateQrParent = true;
                    this.generatePassword(true);
                    // await this.pdf(true);
                    await this.patchElement();
                }
            } else {
                if (this.password) { // Password was set manually
                    await this.patchElement();
                } else { // Password gets randomly generated
                    this.showMorePupil = true;
                    // this.regenerateQr = true;
                    this.generatePassword();
                    await this.patchElement();
                }
            }
        },

        async pdf(parent, returnPdfBytes, groups = null) {
            const role = parent ? 'parent' : this.editObjectRole ? this.editObjectRole : this.createType;
            console.log('--- DEBUG INFO ---')
            console.log(`create pdf - determined role ${role}`);
            console.log('this.editObjectRole', this.editObjectRole);
            console.log('this.createType', this.createType);
            // moved create pdf to own sub function
            const pdfGroupName = this.groupName ? this.groupName + '_' : '';
            this.showPdfOverlay = true;
            const createPdf = async (groupName = '') => {
                let pdfBytes = null;
                if (role === 'pupil' || role === 'teacher') {
                    pdfBytes = await LoginPdf.getNormalLoginPdf({
                        firstName: this.firstName,
                        lastName: this.lastName,
                        accountName: this.username,
                        password: this.password,
                        qrPassword:  this.qrPassword,
                        hidePassword: this.regenerateQr && !this.password,
                        groupName,
                        role
                    });
                } else if (role === 'parent') {
                    pdfBytes = await LoginPdf.getParentLoginPdfv2({
                        fullChildName: `${this.firstName} ${this.lastName}`,
                        accountName: this.usernameParent,
                        password: this.passwordParent,
                        qrPassword:  this.qrPasswordParent,
                        hidePassword: this.regenerateQrParent && !this.passwordParent,
                    })
                } else {
                     this.showPdfOverlay = false;
                
                    console.warn('Trying to generate pdf with invalid role:', role, ' | returning');
                    return;
                }

                if(returnPdfBytes || (groups && groups.length)) {
                     this.showPdfOverlay = false;
                    return pdfBytes;
                } else {
                    let pdfName = '';
                    if (role === 'parent') {
                        pdfName = 'Eltern_von_' + this.firstName + ' ' + this.lastName;
                    } else if (role === 'pupil') {
                        pdfName = 'SuS_' + this.lastName + '_' + this.firstName;
                    } else {
                        pdfName = 'LuL_' + this.lastName + '_' + this.firstName;
                    }
                    const completeName = pdfGroupName + pdfName;
                    this.saveByteArray(completeName + ".pdf", pdfBytes);
                }
            }
            if (groups ? groups.length : false) {
                // loop
                let excelImportReturn;
                let fullGroupsString = '';
                for (let i = 0; i < groups.length; i++) {
                    if (i === 0) {
                        fullGroupsString = groups[i].name;
                    } else {
                        fullGroupsString = fullGroupsString.concat(', ' + groups[i].name);
                    }
                }
                    // create pdf
                    const pdfBytes = await LoginPdf.getNormalLoginPdf({
                        firstName: this.firstName,
                        lastName: this.lastName,
                        accountName: this.username,
                        password: this.password,
                        qrPassword:  this.qrPassword,
                        hidePassword: this.regenerateQr && !this.password,
                        groupName: fullGroupsString,
                        role
                    });
                    // check for excel import
                    if (returnPdfBytes) {
                        excelImportReturn = pdfBytes;
                    }
                this.saveByteArray(`${fullGroupsString}_${(role === 'pupil') ? 'SuS' : (role === 'parent') ? 'Eltern' : 'LuL'}_${this.lastName}_${this.firstName}.pdf`, pdfBytes);
                // check for excel import
                if (returnPdfBytes) {
                     this.showPdfOverlay = false;
                    return excelImportReturn;
                }
                return true;
            }
            const pdf = await createPdf();
            this.showPdfOverlay = false;            
            return pdf;
        },

        saveByteArray(reportName, byte) {
            let blob = new Blob([byte], {type: "application/pdf"});
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = reportName;
            this.showPdfOverlay = false;
            link.click();
        },
        //#endregion

        async requestSubjects() {
            // this.subjects = await this.getSubjects();
            // this.subjects.sort((a, b) => this.alphabetically(a.name, b.name));
            // this.setSubjectListStatus(false);
        },

        async requestGroups() {
            await this.setGroupInvalidStatus(false);
        },

        setTeacherGroups(teacherId) {
                const teacherGroups = [];

                this.groups.forEach((group) => {
                    if(group.leaders && group.leaders.includes(teacherId)){
                        teacherGroups.push(group);
                    }
                });

                return teacherGroups;
        },

        async requestList() {
            // this.showLoadingOverlay = true;

            // // Todo maybe think about how to update data
            // this.getGroups(update);
            // this.getParents(update);
            // this.getPupils(update);
            // this.getTeachers(update);

            this.showLoadingOverlay = false;
        },

        async editRow(value) {
            if (this.editObjectRole !== 'group') {
                value.accountRole === 'pupil' ? this.editObjectRole = 'pupil' : this.editObjectRole = 'teacher';
            }
            this.editObject = value;
            console.log(value);
            if (this.editObjectRole === 'pupil') {
                // When creating pupil, we create new parent by default
                // But now we edit, so by default we assume there is already a parent
                if(value.parent){
                    this.pupilHasParent = true;
                    this.createNewParent = false;
                }else {
                    this.pupilHasParent = false;
                    this.deactivateParent = true;
                }

                // Set accessibility values
                if (value.accessibility) {
                    this.setA11y(value);
                }

                //set groups for pupil
                this.editGroups = this.editObject.pupilGroups;
                //create List of GroupLeaders to display on Pupil
                let leaderList;
                leaderList = this.editObject.pupilGroups.map((group) => group.leaders);

                leaderList = leaderList.reduce(function (list1, list2){
                  return  [...new Set(list1.concat(list2))];
                },[]);
                this.pupilTeachers = leaderList.map((leader) => this.teachersById[leader]);
                this.canChangePassword = this.accountsById[value.account].canChangePassword;
                this.allowGoogleTranslate = this.accountsById[value.account].allowGoogleTranslate;
                this.qrLoginDeactivated = this.accountsById[value.account].qrLoginDeactivated;
            }
            else if (this.editObjectRole === 'teacher') {
                this.createNewParent = false;

                // this.subjects = await this.getSubjects();
                // this.subjects.sort((a, b) => this.alphabetically(a.name, b.name));

                this.subjectsTeacher = [];
                this.locationsTeacher =[];
                this.groupsTeacher = [];
                this.canEditTimeslot = this.editObject.canEditTimeslot;
                this.canEditQrCodes = this.editObject.canEditQrCodes;
                this.pinActivated =  this.accountsById[value.account].pinActivated;
                this.messagesPinActivated =  this.accountsById[value.account].messagesPinActivated;
                this.qrLoginDeactivated = this.accountsById[value.account].qrLoginDeactivated;
                this.loginPinActivated = this.accountsById[value.account].loginPinActivated;
                // Check which subjects teacher teaches to preselect them in v-select
                for (let i = 0; i < this.subjects.length; i++) {
                    let currSubj = this.subjects[i];
                    for (let j = 0; j < currSubj.teachers.length; j++) {
                        if (currSubj.teachers[j]._id === this.editObject._id) {
                            // As the v-select has return-object we also need to push the object and not only it's id
                            this.subjectsTeacher.push(currSubj);
                            break;
                        }
                    }
                }

                this.subjectDiff = this.subjectsTeacher;

                this.locationsTeacher = this.editObject.locations;

                this.groupsTeacher = this.editObject.teacherGroups;
                const res = await backend.accountHasPin(this.editObject.account);
                if(res.status === 200){
                    this.editObject.accountHasPin = true;
                }
            }
            else if (this.editObjectRole === 'group') {
                this.groupName = this.editObject.name;

                // Group leaders can only be teachers, so we find the leaders by their id in teachers array
                // map has if because deleting from group is difficult
                const leaders = this.editObject.leaders.map(leaderId => {
                    const teacherObj = this.teachers.find(teacher => teacher._id === leaderId);
                    if (teacherObj) {
                        return teacherObj;
                    }
                });
                this.setGroupLeaders(leaders);
                this.setGroupMembers(this.editObject.participants);
                return;
            }

            // Get the pupils parent if there is a parent id
            let parent = null;

            if (this.editObjectRole === 'pupil' && value.parent) {
                const parentObject = await this.getParent(value.parent);
                const account = this.accounts.find(acc => acc._id === parentObject.account );    // Get acc from vuex store
                this.parentUsageAgreement = account.signedUsageAgreement;
                this.pupilHasParent = true;
                this.parentPinActivated = account.messagesPinActivated;
                this.parentQrLoginDeactivated = account.qrLoginDeactivated;
                this.loginPinActivated = account.loginPinActivated;
                const hasPinRes =  await backend.accountHasPin(parentObject.account);
                if(hasPinRes.status === 200){
                   this.editObject.parentAccountHasPin = true;
                }
                if (account.deactivated === 'true' || account.deactivated === true) {
                    this.deactivateParent = true;
                }
                this.groupName = (this.editObject.pupilGroups[0] || {}).name;

                this.usernameParent = account.accountName;
                this.noteParent = account.note;
            }

            if (this.editObjectRole === 'pupil' || this.editObjectRole === 'teacher') {
                this.firstName = value.name;
                this.lastName = value.lastName;
                if (this.editObjectRole === 'teacher') {
                    this.username = value.accountName;
                    this.hoursPerWeek = value.hoursPerWeek;
                    this.substitutionBlocked = value.substitutionBlocked;
                } else {
                    // Pupil's username is in account model so we gotta do this
                    let allAccounts = this.accounts;
                    let pupil = allAccounts.find(el => el._id === value.account);
                    this.username = pupil.accountName;
                }
                this.note = value.note;
            }

            if (!this.pupilHasParent) {
                this.generateParentLoginId();
                this.generatePassword(true);
            }
        },

        // Erneuern with multi-select
        async bulkRegenerate() {
            this.showLoadingOverlay = true;

            const jsZip = new JSZip();

            await this.selected.reduce(async (previousPromise, user) => {
                const previous = await previousPromise;
                this.regenerateProgress = previous;

                let parent  = null;
                this.editObjectRole = user.accountRole;
                if (user.accountRole === 'pupil') {
                    parent = this.parents.find(parent => parent.pupils.find(child => child && child._id === user._id));
                }

                this.firstName = user.name;
                this.lastName = user.lastName;
                if (this.editObjectRole === 'teacher') {
                    this.username = user.accountName;
                } else {
                    // Pupil's username is in account model so we gotta do this
                    let allAccounts = this.accounts;
                    let pupil = allAccounts.find(el => el._id === user.account);
                    this.username = pupil.accountName;
                }
                this.generatePassword();

                const response = await backend.patchAccount(user.account, {
                    ...(this.password ? { 'password' : this.password } : {}),
                    ...(this.password ? { 'qrPassword' : this.qrPassword } : {}),
                });
                const pushObj = await response.json();
                pushObj.status = response.status;
                const pdfBytes = await this.pdf(false, true);
                jsZip.file(`${this.username}.pdf`, pdfBytes);

                // If pupil has parent also patch their account
                if (parent) {
                    let parentAccount = this.accountsById[parent.account];
                    this.usernameParent = parentAccount.accountName; // Have to set this for writing it in PDF

                    this.generatePassword(true);

                    const parentRes = await backend.patchAccount(parent.account, {
                        ...(this.passwordParent ? { 'password' : this.passwordParent } : {}),
                        ...(this.passwordParent ? { 'qrPassword' : this.qrPasswordParent } : {}),
                    });
                    const parentPatchRes = await parentRes.json();
                    parentPatchRes.status = parentRes.status;

                    // PDF after patching, otherwise pdf with invalid data would be generated if patch fails
                    const parentPdfBytes = await this.pdf(true, true);
                    jsZip.file(`${this.usernameParent}.pdf`, parentPdfBytes);
                }

                previous.push(pushObj);
                return previous;
            }, Promise.resolve([]));

            const content = await jsZip.generateAsync({type: 'blob'});
            saveAs(content, 'Erneuerte-Accounts.zip');

            this.showLoadingOverlay = false;
            this.confirmDialog = false;
            this.regenerateProgress = null;
            // await this.stop();
        },

        // There are 3 delete cases:
        // 1) Looking at pupil/teachers, so we check which role they have
        // 2) Looking at groups & wanting to delete group members (which are pupils) so we compare "|| this.showGroups"
        // 3) Looking at groups & wanting to delete groups
        async bulkDelete() {
            this.showLoadingOverlay = true;
            let error = 0;

            this.showSnackbar({ message: 'Löschen in Arbeit', color: 'blue' });

            await this.selected.reduce(async (previousPromise, selection) => {
                const previous = await previousPromise;
                this.deleteProgress = previous;
                let response = null;
                if (!selection.account) {
                    response = await this.deleteGroup(selection);
                } else if (selection.accountRole === 'pupil') {
                    response = await this.deletePupile(selection._id);
                } else if (selection.account) {
                    response = await this.deleteTeacher(selection._id);
                }

                if (!response) { error++; }
                previous.push(response);
                return previous;
            }, Promise.resolve([]));

            if (error > 0) {
                this.showSnackbar({ message: `Beim Löschen sind ${error} Fehler aufgetreten`, color: 'error' });
            } else {
                this.showSnackbar({ message: 'Löschen erfolgreich' });
            }

            this.deleteDialog = false;
            // Todo update better
            await this.getTeachers(true);
            await this.getPupils(true);
            await this.getGroups(true);
            await this.stop();
        },

        // Universal delete function
        async deleteElement() {
            this.showLoadingOverlay = true;

            this.deleteLoading = true;
            let response;
            if (this.editObjectRole === 'pupil') {
                response = await this.deletePupile(this.editObject._id);
                await this.getPupils(true);
            }
            else if (this.editObjectRole === 'teacher') {
                response = await this.deleteTeacher(this.editObject._id);
                await this.getTeachers(true);
            }
            else if (this.editObjectRole === 'group') {
                response = await this.deleteGroup(this.editObject._id);
            }
            await this.getGroups(true);

            if (response) {
                this.showSnackbar({message: 'Löschen erfolgreich'});
            } else {
                this.showSnackbar({
                    message: 'Beim Löschen ist ein Fehler aufgetreten',
                    color: 'error'
                });
            }

            await this.stop();
            this.deleteLoading = false;
            this.showLoadingOverlay = false;
        },

        async deleteParentAccount() {
            if(this.showAreYouSureDelete){
                this.showAreYouSureDelete = false;
            }
            this.showLoadingOverlay = true;
            let response = await this.deleteParent(this.editObject.parent);
            if (response) {
                this.showSnackbar({message: 'Löschen erfolgreich'});
            } else {
                this.showSnackbar({
                    message: 'Beim Löschen ist ein Fehler aufgetreten',
                    color: 'error'
                });
            }
            await this.getPupils(true); // TODO: Just a quick fix. Would me more elegant to delete the parent attribute locally to avoid backend request
            await this.stop();
            await this.toggleUpdatePossible(true);
            this.showLoadingOverlay = false;
        },

        // Universal save/create function
        async saveElement() {
            let valid;
            if (this.createType === 'group') {
                valid = this.validateInputGroup();
            } else {
                valid = await this.validateInput();
            }
            // If an input is not valid (change only fires when input is no longer focused)
            // so we do this to prevent: changing an input field to an invalid input, then clicking save
            if (!valid) { return; }

            this.showLoadingOverlay = true;

            let response, data;
            if (this.createType === 'pupil') {
                let parentIdPupil;

                // Create parent, create first to save reference to parent in pupil account
                // Check for selectedParent first, as createNewParent isn't false when selecting an existing parent account
                if (this.selectedParent) {
                    let parentAcc = this.parents.find(parent => parent.account === this.selectedParent);
                    parentIdPupil = parentAcc._id;

                }
                else if (this.createNewParent && !this.deactivateParent) {
                    let givenDefaultPic = defaultProfilePicture;
                    try {
                        /**  set avatar.svg as profile picture string */
                        const split1 = defaultProfilePicture.split('/');
                        const split2 = split1[split1.length - 1].split('.')[0];
                        givenDefaultPic = {
                            img: `/${split2}.svg`,
                        }
                    } catch (e) {
                        console.warn(e);
                    }

                    let parentData = {
                        gender: '',
                        accountName: this.usernameParent.trim(),
                        password: this.passwordParent,
                        qrPassword: this.qrPasswordParent,
                        note: this.noteParent,
                        pupils: [],
                        profileColor: '#95509C',
                        profilePicture: givenDefaultPic,
                        qrLoginDeactivated: this.parentQrLoginDeactivated,
                        loginPinActivated: this.loginPinActivated,
                        messagesPinActivated: this.parentPinActivated,
                    };

                    let responseParent = await this.createParent(parentData);
                    parentIdPupil = responseParent["_id"];

                    if (Number.isInteger(responseParent)) {
                        this.showSnackbar({
                            message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                            color: 'error'
                        });
                    }
                }

                this.getA11y();

                let givenDefaultPic = defaultProfilePicture;
                try {
                    /**  set avatar.svg as profile picture string */
                    const split1 = defaultProfilePicture.split('/');
                    const split2 = split1[split1.length - 1].split('.')[0];
                    givenDefaultPic = {
                        img: `/${split2}.svg`,
                    }
                } catch (e) {
                    console.warn(e);
                }

                // Create pupil
                data = {
                    name: this.firstName,
                    lastName: this.lastName,
                    gender: '',
                    note: this.note,
                    babyView: this.babyView,
                    translationAllowed: this.translationAllowed,
                    accessibility: {
                        sehen: this.sehen,
                        screenreader: this.screenreader,
                        hoeren: this.hoeren,
                        motorik: this.motorik,
                    },
                    allowGoogleTranslate: this.allowGoogleTranslate,
                    accountName: this.username.trim(),
                    password: this.password,
                    qrPassword: this.qrPassword,
                    parent: parentIdPupil,
                    profileColor: '#343e55',
                    profilePicture: givenDefaultPic,
                    canChangePassword: this.canChangePassword,
                    qrLoginDeactivated: this.qrLoginDeactivated,
                }

                response = await this.createPupil(data);

                if (Number.isInteger(response)) {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                    await this.stop();
                    await this.toggleUpdatePossible(true);
                    return;
                }
                const imageRight = await this.postImageRights({pupilId: response._id, restrictImage: false})

                // Add children to newly created or existing parent account
                let pupils = [];
                let parentId;

                // Use existing parent account
                if (!this.deactivateParent && this.selectedParent) {
                    let parentPupils;
                    let parent = this.parents.find(parent => parent.account === this.selectedParent);
                        parentPupils = parent.pupils;
                    if(pupils){
                        parentPupils.push(response["_id"]);
                    }
                    parentId = parent._id;
                    pupils = parentPupils.map(pupil => pupil._id);
                    pupils.pop();
                    pupils.push(parentPupils.pop());
                }
                // Use newly created parent account
                else if (!this.deactivateParent && this.createNewParent) {
                    pupils.push(response["_id"]);
                    parentId = parentIdPupil;
                }

                if (!this.deactivateParent && (this.createNewParent || this.selectedParent)) {
                    let parentUpdate = {
                        _id: parentId,
                        pupils: pupils,
                    };
                    let responseUpdate = await this.editParent(parentUpdate);
                    this.noParents = false;
                    if (Number.isInteger(responseUpdate)) {
                        this.showSnackbar({
                            message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                            color: 'error'
                        });
                        await this.stop();
                        await this.toggleUpdatePossible(true);
                        return;
                    }
                }

                // todo handle update better
                await this.getPupils(true);
                await this.getParents(true);

                this.showSnackbar({message: 'Gespeichert!'});
                this.saveDialog = true;
            }
            else if (this.createType === 'teacher') {
                this.showLoadingOverlay = true;

                let responseSubject, responseGroup;
                let errorOnSubjectOrGroup = false;

                let givenDefaultPic = defaultProfilePicture;
                try {
                    /**  set avatar.svg as profile picture string */
                    const split1 = defaultProfilePicture.split('/');
                    const split2 = split1[split1.length - 1].split('.')[0];
                    givenDefaultPic = {
                        img: `/${split2}.svg`,
                    }
                } catch (e) {
                    console.warn(e);
                }

                data = {
                    name: this.firstName,
                    lastName: this.lastName,
                    gender: '',
                    note: this.note,
                    accountName: this.username,
                    password: this.password,
                    qrPassword: this.qrPassword,
                    simpleEditor: this.simpleEditor,
                    hoursPerWeek: Number(this.hoursPerWeek) || 40,
                    substitutionBlocked: this.substitutionBlocked,
                    profileColor: '#ff6633',
                    profilePicture: givenDefaultPic,
                    canEditTimeslot: this.canEditTimeslot,
                    canEditQrCodes: this.canEditQrCodes,
                    pinActivated: this.pinActivated,
                    messagesPinActivated: this.messagesPinActivated,
                    qrLoginDeactivated: this.qrLoginDeactivated,
                    loginPinActivated: this.loginPinActivated,
                    locations: this.locationsTeacher,
                };

                let response = await this.createTeacher(data);

                // Add teacher to selected subjects (if any subject(s) are selected)
                if (this.subjectsTeacher.length > 0) {
                    for (let i = 0; i < this.subjectsTeacher.length; i++) {
                        let newTeachers = this.subjectsTeacher[i].teachers.concat(response['_id']);
                        let data = {
                            _id: this.subjectsTeacher[i]._id,
                            teachers: newTeachers,
                        }
                        responseSubject = await this.editSubject(data);

                        if (Number.isInteger(responseSubject)) {
                            errorOnSubjectOrGroup = true;
                        }
                    }
                }
                // create Group if necessary
                if(this.groupToBeCreated){
                   let groupData = this.groupToBeCreated;
                   //set placeholdersId to Id from new created Teacher
                   const fullLeaders = groupData.leaders.map(leaderId => {
                        return leaderId === 'placeholder' ? response['_id'] : leaderId;
                   }
                   )
                    groupData.leaders = fullLeaders;
                    const groupCreation = await this.createGroup(groupData)
                    if (Number.isInteger(groupCreation)) {
                            errorOnSubjectOrGroup = true;
                        }
                }
                // Add teacher as leader to selected groups (if any group(s) are selected)
                if (this.groupsTeacher.length > 0) {
                    //filter out placeholder since this one is already created above
                    this.groupsTeacher = this.groupsTeacher.filter(obj => obj !== this.groupToBeCreated);
                    for (let i = 0; i < this.groupsTeacher.length; i++) {
                        let newLeaders = this.groupsTeacher[i].leaders.concat(response['_id']);
                        let data = {
                            _id: this.groupsTeacher[i]._id,
                            leaders: newLeaders,
                        }

                        responseGroup = await this.editGroup(data);

                        if (Number.isInteger(responseGroup)) {
                            errorOnSubjectOrGroup = true;
                        }
                    }
                }
                if (Number.isInteger(response)) {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                    await this.stop();
                    await this.toggleUpdatePossible(true);
                    return;
                } else {
                    this.saveDialog = true;
                    this.showSnackbar({ message: 'Gespeichert!'});
                }

                await this.getTeachers(true);
                await this.getGroups(true);
                await this.getSubjects(true);
            }
            else if (this.createType === 'group') {
                data = {
                    name: this.groupName.trim(),
                    leaders: this.groupLeaders.map(el => el._id),
                    participants: this.groupMembers.map(el => el._id),
                };

                response = await this.createGroup(data);

                if (Number.isInteger(response)) {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                    await this.stop();
                    await this.toggleUpdatePossible(true);
                    return;
                } else {
                    this.showSnackbar({ message: 'Gespeichert!'});
                    // Todo handle update better
                    await this.getGroups(true);
                    await this.stop();
                }
            }
            else {
                console.warn('Save function not implemented for this type');
            }
            await this.toggleUpdatePossible(true);
            this.showLoadingOverlay = false;
        },

        // Universal patch/edit method
        async patchElement() {
            var noError = true;
            let valid;
            if (this.editObjectRole === 'group') {
                valid = this.validateInputGroup();
            } else {
                valid = await this.validateInput();
            }
            // If an input is not valid (change only fires when input is no longer focused)
            // so we do this to prevent: changing an input field to an invalid input, then clicking save
            if (!valid) { return; }
            this.showLoadingOverlay = true;
            let canStop = false;
            let changedSubjectResponses, changedGroupResponses;

            // update changed group array for accounts
            const updateGroupsForAccount = async (initialGroups, editedGroups, changedKey) => {
                const diff = this.symDiff(initialGroups, editedGroups);
                const responseGroup = await diff.reduce(async (previousPromise, group) => {
                    const previous = await previousPromise;
                    const groupId = group._id;
                    const leaders = group.leaders;
                    let newArray;
                    //check if diffed elements belonged to initialGroups or were added
                    if(editedGroups && editedGroups.includes(group)) {
                        newArray = group[changedKey]
                            .filter(participant => !!participant)
                            .map(participant => participant._id || participant)
                            .concat(this.editObject._id);
                    } else {
                        newArray = group[changedKey]
                            .filter(participant => participant && participant._id !== this.editObject._id)
                            .map(participant => participant._id || participant)
                    }
                    const data = {_id: groupId, leaders: leaders };
                    data[changedKey] = newArray;
                    const response = await this.editGroup(data);
                    previous.push(response);
                    return previous;
                }, Promise.resolve([]));
                return responseGroup;
            };

            // Info: parent is not required
            if (this.editObjectRole === 'pupil') {
                this.showMorePupil = true;
                this.showMoreParent = true;

                let parentId, responseParent;

                // If pupil has no reference to parent yet but there's usernameParent -> Create new parent account
                // if (!this.editObject.parent && this.usernameParent.trim()) {

                //     this.createNewParent = true;
                // }

                // Create a new parent account if the pupil already has an account and creating new parent is true
                // Create parent first to save reference to parent in pupil account
                if (this.createNewParent && !this.deactivateParent) {
                    let givenDefaultPic = defaultProfilePicture;
                    try {
                        /**  set avatar.svg as profile picture string */
                        const split1 = defaultProfilePicture.split('/');
                        const split2 = split1[split1.length - 1].split('.')[0];
                        givenDefaultPic = {
                            img: `/${split2}.svg`,
                        }
                    } catch (e) {
                        console.warn(e);
                    }

                    let parentData = {
                        gender: '',
                        accountName: this.usernameParent.trim(),
                        password: this.passwordParent,
                        note: this.noteParent,
                        profilePicture: givenDefaultPic,
                    };
                    responseParent = await this.createParent(parentData);
                    parentId = responseParent._id;

                    if (Number.isInteger(responseParent)) {
                        this.showSnackbar({
                            message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                            color: 'error'
                        });
                        noError = false;
                    } else {
                        responseParent.accountName = this.usernameParent.trim();
                    }
                }
                // Use already existing parent account chosen in dropdown
                else if (this.selectedParent) {
                    let parent = this.parents.find(parent => parent.account === this.selectedParent);
                    parentId = parent._id;
                }

                this.getA11y();

                if(this.regenerateQr) {
                    this.generateQrPassword();
                }

                // Changed pupil data
                // _id needs to be of the pupil account
                let data = {
                    _id: this.editObject._id,
                    name: this.firstName,
                    lastName: this.lastName,
                    note: this.note,
                    babyView: this.babyView,
                    translationAllowed: this.translationAllowed,
                    accessibility: {
                        sehen: this.sehen,
                        screenreader: this.screenreader,
                        hoeren: this.hoeren,
                        motorik: this.motorik,
                    },
                    accountName: this.username.trim(),
                    password: this.password,
                    qrPassword: this.qrPassword,
                    ...(parentId ? { parent : parentId } : {}),
                }

                // process changes from groups selector
                const initialGroups = this.editObject.pupilGroups;
                const editedGroups = this.editGroups;
                changedGroupResponses = await updateGroupsForAccount(initialGroups, editedGroups, 'participants');

                const accountPatchBody = {
                    ...({ 'qrLoginDeactivated' : this.qrLoginDeactivated }),
                    ...({ 'canChangePassword' : this.canChangePassword }),
                    ...({ 'allowGoogleTranslate' : this.allowGoogleTranslate }),
                    ...(this.password ? { 'password' : this.password } : {}),
                    ...(this.qrPassword ? { 'qrPassword' : this.qrPassword } : {}),
                    ...(changedGroupResponses.length > 0
                        ? { groups: editedGroups.map((editedGroup) => editedGroup._id) }
                        : {})
                }

                const accountPatchRes = await backend.patchAccount(this.editObject.account, accountPatchBody);
                let logoutRes = await backend.forceLogout(this.editObject.account);
                if(this.revokeUsageAgreement){
                     const agreementRes = await this.toggleUsageAgreement({accountId: this.editObject.account});
                }
                let response = await this.editPupil(data);

                if (Number.isInteger(response) || accountPatchRes.status !== 200) {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                    noError = false;
                }

                let pupils = [];
                let parentResponse, parent;

                // If pupil already has parent
                if (this.editObject.parent) {
                    parent = await this.getParent(this.editObject.parent);
                }
                // Get selected parent
                else if (!this.editObject.parent && this.selectedParent){
                    //parent = this.parents.find(parent => parent.account.accountName === this.selectedParent);
                    parent = this.parents.find(parent => parent.displayName === this.selectedParent);
                } else {
                    this.noParents = true;
                }

                // Updating existing parent
                if (!this.createNewParent) {
                    if (parent) {
                        pupils = parent.pupils;
                        pupils.push(response["_id"]);
                        // Patch parent
                        const parentData = {
                            _id: parent._id,
                            note: this.noteParent,
                        };
                        if (this.regenerateQrParent) {
                            this.generateQrPassword(true);
                        }

                        const accountPatchBody = {
                            qrLoginDeactivated: this.parentQrLoginDeactivated,
                            loginPinActivated: this.loginPinActivated,
                            messagesPinActivated: this.parentPinActivated,
                            ...(this.passwordParent.trim() !== '' ? { 'password' : this.passwordParent } : {}),
                            ...(this.qrPasswordParent.trim() !== '' ? { 'qrPassword' : this.qrPasswordParent } : {}),
                            // Set status of deactivated in parent account
                            deactivated: `${this.deactivateParent}`
                        }

                        // Todo maybe move this to store because dangerous
                        await backend.patchAccount(parent.account, accountPatchBody);

                        logoutRes = await backend.forceLogout(parent.account);
                        if(this.revokeParentAgreement){
                            const parentAgreementRes = await this.toggleUsageAgreement({accountId:parent.account});
                        }
                        parentResponse = await this.editParent(parentData);
                    }
                }
                // Create new parent account, this pupil will be it's first pupil
                else if (this.createNewParent && !this.deactivateParent) {
                    pupils.push(this.editObject._id); // This is the id of the already existing pupil acc

                    // Update parent account with pupil
                    let parentUpdate = {
                        _id: responseParent["_id"],
                        pupils: pupils,
                    };
                    parentResponse = await this.editParent(parentUpdate);
                    this.noParents = false;

                }

                // TODO handle error better
                if (Number.isInteger(parentResponse)) {
                    this.showSnackbar({ message: 'Ein unerwarteter Fehler ist aufgetreten!', color: 'error' });
                    noError = false;
                } else {
                    if ((this.password || this.regenerateQr || this.regenerateQrParent || this.passwordParent || this.createNewParent) && !this.selectedParent) {
                        this.patchDialog = true;
                    } else {
                        canStop = true;
                    }
                }
            }
            else if (this.editObjectRole === 'teacher') {

                changedSubjectResponses = await this.subjects.reduce(async (previousPromise, subject) => {
                    const previous = await previousPromise;
                    let response = null;
                    // find subject in teacherSubjects
                    const teacherSubject = this.subjectsTeacher.find(sub => sub._id === subject._id);
                    if (teacherSubject) {
                        // if true check if subject already has teacher
                        if (!subject.teachers.find(teacher => teacher._id === this.editObject._id)) {
                            // if not update subject
                            let data = {
                                _id: subject._id,
                                teachers: subject.teachers.map((teacher) => teacher._id).concat(this.editObject._id),
                            }
                            response = await this.editSubject(data);
                        }
                    } else {
                        // else check if subject used to have object
                        const teacherIndex = subject.teachers.findIndex(teacher => teacher._id === this.editObject._id);
                        if (teacherIndex >= 0) {
                            // if yes then update subject
                            let data = {
                                _id: subject._id,
                                teachers: subject.teachers.filter((teacher) => teacher._id !== this.editObject._id).map((teacher) => teacher._id),
                            }
                            response = await this.editSubject(data);
                        }
                    }
                    if (response) {
                        previous.push(response);
                    }
                    return previous;
                }, Promise.resolve([]));

                const initialGroups = this.editObject.teacherGroups;
                // newLeaders contains selected groups,therefore groups where teacher is leader
                const newLeaders = this.groupsTeacher;
                changedGroupResponses = await updateGroupsForAccount(initialGroups, newLeaders, 'leaders');
                // TODO handle update group edit
                // await this.getTeachers(true);
                // await backend.getTeachers();
                // await this.requestList();

                if(this.regenerateQr) {
                    this.generateQrPassword();
                }

                const teacherPatchBody = {
                    _id: this.editObject._id,
                    name: this.firstName,
                    lastName: this.lastName,
                    note: this.note,
                    hoursPerWeek: Number(this.hoursPerWeek) || 40,
                    substitutionBlocked: this.substitutionBlocked,
                    simpleEditor: false,
                    canEditTimeslot: this.canEditTimeslot,
                    canEditQrCodes: this.canEditQrCodes,
                    locations: this.locationsTeacher,
                };
                const accountPatchBody = {
                    accountName: this.username,
                    loginPinActivated: this.loginPinActivated,
                    ...(this.password ? { 'password' : this.password } : {}),
                    ...(this.qrPassword ? { 'qrPassword' : this.qrPassword } : {}),
                    note: this.note,
                    pinActivated: this.pinActivated,
                    messagesPinActivated: this.messagesPinActivated,
                    qrLoginDeactivated: this.qrLoginDeactivated,
                }
                const accountPatchRes = await backend.patchAccount(this.editObject.account, accountPatchBody);
                const logoutRes = await backend.forceLogout(this.editObject.account);
                if(this.revokeUsageAgreement){
                     const agreementRes = await this.toggleUsageAgreement({accountId: this.editObject.account});
                }
                let response = await this.editTeacher(teacherPatchBody);
                if (Number.isInteger(response) || accountPatchRes.status !== 200) {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen. Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                    noError = false;
                } else {
                    this.subjectsTeacher = [];

                    // Show dialog to download new pdf again
                    // (we don't do this if only username changed, as we always need username and password on the pdf)
                    if (this.password || this.regenerateQr) {
                        this.patchDialog = true;
                        await this.toggleUpdatePossible(true);
                        return;
                    } else {
                        canStop = true;
                    }
                }
            }
            else if (this.editObjectRole === 'group') {
                const leaders = [];
                this.groupLeaders.forEach(el => {
                    if (el) {
                        leaders.push(el._id);
                    }
                });
                let data = {
                    _id: this.editObject._id,
                    leaders,
                    participants: this.groupMembers.map(el => el._id),
                };
                let response = await this.editGroup(data);
                if (Number.isInteger(response)) {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                    noError = false;
                } else {
                    // await this.stop();
                    canStop = true;
                }
            }

            // Doing the update thing
            if (this.editObjectRole === 'group') {
                // todo update - can we do this from store?
                await this.getGroups(true);
            }
            if (this.editObjectRole === 'teacher') {
                // todo update - can we do this from store?
                await this.getTeachers(true);
                // do subject update
                await this.getSubjects(true)
                // do group update
                await this.getGroups(true);
            }
            if (this.editObjectRole === 'pupil') {
                // todo update - can we do this from store?
                // temporary, update locally in the future
                await this.getPupils(true);
                // do parent update
                await this.getParents(true);
                // do group update
                await this.getGroups(true);
            }
            // Todo check subject and group responses for errors

            if (canStop) {
                await this.stop();
            }
            await this.toggleUpdatePossible(true);
            this.showLoadingOverlay = false;
            if (noError) {
                this.showSnackbar({ message: 'Gespeichert! Nutzer wurde aufgrund von Änderung ausgeloggt.'});
            }
        },

        symDiff() {
            const sets = [];
            const result = [];
            // make copy of arguments into an array
            const args = Array.prototype.slice.call(arguments, 0);
            // put each array into a set for easy lookup
            args.forEach(function(arr) {
                sets.push(new Set(arr));
            });
            // now see which elements in each array are unique
            // e.g. not contained in the other sets
            args.forEach(function(array, arrayIndex) {
                // iterate each item in the array
                array.forEach(function(item) {
                    let found = false;
                    // iterate each set (use a plain for loop so it's easier to break)
                    for (let setIndex = 0; setIndex < sets.length; setIndex++) {
                        // skip the set from our own array
                        if (setIndex !== arrayIndex) {
                            if (sets[setIndex].has(item)) {
                                // if the set has this item
                                found = true;
                                break;
                            }
                        }
                    }
                    if (!found) {
                        result.push(item);
                    }
                });
            });
            return result;
        },

        // Setting up things when changing to create screen (where all the inputs for data are)
        async createElement(role) {
            this.showMorePupil = true;
            this.createType = role;
            this.valid = false;

            this.generatePassword(false);

            // For pupil we also need a password for parents
            if (role === 'pupil') {
                this.babyView = true;   // babyView is now set true by default as pupils can disable it themselves
                this.showMoreParent = true;
                this.createNewParent = true;
                this.generatePassword(true);
                this.allowGoogleTranslate = false;
                this.parentQrLoginDeactivated = true;
            } else if (role === 'teacher') {
                this.createNewParent = false;
                this.qrLoginDeactivated = true;
                await this.getGroups(true);
            } else if (role === 'group') {
                this.setGroupLeaders(this.groupLeaders);
            }
        },

        // Used when selecting multiple users, then clicking Gruppieren
        prefillGroup() {
            this.createType = 'group';

            this.showLoadingOverlay = true;

            for (let i = 0; i < this.selected.length; i++) {
                if (this.selected[i].accountRole === 'teacher') {
                    this.groupLeaders.push(this.selected[i]);
                } else if (this.selected[i].accountRole === 'pupil') {
                    this.groupMembers.push(this.selected[i]);
                } else {
                    console.warn("Unsupported role for prefillGroup");
                }
            }

            this.showLoadingOverlay = false;
        },

        // Reset all the data fields, basically call it when you want to return to the list/data-table
        async stop() {
            // TODO find way to call this after creation of accounts
            // (because Waldi didnt want the done button anymore)
            this.showLoadingOverlay = true;
            this.regenerateQr = false;
            this.regenerateQrParent = false;
            this.search = '';
            this.selected = [];
            this.deleteProgress = null;

            this.loginPinActivated = false;
            this.createType = null;
            this.editObject = null;
            this.editObjectRole = '';
            this.showMorePupil = true;
            this.showMoreParent = true;
            this.createSubjectDialog = false;
            this.createGroupDialog = false;
            this.revokeUsageAgreement = false;
            this.revokeParentAgreement = false;
            this.resetLockedUser = false;
            this.resetPin = false;
            this.resetParentPin = false;
            this.qrLoginDeactivated = false;
            this.parentQrLoginDeactivated = false;
            // Pupil
            this.firstName = '';
            this.lastName = '';
            this.note = '';
            this.username = '';
            this.password = '';
            this.qrPassword = '';
            this.babyView = false;
            this.translationAllowed = false;
            this.sehen = false;
            this.hoeren = false;
            this.motorik = false;
            this.screenreader = false;
            this.pupilHasParent = false;
            this.canChangePassword = false;
            this.allowGoogleTranslate = true;

            // Parent
            this.selectedParent = '';
            this.deactivateParent = false;
            this.createNewParent = true;
            this.noteParent = '';
            this.usernameParent = '';
            this.passwordParent = '';
            this.qrPasswordParent = '';
            this.parentPinActivated = false;

            // Teacher
            this.subjectsTeacher = [];
            this.groupsTeacher = [];
            this.searchSubjects = '';
            this.hoursPerWeek = '';
            this.substitutionBlocked = false;
            this.groupsWithPlaceholder = [];
            this.placeholderName = ''
            this.pinActivated = false;
            this.messagesPinActivated = false;
            this.locationsTeacher = [];
            this.searchLocations = '';

            // Group
            this.groupName = '';
            this.groupLeaders = [];
            this.groupMembers = [];
            this.searchLeaders = '';
            this.searchMembers = '';
            this.groupToBeCreated = null;

            // Validation
            this.fields.firstName = false;
            this.fields.lastName = false;
            this.fields.username = false;
            this.fields.usernameStrangeLetters = false;
            this.fields.usernameDuplicate = false;
            this.fields.usernameGenerated = false;
            this.fields.password = false;
            this.fields.parent = false;
            this.fields.parentLoginId = false;
            this.fields.parentLoginIdStrangeLetters = false;
            this.fields.parentLoginIdDuplicate = false;
            this.fields.parentLoginIdGenerated = false;
            this.fields.parentPassword = false;
            this.fields.groupName = false;
            this.fields.groupNameDuplicate = false;
            this.fields.groupLeaders = false;
            this.valid = true;     // Needs to be true by default

            // Dialogs
            this.saveDialog = false;
            this.patchDialog = false;
            this.deleteDialog = false;

            this.showLoadingOverlay = false;
            this.showAreYouSure = false;
            this.showPwPupil = false;
            this.showPwParent = false;
            this.showPwTeacher = false;
        },

        clickDownloadVorlage() {
            document.getElementById("downloadVorlageBtn").click();
        },

        async exelUploadInputChange() {
            this.showLoadingOverlay = true;
            const input = this.$refs.exelUploadInput;
            const file = input.files[0];
            input.value = "";
            this.excelImportLogger = [];
            this.excelAmountOfImports = 0;
            this.excelCurrentStatusOfImports = 0;
            if (!file) {
                return;
            }
            if (
                file.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                this.showSnackbar({
                    message: 'Dateiformat nicht zugelassen. Bitte Excel-Datei auswählen! (".xlsx")',
                    color: 'error'
                });
                return;
            }
            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async (e) => {
                let data = new Uint8Array(reader.result);
                let workbook = xlsx.read(data, {type: "array"});

                // Excel accepts "Männlich" as a valid value
                // but the backend only accepts "m" for the male gender
                const mapXlsxGenderToBackendGender = (gender) => {
                    switch (gender) {
                        case "Männlich":
                        case "m":
                            return "m";
                        case "Weiblich":
                        case "w":
                            return "w";
                        default:
                            // Divers
                            return "d";
                    }
                };

                const existingAccounts = this.pupils.map((pupil) => this.accountsById[pupil.account]);
                const noSpecialChar = new RegExp(/[^0-9a-zA-Z]+/g);
                // Convert workbook to array of users to import
                let givenDefaultPic = defaultProfilePicture;
                try {
                    /**  set avatar.svg as profile picture string */
                    const split1 = defaultProfilePicture.split('/');
                    const split2 = split1[split1.length - 1].split('.')[0];
                    givenDefaultPic = {
                        img: `/${split2}.svg`,
                    }
                } catch (e) {
                    console.warn(e);
                }
                const xlsxUsers = xlsx.utils
                    .sheet_to_json(workbook.Sheets["Main"])
                    .map((row) => ({
                        // Get user data
                        accountName: row["Benutzername"],
                        name: row["Vorname"],
                        lastName: row["Nachname"],
                        gender: mapXlsxGenderToBackendGender(row["Geschlecht"]),
                        babyView: row["Einfache Ansicht"] === "Ja",
                        createParent: row["Eltern generieren"] === "Ja",
                        password: row["Passwort"] || generateRandomPassword({length: 8}),
                        qrPassword: generateRandomPassword({length: 12}),
                        note: row["Notiz"] || "",
                        rowNumber: row["__rowNum__"],
                        profilePicture: givenDefaultPic,
                    }))
                    .map((user) => {
                        // Validate input data and if already exist on server
                        const checkAccountnameNotEmpty =
                            typeof user.accountName === "string" &&
                            user.accountName.length > 0;
                        const checkAccountnameContainsStrangeLetters =
                            this.containsUsernameStrangeLetters(user.accountName);
                        const checkNameValid =
                            typeof user.name === "string" && user.name.length > 0;
                        const checkLastNameValid =
                            typeof user.lastName === "string" && user.lastName.length > 0;
                        const checkPasswordValid =
                            typeof user.password &&
                            user.password.length >= 8 &&
                            /\d/.test(user.password) && // Contains number
                            /[A-Za-z]/.test(user.password); // Contains character
                        return {
                            ...user,
                            accountNameNotEmpty: checkAccountnameNotEmpty,
                            accountNameContainsStrangeLetters: checkAccountnameContainsStrangeLetters,
                            nameValid: checkNameValid,
                            lastNameValid: checkLastNameValid,
                            passwordValid: checkPasswordValid,
                            alreadyExistsOnServer: existingAccounts.some(
                                (existingAccount) =>
                                    existingAccount.accountName === user.accountName
                            ),
                        };
                    });

                xlsxUsers.forEach((user) => {
                    const rowNumber = parseInt(user.rowNumber + 1);
                    if(!user.nameValid) {
                        this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Der/Die Nutzer/in in Zeile ' + rowNumber + ' hat keinen Vornamen (Pflichtfeld). Der/Die Nutzer/in wurde nicht erstellt.', level: 'error' });
                        user.invalidData = true;
                        return;
                    }
                    if(!user.lastNameValid) {
                        this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Der/Die Nutzer/in in Zeile ' + rowNumber + ' hat keinen Nachnamen (Pflichtfeld). Der/Die Nutzer/in wurde nicht erstellt.', level: 'error' });
                        user.invalidData = true;
                        return;
                    }

                    if(user.accountNameContainsStrangeLetters) {
                        const strangeName = user.accountName;
                        user.accountName = user.accountName.replace(noSpecialChar, "");
                        this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Die Login-ID "' + strangeName + '" beinhaltet Sonderzeichen und wurde durch "' + user.accountName + '" ersetzt. Falls die Login-ID noch nicht existiert (siehe nächste Zeile) wurde der/die Nutzer/in trotzdem erstellt.', level: 'warning' });
                    }

                    if(!user.accountNameNotEmpty) {
                        user.accountName = user.name.toLowerCase() + user.lastName.slice(0, 1).toLowerCase();
                        if(this.containsUsernameStrangeLetters(user.accountName)) {
                            const strangeName = user.accountName;
                            user.accountName = user.accountName.replace(noSpecialChar, "");
                            if(user.accountName.length > 0) {
                                this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Die automatisch generierte Login-ID "' + strangeName + '" beinhaltet Sonderzeichen und wurde durch "' + user.accountName + '" ersetzt. Der/Die Nutzer/in wurde trotzdem erstellt.', level: 'warning' });
                            } else {
                                this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Aus Vor- und Nachname des/der Nutzer/Nutzerin in Zeile ' + rowNumber + ' konnte kein Login-ID generiert werden. Der/Die Nutzer/in wurde nicht erstellt.', level: 'error' });
                                user.invalidData = true;
                            }
                        }
                    }

                    if(existingAccounts.some(
                        (existingAccount) =>
                            existingAccount.accountName === user.accountName
                    )) {
                        this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Die Login-ID die aus Vor- und Nachname des/der Nutzer/Nutzerin in Zeile ' + rowNumber + ' generiert wurde existiert bereits. Legen Sie manuell eine Login-ID fest. Der/Die Nutzer/in wurde nicht erstellt.', level: 'error' });
                        user.invalidData = true;
                    }

                    if(user.alreadyExistsOnServer) {
                        this.excelImportLogger.push({ message: '[Zeile ' + rowNumber + ']: Die Login-ID des/der Nutzer/in in Zeile ' + rowNumber + ' existiert bereits. Möglicherweise existiert diese/r Nutzer/in bereits. Der/Die Nutzer/in wurde nicht erstellt.', level: 'error' });
                    }

                    let duplicateAccountNameRowNumbers = [];
                    for(let i=0; i<xlsxUsers.length; i++) {
                        if(xlsxUsers[i].accountName === user.accountName) {
                            duplicateAccountNameRowNumbers.push(parseInt(xlsxUsers[i].rowNumber) + 1);
                        }
                    }
                    if(duplicateAccountNameRowNumbers.length > 1) {
                        if(!user.accountNameNotEmpty) {
                            this.excelImportLogger.push({
                                message: '[Zeile ' + rowNumber + ']: Die aus Vor- und Nachnamen generierte Login-ID ' + user.accountName + ' existiert bereits. Tragen Sie die Account-ID bitte manuell ein. Der/Die Nutzer/in wurde nicht erstellt.',
                                level: 'error'
                            });
                            user.invalidData = true;
                        } else {
                            this.excelImportLogger.push({
                                message: '[Zeile ' + rowNumber + ']: Die Login-ID ' + user.accountName + ' kommt mehrfach in der Exceldatei vor (in den Zeilen ' + duplicateAccountNameRowNumbers.toString() + '). Sie muss aber einmalig sein. Alle Nutzer mit der gleichen Account-ID wurden nicht erstellt.',
                                level: 'error'
                            });
                            user.invalidData = true;
                        }
                    }
                })

                const amountOfAllTriedUsers = xlsxUsers.length;

                const usersToImport = xlsxUsers.filter(
                    (user) => !user.alreadyExistsOnServer && !user.invalidData
                );

                const amountOfCreatedUsers = usersToImport.length;
                this.excelAmountOfImports = amountOfCreatedUsers;
                const amountOfFailedUsers = amountOfAllTriedUsers - amountOfCreatedUsers;

                const importResponses = await usersToImport.reduce(
                    async (importedUsersPromise, user) => {
                        const importedUsers = await importedUsersPromise;
                        try {
                            let parentData = null;
                            if (user.createParent) {
                                let givenDefaultPic = defaultProfilePicture;
                                try {
                                    /**  set avatar.svg as profile picture string */
                                    const split1 = defaultProfilePicture.split('/');
                                    const split2 = split1[split1.length - 1].split('.')[0];
                                    givenDefaultPic = {
                                        img: `/${split2}.svg`,
                                    }
                                } catch (e) {
                                    console.warn(e);
                                }
                                parentData = {
                                    accountName: `${user.accountName}Eltern`.trim(),
                                    password: generateRandomPassword({length: 8}),
                                    name: `Eltern von ${user.name}`,
                                    rowNumber: user.rowNumber,
                                    gender: '',
                                    phone: '',
                                    profilePicture: givenDefaultPic,
                                };
                                const responseParent = await this.createParent(parentData);
                                user.parent = responseParent._id;
                            }

                            const pupil = await this.createPupil(user);
                            const imageRight = await this.postImageRights({pupilId: pupil._id, restrictImage: false})
                            this.excelCurrentStatusOfImports++;
                            return [
                                ...importedUsers,
                                {
                                    userToImport: user,
                                    usersParents: parentData,
                                    importedUser: pupil,
                                },
                            ];
                        } catch (error) {
                            console.error(error);

                            return [
                                ...importedUsers,
                                {
                                    userToImport: user,
                                    error,
                                },
                            ];
                        }
                    },
                    Promise.resolve([])
                );

                const usersToGeneratePDF = importResponses.filter(
                    (importResponse) => !importResponse.error
                );
                if (usersToGeneratePDF.length > 0) {
                    let pdfUsers = [];
                    let pdfParents = [];
                    for (const user of usersToGeneratePDF) {
                        const {userToImport} = user;
                        const compressedName = await compress(userToImport.accountName);
                        const compressedPW = await compress(userToImport.password);
                        const compressedString = JSON.stringify({
                            name: compressedName, pw: compressedPW,
                        });

                        this.editObjectRole = "pupil";
                        this.username = userToImport.accountName;
                        this.firstName = userToImport.name;
                        this.lastName = userToImport.lastName;
                        this.password = userToImport.password;
                        const pdfBytes = await this.pdf(false, true);
                        pdfUsers.push({
                            userToImport, pdfBytes,
                        });

                    }


                    const zip = new JSZip();

                    // https://stackoverflow.com/a/10073788
                    // Add padding to numbers that are too short like 43 => 0043
                    const pad = (n, width, z) => {
                        z = z || "0";
                        n = n + "";
                        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
                    };
                    pdfUsers.forEach((pdfUser) => {
                        const pdfName = pad(pdfUser.userToImport.rowNumber, 3) + "_" + pdfUser.userToImport.accountName + ".pdf";
                        zip.file(pdfName, pdfUser.pdfBytes);
                    });

                    this.excelExtraStatusMessage = 'PDF Dokumente werden bereitgestellt...';
                    const content = await zip.generateAsync({type: "blob"});
                    saveAs(content, "Importierte_Schüler.zip");
                    this.excelExtraStatusMessage = '';

                }

                if(amountOfFailedUsers > 0 && amountOfCreatedUsers > 0) {
                    this.showSnackbar({ message: "Es wurden " + amountOfCreatedUsers + " NutzerInnen erstellt. Die Erstellung von " + amountOfFailedUsers + " NutzerInnen ist Fehlgeschlagen.", color: "orange" });
                } else if(amountOfFailedUsers > 0 && !amountOfCreatedUsers > 0){
                    this.showSnackbar({ message: "Es konnte kein Nutzer erstellt werden.", color: "error" });
                } else {
                    this.showSnackbar({ message: "Es wurden " + amountOfCreatedUsers + " NutzerInnen erstellt.", color: "success" });
                }

                await this.requestList();
            };
        },
        clickExelUploadButton() {
            document.getElementById("exelUploadInput").click();
        },
        fillCreateGroupDialog(){
            this.placeholderName = this.firstName + ' ' + this. lastName;
            this.createGroupDialog = true;
        },
        getAllUsers(group) {
            let allUsers = [];
            group.participants.map((el) => {
                const pupil = this.filteredMembers.find((member) => member._id === el._id);
                allUsers.push(pupil);
            });
            if(group.leaders && group.leaders.length){

            group.leaders.map((el) => {
                    const teacher = this.teachersById[el];
                    let fullTeacher;
                        if (this.filteredLeaders.length && teacher) {
                            this.filteredLeaders.map((leader) => {
                                if (leader && leader._id === teacher._id) {
                                    fullTeacher = leader;
                                }
                            });
                        }
                        if (fullTeacher) {
                            allUsers.push(fullTeacher);
                        }
                    });
                }
            return allUsers;
        },
        async downloadPupilAndParent() {
            const pupilPdfBytes = await this.pdf(false, true, this.editGroups);
            const parentPdfBytes = await this.pdf(true, true, this.editGroups);
            const jsZip = new JSZip();
            const pdfGroupName = this.groupName ? this.groupName + '_' : '';
            jsZip.file(pdfGroupName + 'SuS_' + this.lastName + '_' + this.firstName + '.pdf', pupilPdfBytes);
            const pdfNameParents = 'Eltern_' + this.lastName + ' ' + this.firstName;
            jsZip.file(pdfNameParents + '.pdf', parentPdfBytes);
            const content = await jsZip.generateAsync({ type: 'blob' });
            saveAs(content, 'SuS_und_Eltern_' + this.lastName + '.zip');
        }
    }
}
</script>
<style>
.usersCheckbox .v-input__slot  {
    margin-bottom: 0px !important;
}
.usersCheckbox .v-messages  {
    min-height: 0px !important;
}
</style>
<style lang="scss" scoped>
// Removes the margin and padding from the search field
.v-text-field {
    margin-top: 0;
    padding-top: 0;
}

// remove margin and padding from checkboxes
.v-input--selection-controls {
    margin: 0;
    padding: 0;
}

.v-list-item__icon {
    margin-right: 8px !important;
}

.textBlock {
    display: flex;
    align-items: center;
    justify-content: center;
}

.separatorLine {
    width: 2px;
    height: 100%;
    background-color: white;
}

.pinkTextBlock {
    min-width: 25%;
    background-color: hotpink;
    color: white;
}

.blueTextBlock {
    min-width: calc(25% - 2px);
    background-color: dodgerblue;
    color: white;
    cursor: pointer;
}

.numberBadge {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: var(--v-gruen-base);
    color: white;
}

.inputField {
    background-color: white;
    border-radius: 8px;
}
.inputField:disabled {
    color: rgba(0, 0, 0, 0.38) !important;
}

#groupPanel {
    padding: 0 16px !important;
}

.errorInput {
    border: 2px solid red !important;
}

.scroll-area {
    position: relative;
    border-radius: 0 0 16px 16px;
}

.header {
    height: 56px;
    background-color: var(--v-rot-base);
    border-radius: 16px 16px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}

.headerRow {
    max-width: 100%;
    height: 35px;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

#saveBtn {
    color: white;
    border-radius: 8px;
    height: 35px !important;
}

.helpSquare {
    background-color: pink;
    border-radius: 8px;
    min-height: 75px;
    max-height: 75px;
    min-width: 75px;
    max-width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        min-height: 55px;
        max-height: 55px;
        min-width: 55px;
        max-width: 55px;
    }
}

.loggerDiv {
    border: 1px solid grey;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
}

.loggerLevelerror {
    background-color: red;
    color: white;
}

.loggerLevelwarning {
    background-color: orange;
    color: white;
}

@media only screen and (min-width: 901px) and (max-width: 1100px) {
    .fontSizeChange {
        font-size: small;
    }
}

.option {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}
.overlaySize {
     width: 30vw;
    max-width: 30vw;
    min-height: calc(82vh - 56px);
    max-height: 82vh;
    border-radius: 0 0 16px 16px !important;
    position: relative;
    z-index: 2!important;
}
@media only screen and (max-width: 900px) {
    .overlaySize {
        width: 90vw;
        max-width: 90vw;
    }
}
.badgeEventVisualize {
    background-color: grey;
}
</style>
