<template>
    <div>
        <v-dialog
            v-model="showReadContentDialog"
            persistent
            fullscreen
        >
            <v-card>
                <v-card-title>
                    <div
                        class="d-flex justify-space-between align-center"
                        style="width: 100%"
                    >
                        <p>Export und Import von Accounts</p>
                        <v-btn
                            x-small
                            style="width: 35px; height: 35px;"
                            @click="close"
                        >
                            <img
                                :src="closeIcon"
                                style="height: 30px"
                            >
                        </v-btn>
                    </div>
                </v-card-title>
                <!-- Button to trigger upload UI -->
                <v-card-text
                    style="overflow-y: auto; max-height: 90vh"
                >
                    <!-- second page - display given changes -->
                    <div v-if="displayChangesFromExcel">
                        <p v-if="blockBecauseError">
                            Es wurde ein Fehler in einem der Einträge gefunden. Bitte korigieren Sie
                            diesen in Excel und versuchen es erneut. <br>
                            TODO: Add Error correction UI for maintainers here.
                        </p>

                        <div style="max-height: 80vh; overflow-y: scroll">
                            <v-expansion-panels
                                v-model="changesPanels"
                                accordion
                                flat
                                style="border: 1px solid var(--v-grau-base)"
                            >
                                <v-expansion-panel
                                    v-for="(group, groupIndex) in changesWithGroups"
                                    :key="`newGroup${group._id}${group.newName}`"
                                    class="groupExpansionPanel"
                                    :disabled="group.status === 'discontinued'"
                                    @change="panelOpened(groupIndex, group._id)"
                                >
                                    <v-expansion-panel-header
                                        class="pa-4"
                                        style="background-color: var(--v-hellgrau-base); max-height: 66px"
                                    >
                                        <div class="d-flex justify-space-between align-center">
                                            <div class="d-flex flex-row justify-start align-center">
                                                <div>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                color="primary"
                                                                dark
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <img
                                                                    :src="statusIcon(group.status)"
                                                                    style="filter: brightness(0%); height: 20px"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span> {{ statusTooltipGroup(group.status) }} </span>
                                                    </v-tooltip>
                                                </div>
                                                <p style="font-size: large">
                                                    <!-- TODO display new and old name -->
                                                    {{ group.newName || group.name }}
                                                </p>
                                            </div>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <!-- Todo change to new leaders and handle edge cases -->
                                        <ExcelImportExpansionPanel
                                            v-for="(leader, lIndex) in group.newLeaders"
                                            :key="leader._id"
                                            class="pa-3"
                                            :account="leader"
                                            :index="lIndex"
                                            @errorCorrection="(updatedValues) => updateAccountValues({
                                                updatedValues,
                                                accountRole: 'teacher',
                                                accountKey: leader.key
                                            })"
                                        />
                                        <div
                                            v-if="!group.newLeaders || !group.newLeaders.length"
                                            class="d-flex justify-center align-center pa-2"
                                            style="width: 100%"
                                        >
                                            Fehler: Keine Leitenden angegeben! -
                                            <v-select
                                                v-model="dummyLeaders"
                                                outlined
                                                dense
                                                hide-details
                                                multiple
                                                :items="currentTeachers"
                                                :item-text="item => item.name + ' ' + item.lastName"
                                                :item-value="item => item"
                                                label="Neue Leitende"
                                                @focusout="() => updateGroupLeaders(groupIndex)"
                                            />
                                            <div style="width: 25%;" />
                                        </div>
                                        <!-- Todo display more pupil data, handle edge cases -->
                                        <!-- Handle errors! -->
                                        <ExcelImportExpansionPanel
                                            v-for="(participant, pIndex) in group.newParticipants"
                                            :key="`${participant._id}${participant.lastName || participant.newLastName}${participant.name || participant.newName}`"
                                            class="pa-3"
                                            :class="{'inactivePupil': participant.status && participant.status !== 'normal'}"
                                            :account="participant"
                                            :index="pIndex"
                                            @errorCorrection="(updatedValues) => updateAccountValues({
                                                updatedValues,
                                                accountRole: 'pupil',
                                                accountKey: participant.key
                                            })"
                                        />
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel
                                    v-if="changesWithoutGroup.length"
                                    class="groupExpansionPanel"
                                    @change="panelOpened(changesWithGroups.length, null)"
                                >
                                    <v-expansion-panel-header
                                        class="pa-4"
                                        style="background-color: var(--v-hellgrau-base); max-height: 66px"
                                    >
                                        <div class="d-flex justify-space-between align-center">
                                            <div class="d-flex flex-column justify-start">
                                                <p style="font-size: large">
                                                    Keine Gruppe zugeteilt
                                                </p>
                                            </div>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <!-- Todo display more pupil data, handle edge cases -->
                                        <ExcelImportExpansionPanel
                                            v-for="(account, index) in changesWithoutGroup"
                                            :key="`${account._id}${account.lastName || account.newLastName}${account.name || account.newName}`"
                                            class="pa-3"
                                            :account="account"
                                            :index="index"
                                            @errorCorrection="(updatedValues) => updateAccountValues({
                                                groupIndex: -1,
                                                accountIndex: index,
                                                updatedValues,
                                                accountRole: account.role,
                                                accountKey: account.key
                                            })"
                                        />
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                        <br>
                        <div
                            style="width: 90%"
                            class="d-flex justify-space-between align-center mx-auto"
                        >
                            <v-btn
                                style="text-transform: inherit"
                                @click="() => { displayChangesFromExcel = false; }"
                            >
                                Zurück
                            </v-btn>
                            <v-btn
                                :disabled="blockBecauseError"
                                style="text-transform: inherit"
                                @click="handleForwardButton"
                            >
                                Weiter
                            </v-btn>
                        </div>
                    </div>

                    <div
                        v-else-if="changeSchoolYearCheckbboxes"
                        style="width: 90%"
                    >
                        <p>
                            Diese Checkboxen sind Teil des Schuljahreswechsels. Ein gesetzter Haken zeigt hierbei an
                            welche Daten <span style="font-weight: bold">behalten</span> werden sollen! Das heißt wenn
                            Sie wollen das bei allen geänderten Gruppen z.B. der Stundenplan zurück gesetzt wird, dann
                            muss der Haken bei 'Stundenpläne' entfernt werden. Weitere Erklärungen stehen neben den Haken.
                        </p>
                        <br>
                        <div
                            class="d-flex flex-column pa-3"
                            style="width: 50%"
                        >
                            <div
                                style="width: 100%"
                                class="d-flex justify-space-between my-2"
                            >
                                <v-checkbox
                                    v-model="options.keepChat"
                                    color="dunkelgrau"
                                    hide-details
                                    class="ma-0 pa-0 pl-1"
                                    label="Chatverläufe"
                                />
                                <p style="width: 75%; font-size: medium">
                                    Mit dieser Box werden die Chatverläufe der Gruppenchats kontrolliert.
                                    Wenn hier kein Haken gesetzt ist werden diese aus dem System entfernt,
                                    und alte Nachrichten werden gelöscht.
                                </p>
                            </div>
                            <div
                                style="width: 100%"
                                class="d-flex justify-space-between my-2"
                            >
                                <v-checkbox
                                    v-model="options.keepAppointments"
                                    color="dunkelgrau"
                                    hide-details
                                    class="ma-0 pa-0 pl-1"
                                    label="Stundenpläne"
                                />
                                <p style="width: 75%; font-size: medium">
                                    Mit dieser Box werden die Fachstunden einer Gruppe kontrolliert.
                                    Wenn hier kein Haken gesetzt ist werden eingetragene Fachstunden aus dem Stundenplan
                                    entfernt, inklusive der jeweiligen Uploads und Abgaben von Lehrern und Schülern.
                                </p>
                            </div>
                            <v-checkbox
                                v-if="false"
                                v-model="options.keepPrivateFolder"
                                color="dunkelgrau"
                                hide-details
                                class="ma-0 pa-0 pl-1"
                                label="Privater Ordner"
                            />
                            <v-checkbox
                                v-if="false"
                                v-model="options.keepLeavingStudents"
                                color="dunkelgrau"
                                hide-details
                                class="ma-0 pa-0 pl-1"
                                label="Accounts von Schülern, welche die Schule verlassen"
                            />
                        </div>
                        <div
                            style="width: 90%"
                            class="d-flex justify-space-between align-center mx-auto"
                        >
                            <v-btn
                                style="text-transform: inherit"
                                @click="handleBackButton"
                            >
                                Zurück
                            </v-btn>
                            <v-btn
                                :disabled="blockBecauseError"
                                style="text-transform: inherit"
                                @click="handleForwardButton"
                            >
                                Weiter
                            </v-btn>
                        </div>
                    </div>

                    <!-- last page - Upload to backend and console log, gives out zip files in the end -->
                    <div v-else-if="justSendIt">
                        Um möglichen Fehlern beim Import vorzubeugen befindet sich im Folgenden eine Konsole auf der mögliche Fehler (rot) und Warnungen (orange) ausgegeben werden. Bei einer roten Meldung wird der/die Nutzer/in nicht erstellt. Bei einer orangenen Meldung wird der Nutzer trotzdem erstellt. Die Fehlermeldungen beinhalten immer eine Zeilenangabe, sodass Sie wissen in welcher Zeile ihrer Excel-Datei ein Fehler aufgetreten ist.
                        <br>
                        <div
                            class="loggerDiv mt-4 d-flex flex-column"
                        >
                            <p
                                v-for="(log, index) in excelImportLoggerFilterLogLevel"
                                :key="index"
                                :class="'loggerLevel' + log.level"
                            >
                                {{ log.message }}
                            </p>
                            <p
                                v-if="excelImportLogger.length === 0"
                                class="ml-2 mt-2"
                                style="font-size: x-large; opacity: 50%"
                            >
                                Konsole
                            </p>
                        </div>
                    </div>
                    <!-- basically first page -->
                    <div
                        v-else
                        class="d-flex justify-center ma-auto"
                        style="width: 90%"
                    >
                        <div
                            id="leftColumn"
                            style="width: 50%"
                        >
                            <p v-if="changeSchoolYear">
                                Hier können Sie Schüler, Lehrer und Gruppen aus einer Excel-Datei (.xlsx) hochladen. <br>
                                Laden Sie dafür die Vorlage über den unteren Knopf 'Vorlage' herunter und füllen Sie
                                die Listen anhand der Beispiele auf der Startmappe aus. Alle aktuellen Gruppen,
                                Lehrer und Schüler sind in der Vorlage eingetragen, in den vorhandenen Konstellationen. <br>
                                Um Schüler neuen Klassen zuzuweisen müssen nur die Klasseneinträge dieser geändert werden.
                                Neue Einträge können natürlich ebenfalls gemacht werden.
                            </p>
                            <div v-else>
                                <p>
                                    Hier können Sie Schüler, Lehrer und Gruppen aus einer Excel-Datei (.xlsx) hochladen. <br>
                                    Laden Sie dafür die Vorlage über den unteren Knopf 'Vorlage' herunter und füllen Sie
                                    die Listen anhand der Beispiele auf der Startmappe aus. Aktuelle Gruppen und Lehrer sind
                                    schon eingetragen, Schüler allerdings nicht.
                                </p>
                            </div>
                            <br>
                            Dabei müssen Sie einige Regeln beachten:
                            <ul>
                                <li>Ändern Sie nicht die Namen der Spalten (Benutzername, Vorname, ...) in der Vorlage.</li>
                                <li>
                                    Leitende einer Gruppe werden per 'Nachname, Vorname' eingetragen, einzelne Personen
                                    werden per Semicolon (;) getrennt.
                                </li>
                                <li>
                                    Einzelne Gruppeneinträge bei Lehrern und Schülern werden per Gruppenname eingetragen
                                    und mit Kommata getrennt.
                                </li>
                                <li>
                                    Der Benutzername darf keine Umlaute oder Sonderzeichen enthalten.
                                    Lassen Sie das Feld frei um automatisch aus dem Vornamen und dem ersten
                                    Buchstaben des Nachnamens die Login-ID zu generieren.
                                    Ändern oder entfernen Sie den Benutzernamen wird automatisch ein neuer Nutzer erstellt!
                                </li>
                                <li>Pflichtfelder sind: Vor- und Nachnamen, sowie Namen und Leitende einer Gruppe</li>
                            </ul>
                            <p v-if="false">
                                Diese Regeln stehen auch nochmal auf der Startmappe der Vorlage.
                            </p>
                            <br>
                            <v-btn
                                v-if="changeSchoolYear"
                                style="text-transform: inherit"
                                @click="exportCurrentState"
                            >
                                Vorlage
                            </v-btn>
                            <v-btn
                                v-else
                                style="text-transform: inherit"
                                @click="exportGroupsAndTeachers"
                            >
                                Vorlage
                            </v-btn>
                            <br>
                        </div>
                        <div
                            id="rightColumn"
                            style="width: 50%"
                        >
                            <p>
                                Nachdem Sie die Vorlage heruntergeladen und ausgefüllt haben können Sie diese über den
                                Knopf 'Hochladen' in EKLARA laden. Der Inhalt wird automatisch auf kritische Fehler
                                überprüft und Ihnen danach nochmals präsentiert.<br>
                                Sollten keine Fehler auftreten können Sie danach den Prozess starten,
                                dieser wird einige Minuten in Anspruch nehmen. Kleinere Fehler lassen sich auf der
                                nächsten Seite korrigieren, für größere Fehler (z.B. fehlende Gruppennamen) müssten Sie
                                dies in Excel korrigeren und die Datei erneut hochladen.
                                <br>
                            </p>
                            <br>
                            <v-btn
                                style="text-transform: inherit"
                                @click="clickExcelUploadButton"
                            >
                                Hochladen
                            </v-btn>
                            <div
                                v-if="changesWithGroups || changesWithoutGroup"
                            >
                                If excel is already loaded click here: <br>
                                <v-btn
                                    style="text-transform: inherit"
                                    @click="() => { displayChangesFromExcel = true; }"
                                >
                                    Forward
                                </v-btn>
                            </div>
                            <div
                                v-if="fileCheck.status && fileCheck.reason"
                                class="mt-1"
                            >
                                <p style="font-size: large; font-weight: bold">
                                    Fehler in Datei!
                                </p>
                                Die hochgeladene Datei wurde auf ihre Struktur überprüft.
                                Dabei wurden Abweichungen mit der Vorlage entdeckt,
                                und die Datei wird nicht weiter verarbeitet. <br>
                                <p v-if="fileCheck.reason === 'changedSheets'">
                                    In der Datei fehlt mindestens eine der vorausgesetzten Spalten: Gruppen, Lehrer oder Schüler.
                                </p>
                                <p v-else>
                                    Spaltennamen in der {{
                                        fileCheck.reason === 'groupKeys'
                                            ? 'Gruppen' : fileCheck.reason === 'teacherKeys'
                                                ? 'Lehrer' : fileCheck.reason === 'pupilKeys' ? 'Schüler' : '' }}
                                    Mappe stimmen nicht mehr mit der Vorlage überein.
                                </p>
                                Bitte korrigieren Sie diese Abweichungen und versuchen es erneut. <br>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- uploadInput -->
        <input
            id="extendedExcelUploadInput"
            ref="extendedExcelUploadInput"
            type="file"
            hidden
            @change="displayNewStateFromExcel"
        >
        <!-- UI to download current status, upload new status, display read results -->
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as xlsx from "xlsx";
import changedIcon from '@/assets/Icons/ausrufezeichen-62.svg';
import newIcon from '@/assets/Icons/plus.svg';
import ExcelImportExpansionPanel from "@/components/Verwaltung/Excel/ExcelImportExpansionPanel";
import generateRandomPassword from "@/util/generateRandomPassword";
import sanitizeHtml from "@/util/sanitizeHtml";
import JSZip from "jszip";
import LoginPdf from "@/util/loginPdf";
import {saveAs} from "file-saver";
import closeIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import errorIcon from '@/assets/Icons/FaceliftIcons/f-physik.svg';
import excelTemplate from '@/assets/eklara-excel-vorlage.xlsx';

export default {
    name: 'ExtendedExcelImport',
    components: {ExcelImportExpansionPanel},
    props: {
        // Flag to check whether we are on changeSchoolYear
        changeSchoolYear: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        currentFile: null,
        errorCorrectionFillUp: {},
        dummyLeaders: [],
        showReadContentDialog: false,
        changesPanels: 0,
        lastPressedPanelIndex: 0,
        excelTemplate,
        changedIcon,
        newIcon,
        closeIcon,
        errorIcon,

        // displaying the progress of sending it all to the backend
        progress: 0,
        excelImportLogger: [],
        excelImportLoggerShowErrors: true,
        excelImportLoggerShowWarnings: true,

        fileCheck: {},
        allInputFromCurrentFile: null,
        allTeacherInput: null,
        allPupilInput: null,
        // changesWithGroups: null,
        displayChangesFromExcel: false,
        justSendIt: false,
        changeSchoolYearCheckbboxes: false,

        // keeps should all be true on default
        // Todo maybe think this through with design
        options: {
            keepChat: true,
            keepAppointments: true,
            keepLeavingStudents: true, // are those that were previously in a group but now arent
            // (maybe try thinking of edge cases before just setting this!)
            keepHomework: true,
            keepPrivateFolder: true,
        },
    }),
    computed: {
        ...mapGetters('accounts', ['accountsById']),
        ...mapGetters('teachers', ['teachers']),
        ...mapGetters('groups', ['groups']),
        ...mapState('pupils', ['pupils']),
        statusIcon() {
            return (status) => {
                switch (status) {
                    case 'new':
                        return this.newIcon;
                    case 'changed':
                        return this.changedIcon;
                    case 'changeInside':
                        return this.changedIcon;
                    case 'error':
                    case 'errorInside':
                        return this.errorIcon;
                    default: break;
                }
            }
        },
        statusTooltipGroup() {
            return (status) => {
                switch (status) {
                    case 'new': return 'Diese Gruppe wird im nächsten Schritt neu angelegt.';
                    case 'changed': return 'Es wurden Änderungen an dieser Gruppe festgestellt. ' +
                        'Diese werden im nächsten Schritt angewandt.';
                    case 'changeInside': return 'Innerhalb dieser Gruppe wurden Änderungen festgestellt. ' +
                        'Klappen Sie sie auf um mehr zu erfahren.';
                    case 'error': return 'Diese Gruppe ist fehlerhaft. ' +
                        'Korrigieren Sie die Fehler und versuchen Sie es erneut.';
                    case 'errorInside': return 'In den Mitgliedern dieser Gruppe sind Fehler gefunden worden. ' +
                        'Korrigieren Sie diese und versuchen Sie es erneut.'
                    default: break;
                }
            }
        },
        excelImportLoggerFilterLogLevel() {
            if(this.excelImportLoggerShowErrors && this.excelImportLoggerShowWarnings) {
                return this.excelImportLogger;
            } else if(this.excelImportLoggerShowErrors && !this.excelImportLoggerShowWarnings){
                return this.excelImportLogger.filter(log => log.level === "error");
            } else if(!this.excelImportLoggerShowErrors && this.excelImportLoggerShowWarnings) {
                return this.excelImportLogger.filter(log => log.level === "warning");
            } else {
                return [];
            }
        },
        groupAccountDisplay() {
          return (role, keyArray) => {
              const result = [];
              keyArray.forEach(keyObj => {
                  if (role === 'teacher') {
                    result.push({
                        ...this.allTeacherInput[keyObj.key],
                        ...keyObj
                    });
                  } else if (role === 'pupil') {
                      result.push({
                          ...this.allPupilInput[keyObj.key],
                          ...keyObj
                      });
                  }
              });
              console.log('groupAccountDisplay', role, result)
              return result;
          }
        },
        currentPupils() {
            return this.pupils.map(pup => {
                return { ...pup, accountName: this.accountsById[`${pup.account}`].accountName , role: 'pupil'}
            });
        },
        currentTeachers() {
            return this.teachers;
        },
        currentGroups() {
            return this.groups.map(group => {
                // console.log('leaders before map', group);
                group.leaders = group.leaders.map(leaderId => this.currentTeachers.find(t => t._id === leaderId));
                return group;
            });
        },
        blockBecauseError() {
            let result = false;
            const keys = Object.keys(this.allInputFromCurrentFile);
            for (let i = 0; i < keys.length; i++) {
                if (this.allInputFromCurrentFile[keys[i]].status === 'error'
                    || this.allInputFromCurrentFile[keys[i]].status === 'errorInside') {
                    result = true;
                    break;
                }
            }
            return result;
        },
        changesWithGroups() {
            let result = null;
            if (this.allInputFromCurrentFile) {
                result = [];
                const groupKeys = Object.keys(this.allInputFromCurrentFile).filter(k => k !== 'Ohne Gruppe');
                groupKeys.forEach(gKey => {
                    const group = this.allInputFromCurrentFile[gKey];
                    if (group.status !== 'normal') {
                        group.newLeaders = group.newLeaderKeys.map(keyObj => {
                            return {
                                ...this.allTeacherInput[keyObj.key],
                                ...keyObj
                            }
                        });
                        group.newParticipants = group.newParticipantKeys.map(keyObj => {
                            return {
                                ...this.allPupilInput[keyObj.key],
                                ...keyObj
                            }
                        });
                        result.push(group);
                    }
                });
            }
            return result;
        },
        changesWithoutGroup() {
            let result = null;
            if (this.allInputFromCurrentFile && this.allInputFromCurrentFile['Ohne Gruppe']) {
                result = [];
                this.allInputFromCurrentFile['Ohne Gruppe'].forEach(entry => {
                    if (entry.status !== 'normal') {
                        if (entry.role === 'teacher') {
                            result.push({
                                ...this.allTeacherInput[entry.key],
                                ...entry
                            });
                        } else if (entry.role === 'pupil') {
                            result.push({
                                ...this.allPupilInput[entry.key],
                                ...entry
                            });
                        }
                    }
                })
            }
            return result;
        },
    },
    async mounted() {
        await this.getTeachers(true);
        await this.getGroups(true);
        this.showReadContentDialog = true;
    },
    methods: {
        ...mapActions('auth', ['isAccountNameAvailable']),
        ...mapActions('groups', ['getGroups','createGroup', 'editGroup']),
        ...mapActions('teachers', ['getTeachers', 'createTeacher', 'deleteTeacher', 'editTeacher']),
        ...mapActions('parents', ['getParent', 'createParent', 'editParent', 'deleteParent']),
        ...mapActions('pupils', ['getPupils', 'createPupil', 'editPupil']),
        ...mapActions('accounts', ['fetchAccounts']),
        ...mapMutations('snackbar', ['showSnackbar']),

        // Export of the current eklara Accounts state
        async exportCurrentState() {
            // start base for xslx file
            const fileData = await fetch(this.excelTemplate)
                .then((res) => res.arrayBuffer());
            const completeWorkbook = xlsx.read(fileData, { cellStyles: true });

            // add landing page with text corresponding to wanted mode
            // TODO make template start nice

            // add page to workbook
            const addPageToWorkbook = (dataArray, headerArray, name) => {
                const worksheet = xlsx.utils.json_to_sheet(dataArray,
                    {header: headerArray});
                const widthsArray = [];
                dataArray.forEach(entry => {
                   const keys = Object.keys(entry);
                    for (let i = 0; i < keys.length; i++) {
                        if (widthsArray[i] && widthsArray[i].width && entry[`${keys[i]}`]) {
                            widthsArray[i].width = Math.max(widthsArray[i].width, entry[`${keys[i]}`].length);
                        } else {
                            widthsArray[i] = { width: entry[`${keys[i]}`] ? Math.max(10, entry[`${keys[i]}`].length) : 10 }
                        }
                    }
                });
                worksheet["!cols"] = widthsArray;
                // Add group page to workbook
                xlsx.utils.book_append_sheet(completeWorkbook, worksheet, name);
            };

            // fetch data for filling the next pages/sheets
            const currentGroups = await this.getGroups(true);
            const currentTeachers = await this.getTeachers(true);
            const currentPupils = await this.getPupils(true);
            await this.fetchAccounts();

            // add group page
            const groupHeaders = ['Name', 'Leiter'];
            // populate goup
            const neededGroupData = currentGroups.map(group => {
                if (group && group.name !== 'Alle') {
                    // find Leaders corresponding to group
                    // TODO do this for now, maybe later make it global to use in all worksheets LinkedList
                    const leaderNames = group.leaders.map(leaderId => {
                        const tempTeacher = currentTeachers.find(t => t._id === leaderId);
                        if (tempTeacher) {
                            return `${tempTeacher.lastName}, ${tempTeacher.name}`;
                        }
                    });
                    return {
                        Name: group.name,
                        Leiter: leaderNames.join('; ')
                    }
                }
            }).filter(group => group !== undefined);
            addPageToWorkbook(neededGroupData, groupHeaders, 'Gruppen');

            // add teacher page
            const teacherHeaders = ['Benutzername', 'Vorname', 'Nachname', 'Notiz', 'Gruppen'];
            const neededTeacherData = currentTeachers.map(teacher => {
                // TODO do this for now, maybe later make it global to use in all worksheets LinkedList
                const groupNames = currentGroups
                    .filter(group => group.leaders.find(leaderId => teacher._id === leaderId))
                    .map(group => group.name);
                return {
                    Benutzername: teacher.accountName,
                    Vorname: teacher.name,
                    Nachname: teacher.lastName,
                    Notiz: teacher.note,
                    Gruppen: groupNames.join(', ')
                }
            });
            addPageToWorkbook(neededTeacherData, teacherHeaders, 'Lehrer');

            // Add pupil page
            const pupilHeaders = ['Benutzername', 'Vorname', 'Nachname', 'Notiz', 'Einfacher Modus', 'Eltern', 'Gruppen'];
            const pupilAccountData = {};
            const keys = Object.keys(this.accountsById);
            for (let i = 0; i < keys.length; i++) {
                if (this.accountsById[`${keys[i]}`].role === 'pupil') {
                    pupilAccountData[`${keys[i]}`] = this.accountsById[`${keys[i]}`];
                }
            }
            const neededPupilData = currentPupils.map(pupil => {
                // find groups the pupil is in
                const groupNames = currentGroups
                    .filter(group => group.participants.find(part => part._id === pupil._id))
                    .map(group => group.name);
                return {
                    Benutzername: pupilAccountData[`${pupil.account}`].accountName,
                    Vorname: pupil.name,
                    Nachname: pupil.lastName,
                    Notiz: pupil.note,
                    Eltern: pupil.parent ? 'Ja' : 'Nein',
                    'Einfacher Modus': pupil.babyView ? 'Ja' : 'Nein',
                    Gruppen: groupNames.join(', ')
                }
            });
            addPageToWorkbook(neededPupilData, pupilHeaders, 'Schüler');

            // Prompt for download
            await xlsx.writeFile(completeWorkbook, 'eklara-nutzerexport.xlsx')
        },

        // Export with empty students to make it easier to add new ones
        async exportGroupsAndTeachers() {
            // start base for xslx file
            const fileData = await fetch(this.excelTemplate)
                .then((res) => res.arrayBuffer());
            const completeWorkbook = xlsx.read(fileData, { cellStyles: true });

            // add page to workbook
            const addPageToWorkbook = (dataArray, headerArray, name) => {
                const worksheet = xlsx.utils.json_to_sheet(dataArray,
                    {header: headerArray});
                const widthsArray = [];
                dataArray.forEach(entry => {
                    const keys = Object.keys(entry);
                    for (let i = 0; i < keys.length; i++) {
                        if (widthsArray[i] && widthsArray[i].width && entry[`${keys[i]}`]) {
                            widthsArray[i].width = Math.max(widthsArray[i].width, entry[`${keys[i]}`].length);
                        } else {
                            widthsArray[i] = { width: entry[`${keys[i]}`] ? Math.max(10, entry[`${keys[i]}`].length) : 10 }
                        }
                    }
                });
                worksheet["!cols"] = widthsArray;
                // Add group page to workbook
                xlsx.utils.book_append_sheet(completeWorkbook, worksheet, name);
            };

            // fetch data for filling the next pages/sheets
            const currentGroups = await this.getGroups(true);
            const currentTeachers = await this.getTeachers(true);
            const currentPupils = await this.getPupils(true);
            await this.fetchAccounts();

            // add group page
            const groupHeaders = ['Name', 'Leiter'];
            // populate goup
            const neededGroupData = currentGroups.map(group => {
                if (group && group.name !== 'Alle') {
                    // find Leaders corresponding to group
                    // TODO do this for now, maybe later make it global to use in all worksheets LinkedList
                    const leaderNames = group.leaders.map(leaderId => {
                        const tempTeacher = currentTeachers.find(t => t._id === leaderId);
                        if (tempTeacher) {
                            return `${tempTeacher.lastName}, ${tempTeacher.name}`;
                        }
                    });
                    return {
                        Name: group.name,
                        Leiter: leaderNames.join('; ')
                    }
                }
            }).filter(group => group !== undefined);
            addPageToWorkbook(neededGroupData, groupHeaders, 'Gruppen');

            // add teacher page
            const teacherHeaders = ['Benutzername', 'Vorname', 'Nachname', 'Notiz', 'Gruppen'];
            const neededTeacherData = currentTeachers.map(teacher => {
                // TODO do this for now, maybe later make it global to use in all worksheets LinkedList
                const groupNames = currentGroups
                    .filter(group => group.leaders.find(leaderId => teacher._id === leaderId))
                    .map(group => group.name);
                return {
                    Benutzername: teacher.accountName,
                    Vorname: teacher.name,
                    Nachname: teacher.lastName,
                    Notiz: teacher.note,
                    Gruppen: groupNames.join(', ')
                }
            });
            addPageToWorkbook(neededTeacherData, teacherHeaders, 'Lehrer');

            // add empty pupil page
            const pupilHeaders = ['Benutzername', 'Vorname', 'Nachname', 'Notiz', 'Einfacher Modus', 'Eltern', 'Gruppen'];
            addPageToWorkbook([], pupilHeaders, 'Schüler')

            // Prompt for download
            await xlsx.writeFile(completeWorkbook, 'eklara-nutzerexport.xlsx')
        },

        // Import new state via excel file, might be new students or changeschoolyear
        async displayNewStateFromExcel() {
            const input = this.$refs.extendedExcelUploadInput;
            const file = input.files[0];
            input.value = '';

            if (!file) {
                return;
            }

            if (file.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                this.showSnackbar({
                    message: 'Dateiformat nicht zugelassen. Bitte Excel-Datei auswählen! (".xlsx")',
                    color: 'error'
                });
                return;
            }

            // safety checks passed, now open file
            // TODO check if this worked on every browser
            this.currentFile = file;
            const allChanges = await this.computeChangesFromExcelFile();
            if (allChanges === 'fileStructureError') {
                return;
            }
            // transform changes to array
            // const withGroups = [];
            // const withoutGroup = [];
            // const changesKeys = Object.keys(allChanges).filter(key => key !== 'Ohne Gruppe');
            // for (let i = 0; i < changesKeys.length; i++) {
            //     withGroups.push(allChanges[`${changesKeys[i]}`]);
            // }
            // if (allChanges['Ohne Gruppe'].length) {
            //     for (let i = 0; i < allChanges['Ohne Gruppe'].length; i++) {
            //         withoutGroup.push(allChanges['Ohne Gruppe'][i])
            //     }
            // }
            // give array to html
            // this.changesWithGroups = withGroups;
            // Sort without group by account role
            // enable checking ui
            this.displayChangesFromExcel = true;
        },

        async updateAccountValues({ groupIndex, groupAccountArray, accountIndex = -1, updatedValues, accountKey, accountRole }) {
            const checkForErrorsAndUpdate = (account) => {
                let error = null;
                if (!account.newName) {
                    error = 'newName';
                }
                else if (!account.newLastName) {
                    error = 'newLastName';
                }
                // check accountName for syntax, would need bigger update

                // TODO update status if necessary
                this.checkAccountStatusAndUpdate(account);
                if (account.error && !error) {
                    account.error = null;
                } else if (error) {
                    account.error = error;
                }
                // toggle group updates where this is in
                const doesGroupContainAccount = (group) => {
                    let foundAcc = null;
                    // assumes only teachers in leaders and only pupils in participants
                    if (account.role === 'teacher') {
                        foundAcc = group.newLeaderKeys.find(keyEntry => keyEntry.key === account.key);
                    }
                    if (account.role === 'pupil') {
                        foundAcc = group.newParticipantKeys.find(keyEntry => keyEntry.key === account.key);
                    }
                    return foundAcc;
                }
                const groupKeys = Object.keys(this.allInputFromCurrentFile).filter(k => k !== 'Ohne Gruppe');
                groupKeys.forEach(key => {
                    const foundAcc = doesGroupContainAccount(this.allInputFromCurrentFile[key]);
                    if (foundAcc) {
                        foundAcc.error = error;
                        // check for status update of group
                        this.checkGroupStatusAndUpdate(this.allInputFromCurrentFile[key]);
                    }
                });
                for (let i = 0; i < this.allInputFromCurrentFile['Ohne Gruppe'].length; i++) {
                    if (this.allInputFromCurrentFile['Ohne Gruppe'][i].key === account.key) {
                        this.allInputFromCurrentFile['Ohne Gruppe'][i].error = error;
                    }
                }
            };
            if (accountRole && accountKey) {
                if (accountRole === 'teacher') {
                    const acc = this.allTeacherInput[accountKey];
                    this.$set(this.allTeacherInput, accountKey, {
                        ...acc,
                        ...updatedValues
                    });
                    // check for errors and update status
                    checkForErrorsAndUpdate(this.allTeacherInput[accountKey]);
                    console.log(this.allTeacherInput[accountKey]);
                }
                if (accountRole === 'pupil') {
                    const acc = this.allPupilInput[accountKey];
                    this.$set(this.allPupilInput, accountKey, {
                        ...acc,
                        ...updatedValues
                    });
                    // check for errors and update status
                    checkForErrorsAndUpdate(this.allPupilInput[accountKey]);
                    console.log(this.allPupilInput[accountKey]);
                }
            }
        },

        checkAccountStatusAndUpdate(account) {
            let oldAcc = null;
            if (account.role === 'pupil') {
                oldAcc = this.currentPupils.find(pup => pup.accountName === account.accountName);
            }  else if (account.role === 'teacher') {
                oldAcc = this.currentTeachers.find(teacher => teacher.accountName === account.accountName);
            } else {
                console.error('invalid account role', account.role);
                // Todo handle null return value to handle error
                return account;
            }
            if (!oldAcc) {
                account.status = 'new';
                return account;
            }

            const keys = Object.keys(account);
            for (let i = 0; i < keys.length; i++) {
                // TODO check if parentheses are even needed
                if (account[`${keys[i]}`] !== oldAcc[`${keys[i]}`]) {
                    if (`${keys[i]}` === 'parent') {
                        if ((account[`${keys[i]}`] === 'Ja') !== (!!oldAcc[`${keys[i]}`])) {
                            account.status = 'changed';
                            break;
                        }
                    } else if (`${keys[i]}` !== 'role'){
                        account.status = 'changed';
                        break;
                    }
                }
            }
            return account;
        },

        checkGroupStatusAndUpdate(group) {
            let error = null;
            let status = 'normal';
            if (!group.newName) {
                status = 'error';
                error = 'newName';
            }
            if (!group.newLeaderKeys.length) {
                status = 'error';
                error = 'leaders';
            }

            // check for group changes
            const oldGroup = this.currentGroups.find(cGroup => group.newName === cGroup.name);
            if (!oldGroup) {
                status = 'new';
            }
            if (oldGroup.leaders.length !== group.newLeaderKeys.length
                || oldGroup.participants.length !== group.newParticipantKeys.length) {
                // should catch if someone was removed from a group
                status = 'changed';
            }

            if (status === 'normal') {
                for (let i = 0; i < group.newLeaderKeys.length; i++) {
                    const keyEntry = group.newLeaderKeys[i];
                    console.log('oldGroup', oldGroup);
                    const oldCorrespondingLeader = oldGroup.leaders.find(ol => ol.accountName === keyEntry.key);
                    if (!oldCorrespondingLeader) {
                        status = 'changed';
                        break;
                    }
                    if (this.allTeacherInput[keyEntry.key].status === 'changed'
                        || this.allTeacherInput[keyEntry.key].status === 'added'
                        || this.allTeacherInput[keyEntry.key].status === 'new') {
                        status = 'changeInside';
                        break;
                    }
                    if (this.allTeacherInput[keyEntry.key].error) {
                        status = 'errorInside';
                        break;
                    }
                }
            }
            if (status === 'normal') {
                for (let i = 0; i < group.newParticipantKeys.length; i++) {
                    const keyEntry = group.newParticipantKeys[i];
                    if (this.allPupilInput[keyEntry.key].status === 'changed'
                        || this.allPupilInput[keyEntry.key].status === 'added'
                        || this.allPupilInput[keyEntry.key].status === 'new') {
                        status = 'changeInside';
                        break;
                    } if (this.allPupilInput[keyEntry.key].error) {
                        status = 'errorInside';
                        break;
                    }
                }
            }
            group.status = status;
            group.error = error;
            return group;
        },

        async updateGroupLeaders(groupIndex) {
            console.log('updateGroupLeaders for group', groupIndex, this.dummyLeaders);
            const groupKey = this.changesWithGroups[groupIndex].newName;
            this.dummyLeaders.forEach(dummyLead => {
                // update moved status
                this.allInputFromCurrentFile[groupKey].newLeaders.push(dummyLead);
                this.allInputFromCurrentFile[groupKey].newLeaderKeys.push({
                    key: dummyLead.accountName,
                    status: 'added',
                    role: 'teacher'
                });
            });
            // this.changesWithGroups[groupIndex] = this.allInputFromCurrentFile[groupKey];

            this.checkGroupStatusAndUpdate(this.allInputFromCurrentFile[groupKey]);
            this.dummyLeaders = [];
        },

        async updateGroupName(groupIndex, value) {
            console.log('updateGroupName for group', groupIndex, value);
            // TODO keyword
            // await this.errorCorrectionCheck();

            const oldGroupKey = ''+this.changesWithGroups[groupIndex].newName;
            const oldObject = this.allInputFromCurrentFile[oldGroupKey];
            this.$set(this.allInputFromCurrentFile, value, {
                ...oldObject,
                newName: value
            })
            // this.allInputFromCurrentFile[value] = {
            //     ...oldObject,
            //     newName: value
            // };
            this.checkGroupStatusAndUpdate(this.allInputFromCurrentFile[value]);
            // delete this.allInputFromCurrentFile[oldGroupKey];
            // this.changesWithGroups[groupIndex] = this.allInputFromCurrentFile[value];
        },

        async errorCorrectionCheck() {
            console.log('errorCorrectionCheck', this.errorCorrectionFillUp);
            const withGroups = this.changesWithGroups;
            const withoutGroup = this.changesWithoutGroup;

            for (let i = 0; i < withGroups.length; i++) {
                if (withGroups[i].status === 'error'
                    || withGroups[i].error
                    || withGroups[i].status === 'errorInside') {
                    // find it in errorcorrection
                    const errorGroup = this.errorCorrectionFillUp[`${withGroups[i].name}`];
                    if (errorGroup) {
                        // update group data if necessary
                        withGroups[i].name = errorGroup.name;
                        let goThroughLeaders = withGroups[i].newLeaders;
                        if (!goThroughLeaders.length) {
                            goThroughLeaders = errorGroup.newLeaders;
                        }
                        // update leader data if necessary
                        for (let j = 0; j < goThroughLeaders.length; j++) {
                            goThroughLeaders[j] = {
                                ...goThroughLeaders[j],
                                ...errorGroup.newLeaders[j],
                            }
                        }
                        withGroups[i].newLeaders = goThroughLeaders;
                        // update participant data if necessary
                        for (let j = 0; j < withGroups[i].newParticipants.length; j++) {
                            const acc = withGroups[i].newParticipants[j];
                            withGroups[i].newParticipants[j] = {
                                ...acc,
                                ...errorGroup.newParticipants[j],
                            }
                        }
                    }
                }
            }

            for (let i = 0; i < withoutGroup.length; i++) {
                if (withoutGroup[i].status === 'error'
                    || withoutGroup[i].error.length) {
                    // update account data if necessary
                    withoutGroup[i] = {
                        ...withoutGroup[i],
                        ...this.errorCorrectionFillUp['Ohne Gruppe'][i]
                    }
                }
            }
        },

        // Analyze and compute changes from given excel file
        async computeChangesFromExcelFile() {
            const fileData = await this.currentFile.arrayBuffer();
            const mainWorkbook = xlsx.read(fileData);

            const fileCheck = this.checkFileConsistency(mainWorkbook);
            if (fileCheck.status) {
                this.fileCheck = fileCheck;
                return 'fileStructureError';
            }

            const currentGroups = JSON.parse(JSON.stringify(this.currentGroups));
            const allInputSortedByGroup = {};
            const allTeacherInput = {};
            const allPupilInput = {};
            const allChangesSortedByGroup = {};

            //#region Show/load new groups
            xlsx.utils.sheet_to_json(mainWorkbook.Sheets["Gruppen"])
                .map((row) => {
                    // check if accountName is new or not set
                    let status = 'normal';
                    let error;
                    let leaders = [];
                    const oldGroupData = currentGroups.find(goup => goup.name === row["Name"]);
                    if (!oldGroupData) {
                        status = 'new';
                    }
                    if (!row['Name']) {
                        status = 'error';
                        error = 'newName';
                    }
                    if (!row["Leiter"] || !row["Leiter"].length) {
                        status = 'error';
                        error = 'leaders';
                    } else {
                        row['Leiter'].split('; ').forEach(compositeName => {
                            if (compositeName.length) {
                                const newName = compositeName.split(',')[1].trim();
                                const newLastName = compositeName.split(',')[0].trim();
                                const foundT = this.currentTeachers.find(t => t.name === newName && t.lastName === newLastName);
                                if (foundT) {
                                    leaders.push(foundT);
                                }
                                leaders.push({ newName, newLastName, status: 'new', role: 'teacher' });
                            }
                        });
                        if (oldGroupData) {
                            leaders.forEach(nl => {
                                const corresNewLead = oldGroupData.leaders.find(ol => ol._id === nl._id);
                                if (!corresNewLead) {
                                    status = 'changed';
                                }
                            });
                        }
                    }
                    const leaderKeys = [];
                    leaders.map(lead => {
                        if (lead.accountName) {
                           leaderKeys.push({
                               key: lead.accountName,
                               status: `${status === 'new' ? 'added' : 'normal'}`,
                               role: 'teacher',
                           });
                        }
                    })
                    const result = {
                        ...oldGroupData,
                        newName: row["Name"],
                        newLeaders: leaders,
                        newLeaderKeys: leaderKeys,
                        newParticipants: [],
                        newParticipantKeys: [],
                        status,
                        error
                    };
                    allInputSortedByGroup[`${row['Name']}`] = result;

                    return result;
                });
            allInputSortedByGroup[`Ohne Gruppe`] = [];
            // Needed in the end
            allChangesSortedByGroup[`Ohne Gruppe`] = [];
            console.log('allInputSortedByGroup', allInputSortedByGroup);
            //#endregion


            //#region Show/load new teachers
            const allTeachersFromFile = xlsx.utils
                .sheet_to_json(mainWorkbook.Sheets["Lehrer"])
                .map((row) => {
                    // check if accountName is new or not set
                    let error;
                    // let status = 'normal';
                    if (!row["Vorname"] || !row["Vorname"].length) {
                        // status = 'error';
                        error = 'newName';
                    }
                    if (!row["Nachname"] || !row["Nachname"].length) {
                        // status = 'error';
                        error = 'newLastName';
                    }
                    const newTeacherObject = {
                        accountName: row["Benutzername"],
                        name: row["Vorname"],
                        lastName: row["Nachname"],
                        note: row["Notiz"] || "",
                        role: 'teacher'
                    }
                    const oldTeacher = this.currentTeachers.find(t => t.accountName === row["Benutzername"]);
                    const checkedTeacher = this.checkAccountStatusAndUpdate(newTeacherObject);
                    const status = checkedTeacher.status || 'normal';
                    // TODO check and Generate username in here
                    const groupNames = row["Gruppen"] ? row["Gruppen"].split(',') : [];
                    let result = {
                        ...oldTeacher,
                        newAccountName: row["Benutzername"],
                        newName: row["Vorname"],
                        newLastName: row["Nachname"],
                        newNote: row["Notiz"] || "",
                        newGroups: groupNames || [],
                        rowNumber: row["__rowNum__"],
                        status, //TODO keyword
                        error,
                        role: 'teacher',
                    };
                    if (!result.newAccountName) {
                        // create account name as key
                        result = this.checkAndGenerateDataForNewAccount(result);
                    }
                    result.key = result.newAccountName;
                    const keyObjectForGroups = {
                        key: result.key,
                        role: 'teacher',
                        status,
                        error
                    };
                    allTeacherInput[`${result.key}`] = result;

                    if (!groupNames.length) {
                        // check all groups and see if teacher is added there somewhere
                        const allInputKeys = Object.keys(allInputSortedByGroup).filter(key => key !== 'Ohne Gruppe');
                        for (let i = 0; i < allInputKeys.length; i++) {

                            if (allInputSortedByGroup[`${allInputKeys[i]}`].newLeaders
                                .find(newL => (newL.newName === result.newName && newL.newLastName === result.newLastName && !newL._id)
                                    || (newL.newAccountName === result.newAccountName)
                                    || (newL._id === result._id))) {
                                groupNames.push(allInputSortedByGroup[`${allInputKeys[i]}`].newName);
                            }
                        }
                        if (!groupNames.length) {
                            allInputSortedByGroup[`Ohne Gruppe`].push(keyObjectForGroups);
                        }
                    }
                    // todo Keyword for each gruppen name check if teacher is in leaders and add there
                    groupNames.forEach(gn => {
                        if (gn.trim()) {
                            if (status !== 'normal' && allInputSortedByGroup[`${gn.trim()}`].status === 'normal') {
                                allInputSortedByGroup[`${gn.trim()}`].status = 'changeInside';
                            }
                            if (error || keyObjectForGroups.status === 'error') {
                                allInputSortedByGroup[`${gn.trim()}`].status = 'errorInside';
                            }
                            // TODO change key check
                            allInputSortedByGroup[`${gn.trim()}`].newLeaders =
                                allInputSortedByGroup[`${gn.trim()}`].newLeaders.map(nl => {
                                if (nl === `${result.newLastName}, ${result.newName}`
                                    || (result.newAccountName && nl.accountName === result.newAccountName)) {
                                    return result;
                                }
                                return nl;
                            });
                            // TODO something doesnt add up if leader is only added in Leiter Sheet
                            if (!allInputSortedByGroup[`${gn.trim()}`].newLeaders.find(nl =>
                                (result.newAccountName && nl.newAccountName === result.newAccountName))) {
                                console.log('add teacher to group');
                                // Push teacher to newleaders because it isnt done on gruppen page

                                let statusFromGroup = status;
                                if ((allInputSortedByGroup[`${gn.trim()}`].leaders
                                    && !allInputSortedByGroup[`${gn.trim()}`].leaders.find(l =>
                                        result.newAccountName && l.accountName === result.newAccountName))) {
                                    if (allInputSortedByGroup[`${gn.trim()}`].status !== 'new') {
                                        allInputSortedByGroup[`${gn.trim()}`].status = 'changed';
                                    }
                                    // only possible error is missing leaders
                                    if (allInputSortedByGroup[`${gn.trim()}`].status === 'error') {
                                        allInputSortedByGroup[`${gn.trim()}`].status = 'changeInside';
                                    }
                                    statusFromGroup = 'added';
                                }
                                // TODO keyword
                                allInputSortedByGroup[`${gn.trim()}`].newLeaders.push(allTeacherInput[`${result.key}`]);
                                allInputSortedByGroup[`${gn.trim()}`].newLeaderKeys.push({
                                    ...keyObjectForGroups,
                                    status: statusFromGroup
                                });
                            }
                        }
                    });
                    return result;
                });
            console.log('allTeacherInput', allTeacherInput);
            //#endregion


            //#region Show/load new pupils
            const allPupilsFromFile = xlsx.utils
                .sheet_to_json(mainWorkbook.Sheets["Schüler"])
                // TODO async map not recommended, change to reduce
                .map(async (row) => {
                    // check if accountName is new or not set
                    let error = null;
                    if (!row["Vorname"] || !row["Vorname"].length) {
                        error = 'newName';
                    }
                    if (!row["Nachname"] || !row["Nachname"].length) {
                        error = 'newLastName';
                    }
                    const newPupilObject = {
                        accountName: row["Benutzername"],
                        name: row["Vorname"],
                        lastName: row["Nachname"],
                        note: row["Notiz"],
                        parent: row["Eltern"], //TODO handle parent entry
                        babyView: row['Einfacher Modus'] === 'Ja',
                        role: 'pupil',
                    };
                    const checkedAccount = this.checkAccountStatusAndUpdate(newPupilObject)
                    const status = checkedAccount.status || 'normal';
                    const oldPupil = this.currentPupils.find(pup => pup.accountName === row['Benutzername']);
                    const groupNames = row["Gruppen"] ? row["Gruppen"].split(',') : [];
                    let result = {
                        ...oldPupil,
                        newAccountName: row["Benutzername"],
                        newName: row["Vorname"],
                        newLastName: row["Nachname"],
                        newNote: row["Notiz"] || "",
                        newGroups: groupNames || [],
                        rowNumber: row["__rowNum__"],
                        newParent: row["Eltern"], //TODO handle parent entry
                        newBabyView: row['Einfacher Modus'] === 'Ja',
                        role: 'pupil',
                        status,
                        error,
                    };
                    if (oldPupil) {
                        // TODO make nice and handle parent delete
                        // check for parent entry
                        if (oldPupil.parent && row['Eltern'] === 'Nein') {
                            // how to toggle delete parent later
                        }
                        if (!oldPupil.parent && row['Eltern'] === 'Nein') {
                            // default case, no parent, dont do anything
                        }
                        if (oldPupil.parent && row['Eltern'] === 'Ja') {
                            // just take old parent
                            result.newParent = oldPupil.parent;
                        }
                        if (!oldPupil.parent && row['Eltern'] === 'Ja') {
                            // create new parent
                            result.newParent = row['Eltern'];
                        }
                    }
                    if (!result.newAccountName) {
                       result = await this.checkAndGenerateDataForNewAccount(result);
                    }
                    result.key = result.newAccountName;
                    allPupilInput[`${result.key}`] = result;

                    const keyObjectForGroups = {
                        key: result.key,
                        role: 'pupil',
                        status: error ? 'error': status,
                        error
                    };

                    if (!groupNames.length) {
                        // TODO Keyword update
                        // allInputSortedByGroup['Ohne Gruppe'].push(allPupilInput[`${result.key}`]);
                        allInputSortedByGroup['Ohne Gruppe'].push(keyObjectForGroups);
                    }
                    groupNames.forEach(gn => {
                        let statusFromGroup = status;
                        if (status !== 'normal' && allInputSortedByGroup[`${gn.trim()}`].status === 'normal') {
                            allInputSortedByGroup[`${gn.trim()}`].status = 'changeInside';
                        }
                        if (error || keyObjectForGroups.status === 'error') {
                            allInputSortedByGroup[`${gn.trim()}`].status = 'errorInside';
                        }
                        if (!allInputSortedByGroup[`${gn.trim()}`].newParticipants.includes(result)) {
                            if (oldPupil
                                && (allInputSortedByGroup[`${gn.trim()}`].participants
                                    && !allInputSortedByGroup[`${gn.trim()}`].participants
                                        .find(pup => pup._id === oldPupil._id))) {
                                // If the pupil isnt new but wasnt in the group before
                                allInputSortedByGroup[`${gn.trim()}`].status = 'changed';
                                statusFromGroup = 'added';
                            }
                            // TODO Keyword update
                            // allInputSortedByGroup[`${gn.trim()}`].newParticipants.push(allPupilInput[`${result.key}`]);
                            allInputSortedByGroup[`${gn.trim()}`].newParticipantKeys.push({
                                ...keyObjectForGroups,
                                status: statusFromGroup
                            });
                        }

                    });
                    return result;
                });
            console.log('allPupilInput', allPupilInput);
            //#endregion

            console.log('allInputSortedByGroup in the end', allInputSortedByGroup);
            // check error status and change UI in here as well
            const allInputKeys = Object.keys(allInputSortedByGroup).filter(key => key !== 'Ohne Gruppe');
            for (let i = 0; i < allInputKeys.length; i++) {
                if (allInputSortedByGroup[`${allInputKeys[i]}`].status !== 'normal') {
                    allChangesSortedByGroup[`${allInputKeys[i]}`] = allInputSortedByGroup[`${allInputKeys[i]}`];
                }
                // if (allInputSortedByGroup[`${allInputKeys[i]}`].status === 'error' || allInputSortedByGroup[`${allInputKeys[i]}`].status === 'errorInside') {
                //     this.blockBecauseError = true;
                // }
            }
            for (let i = 0; i < allInputSortedByGroup[`Ohne Gruppe`].length; i++) {
                if (allInputSortedByGroup[`Ohne Gruppe`][i].status !== 'normal') {
                    allChangesSortedByGroup['Ohne Gruppe'].push({ ...allInputSortedByGroup[`Ohne Gruppe`][i] });
                }
            }
            console.log('allInputSortedByGroup', allInputSortedByGroup);
            this.allInputFromCurrentFile = allInputSortedByGroup;
            this.allTeacherInput = allTeacherInput;
            this.allPupilInput = allPupilInput;
            return allChangesSortedByGroup;
        },

        checkFileConsistency(workbook) {
            const fileWrong = { status: false, reason: '' };

            const checkArrayKeys = (excelArray, reference, reason) => {
                if (excelArray.length !== reference.length) {
                    fileWrong.status = true;
                    fileWrong.reason = reason;
                }
                for (let i = 0; i < excelArray.length; i++) {
                    if (excelArray[i] !== reference[i]) {
                        fileWrong.status = true;
                        fileWrong.reason = reason;
                    }
                }
            };

            if (!workbook.Sheets["Gruppen"] || !workbook.Sheets["Lehrer"] || !workbook.Sheets["Schüler"]) {
                fileWrong.status = true;
                fileWrong.reason = 'changedSheets';
                return fileWrong;
            }

            if (workbook.Sheets["Gruppen"]) {
                const groupSheet = xlsx.utils.sheet_to_json(workbook.Sheets["Gruppen"]);
                const groupKeys = Object.keys(groupSheet[0]);
                const neededKeys = ['Name', 'Leiter'];
                checkArrayKeys(groupKeys, neededKeys, 'groupKeys');
            }

            if (workbook.Sheets["Lehrer"]) {
                const teacherSheet = xlsx.utils.sheet_to_json(workbook.Sheets["Lehrer"]);
                // TODO max number of keys from whole array, not to miss any
                const teacherKeys = Object.keys(teacherSheet[0]);
                const neededKeys = ['Benutzername', 'Vorname', 'Nachname', 'Notiz', 'Gruppen'];
                checkArrayKeys(teacherKeys, neededKeys, 'teacherKeys');
            }

            if (workbook.Sheets["Schüler"]) {
                const pupilSheet = xlsx.utils.sheet_to_json(workbook.Sheets["Schüler"]);
                if (pupilSheet.length) {
                    // TODO max number of keys from whole array, not to miss any
                    const pupilKeys = Object.keys(pupilSheet[0]);
                    const neededKeys = ['Benutzername', 'Vorname', 'Nachname', 'Notiz', 'Einfacher Modus', 'Eltern', 'Gruppen'];
                    checkArrayKeys(pupilKeys, neededKeys, 'pupilKeys');
                }
            }

            return fileWrong;
        },

        showCheckboxes() {
            this.displayChangesFromExcel = false;
            this.justSendIt = false;
            this.changeSchoolYearCheckbboxes = true;
        },

        async changesToBackend() {
            this.excelImportLogger.push({
                message: `Vorbereitete Änderungen werden an den Server geschickt, bitte warten.`,
                level: 'normal'
            });
            this.displayChangesFromExcel = false;
            this.changeSchoolYearCheckbboxes = false;
            this.justSendIt = true;
            const jsZip = new JSZip();


            // go through all groups and pool new accounts, update afterwards
            const allAccountsToUpdate = [];
            Object.keys(this.allTeacherInput).forEach(key => {
               if (this.allTeacherInput[key].status === 'new' || this.allTeacherInput[key].status === 'changed') {
                   allAccountsToUpdate.push(this.allTeacherInput[key]);
               }
            });
            Object.keys(this.allPupilInput).forEach(key => {
                if (this.allPupilInput[key].status === 'new' || this.allPupilInput[key].status === 'changed') {
                    allAccountsToUpdate.push(this.allPupilInput[key]);
                }
            });

            const allUpdatedAccounts = await allAccountsToUpdate.reduce(async (previousPromise, account) => {
                const previous = await previousPromise;
                const { requestResponse, postParentResponse, parentData, dataForBackend }
                    = await this.patchOrPostAccount(account);

                if (requestResponse) {
                    dataForBackend._id = requestResponse._id;
                }
                previous.push({
                    ...dataForBackend,
                    parentData,
                    postParentResponse,
                    requestResponse
                });
                return previous;
            }, Promise.resolve([]));

            // go through changes with groups
            await this.changesWithGroups.reduce(async (previousPromise, group) => {
                const previous = await previousPromise;

                const updateEntry = async (account, arrayTarget) => {
                    if (account.status === 'new') {
                        const updatedAcc = allUpdatedAccounts.find(acc => acc.accountName === account.key);
                        // create parent pdf
                        if (updatedAcc.parentData) {
                            const parentPdfBytes = await this.pdf(updatedAcc.parentData, 'parent');
                            const pdfName = `${group.name || group.newName}/${updatedAcc.parentData.accountName}.pdf`
                            jsZip.file(pdfName, parentPdfBytes);
                        }
                        // create pdf
                        console.log('updatedAcc for pddf', updatedAcc);
                        const accountPdfBytes = await this.pdf(updatedAcc, account.role);
                        const pdfName = `${group.name || group.newName}/${updatedAcc.accountName}.pdf`
                        jsZip.file(pdfName, accountPdfBytes);
                        arrayTarget.push(updatedAcc);
                    }
                    else if (account.status === 'changed') {
                        arrayTarget.push(allUpdatedAccounts.find(acc => acc._id === account._id));
                    } else {
                        arrayTarget.push(account);
                    }
                };

                // find leader accounts in new array
                group.newLeaders = await group.newLeaderKeys.reduce(async (accountsPromise, newLeadEntry) => {
                   const accounts = await accountsPromise;
                   const newL = {
                       ...this.allTeacherInput[newLeadEntry.key],
                       role: 'teacher'
                   };
                   await updateEntry(newL, accounts);
                   return accounts;
                }, Promise.resolve([]));

                // find participant accounts in new array
                group.newParticipants = await group.newParticipantKeys.reduce(async (accountsPromise, newPartEntry) => {
                    const accounts = await accountsPromise;
                    const newP = {
                        ...this.allPupilInput[newPartEntry.key],
                        role: 'pupil'
                    };
                    await updateEntry(newP, accounts);
                    return accounts;
                }, Promise.resolve([]));

                const groupForBackend = {
                    _id: group._id,
                    name: group.newName,
                    leaders: group.newLeaders.map(l => l._id),
                    participants: group.newParticipants.map(p => p._id),
                }
                let groupResponse = null;
                if (group.status === 'new') {
                    // post new groups
                    groupResponse = await this.createGroup(groupForBackend);
                    if (groupResponse._id) {
                        this.excelImportLogger.push({
                            message: `Gruppe ${group.newName} wurde erfolgreich erstellt.`,
                            level: 'normal'
                        });
                    }
                } else if (group.status === 'changed') {
                    // patch changed groups
                    // add options for change (only if Schuljahreswechsel)
                    if (this.changeSchoolYear) {
                        groupForBackend.options = {
                            deleteChat: !this.options.keepChat,
                            deleteAppointments: !this.options.keepAppointments,
                        };
                    }
                    groupResponse = await this.editGroup(groupForBackend);
                    if (groupResponse._id) {
                        this.excelImportLogger.push({
                            message: `Gruppe ${group.newName} wurde erfolgreich geändert.`,
                            level: 'normal'
                        });
                    }
                }

                previous.push(groupResponse);
                return previous;
            }, Promise.resolve([]));

            // go through changes without groups next
            await this.changesWithoutGroup.reduce(async (previousPromise, account) => {
                const previous = await previousPromise;
                let allResponseData = allUpdatedAccounts.find(acc => acc.accountName === account.key);
                if (account.status === 'new') {
                    // (print new documents in corresponding zip subfolders)
                    if (allResponseData.parentData) {
                        const parentPdfBytes = await this.pdf(allResponseData.parentData, 'parent');
                        const pdfName = `Ohne Gruppe/${allResponseData.parentData.accountName}.pdf`
                        jsZip.file(pdfName, parentPdfBytes);
                    }
                    const accountPdfBytes = await this.pdf(allResponseData, account.role);
                    const pdfName = `Ohne Gruppe/${allResponseData.accountName}.pdf`
                    jsZip.file(pdfName, accountPdfBytes);
                }

                return previous;
            }, Promise.resolve([]))

            // download big zip folder
            if (allUpdatedAccounts.length) {
                this.excelImportLogger.push({
                    message: `Neue Anmeldedokumente für ${allUpdatedAccounts.length} Accounts werden zum Download zur Verfügung gestellt...`,
                    level: 'normal'
                });
                const content = await jsZip.generateAsync({type: "blob"});
                saveAs(content, `${this.changeSchoolYear ? 'Anmeldedokumente Schuljahreswechsel' : 'Importierte Schüler'}.zip`);
            }
            this.excelImportLogger.push({
                message: `Prozess wurde erfolgreich abgeschlossen. Sie können den Dialog nun schließen.`,
                level: 'normal'
            });
        },

        async checkAndGenerateDataForNewAccount (newAccount) {
            // check if accountname valid and available
            const isAccNameValidAndAvailable = async (name) => {
                return name && !this.containsUsernameStrangeLetters(name)
                    && await this.isAccountNameAvailable(name);
            };
            // (if not generate new accountname)
            if (!(await isAccNameValidAndAvailable(newAccount.newAccountName))) {
                let accNameAvailable = false;
                let nameProposal = newAccount.newAccountName;
                for (let i = 1; !accNameAvailable; i++) {
                    // check for strange chars and change them
                    // TODO move lots of this stuff to util functions like string checking and stuff
                    console.log('nameProposal', nameProposal, this.containsUsernameStrangeLetters(nameProposal));
                    if (nameProposal) {
                        if (this.containsUsernameStrangeLetters(nameProposal)) {
                            nameProposal = this.cureNameFromStrangeLetters(nameProposal);
                        }
                        console.log('nameProposal after', nameProposal, this.containsUsernameStrangeLetters(nameProposal));
                        // check validity
                        accNameAvailable = await this.isAccountNameAvailable(nameProposal);
                        if (accNameAvailable) {
                            break;
                        }
                    }
                    // create new
                    if (newAccount.role === 'teacher') {
                        nameProposal =
                            `${newAccount.newName.slice(0, 1).toLowerCase()}${newAccount.newLastName.toString().trim().toLowerCase()}${nameProposal ? i : ''}`;
                    } else if (newAccount.role === 'pupil') {
                        nameProposal =
                            `${newAccount.newName.toString().trim().toLowerCase()}${newAccount.newLastName.toString().slice(0, 1).toLowerCase()}${nameProposal ? i : ''}`;
                    } else if (newAccount.role === 'parent') {
                        nameProposal =
                            `${newAccount.newAccountName.toString().trim().toLowerCase()}${nameProposal ? i : ''}`;
                    }
                }
                // add output to log
                const correctRole = newAccount.role === 'teacher' ? 'Lehrer'
                    : newAccount.role === 'parent' ? 'Elternaccount' : 'Schüler';
                let loggerMessage = 'Nutzername von ' + correctRole;
                loggerMessage += correctRole === 'Elternaccount'
                    ? ' wurde' : ` ${newAccount.newLastName}, ${newAccount.newName} wurde`;
                loggerMessage += newAccount.newAccountName ? ` von '${newAccount.newAccountName}'` : '';
                loggerMessage += ` zu ${nameProposal} geändert.`
                this.excelImportLogger.push({
                    message: loggerMessage,
                    level: 'normal'
                });
                newAccount.newAccountName = nameProposal;
            }
            // generate pw and qr-pw
            newAccount.newPassword = generateRandomPassword({passwordLength: 8});
            newAccount.newQrPassword = generateRandomPassword({passwordLength: 16});
            // return all data
            return newAccount;
        },

        async postNewAccount(newAccount) {
            let requestResponse = '';
            let postParentResponse = '';
            let dataForBackend = null;
            let parentData = null;
            // check if teacher or pupil
            if (newAccount.role === 'pupil') {
                // if pupil check if create parent
                if (newAccount.newParent) {
                    parentData = await this.checkAndGenerateDataForNewAccount({
                        gender: '',
                        newAccountName: newAccount.newLastName.toLowerCase(),
                        role: 'parent',
                        note: '',
                        pupils: [],
                    });
                    parentData.accountName = parentData.newAccountName;
                    parentData.password = parentData.newPassword;
                    parentData.qrPassword = parentData.newQrPassword;
                    console.log('parentData', parentData);

                    postParentResponse = await this.createParent(parentData);
                    newAccount.newParent = postParentResponse["_id"];
                }
                dataForBackend = {
                    accountName: newAccount.newAccountName,
                    password: newAccount.newPassword,
                    qrPassword: newAccount.newQrPassword,
                    name: newAccount.newName,
                    lastName: newAccount.newLastName,
                    gender: '',
                    note: newAccount.newNote,
                    babyView: newAccount.newBabyView,
                    ... (newAccount.newParent === 'Ja' || newAccount.newParent === 'Nein'
                        ?  {} : {parent: newAccount.newParent}),
                }
                requestResponse = await this.createPupil(dataForBackend);
            } else if (newAccount.role === 'teacher') {
                // if teacher just create teacher
                dataForBackend = {
                    accountName: newAccount.newAccountName,
                    password: newAccount.newPassword,
                    qrPassword: newAccount.newQrPassword,
                    simpleEditor: false,
                    name: newAccount.newName,
                    lastName: newAccount.newLastName,
                    gender: '',
                    note: newAccount.newNote,
                }
                const teacherPdfBytes = await this.pdf(dataForBackend, 'teacher');
                requestResponse = await this.createTeacher(dataForBackend);
            } else {
                // error to logger
                this.excelImportLogger.push({
                    message: `Fehler: ${newAccount.role === 'teacher' ? 'Lehrer' : 'Schüler'} ${newAccount.newLastName}, ${newAccount.newName} hat eine unbekannte Rolle: '${newAccount.role}'`,
                    level: 'error'});
            }
            if (Number.isInteger(requestResponse)) {
                this.excelImportLogger.push({
                    message: `Etwas ist beim Übertragen auf den Server fehlgeschlagen, Fehlercode ${requestResponse}`,
                    level: 'warning'
                });
            } else {
                this.excelImportLogger.push({
                    message: `Neuer Nutzer ${newAccount.role === 'teacher' ? 'Lehrer' : 'Schüler'} ${newAccount.newLastName}, ${newAccount.newName} wurde erfolgreich erstellt.`,
                    level: 'normal'
                });
            }
            // return response
            return { requestResponse, postParentResponse, parentData, dataForBackend};
        },

        async patchExistingAccount(account) {
            let requestResponse = '';
            let dataForBackend = null;
            let postParentResponse = '';
            let parentData = null;
            // check if teacher or pupil
            if (account.role === 'pupil') {
                // if pupil check for parent
                if (account.newParent != null && !account.parent) {
                    parentData = await this.checkAndGenerateDataForNewAccount({
                        gender: '',
                        newAccountName: account.newLastName,
                        note: '',
                        pupils: [],
                    });
                    parentData.accountName = parentData.newAccountName;
                    parentData.password = parentData.newPassword;
                    parentData.qrPassword = parentData.newQrPassword;

                    postParentResponse = await this.createParent(parentData);
                    account.newParent = postParentResponse["_id"];
                }
                dataForBackend = {
                    _id: account._id,
                    accountName: account.newAccountName,
                    password: account.newPassword,
                    qrPassword: account.newQrPassword,
                    name: account.newName,
                    lastName: account.newLastName,
                    gender: '',
                    note: account.newNote,
                    babyView: account.newBabyView,
                    ... (account.newParent === 'Ja' || account.newParent === 'Nein'
                        ?  {} : {parent: account.newParent}),
                }
                // post parent and patch pupil
                requestResponse = await this.editPupil(dataForBackend);

            } else if (account.role === 'teacher') {
                // if teacher just patch teacher
                dataForBackend = {
                    _id: account._id,
                    accountName: account.newAccountName,
                    password: account.newPassword,
                    qrPassword: account.newQrPassword,
                    name: account.newName,
                    lastName: account.newLastName,
                    gender: '',
                    note: account.newNote,
                    simpleEditor: false,
                }
                // post parent and patch pupil
                requestResponse = await this.editTeacher(dataForBackend);
            } else {
                // error to logger
                this.excelImportLogger.push(`Fehler: ${account.role === 'teacher' ? 'Lehrer' : 'Schüler'} ${account.newLastName}, ${account.newName} hat eine unbekannte Rolle: '${account.role}'`);
            }
            // add output to logger
            if (Number.isInteger(requestResponse)) {
                this.excelImportLogger.push(`Etwas ist beim Übertragen auf den Server fehlgeschlagen, Fehlercode ${requestResponse}`);
            } else {
                this.excelImportLogger.push(`Nutzer ${account.role === 'teacher' ? 'Lehrer' : 'Schüler'} ${account.newLastName}, ${account.newName} wurde erfolgreich geändert.`);
            }
            // return response
            return { requestResponse, postParentResponse, parentData, dataForBackend};

        },

        containsUsernameStrangeLetters(username) {
            // eslint-disable-next-line no-useless-escape
            const format = /[!@#$äüö%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            return format.test(username);
        },

        cureNameFromStrangeLetters(name) {
            name = sanitizeHtml(name);
            // replace umlaute with valid counterparts
            name = name.replace('ä', 'ae')
                .replace('ö', 'oe')
                .replace('ü', 'ue');
            // everything else
            // eslint-disable-next-line no-useless-escape
            name = name.replace(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/, '');
            return name;
        },

        async patchOrPostAccount(account) {
            let allResponseData = {};
            // post new accounts
            if (account.status === 'new') {
                allResponseData = await this.postNewAccount(await this.checkAndGenerateDataForNewAccount(account))
            }
            // patch changed accounts
            if (account.status === 'changed' && account._id) {
                allResponseData = await this.patchExistingAccount(account);
            }
            return allResponseData;
        },

        // Start Method of it all rip
        clickExcelUploadButton() {
            this.fileCheck = {};
            document.getElementById("extendedExcelUploadInput").click();
        },

        // event handling for panels because design
        panelOpened(index, groupId) {
            this.$nextTick(() => {
                // Check if panel is open in oldPanels
                if (this.lastPressedPanelIndex === index) {
                    this.changesPanels = undefined;
                    this.lastPressedPanelIndex = -1;
                } else {
                    this.changesPanels = index;
                    this.lastPressedPanelIndex = index;
                }
            });
        },

        async pdf(account, role) {

            let pdfBytes = null;
            if (role === 'pupil' || role === 'teacher') {
                pdfBytes = await LoginPdf.getNormalLoginPdf({
                    firstName: account.name,
                    lastName: account.lastName,
                    accountName: account.accountName,
                    password: account.password,
                    qrPassword:  account.qrPassword,
                    role
                });
            } else if (role === 'parent') {
                pdfBytes = await LoginPdf.getParentLoginPdfv2({
                    accountName: account.accountName,
                    password: account.password,
                    qrPassword:  account.qrPassword,
                })
            } else {
                console.warn('Trying to generate pdf with invalid role:', role, ' | returning');
                return;
            }

            return pdfBytes;
        },

        handleBackButton() {
            if (this.displayChangesFromExcel) {
                this.displayChangesFromExcel = false;
            } else if (this.changeSchoolYearCheckbboxes) {
                this.changeSchoolYearCheckbboxes = false;
                this.displayChangesFromExcel = true;
            }
        },

        handleForwardButton() {
            if (this.changeSchoolYear && this.displayChangesFromExcel) {
                this.showCheckboxes();
            } else {
                this.changesToBackend();
            }
        },

        close() {
            this.showReadContentDialog = false;
            this.$emit('close');
        }
    }
}
</script>

<style scoped lang="scss">
.loggerDiv {
    border: 1px solid grey;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
}
</style>
