<template>
    <div>
        <v-menu
            :close-on-content-click="false"
            offset-x
            :left="colorPickerUsage !== 'pinboardBase'"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    x-small
                    elevation="0"
                    v-bind="attrs"
                    class="ml-0"
                    :class=" uploadIcon || defaultProfilePic ? 'iconBtn' :'colorButton'"
                    :style=" !uploadIcon ? `background-color: ${currentColor};` : ''"
                    v-on="on"
                >
                    <div>
                        <img
                            :hidden="getIcon(currentIcon)==='loading'"
                            class="icon30 mb-0"
                            :class="uploadIcon || defaultProfilePic ? '' : ' iconToWhite'"
                            :src="getIcon(currentIcon)"
                            :style="getCurrentHeight"
                            alt="Icon"
                        >
                    </div>
                    <img
                        :class="uploadIcon ? 'ownIcon' :'colorIcon'"
                        :src="colorPickerUsage === 'fachErstellen' || colorPickerUsage === 'brett' || colorPickerUsage === 'messages' || colorPickerUsage === 'externalApp' ? bearbeitenIconSchwarz : bearbeitenIcon"
                        :style="colorPickerUsage === 'externalApp' ? 'margin-right: -6px; margin-bottom: -6px;' : ''"
                    >
                </v-btn>
            </template>
            <v-card
                id="colorButtonContainer"
                :color="backgroundColor ? backgroundColor : 'rgba(98,98,98,255)'"
            >
                <v-btn-toggle
                    id="colorButtonContainer"
                    v-model="toggleExclusive"
                    mandatory
                    light
                    :background-color="backgroundColor? backgroundColor : 'rgba(98,98,98,255)'"
                >
                    <v-btn
                        v-for="(icon, index) in allIcons"
                        :key="index"
                        :value="icon"
                        active-class="selected"
                        :class="icon.uploader ? 'iconBtn' : ''"
                        elevation="0"
                        style="margin: 6px; border-radius: 8px;"
                        :style="colorPickerUsage === 'createGroupChat' ? 'background-color: black; max-width: 48px' : ''"
                        @click="icon.text ? callbackIcon(icon.text) : callbackIcon(icon._id); colorPickerUsage === 'pinboardBase' ? savePinboardChanges() : {}"
                    >
                        <img
                            :src="icon.icon"
                            :class="(filterIconColorToGrey && icon.text )? 'filterWhiteIconsToGrey' : ''"
                            :style="colorPickerUsage === 'createGroupChat' ? getHeight(icon) : ''"
                            :alt="icon.text"
                        >
                    </v-btn>
                </v-btn-toggle>
                <v-divider dark />
                <v-btn
                    v-for="(color, index) in colors"
                    :key="index"
                    class="colorButton"
                    :class="{ 'selected': color === currentColor }"
                    :style="`background-color: ${color}`"
                    x-small
                    elevation="0"
                    @click="callback(color); colorPickerUsage === 'pinboardBase' ? savePinboardChanges() : {}"
                >
                    <!-- <img
                        v-if="color === currentColor"
                        class="iconToWhite"
                        :src="toggleExclusive.icon"
                    > -->
                </v-btn>
                <div
                    class="d-flex justify-space-around "
                >
                    <ImageUpload
                        :usage="colorPickerUsage === 'pinboard' ? 'pinboardUsage' : 'colorPickerUsage'"
                        :activate-delete-btn="uploadedIcons.length>0"
                        :icon-to-delete="deleteIcon"
                        @iconDeleted="iconDeleted()"
                        @imageUpload="(uploadResponse) => { iconUploaded(uploadResponse) }"
                    />
                </div>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import ImageUpload from '@/components/Utils/ImageUpload';
import bearbeitenIcon from "@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import bearbeitenIconSchwarz from '@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg'
import * as backend from "@/api/backend";
import {mapActions, mapState} from "vuex";

export default {
    name: 'IconPicker',
    components: {
        ImageUpload,
    },
    props: {
        currentColor: { required: true, type: String },
        callback: { required: true, type: Function },
        currentIcon: { required: true },
        callbackIcon: { required: true },
        groupIcons: { required: true, type: Array },
        filterIconColorToGrey: { required: false, type: Boolean, default: false },
        colorPickerUsage: { required: false, type: String },
        currentMessage: { required: false, type: String},
        backgroundColor: { required: false, type: String }
    },
    data: () => ({
        colors: [
            '#f0b423', '#ff6633', '#e6231e', '#3ba934', '#D1BC8A', '#815a30', '#F3a4e7',
            '#95509C','#6995cd', '#40E0D0'
        ],
        toggleExclusive: [],
        bearbeitenIcon,
        bearbeitenIconSchwarz,
        uploadedIcons: [],
        uploadIcon: false,
        defaultProfilePic: false,
        deleteIcon: '',
    }),
    computed:{
        ...mapState("auth", ["token"]),

        allIcons(){
            // combine standard icons and uploaded icons list
            let icons = [];
            if(this.groupIcons.length > 0){
                icons = icons.concat(this.groupIcons);
            }
            if(this.uploadedIcons.length > 0){
                icons = icons.concat(this.uploadedIcons);
            }
            return icons;
        },

        getCurrentHeight() {
            if (this.currentIcon.toLowerCase().includes('group')) {
                return 'height: 25px !important; width: 25px !important'
            }
            return '';
        }
    },
    watch:{
        toggleExclusive(newVal){
            if(newVal.file){
                this.deleteIcon = newVal._id
            } else if (newVal.text){
                this.deleteIcon = "defaultIcon"
            }
        }
    },
    async mounted() {
        await this.requestIcons();
    },
    methods: {
        ...mapActions('uploadedIcons', ['getUploadedIcon', 'getIconUploads']),
        iconUploaded(res) {
            this.requestIcons(true);
            this.$emit("changeIcon", res._id);
        },
        async iconDeleted() {
            this.$emit('changeIcon', 'pinIconWeiss');
            await this.requestIcons(true);
        },
        async requestIcons(forceRequest){
            const icons = await this.getIconUploads(forceRequest);
            // map urls to Icons
            this.uploadedIcons = await Promise.all(icons.map(async (icon) => ({
                    ...icon,
                    icon: await this.getUploadedIcon(icon._id),
                })
            ));
        },
        getIcon(ref){
            //return icon from object, this.uploadIcon needed for styling
            const res = this.allIcons.find((icon) =>{
                    if(icon.text){
                        if (icon.text === ref) {
                            this.uploadIcon = false;
                            return icon.icon
                        }
                    } else {
                        if (icon._id === ref) {
                            this.uploadIcon = true;
                            return icon.icon
                        }
                    }
                }
            );
            if(res){
                return res.icon;
            } else {
                if (typeof this.currentIcon === 'string' && this.currentIcon.includes('blob')) {
                    this.uploadIcon = true;
                    return this.currentIcon;
                } else if (typeof this.currentIcon === 'string') {
                    this.defaultProfilePic = true;
                    return this.currentIcon;
                } else {
                    return this.groups[0].icon;
                }
            }
        },
        savePinboardChanges() {
            this.$emit("savePinboardChanges");
        },
        getHeight(icon) {
            if (icon.text === 'Gruppen-Icon') {
                return 'height: 25px !important; width: 25px !important'
            }
            return 'height: 45px; width: 45px'
        }
    }
}
</script>

<style lang="scss" scoped>
#colorButtonContainer {
    max-width: 240px;
    height: 100px;
    display: table;
    border-radius: 8px;
    background-color: rgba(98,98,98,255)
}
.colorButton {
    width: 48px !important;
    height: 48px !important;
    margin: 6px;
    border-radius: 8px;
    border: none;
}

.iconBtn{
    width: 48px !important;
    height: 48px !important;
    margin: 6px;
    border-radius: 8px;
    & img {
        width: 48px !important;
        height: 48px !important;
        border-radius: 8px;
    }
}
.selected {
    outline: 3px solid #62aed2 !important;
}
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}
.filterWhiteIconsToGrey {
    filter: invert(50%) sepia(0%) saturate(249%) hue-rotate(136deg) brightness(86%) contrast(91%);
}
.ownIcon{
    position: absolute;
    right:-2px;
    bottom:-2px;
    width: 20px !important;
    height: 20px !important;
    max-width: 20px !important;
    max-height: 20px !important;
}
.colorIcon{
    position: absolute;
    right: -10px;
    bottom: -10px;
    width: 20px !important;
    height: 20px !important;
    max-width: 20px !important;
    max-height: 20px !important;
}
</style>
