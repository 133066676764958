<template>
    <div
        class="group-list-container"
        :style="dataLoading ? 'display:none;' :''"
    >
        <div
            class="group-chat-header"
        >
            <div
                class="d-flex align-center justify-space-between"
                style="width: 100%"
            >
                <v-tooltip
                    top
                    style="z-index: 10"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            class="d-flex justify-center align-center"
                        >
                            <img
                                :src="chatIcon"
                                class="mr-2 icon20 iconToWhite"
                                alt=""
                                v-bind="attrs"
                                v-on="on"
                            >
                            <!-- <p
                                tabindex="0"
                                class="mb-0"
                                style="font-size: larger"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Chat
                            </p> -->
                            
                                
                            <v-text-field
                                v-model="searchChatGroups"
                                :append-icon="searchChatGroups.trim().length > 0 ? 'mdi-close' : ''"
                                hide-details
                                dark
                                dense
                                tabindex="0" 
                                class="mb-1"
                                placeholder="Nach Chat suchen..."
                                @click:append="() => { searchChatGroups = '' }"
                            />
                        </div>
                    </template>
                    <span>{{ widgetInfoText }}</span>
                </v-tooltip>

                <v-spacer />
                <v-tooltip
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            height="20"
                            color="white"
                            class="mr-2"
                            v-on="on"
                            @click="triggerReload"
                        >
                            <v-icon>
                                mdi-reload
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Chat neu laden</span>
                </v-tooltip>
                <!-- <ToggleChatNotificationsButton
                    :big="true"
                    style="height: 50px"
                /> -->
                <v-menu
                    offset-y
                    left
                    style="z-index: 10"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            id="plusButton"
                            class="optionBtn ml-2"
                            x-small
                            icon
                            elevation="0"
                            v-bind="attrs"
                            style="margin-right: 19px"
                            v-on="on"
                        >
                            <img
                                :src="plusIcon"
                                class="icon20 iconToWhite"
                                alt="Erstellen-Menü"
                            >
                        </v-btn>
                    </template>

                    <v-list
                        style="max-width: 255px;"
                    >
                        <v-list-item @click="clickCreateNewPrivate">
                            <v-list-item-icon>
                                <img
                                    :src="pupilIcon"
                                    class="icon20 iconToDunkelgrau"
                                    alt="Kontakt"
                                >
                            </v-list-item-icon>
                            <v-list-item-title>
                                Kontakt hinzufügen
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click="accountRole === 'teacher' || accountRole === 'maintainer' ? clickCreateNewGroup() : showWarning()"
                        >
                            <v-list-item-icon>
                                <img
                                    :src="groupIcon"
                                    class="icon20 iconToDunkelgrau"
                                    alt="Gruppe"
                                >
                            </v-list-item-icon>
                            <v-list-item-title
                                :style="accountRole === 'teacher' || accountRole === 'maintainer' ? '' : 'color: grey'"
                            >
                                Gruppe erstellen
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <vue-custom-scrollbar
            v-if="mappedChatRooms.length > 0"
            class="scroll-area chat-bodyBg"
            :settings="settings"
        >
            <div
                class="chat-body pa-0"
                style="max-height: 74.5vh"
            >
                <div
                    v-for="room in mappedChatRooms"
                    :key="room._id"
                    :ref="`chat${room._id}`"
                    style="border-bottom: solid; border-color: rgba(0, 0, 0, 0.12); border-width: 1px; background-color: #fff"
                    class="group-item d-flex justify-start align-center"
                    tabindex="0"
                    :color="room.isOpen ? '' : 'red'"
                    @click="() => selectRoom(getRoomWithPic(room._id))"
                    @keypress.enter="() => selectRoom(getRoomWithPic(room._id))"
                    @keypress.space="() => selectRoom(getRoomWithPic(room._id))"
                >
                    <div
                        class="ma-3"
                        style="z-index: 4"
                    >
                        <!-- ":value" in v-badge equals "to render or not to render" -->
                        <v-badge
                            color="#ff0000"
                            :content="room.newMessagesCount"
                            :value="room.newMessagesCount"
                            overlap
                            left
                            style="z-index: 9"
                        >
                            <div
                                class="d-flex justify-center align-center"
                                style="height: 56px; width: 56px; border-radius: 8px"
                                :style="`background-color: ${room.color ? room.color : room.type === 'system' ? '#ff6941' : '#6994cc'}`"
                            >
                                <div v-if="getRoomWithPic(room._id) === undefined">
                                    <v-progress-circular
                                        indeterminate
                                        color="white"
                                    />
                                </div>
                                <div v-else-if="getRoomWithPic(room._id).roomPictureObject === undefined">
                                    <v-progress-circular
                                        indeterminate
                                        color="white"
                                    />
                                </div>
                                <div
                                    v-else-if="room.type === 'privateChat' && getRoomWithPic(room._id).roomPictureObject !== 'groupPicture'"
                                >
                                    <div
                                        v-if="false"
                                        class="d-flex justify-space-around align-center"
                                        style="height: 56px; width: 56px; border-radius: 8px; "
                                        :style="'background-color: ' + getRoomWithPic(room._id).roomPictureObject.color"
                                    >
                                        <v-icon
                                            v-if="getRoomWithPic(room._id).roomPictureObject.icon.includes('mdi-')"
                                            aria-hidden="true"
                                            color="white"
                                            large
                                        >
                                            {{ getRoomWithPic(room._id).roomPictureObject.icon }}
                                        </v-icon>
                                        <img
                                            v-else
                                            aria-hidden="true"
                                            :src="getRoomWithPic(room._id).roomPictureObject.icon"
                                            alt="Chat"
                                            class="chatIcon"
                                            style="height: 50px; width: 50px; border-radius: 8px;"
                                        >
                                    </div>
                                    <Avatar
                                        v-else-if="room.type === 'privateChat'"
                                        :profile="extractIdFromOtherRoomMember(room)"
                                        :size="56"
                                    />
                                    <!--                                    <img-->
                                    <!--                                        v-else-if="room.type === 'privateChat'"-->
                                    <!--                                        aria-hidden="true"-->
                                    <!--                                        :src="getRoomWithPic(room._id).roomPictureObject"-->
                                    <!--                                        alt="Chat"-->
                                    <!--                                        class="chatIcon"-->
                                    <!--                                        style="height: 56px; width: 56px; border-radius: 8px;"-->
                                    <!--                                    >-->
                                </div>
                                <div
                                    v-else-if="room.type === 'groupChat' && room.icon && room.color"
                                    class="d-flex justify-space-around align-center"
                                    style="height: 35px; width: 35px; border-radius: 8px"
                                >
                                    <img
                                        aria-hidden="true"
                                        :src="getRoomWithIcon(room).groupIcon"
                                        class="icon20"
                                        :style="getHeight(room)"
                                        alt="Gruppenchat"
                                    >
                                </div>
                                <img
                                    v-else-if="room.type === 'system'"
                                    aria-hidden="true"
                                    :src="infoIcon"
                                    style="height: 30px"
                                    alt="Meldungen"
                                    class="iconToWhite chatIcon"
                                >
                                <img
                                    v-else
                                    aria-hidden="true"
                                    :src="groupIcon"
                                    alt="Gruppenchat"
                                    class="iconToWhite groupChatIcon"
                                >
                            </div>
                        </v-badge>
                    </div>
                    <div
                        role="button"
                        class="greyText chat-name"
                    >
                        {{ room.displayName }}
                    </div>
                    <v-spacer />
                    <!-- Can be switched to menu component later -->
                    <!-- v-if="canUserDeleteRoom(room)" -->
                    <!-- <DeleteChatButtonV2
                        :room="room"
                    /> -->
                    <!-- v-if="!isTeacher(room) && room.name !== 'Lehrer'" -->
                    <!-- @lockStatusChanged="handleLockStatusChanged" -->
                    <!-- <img
                        v-if="room.type === 'groupChat' && room.isOpen && accountRole !== 'pupil'"
                        :src="schlossOffenIcon"
                        class="iconToGrey"
                        alt="Chat offen"
                        style="width: 30px; height: 30px; margin-right: 16px; opacity: 0.4"
                    > -->
                    <v-tooltip
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <img
                                v-if="chatMuted(room)"
                                class="icon30"
                                :class="room.type === 'groupChat' && !room.isOpen ? 'mr-2' : 'mr-4'"
                                :src="bellIcon"
                                alt="Stummgeschalten"
                                style="opacity: 0.4"
                                v-on="on"
                                @click="(event)=>{event.stopPropagation();}"
                            >
                        </template>
                        <span>Stummgeschaltener Chat</span>
                    </v-tooltip>
                    <v-tooltip
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <img
                                v-if="room.type === 'groupChat' && !room.isOpen"
                                :src="schlossZuIcon"
                                class="iconToRed"
                                alt="Chat geschlossen"
                                style="width: 30px; height: 30px; margin-right: 16px; opacity: 0.4"
                                v-on="on"
                                @click="(event)=>{event.stopPropagation();}"
                            >
                        </template>
                        <span>Geschlossener Chat</span>
                    </v-tooltip>
                </div>
            </div>
        </vue-custom-scrollbar>

        <div
            v-else
            style="padding-top: 11vh; cursor: pointer"
            @click="clickPlusButton()"
        >
            <div
                class="pa-4 mb-0 text-center"
            >
                <img
                    :src="keineChatnachrichtenIcon"
                    alt="Keine Chatnachrichten"
                    class="mt-2"
                    style="height: 100px"
                >
                <p
                    class="mt-2 text-center"
                    style="font-size: 20px"
                >
                    Keine Chats
                </p>
                <p
                    class="mt-2 text-center"
                    style="font-size: 20px"
                >
                    Auf "+ Plus" oder hier klicken um neuen Chat zu erstellen
                </p>
            </div>
        </div>
        <ChatRoomCreateGroupChatDialog
            v-if="showCreateGroupChatDialog"
            @closeDialog="showCreateGroupChatDialog = false"
        />
        <ChatRoomCreatePrivateChatDialog
            v-if="showChatRoomPrivateChatDialog"
            @closeDialog="showChatRoomPrivateChatDialog = false"
        />
        <v-snackbar
            v-model="snackbarVisible"
            timeout="7000"
            color="error"
        >
            <template
                v-if="accountRole === 'pupil'"
                v-slot:action="{ attrs }"
            >
                <v-btn
                    :aria-hidden="me && me.accessibility && me.accessibility.screenreader"
                    small
                    fab
                    class="pa-0 ml-2 preventColorInvert"
                    elevation="6"
                    style="height: 30px; background-color:white; border-radius: 4px !important;"
                    v-bind="attrs"
                    @click="readText('Bitte kontaktiere eine Lehrperson, um eine Gruppe zu erstellen.', $event)"
                >
                    <img
                        :src="lautsprecherIcon"
                        class="icon20"
                        alt="Vorlesen"
                    >
                </v-btn>
            </template>
            <span v-if="accountRole === 'parent'">Bitte kontaktieren Sie eine Lehrperson, um eine Gruppe zu erstellen.</span>
            <span v-else-if="accountRole === 'pupil'">Bitte kontaktiere eine Lehrperson, um eine Gruppe zu erstellen.</span>
        </v-snackbar>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
// import DeleteChatButtonV2 from "@/components/ChatV2/Components/DeleteChatButtonV2";
import ChatRoomCreateGroupChatDialog from "./Components/ChatRoomCreateGroupChatDialog";
import ChatRoomCreatePrivateChatDialog from "./Components/ChatRoomCreatePrivateChatDialog";
import ToggleChatNotificationsButton
    from "@/components/ChatV2/Components/ToggleBrowserChatNotifications/ToggleChatNotificationsButton";
import vueCustomScrollbar from "vue-custom-scrollbar";
import {textToSpeech} from "@/util/textToSpeech";

import infoIcon from "@/assets/Icons/FaceliftIcons/info_hinweis.svg";
import suchIcon from '@/assets/Icons/suche-26.svg';
import plusIcon from "../../assets/Icons/plus.svg";
import chatIcon from "../../assets/Icons/chat-alternative-filled-102.svg";
import groupIcon from "../../assets/Icons/group.svg";
import pupilIcon from "../../assets/Icons/pupil.svg";
import schlossOffenIcon from "../../assets/Icons/FaceliftIcons/schloss_offen.svg";
import schlossZuIcon from "../../assets/Icons/FaceliftIcons/schloss_zu.svg";
import keineChatnachrichtenIcon from "../../assets/Icons/FaceliftIcons/keine_chatnachrichten.svg";
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
import bellIcon from'@/assets/Icons/FaceliftIcons/benachrichtigung_aus.svg';
import Avatar from "@/components/SharedViews/Profile/Avatar";
import avatar1 from "@/assets/Icons/Avatar1.svg";
import avatar2 from "@/assets/Icons/Avatar2.svg";
import avatar3 from "@/assets/Icons/Avatar3.svg";
import avatar4 from "@/assets/Icons/Avatar4.svg";
import avatar5 from "@/assets/Icons/Avatar5.svg";
import avatar6 from "@/assets/Icons/Avatar6.svg";
import avatar7 from "@/assets/Icons/Avatar7.svg";
import avatar8 from "@/assets/Icons/Avatar8.svg";
import avatar9 from "@/assets/Icons/Avatar9.svg";
import avatar10 from "@/assets/Icons/Avatar10.svg";
import avatar11 from "@/assets/Icons/Avatar11.svg";
import avatar12 from "@/assets/Icons/Avatar12.svg";
import avatar13 from "@/assets/Icons/Avatar13.svg";
import avatar14 from "@/assets/Icons/Avatar14.svg";

export default {
    name: "ChatWidgetGroupListV2",
    components: {
        Avatar,
        // DeleteChatButtonV2,
        vueCustomScrollbar,
        ChatRoomCreateGroupChatDialog,
        ChatRoomCreatePrivateChatDialog,
        // ToggleChatNotificationsButton
    },
    props: {
        showTooltips: { required: false, default: true },
        currentChild: { required: false, type: Object },
        dataLoading: {  required: false, default: false},
    },
    data: () => ({
        searchChatGroups:'',
        useProfileColor: false,
        chatRoomsWithPictures: null,
        matrixUsers: [],
        maintainers: [],
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
        },

        teachers: [],
        teacherPics: [], //collection of teacher profile pictures
        systemCreatedMatrixRoomsIds: [], // ids of group chats generated by the system
        person: {
            accessibility: {
                screenreader: false,
            },
        },

        showCreateGroupChatDialog: false,
        showChatRoomPrivateChatDialog: false,
        snackbarVisible: false,

        showTextTimeout: null,
        me: {
            accessibility: {
                screenreader: false,
            }
        },

        infoIcon,
        plusIcon,
        chatIcon,
        groupIcon,
        pupilIcon,
        schlossOffenIcon,
        schlossZuIcon,
        keineChatnachrichtenIcon,
        lautsprecherAusIcon,
        lautsprecherIcon,
        bellIcon,
        suchIcon,

        icons: [
            {icon: groupIcon, text: "Gruppen-Icon"},
            {icon: avatar1, text: "avatar1"},
            {icon: avatar2, text: "avatar2"},
            {icon: avatar3, text: "avatar3"},
            {icon: avatar4, text: "avatar4"},
            {icon: avatar5, text: "avatar5"},
            {icon: avatar6, text: "avatar6"},
            {icon: avatar7, text: "avatar7"},
            {icon: avatar8, text: "avatar8"},
            {icon: avatar9, text: "avatar9"},
            {icon: avatar10, text: "avatar10"},
            {icon: avatar11, text: "avatar11"},
            {icon: avatar12, text: "avatar12"},
            {icon: avatar13, text: "avatar13"},
            {icon: avatar14, text: "avatar14"},
        ],
    }),
    computed: {
        ...mapState('translation', ['targetLang','translationInitialized']),
        ...mapGetters("auth", ["accountRole", "accountId"]),
        ...mapGetters("chat", [
            "chatRooms",
            "chatRoomEvents",
            "chatRoomEventsByChatRoom",
        ]),
        ...mapGetters('teachers', ['teachersByAccountId']),
        ...mapGetters("accounts", ["accountsById"]),

        mappedChatRooms() {
            let alreadyEmitted = false;
            this.$emit('clearAllBadge');
            const eventList = [];
            // get the new messages in a chatRoom for a given account
            const getNewMessagesCount = (chatRoom, accountId) => {
                const accountTimestampObject = chatRoom.lastSeenTimestamps.find(item => item.account === accountId);

                // Todo might have fixed unwated badges bug, unable to reproduce
                // from statistical code analysis: this is the only spot where unknown badges might come from in frontend,
                // need to look at backend as well (migration script)
                if (!accountTimestampObject) {
                    return 0;
                }

                const lastSeenTimestamp = new Date(accountTimestampObject.timestamp);

                const newChatRoomEvents = (this.chatRoomEventsByChatRoom[chatRoom._id] || [])
                    .filter(chatRoomEvent => new Date(chatRoomEvent.createdAt) >= lastSeenTimestamp);

                if(!alreadyEmitted && newChatRoomEvents.length) {
                    alreadyEmitted = true;
                    this.$emit('chatBadge');
                }

                for(let i = 0; i < newChatRoomEvents.length; i++) {
                    eventList.push(newChatRoomEvents[i]);
                }

                return newChatRoomEvents.length;
            };

            const getLastEventTime = (chatRoom) => {
                const chatRoomEvents =
                    this.chatRoomEventsByChatRoom[chatRoom._id] || [];
                const lastChatRoomEvent = chatRoomEvents[chatRoomEvents.length - 1];
                return lastChatRoomEvent
                    ? new Date(lastChatRoomEvent.createdAt).getTime()
                    : new Date(chatRoom.createdAt).getTime();
            };

            const chatrooms = this.chatRooms
                .map((room) => ({
                    ...room,
                    newMessagesCount: getNewMessagesCount(room, this.accountId),
                }))
                .sort(
                    (room1, room2) => getLastEventTime(room2) - getLastEventTime(room1)
                );

            this.$emit('chatEntryCount', eventList);
            //const filteredRooms = chatrooms.filter((room) => (room.type === "system" && room.newMessagesCount > 0) || (room.type !== 'system'))
            //return filteredRooms;
            if(this.searchChatGroups !== ''){
                return chatrooms.filter((room) => room.type === 'privateChat' ? room.displayName.toLowerCase().includes(this.searchChatGroups.toLowerCase()) : room.name.toLowerCase().includes(this.searchChatGroups.toLowerCase()));
            }
            return chatrooms;
        },

        canUserDeleteRoom(room) {
            return (room) => {
                return false;
            };
        },

        widgetInfoText() {
            if (this.accountRole === 'pupil') {
                return 'Im Chat kannst du Kontakt zu deinen Lehrern aufnehmen.';
            } else if (this.accountRole === 'teacher') {
                return 'Im Chat können Sie Kontakt zu Schülern und Eltern aufnehmen.';
            } else if (this.accountRole === 'parent') {
                return 'Im Chat können Sie zu zuständigen Lehrer Ihres Kindes Kontakt aufnehmen.';
            } else {
                return 'Im Chat können Sie sich mit Ihren Kontakten austauschen.';
            }
        }
    },
    watch: {
        // currentChild: async function () {
        //     if (this.accountRole === "parent" && this.currentChild) {
        //         const appointments = await this.getAppointmentsParent(
        //             this.currentChild._id
        //         );
        //         appointments.forEach((el) => {
        //             if (el.teachers) {
        //                 if (el.teachers instanceof Array) {
        //                     el.teachers.forEach((element) => {
        //                         const index = this.matrixUsers.indexOf(element);
        //                         if (index === -1) {
        //                             this.matrixUsers.push(element);
        //                         }
        //                     });
        //                 } else {
        //                     this.matrixUsers.indexOf(el.teachers);
        //                     if (this.matrixUsers.indexOf(el.teachers) === -1) {
        //                         this.matrixUsers.push(el.teachers);
        //                     }
        //                 }
        //             }
        //         });
        //     }
        // },
        async mappedChatRooms(newVal,oldVal){
            if(newVal && newVal.length !== oldVal.length){
                await this.mapPictureToRoom();
            }
        }
    },
    async mounted() {
        if (this.accountRole === 'pupil') {
            this.me = await this.getMePupil();
        }
        await this.getAccounts();
        // await Promise.all([this.requestAppointments(), this.requestPupil()]);
        // await this.requestTeachers();
        await this.requestMaintainers();
        await this.requestPerson();
        this.getSystemCreateRoomIds();
        await this.mapPictureToRoom();
    },
    async unmounted() {
        this.teacherPics.forEach(async (el) => {
            if (el[2]) {
                URL.revokeObjectURL(el[2]);
            }
        });
    },
    methods: {
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang','setTranslationInitialized']),
        ...mapActions("appointments", ["getAppointments", "getAppointmentsParent"]),
        ...mapActions("pupils", ["getPupils", "getMePupil"]),
        ...mapActions("matrix", ["createCustomRoom"]),
        ...mapActions("parents", ["getParents"]),
        ...mapActions("teachers", [
            "getProfilePicture",
            "getTeachers",
            "getMeTeacher",
        ]),
        ...mapActions("chat", ["fetchChats"]),
        ...mapActions("maintainers", ["getMaintainers", "getMaintainerProfilePicture"]),
        ...mapActions("groups", ["getGroupsInfo"]),
        ...mapActions("accounts", ["getAccounts"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions('uploadedIcons', ['getUploadedIcon']),
        chatMuted(room){
            if (room.muted && room.muted.includes(this.accountId)){
                return true
            }
            return false;
        },
        async triggerReload(){
                    await this.fetchChats();
                },
        async mapPictureToRoom(){
            this.chatRoomsWithPictures = await Promise.all(this.chatRooms.map(async (room) => {
                if (room.type === 'groupChat' && room.icon && room.color) {
                    return {
                        ...room,
                        roomPictureObject: room.type === 'groupChat' ? 'groupPicture' : await this.setRoomPicture(room),
                        groupIcon: await this.getGroupIcon(room),
                    };
                } else {
                    return {
                        ...room,
                        roomPictureObject: room.type !== 'privateChat' ? 'groupPicture' : await this.setRoomPicture(room),
                    };
                }
            }));
        },

        async setFocusOn(elementId) {
            await this.$nextTick();
            document.getElementById(elementId).focus();
        },
        // async requestAppointments() {
        //     if (this.accountRole === "pupil") {
        //         const appointments = await this.getAppointments();
        //         appointments.forEach((el) => {
        //             if (el.teachers) {
        //                 if (el.teachers instanceof Array) {
        //                     el.teachers.forEach((element) => {
        //                         const index = this.matrixUsers.indexOf(element);
        //                         if (index === -1) {
        //                             this.matrixUsers.push(element);
        //                         }
        //                     });
        //                 } else {
        //                     this.matrixUsers.indexOf(el.teachers);
        //                     if (this.matrixUsers.indexOf(el.teachers) === -1) {
        //                         this.matrixUsers.push(el.teachers);
        //                     }
        //                 }
        //             }
        //         });
        //     }
        //     if (this.accountRole === "parent" && this.currentChild) {
        //         const appointments = await this.getAppointmentsParent(
        //             this.currentChild._id
        //         );
        //         appointments.forEach((el) => {
        //             if (el.teachers) {
        //                 if (el.teachers instanceof Array) {
        //                     el.teachers.forEach((element) => {
        //                         const index = this.matrixUsers.indexOf(element);
        //                         if (index === -1) {
        //                             this.matrixUsers.push(element);
        //                         }
        //                     });
        //                 } else {
        //                     this.matrixUsers.indexOf(el.teachers);
        //                     if (this.matrixUsers.indexOf(el.teachers) === -1) {
        //                         this.matrixUsers.push(el.teachers);
        //                     }
        //                 }
        //             }
        //         });
        //     }
        // },
        async requestPupil() {
            if (this.accountRole === "teacher") {
                const pupils = await this.getPupils();
                const teachers = await this.getTeachers();
                const parents = await this.getParents();

                const mappedPupils = pupils.map((pupil) => ({
                    ...pupil,
                    account: pupil.account,
                }));
                let mappedTeachers = teachers.map((teacher) => ({
                    ...teacher,
                    account: teacher.account,
                }));
                const mappedParents = parents.map((parent) => ({
                    ...parent,
                    account: parent.account,
                    name:
                        pupils.find((pupil) => pupil._id === parent.pupils[0])?.name ||
                        "Unbekannt",
                    lastName: "Eltern",
                }));

                // filter out teacher that's using the chat so he can't try to start chat with himself
                const me = await this.getMeTeacher();
                mappedTeachers = mappedTeachers.filter(
                    (teacher) =>
                        teacher.name !== me.name && teacher.lastName !== me.lastName
                );

                this.matrixUsers = [
                    ...mappedPupils,
                    ...mappedTeachers,
                    ...mappedParents,
                ].sort((a, b) => a.name.localeCompare(b.name));
                this.matrixUsers.map(
                    (el) => (el.fullName = el.name + " " + el.lastName)
                );
            }
        },
        // async requestTeachers() {
        //     this.teachers = await this.getTeachers();
        //     if (this.accountRole === "maintainer") {
        //         this.matrixUsers = await JSON.parse(JSON.stringify(this.teachers));
        //         this.matrixUsers.map(
        //             (el) => (el.fullName = el.name + " " + el.lastName)
        //         );
        //         this.matrixUsers.sort((a, b) => a.name.localeCompare(b.name));
        //     }

        //     //trim down the ridiculous size of duplicate matrixUsers, only keep unique entries
        //     this.matrixUsers = [
        //         ...new Set(this.matrixUsers.map((el) => JSON.stringify(el))),
        //     ].map((str) => JSON.parse(str));
        //     this.matrixUsers.map((el) => (el.fullName = el.name + " " + el.lastName));
        //     await this.setTeacherProfilePics();
        // },
        async requestMaintainers() {
            if (this.accountRole === "teacher") {
                this.maintainers = await this.getMaintainers();
            }
        },
        async requestPerson() {
            if (this.accountRole === "pupil") {
                this.person = await this.getMePupil();
            }
        },
        selectRoom(roomWithPic) {
            this.$emit("roomSelected", roomWithPic);
            //somehow on apple devices there is the problem that tts for translatedLanguages doesnt work
            //unless it is used once with german language. therefore we do this (it works pls dont break unless u find better solution)
            if(!this.translationInitialized){
                let msg = new SpeechSynthesisUtterance();
                msg.text = '';
                msg.lang = 'de-DE';
                window.speechSynthesis.speak(msg);
                this.setTranslationInitialized(true);
            }
        },
        // Get the highest role ('moderator', 'user') of an account in a chatRoom
        getChatRoomMembershipRole(chatRoom, accountId) {
            const memberships = chatRoom.memberships.filter((membership) =>
                membership.addressedAccounts.find(
                    (addressedAccount) => addressedAccount === accountId
                )
            );

            const isModerator = memberships.find(
                (membership) => membership.role === "moderator"
            );
            return isModerator ? "moderator" : "member";
        },
        // async setTeacherProfilePics() {
        //     this.teachers.map(async (teacher) => {
        //         let matchingTeacher = this.matrixUsers.find((matrixUser) => {
        //             return teacher.account == matrixUser.account;
        //         });
        //         if (matchingTeacher) {
        //             if (matchingTeacher.profilePicture) {
        //                 const objectURL = await this.getProfilePicture(
        //                     matchingTeacher._id
        //                 );
        //                 this.teacherPics.push([
        //                     matchingTeacher.name,
        //                     matchingTeacher.lastName,
        //                     objectURL,
        //                 ]);
        //             }
        //         }
        //     });
        // },

        // saves the ids of the system created matrix room ids into systemCreateMatrixRoomsIds
        async getSystemCreateRoomIds() {
            let groups = await this.getGroupsInfo();
            this.systemCreatedMatrixRoomsIds = groups.map((el) => el.matrixRoom);
            this.systemCreatedMatrixRoomsIds =
                this.systemCreatedMatrixRoomsIds.filter((el) => {
                    return el !== undefined;
                });
        },

        async readWidgetInfoText() {
            if (!this.person.accessibility.screenreader) {
                if ("speechSynthesis" in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return;
                }

                let text;
                if (this.accountRole === "pupil") {
                    text = "Im Chat kannst du Kontakt zu deinen Lehrern aufnehmen.";
                } else if (this.accountRole === "teacher") {
                    text = "Im Chat können Sie Kontakt zu Schülern und Eltern aufnehmen.";
                } else if (this.accountRole === "parent") {
                    text =
                        "Im Chat können Sie zu zuständigen Lehrer Ihres Kindes Kontakt aufnehmen.";
                } else {
                    text = "Im Chat können Sie sich mit Ihren Kontakten austauschen.";
                }

                if (this.targetLang !== "de") {
                    text = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: text,
                    });
                }

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    this.showTranslation(false);
                } else {
                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000);

                    let msg = new SpeechSynthesisUtterance();
                    msg.text = text;
                    if (this.isLangPackageAvailable()) {
                        msg.lang = this.targetLang;
                        window.speechSynthesis.speak(msg);
                    }
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (
                    window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)
                ) {
                    return true;
                }
            }

            return false;
        },

        clickCreateNewGroup() {
            this.selectedPrivateChatAccountSetId = "";
            this.showCreateGroupChatDialog = true;
        },

        clickCreateNewPrivate() {
            this.showChatRoomPrivateChatDialog = true;
        },
        async setRoomPicture(room) {
            let acc;
            room.memberships.forEach(member => { if (member.addressedAccounts[0] !== this.accountId){
                acc = member.addressedAccounts[0];
            } });
            if (this.accountsById[acc] && this.accountsById[acc].role === 'teacher') {
                //if account is teacher and has uploadedPicture
                if(this.teachersByAccountId[acc] && this.teachersByAccountId[acc].profilePicture){
                    return await this.getProfilePicture(this.teachersByAccountId[acc]._id);
                }else {
                    // return profile icon or take standard icon
                    const userAccount = this.accountsById[acc];
                    const iconObject = {
                        color: userAccount.profileColor ? userAccount.profileColor : '#ff6633',
                        icon: userAccount.profilePicture && userAccount.profilePicture.img ? userAccount.profilePicture.img
                            : userAccount.profilePicture && userAccount.profilePicture.text ? 'mdi-'+ userAccount.profilePicture.text
                                : 'mdi-emoticon-happy-outline'
                    }
                    this.useProfileColor = true;
                    return iconObject;
                }
            } else if (this.accountsById[acc] && this.accountsById[acc].role === 'maintainer') {
                if (this.maintainers[0] && this.maintainers[0].profilePicture) {
                    return await this.getMaintainerProfilePicture(this.maintainers[0]._id);
                } else {
                    // return profile icon or take standard icon
                    const userAccount = this.accountsById[acc];
                    const iconObject = {
                        color: userAccount.profileColor ? userAccount.profileColor : '#ff6633',
                        icon: userAccount.profilePicture && userAccount.profilePicture.img ? userAccount.profilePicture.img
                            : userAccount.profilePicture && userAccount.profilePicture.text ? 'mdi-' + userAccount.profilePicture.text
                                : 'mdi-emoticon-happy-outline'
                    }
                    this.useProfileColor = true;
                    return iconObject;
                }
            } else {
                // return profile icon or take standard icon
                const userAccount = this.accountsById[acc];
                const iconObject = {
                    color: userAccount.profileColor ? userAccount.profileColor : '#ff6633',
                    icon: userAccount.profilePicture && userAccount.profilePicture.img ? userAccount.profilePicture.img
                        : userAccount.profilePicture && userAccount.profilePicture.text ? 'mdi-'+ userAccount.profilePicture.text
                            : 'mdi-emoticon-happy-outline'
                }
                this.useProfileColor = true;
                return iconObject;
            }
        },
        getRoomWithPic(roomId){
            if(this.chatRoomsWithPictures){
                const val = this.chatRoomsWithPictures.filter((room) => room._id === roomId );
                return val[0];
            }
        },

        extractIdFromOtherRoomMember(room) {
            // extract account IDs from memberships
            const allMemberAccounts = [];
            room.memberships.forEach(member => {
                allMemberAccounts.push(member.addressedAccounts[0])
            });
            const allOtherAccounts = allMemberAccounts.filter(acc => acc !== this.accountId);
            return allOtherAccounts[0];
        },

        clickPlusButton() {
            document.getElementById("plusButton").click();
        },

        showWarning() {
            this.snackbarVisible = !this.snackbarVisible;
        },

        async readText(text, event) {
            let txt;

            let tmp = document.createElement("DIV");
            tmp.innerHTML = text;
            txt = tmp.textContent || tmp.innerText || "";

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                event.target.src = lautsprecherAusIcon;
            }, () => {
                event.target.src = lautsprecherIcon;
            });
        },

        getGroupIcon(room) {
            if (!room.icon.includes('avatar') && !room.icon.includes('group')) {
                return this.getUploadedIcon(room.icon);
            } else {
                const icon = this.icons.find((icon) => {
                    return icon.icon.toLowerCase().includes(room.icon)
                })
                if (icon) {
                    return icon.icon;
                }
                return null
            }
        },

        getRoomWithIcon(selectedRoom) {
            return this.chatRoomsWithPictures.find((room) => room._id === selectedRoom._id);
        },

        getHeight(room) {
            if (room.icon.toLowerCase().includes('avatar')) {
                return 'width: 51px; height: 51px;'
            }
            if (room.icon.toLowerCase().includes('group')) {
                return 'width: 25px; height: 25px;'
            }
            return 'width: 56px; height: 56px; border-radius: 8px'
        }
    },
};
</script>

<style lang="scss" scoped>
.group-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.group-chat-header-btn {
    padding: 0 !important;
    min-width: 0 !important;
}

.group-chat-header {
    min-height: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background-color: var(--v-chatColor-base); // 8CBD46 - 5% Luminosity
    padding: 0 0 0 16px;
    border-radius: 16px 16px 0 0 !important;
}

.chat-container {
    height: auto;
    width: 90%;
    min-height: 80vh;
    border-radius: 16px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
}

.chat-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.chat-header {
    background-color: var(--v-chatColor-base);
    border-radius: 15px 15px 0 0;
    font-size: larger;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    color: white;
}

.chat-header-btn {
    background-color: #f8f8f880 !important;
    width: 35px;
    height: 35px !important;
}

.chat-header-img {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
    flex: 1;
}

.chat-bodyBg {
    background-color: var(--v-widgetBg-base);
    border-radius: 0 0 16px 16px !important;
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.roundImage {
    border-radius: 50%;
    height: 56px;
    width: 56px;
}

.chatIcon {
    height: 38px;
}

.groupChatIcon {
    height: 25px;
}

#eK-button-disabled.v-btn--disabled {
    opacity: 0.5;
    background-color: var(--v-grau-base) !important;
    color: white !important;
}

.formHeader {
    font-size: 16px;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
}

.iconToGrey {
    filter: brightness(0) saturate(100%) invert(89%) sepia(0%) saturate(0%)
    hue-rotate(326deg) brightness(87%) contrast(88%) !important;
}

.badgeEventVisualize {
    animation: badgeAppointmentRipple 1000ms ease-in-out;
}

@keyframes badgeAppointmentRipple {
    0% {
        background-color: rgba(255, 255, 255, 0.0);
    }
    10% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    75% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    100% {
        background-color: rgba(255, 255, 255, 0.0);
    }
}
</style>
