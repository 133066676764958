import { render, staticRenderFns } from "./Plan.vue?vue&type=template&id=f0141144&scoped=true&"
import script from "./Plan.vue?vue&type=script&lang=js&"
export * from "./Plan.vue?vue&type=script&lang=js&"
import style0 from "./Plan.vue?vue&type=style&index=0&id=f0141144&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0141144",
  null
  
)

export default component.exports