<template>
    <div v-if="magnifier">
        <anti-scroll
            ref="scroll"
            :class="'scroll-area-antiScroll' + getCss()"
        >
            <slot />
        </anti-scroll>
    </div>
    <div v-else>
        <vue-custom-scrollbar
            id="vue-custom-scrollbar"
            ref="scroll"
            :class="'scroll-area-scrollbar' + getCss()"
            :settings="settings"
            @ps-y-reach-start="loadMore()"
            @ps-y-reach-end="loadMoreMessages()"
            @ps-scroll-y="scrollY()"
        >
            <slot />
        </vue-custom-scrollbar>
    </div>
</template>

<script>
import { mapState } from "vuex";
import AntiScroll from "@/components/AntiScroll";
import vueCustomScrollbar from "vue-custom-scrollbar";
import debounce from 'lodash.debounce';

export default {
    name: "ScrollAreaMagnifier",
    components: { AntiScroll, vueCustomScrollbar },
    props: {
        settings: { type: Object, required: false },
        usage: { type: String, required: false },
        message: {type: Number, required: false },
    },
    computed: {
        ...mapState("magnifier", ["magnifier"]),
        
    },
    watch: {
        magnifier() {
            if (this.magnifier) {
                setTimeout(() => {
                    this.$refs.scroll.scrollTop();
                }, 1000);

            }
        },
        async message(newVal){
            if(newVal && newVal > 0) {
              await this.jumpToLatest();
            }
        }
    },

    async created() {
        if (this.usage === 'chatWidget') {
            await this.jumpToLatest();
        }
        this.scrollY = debounce(() => {
            this.$emit("scrollY", this.$refs.scroll.$el.scrollTop);
        }, 25);
    },

    methods: {
        // vue/no-unused-properties cannot detect methods by $ref, so make a exception
        // eslint-disable-next-line vue/no-unused-properties,
        sendMessage() {
            this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight
        },
        sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        },
        async loadMore() {
            if (this.usage === 'chatWidget') {
                this.$emit('ps-y-reach-start')
                this.$refs.scroll.$el.scrollTop = 10;

                if (this.magnifier && this.usage === 'chatWidgetBaby') {
                    this.$refs.scroll.scrollTop();
                }
            }
        },
        async jumpToLatestInitial() {
            while (!this.$refs.scroll) {
                await this.sleep(500);
            }
            this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
        },
        async jumpToLatest() {
            setTimeout(() => {
                this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
                if (this.magnifier) {
                    this.$refs.scroll.scrollBottom();
                }
            }, 500);
        },
        getCss() {
            if (this.usage === 'chatWidget') {
                return '-chatWidget';
            } else if (this.usage === 'brettWidget') {
                return '-brettWidget';
            }
        },
        async loadMoreMessages() {
            if (this.usage === 'brettWidget') {
                this.$emit('ps-y-reach-end');
            }
        },
    },
}
</script>

<style>
.scroll-area-scrollbar-chatWidget {
    flex: 1 1 0;
}


.scroll-area-antiScroll-chatWidget {
    flex: 1 1 0;

}


.scroll-area-scrollbar-brettWidget {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
}

.scroll-area-antiScroll-brettWidget {
    position: relative;
    overflow: hidden;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
}
</style>
