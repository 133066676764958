<template>
    <div
        style="background-color: #f8f8f8"
        class="backgroundImg"
    >
        <div
            v-if="windowWidth < 901 && !hideNavbar"
            class="btmNav"
        >
            <div
                class="d-flex flex-row align-center justify-space-around"
                style="height: 100%; width: 100%"
            >
                <v-btn
                    v-for="item in btmNavItems"
                    :key="item.name"
                    elevation="0"
                    small
                    class="btmNavBtn pa-0"
                    :style="currSlide === item.goToIndex ? 'background-color:' + item.bgColor + '!important;' : ''"
                    @click="switchSlide(item.goToIndex)"
                >
                    <v-badge
                        :content="getBadge(item.goToIndex)"
                        :value="getBadge(item.goToIndex)"
                        color="#ff0000"
                        style="z-index: 1;"
                        offset-x="27"
                    >
                        <v-overlay
                            v-if="!isMounted(item)"
                            color="grey"
                            opacity="0.6"
                            style="border-radius: 8px;position: absolute; cursor: pointer; height: 50px; width: 50px;top: -7px; left: -7px;"
                            z-index="5"
                        />
                        <img
                            v-if="item.icon"
                            :src="item.icon"
                            :alt="item.alt"
                            class="btmNavIcon"
                            :style="currSlide === item.goToIndex ? 'filter: brightness(1000%);' : item.color"
                        >
                        <v-icon
                            v-if="item.mdi"
                            size="xx-large"
                            :style="currSlide === item.goToIndex ? 'color: white' : `color: ${item.bgColor}`"
                        >
                            {{ item.mdi }}
                        </v-icon>
                    </v-badge>
                </v-btn>

                <v-badge
                    v-if="showEmailBadge"
                    content="1"
                    offset-x="-20"
                    offset-y="12"
                    color="#3ba934"
                    class="align-self-start insetBadge"
                    style="z-index: 9"
                />
                <div @click="sidebarMobile = true; sidebar = true;">
                    <Avatar
                        v-if="person"
                        :profile="person.account"
                        :show-burger="true"
                    />
                </div>
            </div>
        </div>
        <v-overlay
            v-if="sidebar || supportPageOpen"
            z-index="10"
            overlay-opacity="0.8"
        />
        <v-overlay
            v-if="!hidePreloader && (
                windowWidth < 901 && !allWidgetsMounted ||
                windowWidth > 900 && page === 0 && !page0WidgetsMounted || 
                windowWidth > 900 && page === 1 && !page1WidgetsMounted
            )"
            z-index="50"
            :opacity="1.0"
            color="white"
        >
            <Preloader />
        </v-overlay>

        <!-- Top menu bar-->
        <Header
            v-if="windowWidth > 900"
            :show-email-badge="showEmailBadge"
            class="mb-8"
            style="width: 100%; z-index: 5"
            @openSidebar="sidebar = true"
        />
        <div
            v-if="windowWidth > 900 "
            class="big d-flex flex-row"
            style="justify-content: space-around; align-items: center; margin-top:-16px !important;"
        >
            <!-- <div
                class="d-flex justify-space-around pb-0"
                style="width: 100vw; align-items:center"
            >  -->
            <v-tooltip
                v-if="!weeklyView"
                right
            >
                <template v-slot:activator="{ on }">
                    <v-card
                        v-if="!weeklyView && showPageOne"
                        style="height: 50vh; display: flex"
                        class="buttonFocusHover"
                        @click="changePage(-1)"
                        v-on="on"
                    >
                        <v-btn
                            fab
                            small
                            icon
                            color="#eaeaea"
                            style="width: 2vw"
                            class="align-self-center mx-1 mb-8"
                        >
                            <img
                                style="position: relative; margin:auto; width: 30px"
                                alt="Pfeil nach links"
                                :src="left"
                                class="imgFocusHover"
                            >
                        </v-btn>
                    </v-card>
                </template>
                <span>Vorherige Seite</span>
            </v-tooltip>
            <BadgeExplanations
                v-if="!weeklyView"
                :small="false"
            />

            <keep-alive>
                <plan-widget
                    v-if="page === 0 && !planDeactivated"
                    :style="weeklyView ? 'width: 94vw;' : '' "
                    style="width: 30%"
                    @planMounted="planMounted = true"
                />
            </keep-alive>
            <keep-alive>
                <brett-widget
                    v-if="page === 0 && !weeklyView && !brettDeactivated"
                    style="width: 30%"
                    @brettMounted="brettMounted = true"
                />
            </keep-alive>
            <keep-alive>
                <chat-widget
                    v-if="page === 0 && !weeklyView && !chatDeactivated"
                    style="width: 30%"
                    @chatMounted="chatMounted = true"
                />
            </keep-alive>
            <keep-alive>
                <messages-widget
                    v-if="page === 1 && !weeklyView && !messagesDeactivated"
                    style="width: 30%"
                    @messagesMounted="messagesMounted = true"
                />
            </keep-alive>
            <keep-alive>
                <users
                    v-if="page === 1 && !weeklyView"
                    style="width: 30%"
                    @usersMounted="usersMounted = true"
                />
            </keep-alive>
            <keep-alive>
                <app-widget
                    v-if="page === 1 && !weeklyView && !appsDeactivated"
                    style="width: 30%"
                    @appwidgetMounted="appwidgetMounted = true"
                />
            </keep-alive>
            <v-tooltip
                v-if="!weeklyView && showPageOne"
                left
            >
                <template v-slot:activator="{ on }">
                    <v-card
                        style="height: 50vh; display: flex"
                        class="buttonFocusHover"
                        @click="changePage(1)"
                        v-on="on"
                    >
                        <v-btn
                            v-if="!weeklyView"
                            fab
                            small
                            icon
                            color="#eaeaea"
                            style="width: 2vw;"
                            class="align-self-center mx-1 mb-8"
                        >
                            <img
                                style="position: relative; margin:auto; width: 30px"
                                alt="Pfeil nach rechts"
                                :src="right"
                                class="imgFocusHover"
                            >
                        </v-btn>
                    </v-card>
                </template>
                <span>Nächste Seite</span>
            </v-tooltip>
        </div>
        <!-- </div> -->

        <!-- Widgets mobile -->
        <VueSlickCarousel
            v-else-if="windowWidth <901"
            ref="carousel"
            class="small topPadding pb-0"
            :arrows="false"
            :initial-slide="initialSlide"
            :infinite="false"
            :dots="false"
            :touch-move="false"
            :style="weeklyView ? 'width: 90vw; margin-left: auto; margin-right: auto;' : ''"
            @afterChange="vueSlickCarouselAfterChange"
        >
            <plan-widget
                v-if="!planDeactivated"
                style="height: 100vh"
                :reset="reloadPlan"
                @planRemounted="reloadPlan = false"
                @planMounted="planMounted = true"
            />
            <brett-widget
                v-if="!brettDeactivated"
                style="height: 100vh"
                :class="weeklyView ? 'ml-14':''"
                :reset="reloadBrett"
                @brettRemounted="reloadBrett = false"
                @brettMounted="brettMounted = true;"
            />
            <chat-widget
                v-if="!chatDeactivated"
                class="mt-4"
                style="height: 100vh"
                :reset="reloadChat"
                @chatRemounted="reloadChat = false"
                @chatMounted="chatMounted = true"
            />
            <messages-widget
                v-if="!messagesDeactivated"
                class="mt-4"
                style="height: 100vh"
                :reset="reloadMessages"
                @messagesRemounted="reloadMessages = false"
                @messagesMounted="messagesMounted = true"
            />
            <users
                class="mt-4 px-5"
                :reset="reloadUsers"
                style="height: 100vh;"
                @usersRemounted="reloadUsers = false"
                @usersMounted="usersMounted = true"
            />
            <app-widget
                v-if="!appsDeactivated"
                class="mt-4"
                style="height: 100vh"
                :reset="reloadAppwidget"
                @appWidgetRemounted="reloadAppwidget = false"
                @openDiary="diaryDialog = true;"
                @appwidgetMounted="appwidgetMounted = true"
            />
        </VueSlickCarousel>

        <Nutzungsbedingungen
            :usage-agreement-not-signed="usageAgreementNotSigned"
            :current-account="crtAccount"
            :revoke="revokeAgreement"
            @closeDialog="()=>{usageAgreementNotSigned = false}"
        />
        <ForceLogoutDialog
            :current-account="crtAccount._id"
            :show-force-logout-dialog="showForceLogoutDialog"
            :other-connected-devices="otherConnectedDevices"
            @closeForceLogout="(showSnackbar)=>{if(showSnackbar){ confirmForceLogout();} showForceLogoutDialog = false}"
            @confirmForceLogout="confirmForceLogout"
        />
        <SupportChat
            v-if="supportPageOpen"
            @resetTutorial="resetTutorial()"
        />
        <!-- <SchoolInitialization
            :maintainer="this.person"
            :show-first-login-dialog="showFirstLoginDialog"
            @closeFirstLogin="()=>{ showFirstLoginDialog = false}"
        /> -->
        <Sidebar
            v-if="sidebar"
            v-model="sidebar"
            :sidebar-open="sidebar"
            :mobile="sidebarMobile"
            @closeSidebar="()=>{sidebar = false; sidebarMobile = false;}"
            @openUsageAgreement="revokeUsageAgreement"
            @hideBadge="showEmailBadge = false"
        />
        <!-- <Diary
            v-if="diaryDialog"
            :diary-dialog="diaryDialog"
            @close="diaryDialog = false"
        /> -->
        <VideoTutorial
            v-if="videoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="videoTutorialClosed = true"
        />
        <SlideTutorial
            v-if="!introTutorial || showBBBTutorial"
            :content="showBBBTutorial ? tutorialCollection.bbbTeacher.map(el => el.img) : tutorialCollection.normalTeacher.map(el => el.img)"
            :subtitle-headers-german="showBBBTutorial ? tutorialCollection.bbbTeacher.map(el => el.header) : tutorialCollection.normalTeacher.map(el => el.header)"
            :subtitles-german="showBBBTutorial ? tutorialCollection.bbbTeacher.map(el => el.text) : tutorialCollection.normalTeacher.map(el => el.text)"
            :force-tutorial="false"
            @close="showBBBTutorial ? closeBBBTutorial() : closeIntroTutorial()"
        />
        <ContractVerwaltungV2 v-if="showDialogGDPR" />
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";

import left from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import right from '@/assets/Icons/FaceliftIcons/vor_pfeil.svg';
import briefIcon from '@/assets/Icons/letter.svg'
import brettIcon from "@/assets/Icons/brett.svg";
import chatIcon from "../../assets/Icons/chat-alternative-filled-102.svg";
import stundenplanIcon from '../../assets/Icons/timetable.svg';
import aktualisierenIcon from '@/assets/Icons/FaceliftIcons/aktualisieren.svg';

import * as backend from "../../api/backend";
import PlanWidget from "../SharedViews/Stundenplan/Schueler_und_Lehrer/PlanWidget";
// import BrettWidget from '@/components/BrettWidget.vue';
import BrettWidget from '@/components/BrettWidget.vue';
import Users from "@/components/Verwaltung/Users.vue";
import MessagesWidget from "@/components/MessagesWidget.vue";
import ChatWidget from '@/components/ChatV2/ChatWidgetV2.vue';
import AppWidget from '@/components/Apps/AppWidget.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import Sidebar from "@/components/Sidebar";
import Avatar from "@/components/SharedViews/Profile/Avatar";
import EventBus from "../../util/eventBus";
import Diary from "../Lehrer/Diary";
import Preloader from "@/components/Utils/Preloader.vue";
import tutorialCollection from "@/constants/tutorialCollection";
import VideoTutorial from "../../components/Tutorial/VideoTutorial.vue";
import SlideTutorial from "@/components/Tutorial/SlideTutorial";
import SchoolInitialization from './schoolInitialization.vue';
import BadgeExplanations from "@/components/Utils/BadgeExplanations.vue";
import Nutzungsbedingungen from '@/components/Policys/Nutzungsbedingungen.vue';
import ForceLogoutDialog from '../Utils/ForceLogoutDialog.vue';
import Header from "@/components/Verwaltung/Header";
import ContractVerwaltungV2 from '@/components/Verwaltung/ContractVerwaltungV2.vue';
import SupportChat from "../Support/SupportChat";

export default {
    name: "Plan",
    components: {
        ContractVerwaltungV2,
        Header,
        Avatar,
        Sidebar,
        PlanWidget,
        BrettWidget,
        Users,
        MessagesWidget,
        ChatWidget,
        AppWidget,
        VueSlickCarousel,
        // Diary,
        Preloader,
        VideoTutorial,
        SlideTutorial,
        // SchoolInitialization,
        BadgeExplanations,
        Nutzungsbedingungen,
        ForceLogoutDialog,
        SupportChat,
    },
    data: () => ({
        otherConnectedDevices: null,
        showForceLogoutDialog: false,
        hidePreloader: true,        
        usageAgreementNotSigned: false,
        revokeAgreement: false,
        crtAccount: null,

        left,
        right,
        aktualisierenIcon,  
        showEmailBadge: false,
        showLoadingOverlay: false,
        showFirstLoginDialog: false,
        list: [],
        groups: [],
        slots: [],
        group: "",
        reload: false,
        page: 0,
        currSlide: 0,               //to color icons of mobile bottom navBar
        slideChangeAllowed: true,   //to prevent spamming of btmNavBar, which would bring highlighting of btmNavIcons and currently displayed widget out of sync
        person: "",

        sidebar: false,
        sidebarMobile: false,
        diaryDialog: false,
        initialSlide: 0,

        appwidgetMounted: false,
        brettMounted: false,
        chatMounted: false,
        planMounted: false,
        messagesMounted: false,
        usersMounted: false,

        introTutorial: null,
        bbbTutorialCookie: null,
        showBBBTutorial: false,
        tutorialCollection,
        videoTutorial: false,
        videoTutorialClosed: false,
        reloadChat: false,
        reloadBrett: false,
        reloadPlan: false,
        reloadMessages: false,
        reloadUsers: false,
        reloadAppwidget: false,
    }),
    computed: {
        ...mapGetters("maintainer", ["showDialogGDPR"]),
        ...mapGetters('videoTutorial', ['videoTutorialsByFilename']),
        ...mapGetters('groups', ['getGroupStatus']),
        ...mapGetters('timeslots', [ 'getTimeslotsInvalid' ]),
        ...mapGetters('copypaste', [ 'isCopyModeActive' ]),
        ...mapGetters('support',['supportPageOpen']),
        ...mapState('badges', ['maintainerParentalLetterBadge', 'blackboardBadge', 'timetableBadge', 'chatBadge', 'appBadge']),
        ...mapState('copypaste', ['copyMode']),
        ...mapState('util', ['windowWidth', 'windowHeight']),
        ...mapState('profileManagement', ['profileManagement']),
        ...mapState('weeklyView', ["weeklyView"]),
        ...mapState('utilUnpersistent', ['blockMobileWidgetChangeByDrag','hideNavbar']),
        ...mapState('auth', ['stayLoggedIn', 'deviceToken', 'preventLogout']),
        ...mapGetters('deactivatedFeatures',['deactivatedFeatures', 'appsDeactivated','chatDeactivated','brettDeactivated','planDeactivated',
                                                'messagesDeactivated','filesDeactivated','calendarDeactivated','diaryDeactivated','pinboardDeactivated',]),

            allWidgetsMounted(){
                return this.chatMounted && this.brettMounted && this.planMounted && this.appwidgetMounted && this.messagesMounted;
            },
            page0WidgetsMounted(){
                if(this.windowWidth > 900 && this.page === 0 ){
                    return   this.chatMounted && this.brettMounted && this.planMounted;
                }
                return true;
            },
            page1WidgetsMounted(){
                if(this.windowWidth > 900 && this.page === 1 ){
                    return  this.appwidgetMounted && this.messagesMounted;
                }
                return true;
            },
            btmNavItems(){
                   
            const items = [];
        
            if(!this.planDeactivated){
                items.push(
                { name: 'stundenplan', alt: 'Stundenplan', icon: stundenplanIcon, goToIndex: items.length, bgColor: '#6995CD', color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);' },
                )
           }
            if(!this.brettDeactivated){
                items.push(
                { name: 'brett', alt: 'Schwarzes Brett', icon: brettIcon, goToIndex: items.length, bgColor: 'var(--v-dunkelgrau-base)', color: 'filter: brightness(0) saturate(100%) invert(40%) sepia(22%) saturate(0%) hue-rotate(171deg) brightness(102%) contrast(80%);' },
                )
            }
            if(!this.chatDeactivated){
                items.push(
                    { name: 'chat', alt: 'Chat', icon: chatIcon, goToIndex: items.length, bgColor: 'var(--v-chatColor-base)', color: 'filter: brightness(0) saturate(100%) invert(55%) sepia(8%) saturate(4130%) hue-rotate(70deg) brightness(97%) contrast(81%);' },
                )
            }
            if(!this.messagesDeactivated){
                items.push(
                { name: 'brief', alt: 'Elternbrief', icon: briefIcon, goToIndex: items.length, bgColor: '#94519B', color: 'filter: brightness(0) saturate(100%) invert(34%) sepia(43%) saturate(774%) hue-rotate(247deg) brightness(98%) contrast(81%)' },
                )
            }
            items.push(
            { name: 'nutzer', alt: 'Nutzer', mdi: 'mdi-account', goToIndex: items.length, bgColor: 'var(--v-userColor-base)', color: 'filter: brightness(0) saturate(100%) invert(67%) sepia(71%) saturate(1286%) hue-rotate(338deg) brightness(104%) contrast(91%);' },
                );
            if(!this.appsDeactivated){
                items.push(
                { name: 'apps', alt: 'Apps', mdi: 'mdi-apps', goToIndex: items.length, bgColor: 'var(--v-headerblue-base)',}

                )
            }
            return items;
        },
        chatWidgetSlide(){
            return this.btmNavItems.findIndex((item)=> item.name === 'chat');
        },
        showPageOne(){
            let returnVal = true;
            if(this.planDeactivated && this.brettDeactivated && this.chatDeactivated){
                returnVal = false;
            }
            return returnVal;
        },
    },
    watch: {
        group(newValue) {
            this.list = [];
            this.groups.forEach((el) => {
                if (el.name === newValue) {
                    const temp = el.participants;
                    temp.forEach((el) => {
                        this.list.push(el);
                    });
                }
            });
        },
        getGroupStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestGroups();
            }
        },
        getTimeslotsInvalid(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestSlots(false);
            }
        },
        showPageOne(newVal, oldVal){
            if(newVal === false){
                this.$router.replace({ query: { ...this.$route.query, page: 1 } });
            }
        },
        allWidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        },
        page0WidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        },
        page1WidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        }
    },
    beforeCreate() {
        // Returns the ISO week of the date.
        Date.prototype.getWeek = function () {
            var date = new Date(this.getTime());
            date.setHours(0, 0, 0, 0);
            // Thursday in current week decides the year.
            date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
            // January 4 is always in week 1.
            var week1 = new Date(date.getFullYear(), 0, 4);
            // Adjust to Thursday in week 1 and count number of weeks from date to week1.
            return (
                1 +
                Math.round(
                    ((date.getTime() - week1.getTime()) / 86400000 -
                        3 +
                        ((week1.getDay() + 6) % 7)) /
                    7
                )
            );
        };
    },
    async created() {
        this.checkQueryForPage();
        this.checkTutorialState();

        // Check query param for page
        const page = Number.parseInt(this.$route.query.page);
        if (page && Number.isSafeInteger(page)) {
            if (this.windowWidth > 900) {
                this.page = page;
                if (page > 1) {
                    this.page = Math.floor(page / 3);
                    await this.$router
                        .replace({ query: { ...this.$route.query, page: this.page }});
                }
            } else {
                this.currSlide = page;
            }
        }
        //this is needed because if feature is disabled it wont mount so we would get stuck in infinite loading screen
        if(this.deactivatedFeatures.apps){
            this.appwidgetMounted = true;
        }
        if(this.deactivatedFeatures.plan){
            this.planMounted = true;
        }
        if(this.deactivatedFeatures.chat){
            this.chatMounted = true;
        }
        if(this.deactivatedFeatures.messages){
            this.messagesMounted = true;
        }if(this.deactivatedFeatures.blackboard){
            this.brettMounted = true;
        }
        
    },
    async mounted() {
        setTimeout(() => {
            this.hidePreloader = true;
        }, 5000);
        this.crtAccount = await this.getCurrentAccount(true);
        this.usageAgreementNotSigned = this.crtAccount.signedUsageAgreement.isSigned ? false : true;
        await this.requestPerson();
        this.turnOffCopyMode();
        this.showLoadingOverlay = true;
        this.requestGroups();
        this.requestSlots(true);
        this.videoTutorial = (this.$route.query.page == 1)
            ? this.videoTutorialsByFilename['Tutorial_Verwaltung.mp4']
            : this.videoTutorialsByFilename['Tutorial_Verwaltung2.mp4'];
        EventBus.$off('account-forcelogout');
        EventBus.$on('account-forcelogout', () => {
            if (!this.preventLogout) {
                this.logoutUser();
                this.$router.push({ name: 'login'});
            } else {
                this.setPreventLogout(false);
            }
        });
         const connectionsRes =  await backend.checkWebsocketConnections(this.crtAccount._id);
        const clientsObject = await connectionsRes.json();
        this.otherConnectedDevices = clientsObject.connected - 1;
        if(this.otherConnectedDevices > 0){
            this.showForceLogoutDialog = true;
        }
        await this.getLocations(true);
    },
    async destroyed() {
        this.stopCopyMode();
    },
    methods: {
        ...mapActions("maintainers", ["getMeMaintainer"]),
        ...mapActions('weeklyView', ['toggleWeeklyView', 'closeWeeklyView']),
        ...mapActions('auth', ['logoutUser', 'getCurrentAccount']),
        ...mapActions('copypaste', ['turnOffCopyMode', 'setAppointmentData', 'setCopyModeOriginFachId']),
        ...mapActions('groups', ['getGroup', 'getGroups', 'setGroupInvalidStatus']),
        ...mapActions('profileManagement', ['toggleProfileManagement', 'closeProfileManagement']),
        ...mapActions('timeslots', ['getSlots', 'setTimeslotsStatus', 'editSlot']),
        ...mapActions('pinboard', ['toggleOpenPinboardLibrary']),
        ...mapActions('maintainers', ['getMaintainers', 'confirmMaintainerInitialization']),
        ...mapMutations('maintainer', ['setShowDialogGDPR']),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapMutations('support', ['setSupportPageOpen']),
        ...mapMutations('auth', ['setPreventLogout']),
        ...mapActions("location", ['getLocations']),

        confirmForceLogout(){
            this.showForceLogoutDialog = false;
            this.showSnackbar({message:`Andere Geräte erfolgreich ausgeloggt.`, color:'success'})
        },
         revokeUsageAgreement(){
            this.usageAgreementNotSigned = true;
            this.revokeAgreement = true;
        },
        isMounted(item){
            switch(item.name){
                case 'stundenplan':
                    return this.planMounted;
                case 'brett':
                    return this.brettMounted;
                case 'chat':
                    return this.chatMounted;
                case 'apps':
                    return this.appwidgetMounted;
                case 'brief':
                    return this.messagesMounted;
                case 'nutzer':
                    return this.usersMounted;
                default: 
                return true;
            }
        },
        closeIntroTutorial() {
            this.introTutorial = true;
            localStorage.setItem('tutorial', 'true');
        },

        closeBBBTutorial() {
            this.showBBBTutorial = false;
            localStorage.setItem('bbbTut', 'true');
            this.openBBB();
        },
        checkTutorialState() {
            this.introTutorial = JSON.parse(localStorage.getItem('tutorial'));
            this.bbbTutorialCookie = JSON.parse(localStorage.getItem('bbbTutorial'));
        },
        resetTutorial() {
            this.introTutorial = false;
            localStorage.setItem('tutorial', 'false');
        },
        async changePage(direction) {
            this.page = Math.abs((this.page + direction) %2);
            // await this.setFocusOnNextPage()

            // update query params
            await this.$router.replace({ query: { ...this.$route.query, page: this.page } });
            this.resetTutorial()
                        this.videoTutorial = (this.$route.query.page == 1)
            ? this.videoTutorialsByFilename['Tutorial_Verwaltung.mp4']
            : this.videoTutorialsByFilename['Tutorial_Verwaltung2.mp4'];
            this.videoTutorialClosed = false;
        },

        reloadPage() {
            this.$router.go();
        },

        stopCopyMode() {
            let temp = {
                isCopy: null,
                copiedAppointment: null,
                copiedSubject: null,
                copiedTeachers: null,
                copiedIsWeekly: null,
                copiedWeeklyTill: null,
            };

            if (!this.appointment || !this.appointment._id) {
                this.subject = null;
            }
            this.turnOffCopyMode();
            this.setAppointmentData(temp);
            this.copyModeOriginFach = false;
            this.setCopyModeOriginFachId(null);
        },

        vueSlickCarouselAfterChange() {
            this.slideChangeAllowed = true;
            this.currSlide = this.$refs.carousel.$refs.innerSlider.currentSlide;
        },

        switchSlide(index) {
            if (this.slideChangeAllowed && index !== this.currSlide) {
                this.$refs.carousel.goTo(index);
                this.currSlide = index;
                //this.slideChangeAllowed = false;
                this.sidebar = false;
                this.sidebarMobile = false;
                // set weeklyView to closed on change
                this.closeWeeklyView();

                // add page to query
                this.$router.replace({ query: { ...this.$route.query, page: this.currSlide } });
            }else if(index === this.currSlide){
                this.reloadWidget(index)
            }
        },
        reloadWidget(index){
            switch(index){
                  case 0:
                    this.reloadPlan =true;
                    break;
                case 1:
                     this.reloadBrett = true;
                     break;
                case 2:
                    this.reloadChat = true;
                    break;
                case 3:
                   this.reloadMessages = true;
                    break;
                case 4:
                   this.reloadUsers = true;
                    break;
                case 5:
                     this.reloadAppwidget = true;
                    break;
            }
        },
        getBadge(index) {
            switch (index) {
                case 0:
                    return this.timetableBadge;
                case 1:
                    return this.blackboardBadge;
                case 2:
                    return this.chatBadge;
                case 3:
                    return this.maintainerParentalLetterBadge;
                case 5:
                    return this.appBadge;
                default:
                    return false;
            }
        },

        async requestPerson() {
            const maintainers = await this.getMaintainers();
            let maintainerObj = maintainers[0];
            maintainerObj = {
                _id: maintainerObj._id,
                account: maintainerObj.account,
                email: maintainerObj.email,
                hideEmailBadge: maintainerObj.hideEmailBadge,
                hideFirstLogin: maintainerObj.hideFirstLogin,
            }
            this.person = maintainerObj;
            this.showEmailBadge = this.person.hideEmailBadge ? !this.person.hideEmailBadge : true;
            
            if(!maintainers[0].hideFirstLogin){
             this.showFirstLoginDialog = true;
            }
        },
        
        async requestSlots(isMounted) {
            this.showLoadingOverlay = true;
            this.slots = await this.getSlots(isMounted);
            //TODO: Evtl. geht das hier schöner ? evtl. direkt im Backend Sortieren ?
            this.slots.forEach(element => {
                let dateOldStart = new Date(element.start);
                dateOldStart.setFullYear(2020,11,18);
                let dateOldEnd = new Date(element.end);
                dateOldEnd.setFullYear(2020,11,18);
                element.start = dateOldStart.toISOString();
                element.end = dateOldEnd.toISOString();
            });
            this.slots.sort(function(a,b){
                return new Date(a.start) - new Date(b.start);
            });
            this.showLoadingOverlay = false;
            this.setTimeslotsStatus(false);
        },

        async requestGroups() {
            //this is a hotfix which works and I have no clue why so please don't break it
            const response = await backend.getGroups();
            const newArray = await response.json();
            this.groups = newArray.filter((el) => el.name !== "Alle");
            this.setGroupInvalidStatus(false);
            this.reload = !this.reload;

            let temp = JSON.parse(JSON.stringify(this.group));
            this.group = "";

            setTimeout(() => {
                this.group = temp;
            }, 500);
        },

        checkQueryForPage() {
            if (this.$route.query.page) {
                this.initialSlide = parseInt(this.$route.query.page);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.backgroundImg {
    background-image: url(../../assets/Bilder/scribble.svg);
    background-repeat: repeat;
    overflow: hidden;
    height: 100%;
}
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th:nth-child(2),
table tr td:nth-child(2) {
    width: 5px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}
table,
tr,
td {
    border: none;
}

.fach {
    cursor: pointer;
}

.iconSelect {
    width: 42px;
    padding: 2px;
    border: solid 2px #eaeaea;
    border-radius: 10px;
    margin-right: 2px;
}

.iconSelect[selected] {
    width: 42px;
    padding: 2px;
    border: solid 2px #737373;
    border-radius: 10px;
}
.inputField {
    max-width: 500px;
    min-width: 250px;
    background-color: white;
    border-radius: 8px;
}

.boardIcon {
    height: 20px;
    filter: brightness(100) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.textGrau {
    color: var(--v-dunkelgrau-base);
}

.blur {
    z-index: 8;
    background-color: grey;
}

.small {
    display: none;
}
.btmNavIcon {
    height: 35px;
}
.btmNavBtn.v-btn:before {
    opacity: 0 !important;
}
.btmNavBtn {
    height: 50px !important;
    width: 50px !important;
    background-color: white !important;
    border-radius: 8px !important;
}

.hiddenBtmNavBtn {
    height: 50px !important;
    width: 50px !important;
    background-color: transparent !important;
    border-radius: 8px !important;
}

.hideMe {
    display: none !important;
}

.diableHoverButton:hover {
    cursor: default;
}

//make text respect line breaks
.blackboardVCardText {
    white-space: pre-line;
}

input[type="date"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.6rem;
}

#calendarWeekBtn {
    display: inline-grid;
    background-color: #ffffff !important;
    filter: opacity(40%);
    color: black !important;
}

.input {
    width: 80px;
    border-radius: 5px;
    border-width: 0;
    color: black;
    border: none !important;
    background-color: var(--v-hellgrau-base);
}

.errorInput {
    border: 2px solid red !important;
}
.arrowBtn:before {
    opacity: 0 !important;
}
.imgFocus {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%) !important;
}
.buttonFocusHover:hover .imgFocusHover {
    filter: invert(51%) sepia(66%) saturate(310%) hue-rotate(175deg) brightness(98%) contrast(91%) !important;
}
.btmNav {
    display: none;
    z-index: 11;
    position: fixed;
    bottom: 0;
    width: 100% !important;
    height: 70px !important;
    background-color: var(--v-headerblue-base);
}
@media only screen and (max-width: 900px) {
    .small {
        display: block !important;
    }

    .big {
        display: none !important;
    }

    .topPadding {
        padding-top: 12px;
    }

    .displayHide {
        display: none;
    }

    .btmNav {
        display: block;
    }

    // .reload {
    //     display: block;
    //     position: fixed;
    //     bottom: 0;
    //     width: 100% !important;
    //     margin-bottom: 38px !important;
    // }
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0);
    background: linear-gradient(
        to right,
        rgba(112, 112, 112, 0.2) 0%,
        rgba(112, 112, 112, 0.2) 77%,
        rgba(112, 112, 112, 0.5) 92%,
        rgba(112, 112, 112, 0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine {
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}
</style>
