<template>
    <!-- Add long-press to this -->
    <div
        :id="`fileSystemTile${item._id}`"
        class="my-2 mx-auto fileSystemTile"
        style="display: flex; justify-content: center; position: relative"
        :style="getStyle()"
    >
        <!--        activate these once we have a solution fo it to work for iPad
            it activates on scroll after the timeout, even tho nobody is touching it-->
        <!--        v-long-press="2000"-->
        <!--        @long-press-start="startMultiselect"-->
        <!--        @long-press-stop="dontLongPress"-->

        <div
            :style="getStyleList()"
            style="width: 100%; align-items: center"
            role="button"
            :aria-label="!appointment ? item.name || item.uploadedName : item.title"
            @click="handleClickElement()"
        >
            <!-- List View -->
            <div
                v-if="vfsGridWidthPct===100 && !(appointment || vfs.appointment || vfs.isVfsTask)"
            >
                <v-icon
                    v-if="type !== 'folder'"
                    class="pr-1"
                    style="display: flex; justify-content: flex-start; align-content: center"
                    alt="Datei"
                >
                    mdi-file
                </v-icon>
                <img
                    v-else-if="item.learningPath ? item.learningPath.pathVFSOrigin == item._id : false && type === 'folder'"
                    :src="lernpfadIcon"
                    class="iconToDunkelgrau pr-1"
                    :height="25"
                    style="display: flex; justify-content: flex-start; align-content: center; margin-top: 2px; margin-right: 2px"
                    alt="Lernpfad"
                >
                <img
                    v-else-if="item.learningPath ? item.learningPath.pathVFSOrigin == item.parentFolder._id : false && type === 'folder'"
                    :src="lernpfadStationIcon"
                    class="iconToDunkelgrau pr-1"
                    :height="25"
                    style="display: flex; justify-content: flex-start; align-content: center; margin-top: 2px;"
                    alt="Lernpfadstation"
                >
                <v-icon
                    v-else-if="item.sharing_enabled && item.sharing_discoverable && item.sharing_Accounts.length > 0"
                    class="pr-1"
                    style="display: flex; justify-content: flex-start; align-content: center"
                    alt="Geteilter Ordner"
                >
                    mdi-folder-account
                </v-icon>
                <img
                    v-else-if="item.rulesetAppointment"
                    :src="ordnerStundenplan"
                    class="pr-1 iconToDunkelgrau"
                    :height="20"
                    style="display: flex; justify-content: flex-start; align-content: center; margin-top: 2px; margin-right: 2px; margin-left: 2px;"
                    alt="Ordner aus Stundenplan"
                >
                <v-icon
                    v-else
                    class="pr-1"
                    style="display: flex; justify-content: flex-start; align-content: center"
                    alt="Ordner"
                >
                    mdi-folder
                </v-icon>
            </div>
            <!-- Badge for unseen stuff -->
            <div
                v-if="badgeVisible"
            >
                <button
                    class="internFileElementBadge"
                    alt="Zur Markierung springen"
                    :style="vfsGridWidthPct === 100 ? 'left: -17px !important' : '' "
                    @click="badgeOfItemClicked"
                >
                    {{ this.item.type === 'folder' && !this.item.learningPath ? badgeValue : '1' }}
                </button>
            </div>
            <!-- Grid View -->
            <div
                v-if="vfsGridWidthPct < 100 || (appointment || vfs.appointment || vfs.isVfsTask)"
                class="thumbnailContainer"
                :class="`${type === 'draft' ? 'draftFileWidgetElement' : ''}`"
                :style="(item.learningPath ? item.learningPath.pathVFSOrigin == item._id : false && type === 'folder') ? 'background-color: white' :
                    (item.learningPath ? item.learningPath.pathVFSOrigin == item.parentFolder._id : false && type === 'folder') ? ghostElement ? 'background-color: #00000021' : `background-color: ${timelineColors[index % timelineColors.length]}` :
                    ghostElement ? 'background-color: #00000021' : ''"
            >
                <!-- Thumbnail-Preview -->
                <div
                    class="thumbnailDisplayNormal mx-auto"
                    :class="isFileDeleted ? 'outdated-image' : ''"
                    style="overflow: hidden"
                >
                    <img
                        v-if="noThumbnail"
                        :src="dateiIcon"
                        :class="vfsGridWidthPct < 50 ? 'folderDisplay33' : 'folderDisplay'"
                        class="iconToWhite"
                        style="font-size: 120px !important; padding-top: 28% !important; height: 73%"
                        alt="Datei"
                    >
                    <v-img
                        v-else-if="(item.type === 'file' || type ==='file') && !previewPDF && !ghostElement"
                        :class=" isItemAudioFile ? 'white' : ''"
                        :src="preview"
                        aspect-ratio="1.5"
                        :alt="item.name || item.uploadedName"
                        :lazy-src="dateiIcon"
                    >
                        <div class="fill-height bottom-gradient" />
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height fill-width ma-0"
                                align="center"
                                justify="center"
                            >
                                <!-- <v-progress-circular
                                    indeterminate
                                    color="grey"
                                /> -->
                            </v-row>
                        </template>
                    </v-img>
                    <img
                        v-else-if="item.learningPath ? item.learningPath.pathVFSOrigin == item._id : false && type === 'folder'"
                        :src="lernpfadIcon"
                        :class="vfsGridWidthPct < 50 ? 'folderDisplay33' : 'folderDisplay'"
                        style="font-size: 120px !important; padding-top: 30px !important; height: 78%"
                        alt="Lernpfad"
                    >
                    <div
                        v-else-if="item.learningPath ? item.learningPath.pathVFSOrigin == item.parentFolder._id : false && type === 'folder'"
                    >
                        <v-icon
                            class="pr-1"
                            :class="vfsGridWidthPct < 50 ? 'folderDisplay33' : 'folderDisplay'"
                            style="display: block; position: absolute; left: -15%; top: -15%"
                            :style="vfsGridWidthPct < 50 ? 'left: -10%; top: -5%' : ''"
                            x-large
                        >
                            {{ `mdi-numeric-${index+1%10}` }}
                        </v-icon>
                        <img
                            :src="lernpfadStationIcon"
                            class="iconToWhite"
                            :class="vfsGridWidthPct < 50 ? 'folderDisplay33' : 'folderDisplay'"
                            style="display: block; position: absolute; left: 23%; top: 15%; height: 57%"
                            alt="Lernpfadstation"
                        >
                    </div>
                    <img
                        v-else-if="teacherFolder"
                        :src="ordnerLehrerIcon"
                        class="iconToWhite"
                        :class="vfsGridWidthPct < 50 ? 'folderDisplay33' : 'folderDisplay'"
                        style="height: 70%; padding-top: 27%"
                        alt="Ordner aus Stundenplan"
                    >
                    <img
                        v-else-if="item.sharing_enabled && item.sharing_discoverable && item.sharing_Accounts.length > 0"
                        :src="ordner"
                        class="iconToWhite"
                        :class="vfsGridWidthPct < 50 ? 'folderDisplay33' : 'folderDisplay'"
                        style="height: 70%; padding-top: 27%"
                        alt="Ordner"
                    >
                    <v-img
                        v-else-if="item.type === 'draft' || type ==='draft'"
                        :src="preview"
                        aspect-ratio="1.5"
                        :alt="item.name || item.uploadedName"
                        :lazy-src="dateiIcon"
                    >
                        <div class="fill-height bottom-gradient" />
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height fill-width ma-0"
                                align="center"
                                justify="center"
                            >
                                <!-- <v-progress-circular
                                    indeterminate
                                    color="grey"
                                /> -->
                            </v-row>
                        </template>
                    </v-img>
                    <img
                        v-else-if="item.rulesetAppointment"
                        :src="ordnerStundenplan"
                        class="iconToWhite"
                        :class="vfsGridWidthPct < 50 ? 'folderDisplay33' : 'folderDisplay'"
                        style="height: 70%; padding-top: 27%"
                        alt="Ordner aus Stundenplan"
                    >
                    <v-icon
                        v-else-if="ghostElement"
                        class="pr-1"
                        :class="vfsGridWidthPct < 50 ? 'folderDisplay33' : 'folderDisplay'"
                        x-large
                        alt="Ordner aus Stundenplan"
                    >
                        mdi-file-clock
                    </v-icon>
                    <img
                        v-else
                        :src="ordner"
                        class="iconToWhite"
                        :class="vfsGridWidthPct < 50 ? 'folderDisplay33' : 'folderDisplay'"
                        style="height: 70%; padding-top: 27%"
                        alt="Ordner"
                    >
                    <div
                        v-if="!appointment"
                        class="mx-auto"
                        :class="vfsGridWidthPct === 33 ? 'thumbnailOverlay33': 'thumbnailOverlay'"
                        :style="getStyle()"
                    />
                    <div
                        v-else
                        class="mx-auto thumbnailOverlayHausis"
                        :style="getStyle()"
                    />
                    <v-tooltip
                        v-if="accountRole === 'pupil' && babyView && this.item.appointmentId"
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <button
                                style="position: absolute; left: 5%; top: 5%;"
                                v-on="on"
                                @click="ToggleAppointmentInRouter"
                            >
                                <img
                                    style="width: 30px; height: 30px; margin: 3px; filter: brightness(1000%);  background: transparent !important;"
                                    :src="infoIcon"
                                    alt="Datei im Stundenplan anzeigen"
                                >
                            </button>
                        </template>
                        <span>Im Stundenplan anzeigen</span>
                    </v-tooltip>
                    <!-- Descriptive Icons to convey state of File -->
                    <v-tooltip
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <!-- commented out this v-img section because it did not work and I am not sure of its purpose
                            this is the IconInfo section but it generates IconSharing icons+handling -->
                            <!-- <v-img
                                v-if="schuelerBearbeiten"
                                class="iconInfo"
                                style="width: 40px; height: 40px; filter: brightness(1000%); left: 8%; bottom: 22%; background: transparent !important;"
                                :src="lernpfadNutzerBearbeiten"
                                :alt="getIconSharing.description"
                                tabindex="0"
                                elevation="0"
                                role="button"
                                v-on="on"
                                @click.stop="handleIconTypeClicked()"
                            /> -->
                            <v-icon
                                v-if="getIconInfo.icon !== 'mdi-eye'"
                                class="iconInfo"
                                elevation="0"
                                style="left: 5%; bottom: 22%; background: transparent !important;"
                                :style="item.learningPath ? item.learningPath.pathVFSOrigin == item._id ? 'background-color: red' :
                                    (item.learningPath ? item.learningPath.pathVFSOrigin == item.parentFolder._id : false && type === 'folder') ? getIconInfo.icon ? `background-color: ${timelineColors[index % timelineColors.length]}; border-color: white; border-style: double` :
                                        `background-color: ${timelineColors[index % timelineColors.length]}` : '' : getIconInfo.icon === 'mdi-pencil' && accountRole ==='pupil' ? 'background-color: var(--v-chatColor-base)':''"

                                large
                                role="button"
                                :alt="getIconInfo.description"
                                v-on="on"
                                @click.stop="handleClickElement(true)"
                            >
                                {{ getIconInfo.icon }}
                            </v-icon>
                        </template>
                        <span> {{ getIconInfo.description }} </span>
                    </v-tooltip>
                    <!-- Sharing Information of item -->
                    <v-tooltip
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <img
                                v-if="item.rulesetAppointment && item.fachIcon"
                                :src="getSrc(getIconSharing.icon)"
                                class="iconInfo"
                                elevation="0"
                                style="width: 40px; height: 40px; filter: brightness(1000%); right: 5%; bottom: 22%; background: transparent !important;"
                                :alt="getIconSharing.description"
                                v-on="on"
                                @click.stop=""
                            >
                            <img
                                v-else-if="sharedElement"
                                class="iconInfo"
                                style="width: 40px; height: 40px; filter: brightness(1000%); right: 5%; bottom: 22%; background: transparent !important;"
                                :src="groupIcon"
                                :alt="getIconSharing.description"
                                tabindex="0"
                                elevation="0"
                                v-on="on"
                                @click.stop="handleIconSharingClicked()"
                            >
                            <img
                                v-else-if="privateElement"
                                class="iconInfo"
                                style="width: 40px; height: 40px; filter: brightness(1000%); right: 5%; bottom: 22%; background: transparent !important;"
                                :src="pupilIcon"
                                :alt="getIconSharing.description"
                                tabindex="0"
                                elevation="0"
                                v-on="on"
                                @click.stop="handleIconSharingClicked()"
                            >
                            <img
                                v-else-if="nutzerBearbeiten"
                                class="iconInfo"
                                style="width: 40px; height: 40px; filter: brightness(1000%); right: 5%; bottom: 22%; background: transparent !important;"
                                :src="lernpfadNutzerBearbeiten"
                                :alt="getIconSharing.description"
                                tabindex="0"
                                elevation="0"
                                v-on="on"
                                @click.stop="handleIconSharingClicked()"
                            >
                            <img
                                v-else-if="showStundenplanIcon"
                                class="iconInfo"
                                style="width: 40px; height: 40px; filter: brightness(1000%); right: 5%; bottom: 22%; background: transparent !important;"
                                :src="stundenplanIcon"
                                :alt="getIconSharing.description"
                                tabindex="0"
                                elevation="0"
                                v-on="on"
                                @click.stop="handleIconSharingClicked()"
                            >
                            <img
                                v-else-if="showFileIcon"
                                class="iconInfo"
                                style="width: 40px; height: 40px; filter: brightness(1000%); right: 5%; bottom: 22%; background: transparent !important;"
                                :src="fileIcon"
                                :alt="getIconSharing.description"
                                tabindex="0"
                                elevation="0"
                                v-on="on"
                                @click.stop="handleIconSharingClicked()"
                            >
                            <v-icon
                                v-else
                                class="iconInfo"
                                elevation="0"
                                style="right: 5%; bottom: 22%; background: transparent !important;"
                                :style="item.learningPath ? item.learningPath.pathVFSOrigin == item._id ? 'background-color: red; border-color: white; border-style: double; font-size: 31px' :
                                    (item.learningPath ? item.learningPath.pathVFSOrigin == item.parentFolder._id : false && type === 'folder') ? ghostElement ? 'background-color: #00000040' : `background-color: ${timelineColors[index % timelineColors.length]}; border-color: white; border-style: double; font-size: 31px` :
                                    '' : ghostElement ? 'background-color: #00000025' : ''"

                                large
                                :alt="getIconSharing.description"
                                v-on="on"
                                @click.stop="handleIconSharingClicked()"
                            >
                                {{ getIconSharing.icon }}
                            </v-icon>
                        </template>
                        <span> {{ getIconSharing.description }} </span>
                    </v-tooltip>

                    <!-- Multiselect of item -->
                    <v-tooltip
                        v-if="multiselectActive"
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <div
                                v-if="multiselectActive && type === 'file'"
                                class="iconInfo multiselectCheckbox"
                                v-on="on"
                            >
                                <input
                                    :checked="selectedInMultiSelect"
                                    type="checkbox"
                                    @change="multiselectUpdate"
                                    @click="(evt) => { evt.stopPropagation() }"
                                >
                            </div>
                        </template>
                        <span> Auswählen </span>
                    </v-tooltip>
                </div>
            </div>
            <!-- Name of item -->
            <div
                class="fileSystemElementNameCentered"
                :style="(vfsGridWidthPct === 100 && !(appointment || vfs.appointment || vfs.isVfsTask)) ? 'max-width: 70%' : ''"
            >
                <!-- Editing or Creating a Folder - renders additional menu -->
                <div v-if="item.isEditing || isEditing">
                    <v-text-field
                        v-model="editedNameValue"
                        dense
                        outlined
                        single-line
                        hide-details
                        :placeholder="editedNameValue ? editedNameValue : 'Neuer Ordner'"
                        style="margin-bottom: 6px"
                    />
                    <!-- select receiver(s) page -->
                    <div
                        v-show="(item.type !== 'file' || type !=='file') && folderType === 'groupFolder' && (item.isMoving || isMoving || item.isCreating) && this.accountRole === 'teacher'"
                        class="pa-2"
                    >
                        <span> Ordner teilen mit: </span>
                        <div class="d-flex align-center">
                            <img
                                :src="searchIcon"
                                alt="Suche"
                                class="icon mr-2"
                            >

                            <v-text-field
                                v-model="searchTreeView"
                                class="ma-0 pt-0 pb-2"
                                hide-details
                                clearable
                            />
                        </div>
                        <hr class="greyLine">

                        <vue-custom-scrollbar
                            class="scroll-area-treeview"
                            settings="settings"
                        >
                            <v-treeview
                                v-model="receiverAccounts"
                                :items="possibleReceivers"
                                item-children="participants"
                                item-key="_id"
                                item-text="displayName"
                                :search="searchTreeView"
                                selected-color="var(--v-chatColor-base)"
                                selectable
                                dense
                                class="mt-2"
                                alt="Diesen Ordner teilen mit"
                                return-object
                            >
                                <template v-slot:prepend="{ item }">
                                    <div
                                        class="d-flex align-center"
                                        :alt="item.displayName ? item.displayName : ''"
                                    >
                                        <img
                                            v-if="item.gender"
                                            :src="pupilIcon"
                                            class="iconToDunkelgrau"
                                            height="20"
                                            alt=""
                                        >
                                        <img
                                            v-else
                                            :src="groupIcon"
                                            alt=""
                                        >
                                    </div>
                                </template>
                            </v-treeview>
                        </vue-custom-scrollbar>
                    </div>
                </div>
                <div v-else>
                    <div
                        style="display: flex"
                        aria-hidden="true"
                        @click.stop="handleClickElement()"
                        @keypress.enter="handleClickElement()"
                    >
                        <!-- VFS File Name -->
                        <v-tooltip
                            v-if="!appointment"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <p
                                    :id="'fileName' + item._id"
                                    class="pa-1 fileSystemElementName"
                                    :style="vfsGridWidthPct < 50 ? 'font-size: small' : 'width: 100%'"
                                    v-on="on"
                                    @click.stop="accountRole === 'pupil' ? activateTranslationBtn() : ''"
                                >
                                    {{ isFileDeleted ? `Gelöscht: ${(item.name || item.uploadedName)}` : (item.name || item.uploadedName) }}
                                </p>
                            </template>
                            <span> {{ isFileDeleted ? `Gelöscht: ${(item.name || item.uploadedName)}` : (item.name || item.uploadedName) }}</span>
                        </v-tooltip>
                        <!-- Appointment File Name -->
                        <div
                            v-else
                            class="d-flex"
                        >
                            <!-- Fach icon -->
                            <v-tooltip
                                :disabled="!showTooltips"
                                bottom
                            >
                                <template v-slot:activator="{ on }">
                                    <v-avatar
                                        class="smallTopicActionBtn"
                                        :style="`background-color: ${item.fachColor} !important; margin-top: 2px`"
                                        v-on="on"
                                    >
                                        <img
                                            :id="'toDoIcon-' + index"
                                            :src="getSrc(item.fachIcon)"
                                            class="smallTopicActionBtnImg"
                                            :alt="item.groupName"
                                            @click.stop=""
                                        >
                                    </v-avatar>
                                </template>
                                <span> {{ item.groupName }}</span>
                            </v-tooltip>
                            <v-tooltip
                                bottom
                            >
                                <template v-slot:activator="{ on }">
                                    <p
                                        :id="'toDoItem-' + index"
                                        style="width: fit-content"
                                        class="fileTitle mx-2 my-0"
                                        v-on="on"
                                        @click.stop="accountRole === 'pupil' ? activateTranslationBtn() : ''"
                                    >
                                        {{ isFileDeleted ? `Gelöscht: ${item.title}` : item.title }}
                                    </p>
                                </template>
                                <span> {{ isFileDeleted ? `Gelöscht: ${item.title}` : item.title }}</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="item.isEditing || isEditing || item.isMoving || isMoving">
            <v-btn
                x-small
                elevation="0"
                class="ml-2 pa-0 fileWidgetButton"
                @click="cancelFolderEdit"
            >
                <img
                    :src="cancelIcon"
                    style="height: 20px;"
                    alt="Abbrechen"
                >
            </v-btn>
            <v-btn
                x-small
                elevation="0"
                class="ml-2 pa-0 fileWidgetButton"
                @click="acceptFolderEdit"
            >
                <img
                    :src="acceptIcon"
                    style="height: 20px;"
                    alt="Akzeptieren"
                >
            </v-btn>
        </div>
        <p
            v-if="vfsGridWidthPct > 50 && !(appointment || vfs.appointment || vfs.isVfsTask)"
            :id="'fileDate' + item._id"
            class="pa-1 fileSystemElementName datePosition"
            style="margin-right: -45%; font-size: 13px; margin-top: 3px;"
        >
            {{ createDate(item.createdAt) }}
        </p>
        <v-tooltip
            :disabled="!showTooltips"
            bottom
        >
            <template v-slot:activator="{ on }">
                <div
                    ref="speaker"
                    class="showSpeaker"
                >
                    <v-btn
                        v-if="accountRole === 'pupil'"
                        :aria-hidden="me && me.accessibility && me.accessibility.screenreader"
                        small
                        fab
                        absolute
                        bottom
                        right
                        class="pa-0 mr-4 preventColorInvert"
                        elevation="6"
                        style="height: 30px; background-color:white; border-radius: 4px !important; bottom: 5px"
                        v-on="on"
                        @click="readText(item, $event)"
                    >
                        <img
                            :src="lautsprecherIcon"
                            class="icon20"
                            alt="Vorlesen"
                        >
                    </v-btn>
                </div>
            </template>
            <span>Vorlesen</span>
        </v-tooltip>
        <!-- Normal Buttons -->
        <FileTileMenuV2
            v-if="!(type === 'folder' && item.rulesetAppointment) && !multiselectActive && !ghostElement"
            :ref="`fileTileMenu${item._id}`"
            :folder-type="folderType"
            :type="(appointment || vfs.isVfsTask) ? 'appointment' : type"
            :view-file-function="viewFileFunction"
            :vfs="vfs"
            :debug-mode="debugMode"
            :item="itemForMenu"
            :is-task="isTask"
            :active-task="activeTask"
            :homework-category="category"
            :appointment-category="item.displayTeacherFile ? 'teacher' : item.displayPupilFile ? 'pupil' : item.displayCorrectedFile ? 'corrected' : ''"
            :list-view="(vfsGridWidthPct === 100 && !(appointment || vfs.appointment || vfs.isVfsTask))"
            :is-shared-file="isSharedFile"
            style="width: 100%; height: 85%"
            @loadFullscreenPreview="() => { $emit('loadFullscreenPreview', itemForMenu) }"
            @loadEditorPreview="handleClickElement()"
            @downloadItem="() => { $emit('download') }"
            @saveItemTo="() => { $emit('notifyCopyFile') }"
            @moveItemTo="() => { $emit('notifyMoveFile') }"
            @editSharing="() => { $emit('editSharing', item) }"
            @publishItem="() => { $emit('publishDialog') }"
            @deleteItem="handleDeleteButtonPress"
            @editItem="handleEditButtonPress"
            @moveItem="handleMoveButtonPress"
            @openSubmissionsToTask="handleOpenSubmissionsButtonPress"
            @setItemAsTask="setIsTask"
            @openMenu="(value) => { openMenu = value; resizeMenu(value); }"
            @disableParentScroll="(value) => { $emit('disableParentScroll', value); }"
            @getAllTeacherFiles="(fetch) => { $emit('getAllTeacherFiles', fetch) }"
            @multiselectElement="startMultiselect"
            @uploadFile="uploadFile(true)"
            @toggleArchiveElement="() => { $emit('toggleArchiveElement', item) }"
            @playAudio="() => { $emit('playAudio', item) }"
            @playVideo="() => { $emit('playVideo', item) }"
        />
        <ImageCapture
            :toggle-dialog="toggleImageCapture"
            :image-capture-usage="'vfs'"
            @handleImageCaptured="uploadFile"
            @toggleClose="toggleImageCapture = false"
        />
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex';
import { textToSpeech } from "@/util/textToSpeech";
import { allowedMimeTypesForAudioPlayer,
    allowedMimeTypesForEditor,
    allowedMimeTypesForLibre,
    allowedExtensionsForVideoPlayer,
    allowedExtensionsForEditor,
    allowedExtensionsForLibre } from "../../constants/allowedMimeTypes";
import { isFileThumbnailCompatibleExtension } from "../../util/MimetypeHelper.js";
import sanitizeHtml from '@/util/sanitizeHtml';
import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import acceptIcon from '@/assets/Icons/fertig-74.svg';
import img_eklaraLogo from '@/assets/Icons/eklara-logoV2.svg';
import searchIcon from '@/assets/Icons/lupe-18.svg';
import pupilIcon from '@/assets/Icons/pupil.svg';
import groupIcon from '@/assets/Icons/group.svg';
import lernpfadIcon from '@/assets/Icons/FaceliftIcons/Lernpfad.svg';
import lernpfadStationIcon from '@/assets/Icons/FaceliftIcons/Lernpfad_Station.svg';
import lernpfadNutzerBearbeiten from '@/assets/Icons/FaceliftIcons/lernpfad_nutzer_bearbeiten2.svg';
import ordnerStundenplan from '@/assets/Icons/FaceliftIcons/ordner_stundenplan.svg';
import stundenplanIcon from '@/assets/Icons/timetable.svg';
import ordnerLehrerIcon from '@/assets/Icons/FaceliftIcons/ordner_lehrer.svg';
import ordner from '@/assets/Icons/FaceliftIcons/ordner.svg'
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg'
import vueCustomScrollbar from "vue-custom-scrollbar";
import * as backend from "@/api/backend";
import FileTileMenuV2 from "@/components/FileArchive/FileTileMenuV2";
import ImageCapture from "@/components/Utils/ImageCapture.vue";
import dateiIcon from '@/assets/Icons/FaceliftIcons/datei_blatt.svg';
import fileIcon from '@/assets/Icons/file-white.svg';
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
// appointment icons
import bioIcon from "@/assets/Icons/FaceliftIcons/f-biologie.svg";
import chemieIcon from "@/assets/Icons/FaceliftIcons/f-chemie.svg";
import deutschIcon from "@/assets/Icons/FaceliftIcons/f-deutsch.svg";
import englischIcon from "@/assets/Icons/FaceliftIcons/f-englisch.svg";
import erdkundeIcon from "@/assets/Icons/FaceliftIcons/f-erdkunde.svg";
import geschichteIcon from "@/assets/Icons/FaceliftIcons/f-geschichte.svg";
import matheIcon from "@/assets/Icons/FaceliftIcons/f-mathe.svg";
import musikIcon from "@/assets/Icons/FaceliftIcons/f-musik.svg";
import kunstIcon from '@/assets/Icons/FaceliftIcons/f-kunst.svg';
import physikIcon from "@/assets/Icons/FaceliftIcons/f-physik.svg";
import politikIcon from "@/assets/Icons/FaceliftIcons/f-politik.svg";
import religionIcon from "@/assets/Icons/FaceliftIcons/f-religion.svg";
import sportIcon from "@/assets/Icons/FaceliftIcons/f-sport.svg";
import wirtschaftIcon from "@/assets/Icons/FaceliftIcons/f-wirtschaft.svg";
import EventBus from "../../util/eventBus";
import {getVFSUploadThumbnail} from "../../api/backend";
import urlify from '../../util/urlify';


export default {
    name: 'FileSystemTileElementV2',
    components: {
        FileTileMenuV2,
        vueCustomScrollbar,
        ImageCapture
    },
    props: {
        // file, folder, draft
        type: { required: true, type: String },
        // if the file is indexed through Appointment instead of VFS
        appointment: { required: false, default: false },
        item: { required: true, type: Object },
        screenX: { required: false, type: Number, default: 1920 },
        screenY: { required: false, type: Number, default: 1080 },
        index: { required: false, type: Number },
        folderType: { required: false, type: String },
        folderId: { required: false, type: String },
        debugMode: { required: false, type: Boolean, default: false },
        viewFileFunction: { required: false, type: Function, default: () => {} },
        vfs: { required: false, type: Object },
        indexLoadingPermission: { required: false, type: Array },
        notifyLoading: { required: false, type: Boolean },
        // Mode to tell Element to switch to multiselect UI, comes from parent
        multiSelectMode: { required: false, type: Boolean, default: false },
        // added props from Homework
        account: { required: false, type: String },
        hoeren: { required: false, type: Boolean, default: false },
        sehen: { required: false, type: Boolean, default: false },
        showTooltips: { required: false, type: Boolean, default: true },
        // can be todo, done
        category: { required: false, type: String, default: '' },
        // trigger for multiselect ui
        multiselectActive: { required: false, type: Boolean, default: false },
        selectedInMultiSelect: { required: false, type: Boolean, default: false },
    },
    data: () => ({
        isEditing: false,
        isMoving: false,
        // the element is used to show that something is missing or something will be revealed at a later date
        ghostElement: false,
        editedNameValue: null,
        preview: null,
        previewPDF: false,
        thumbnailLoaded: false,
        sharedElement: false,
        privateElement: false,
        nutzerBearbeiten: false,
        showStundenplanIcon: false,
        schuelerBearbeiten: false,
        teacherFolder: false,
        showFileIcon: false,

        isItemEditorFile: false,
        isItemLibreFile: false,
        isItemAudioFile: false,
        isItemVideoFile: false,
        fileExtension: '',
        file: '',

        allowedMimeTypesForLibre,
        allowedMimeTypesForEditor,
        allowedMimeTypesForAudioPlayer,
        allowedExtensionsForVideoPlayer,
        allowedExtensionsForEditor,
        allowedExtensionsForLibre,

        refreshKey: 0,

        // groupFolder data
        possibleReceivers: [],
        searchTreeView: '',
        receiverAccounts: [],
        activeTask: false,
        isTask: false,
        alreadySubmitted: false,

        // listView styling
        openMenu: false,

        // Icons
        cancelIcon,
        acceptIcon,
        img_eklaraLogo,
        searchIcon,
        pupilIcon,
        groupIcon,
        lernpfadIcon,
        lernpfadStationIcon,
        lernpfadNutzerBearbeiten,
        ordnerStundenplan,
        ordner,
        stundenplanIcon,
        ordnerLehrerIcon,
        infoIcon,
        dateiIcon,
        fileIcon,
        lautsprecherIcon,
        lautsprecherAusIcon,

        fachIcons: [
            {name: 'bioIcon', icon: bioIcon},
            {name: 'chemieIcon', icon: chemieIcon},
            {name: 'deutschIcon', icon: deutschIcon},
            {name: 'englischIcon', icon: englischIcon},
            {name: 'erdkundeIcon', icon: erdkundeIcon},
            {name: 'geschichteIcon', icon: geschichteIcon},
            {name: 'matheIcon', icon: matheIcon},
            {name: 'musikIcon', icon: musikIcon},
            {name: 'kunstIcon', icon: kunstIcon},
            {name: 'physikIcon', icon: physikIcon},
            {name: 'politikIcon', icon: politikIcon},
            {name: 'religionIcon', icon: religionIcon},
            {name: 'sportIcon', icon: sportIcon},
            {name: 'wirtschaftIcon', icon: wirtschaftIcon},
        ],

        timeoutForMultiselectActive: false,
        timelineColors: ['#66BB6A', '#64B5F6', '#ba68c8', '#EF5350', '#ff984c'],
        babyView: false,
        toggleImageCapture: false,
        noThumbnail: false,

        isFileThumbnailCompatibleExtension,
        showTextTimeout: null,
        sanitizeHtml,
        urlify,
        me: {
            accessibility: {
                screenreader: false,
            }
        },
    }),
    computed: {
        ...mapGetters('auth', [ 'accountRole', 'accountId' ]),
        ...mapGetters('vfs', ['vfsGridWidthPct']),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters('appointments', ['appointmentsById']),
        ...mapState('util', ['windowWidth', 'windowHeight']),
        ...mapState('translation', ['targetLang',]),

        badgeValue() {
            // TODO: role teacher aufgabenstellung -> SOLL: keine Badge
            // TODO: role pupil abgabe -> SOLL: keine Badge
            let badgeValue = 0;
            const calculateBadgeValue = (folder) => {
                let badgeValueTemp = 0;
                if (folder.learningPath) {
                    if (folder.learningPath.seenBy) {
                        if (!folder.learningPath.seenBy.includes(this.accountId)) {
                            badgeValueTemp ++;
                        }
                    }
                }
                if (folder.files ? folder.files.length > 0 : false) {
                    folder.files.forEach((file) => {
                        if (file.seenBy) {
                            if (!file.seenBy.includes(this.accountId)) {
                                badgeValueTemp++;
                            }
                        } else if(!file.seenByMe && file.uploader !== this.accountId){
                            badgeValueTemp++;
                        }
                    });
                }
                if (folder.folders ? folder.folders.length > 0 : false) {
                    folder.folders.forEach((subFolder) => {
                        badgeValueTemp += calculateBadgeValue(subFolder);
                    });
                }
                return badgeValueTemp;
            }
            badgeValue += calculateBadgeValue(this.item);

            // Calculate badge count for sharing task submissions which the teacher hasn't seen yet
            if (this.accountRole === 'teacher' && this.item.sharing_Tasks && this.item.sharing_Tasks.length > 0) {
                this.item.sharing_Tasks.forEach((task) => {
                    task.submissions.forEach((submission) => {
                        if (!submission.seenByTeacher) {
                            badgeValue++;
                        }
                    })
                })
            }
            return badgeValue;
        },
        // the file is marked in sharing_Files, where the it matches with the current item and we do not own the file (someone else shared this)
        isSharedFile() {
            return this.vfs.sharing_Files ? !!this.vfs.sharing_Files.find((file) => (JSON.stringify(this.item._id) == JSON.stringify(file.file) && this.accountId != file.owner)) : false;
        },
        // the file is marked in sharing_Files, where it matches with the current item and it is atleast shared to one person, irrespective of ownership
        isSharedFileForIcon() {
            return this.vfs.sharing_Files ? !!this.vfs.sharing_Files.find((file) => (JSON.stringify(this.item._id) == JSON.stringify(file.file) && file.sharing_Accounts.length > 0)) : false;
        },
        badgeVisible() {
            let badgeAmount = this.badgeValue;
            if (this.type === 'folder') {
                return badgeAmount > 0;
            }
            if (this.type === 'draft' && this.item.file.seenBy) {
                return !this.item.file.seenBy.includes(this.accountId);
            }
            if (this.type === 'file') {
                // calculate badge count for sharing tasks submissions contained in this file
                // every badge count represents one submission which has not yet been corrected by the teacher
                if (this.accountRole === 'teacher' && this.vfs.sharing_Tasks ? this.vfs.sharing_Tasks.length > 0 : false) {
                    this.vfs.sharing_Tasks.forEach((task) => {
                        if (task.file == this.item._id) {
                            task.submissions.forEach((sub) => {
                                if (!sub.seenByTeacher) {
                                    badgeAmount++;
                                }
                            })
                        }
                    })
                }
                // if((this.accountRole === 'teacher' && this.file && (this.item.displayCorrectedFile || this.file.seenBy.includes(this.accountId))) 
                // || this.accountRole === 'pupil' && this.file && (this.item.uploader === this.accountId) || this.file && this.file.seenBy.includes(this.accountId)){
                //     return false
                // }
                if (this.file) {
                    if (this.accountRole === 'teacher') {
                        if (this.item.displayCorrectedFile || this.file.seenBy.includes(this.accountId)) {
                        return false;
                        }
                    }
                    

                    if (this.accountRole === 'pupil' && (this.item.uploader === this.accountId || this.file.seenBy.includes(this.accountId))) {
                        return false;
                    }
                }
                return (!(this.item.seenBy ? this.item.seenBy.includes(this.accountId) : false)
                    && !this.item.seenByMe && this.category !== 'done' && !this.item.ghostElement) || badgeAmount > 0;
            } else {
                return !this.item.seenByMe && this.item.uploader !== this.accountId;
            }
        },
        // Case differentiator between giving a draft file or a normal file to the menu (for different options)
        itemForMenu() {
            if (this.type === 'draft' && this.item.file._id) {
                // TODO Think about pulling draft file if not yet added
                return {
                    ...this.item.file,
                    type: this.type,
                    author: this.item.author,
                    draftId: this.item._id,
                }
            }
            return this.item;
        },
        getIconInfo() {
            this.refreshKey;
            let icon = '';
            let description = '';
            switch (this.item.type || this.type) {
                case 'file':
                    if (this.isItemAudioFile) {
                        icon = 'mdi-play-circle-outline';
                        description = (this.accountRole === 'pupil' ? 'Du kannst' : 'Sie können') + ' diese Audio anhören'
                    } else if (this.isItemVideoFile) {
                        icon = 'mdi-play-circle-outline';
                        description = (this.accountRole === 'pupil' ? 'Du kannst' : 'Sie können') + ' dieses Video anschauen'
                    } else if (this.accountRole === 'teacher') {
                        icon = 'mdi-pencil';
                        description = 'Sie können die Datei bearbeiten'
                        // consider ghost element
                        if (this.ghostElement) {
                                icon = '';
                                description = '';
                        } else if (this.isTask) {
                            // consider type submission
                            if (this.activeTask) {
                                icon = 'mdi-pencil';
                                description = 'Die Aufgabe kann von SuS bearbeitet werden';
                                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.schuelerBearbeiten = true;
                            } else {
                                icon = '';
                                description = '';
                                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.schuelerBearbeiten = false;
                            }
                        }
                    } else
                    if (this.accountRole === 'pupil') {
                        if (this.vfs.owner === this.accountId && !(this.isSharedFile)) {
                            // owner of folder can edit files
                            // or they should... currently pupils cannot, so treat this as view only
                            // icon = 'mdi-pencil';
                            // description = 'Die Datei kann bearbeitet werden';
                            icon = 'mdi-eye';
                            description = 'Die Datei wurde von dir hochgeladen und kann angeschaut werden';
                        } else {
                            // view only
                            icon = 'mdi-eye';
                            description = 'Die Datei kann angeschaut werden';
                        }
                        // consider type appointment
                        if (this.vfs.appointment || this.vfs.rulesetAppointment || this.vfs.isVfsTask) {
                            if (this.item.displayTeacherFile || this.category === 'toDo') {
                                icon = 'mdi-pencil';
                                description = `Die Aufgabe kann bearbeitet werden`;
                            } else
                            if (this.item.displayPupilFile || this.item.displayCorrectedFile || this.category === 'done' || this.category === 'corrected') {
                                icon = 'mdi-eye';
                                if (this.item.displayPupilFile || this.category === 'done') {
                                    description = `Die abgegebene Aufgabe kann angeschaut werden`;
                                } else {
                                    description = `Die korrigierte Aufgabe kann angeschaut werden`;
                                }
                            }
                        }
                        if (this.isTask) {
                            if (this.alreadySubmitted) {
                                icon = 'mdi-check-circle-outline';
                                description = 'Die Aufgabe wurde bereits gelöst';
                            } else {
                                // can the pupil still submit?
                                if (this.activeTask) {
                                    icon = 'mdi-lock-open-variant';
                                    description = 'Die Aufgabe kann bearbeitet werden';
                                } else {
                                    icon = '';
                                    description = '';
                                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                    this.schuelerBearbeiten = false;
                                }
                            }
                        }
                        if (this.item.archived) {
                            icon = 'mdi-archive-outline';
                            description = 'Die Aufgabe wurde archiviert.';
                        }
                    }
                    break;
                case 'folder':
                    if (this.accountRole === 'pupil' && this.item.stationCompleted && !this.ghostElement) {
                        icon = 'mdi-check-bold';
                        description = 'Alle Aufgaben sind bearbeitet'
                    } else {
                        icon = '';
                        description = '';
                    }
                    break;
                case 'draft':
                    icon = 'mdi-clock-edit';
                    description = 'Die Datei wurde zwischengespeichert';
                    break;
                default:
                    icon = '';
                    break;
            }
        return { icon, description };
        },
        getIconSharing() {
            this.refreshKey;
            let icon = '';
            let description = '';
            switch (this.item.type || this.type) {
                case 'file':
                    if (this.appointment && this.category === 'toDo' && !this.vfs.isVfsTask) {
                        icon = 'mdi-camera';
                        description = 'Antwort hochladen';
                    } else if (this.vfs.appointment || this.vfs.rulesetAppointment) {
                        icon = 'mdi-calendar-check';
                        description = 'Datei aus dem Stundenplan';
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.showStundenplanIcon = true;
                    } else if (this.vfs.isVfsTask) {
                        icon = 'mdi-file';
                        description = 'Aufgabe aus dem Datei Archiv';
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.showFileIcon = true;
                    } else {
                        if ((!this.vfs.sharing_enabled || !this.vfs.sharing_discoverable || this.vfs.sharing_Accounts.length === 0 || (this.vfs.root ? false : !this.isSharedFileForIcon)) &&
                        (this.vfs.root ? !this.isSharedFileForIcon : true))
                        {
                            if (this.ghostElement) {
                                icon = 'mdi-file-clock';
                                description = 'Es gibt noch keine Abgabe';
                            } else {
                                icon = 'mdi-folder-lock';
                                description = 'Private Datei';
                                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.privateElement = true;
                            }
                        } else {
                            icon = 'mdi-folder-account';
                            description = `Geteilte Datei ${this.isSharedFile ? '(extern)' : ''}`;
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                            this.sharedElement = true;
                        }
                    }
                    break;
                case 'folder':
                    if (this.item.appointment || this.item.rulesetAppointment) {
                        if (this.item.fachIcon) {
                            icon = this.item.fachIcon;
                            description = this.item.groupname;
                        } else {
                            icon = 'mdi-calendar-check';
                            description = 'Ordner aus dem Stundenplan';
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                            this.showStundenplanIcon = true;
                        }
                    } else {
                        if (this.item.learningPath) {
                            if (this.item.learningPath.pathVFSOrigin === this.item._id) {
                                icon = 'mdi-account';
                                description = 'Lernpfad';
                                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.nutzerBearbeiten = true;
                            } else {
                                if (this.item.learningPath.stations.filter((el) => el.vfs == this.item._id).length > 0) {
                                    icon = 'mdi-account';
                                    description = 'Lernstation';
                                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                    this.nutzerBearbeiten = true;
                                } else if (!this.item.sharing_enabled || !this.item.sharing_discoverable || this.item.sharing_Accounts.length === 0) {
                                    if (this.ghostElement) {
                                        icon = 'mdi-clock';
                                        description = 'Die Station ist noch nicht freigeschaltet';
                                    } else {
                                        icon = 'mdi-folder-lock';
                                        description = 'Privater Ordner';
                                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                        this.privateElement = true;
                                    }
                                } else {
                                    icon = 'mdi-folder-account';
                                    description = 'Geteilter Ordner';
                                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                    this.sharedElement = true;
                                }
                            }
                        }
                        else if (!this.item.sharing_enabled || !this.item.sharing_discoverable || this.item.sharing_Accounts.length === 0) {
                            icon = 'mdi-folder-lock';
                            description = 'Privater Ordner';
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                            this.privateElement = true;
                        } else {
                            icon = 'mdi-folder-account';
                            description = 'Geteilter Ordner';
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                            this.sharedElement = true;
                        }
                    }
                    break;
                default:
                    icon = '';
                    break;
            }
        return { icon, description };
        },
        isDesktopSize() {
            // height on my 1080p screen is about 762px - Jan
            return this.windowWidth > 900 && this.windowHeight > 700
        },
        isFileDeleted() {
            return this.item.type === 'file' && this.item.isDeleted;
        }
    },
    watch: {
        folderId(val, oldVal) {
            this.refreshReceivers();
        },
        notifyLoading: {
            async handler() {
                if (!this.thumbnailLoaded) {
                    await this.initThumbnail();
                }
            }
        },
        item: {
            async handler() {
                if (this.appointment) {
                    await this.initThumbnail();
                }
            }
        },
    },
    async mounted() {
        if (this.accountRole === 'pupil') {
            this.me = await this.getMePupil();
        }
        EventBus.$off('new-thumbnail');
        EventBus.$on('new-thumbnail', async (fileId) => {
            if (this.file._id !== fileId) {
                return;
            }
            await this.initThumbnail(true);
        });
        // EventBus.$on('vfs-update-folder', (body) => {
        //     // huge bandaid, but I am already replacing the the folder in VirtualFileSystemV2.vue through websocket
        //     // where filesAndFolders is successfully recomputed and should pass the new folder down to this FSTE
        //     // however, no update to the :item prop is ever registered.
        //     // this causes a fresh pull of currentFolder from backend - which should technically not be necessary
        //     if (body.updatedFolder._id == this.item._id) {
        //         // the item already exists, replace it (should be in VFSV2.vue)
        //         // this.$emit('updateVFS');
        //     }
        // });

        // Evaluate whether pupil uses baby view
        if (this.accountRole === 'pupil') {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
        }
        // TODO: monitor performance on unstable connections or large folders, risky code
        if (!this.item._id && !this.item.rulesetAppointment) {
            await new Promise(resolve => setTimeout(resolve, 5000))
            if (!this.item._id) {
                // possible vuex routing issue, attempt reload (be careful to prevent loops)
                this.$emit('updateVFS');
            }
        }
        if (this.appointment) {
            this.item.type = 'file';
            this.type = 'file';
            const test = await backend.getFileInformation(this.item.file, this.item.fileAccessToken);
            const resFile = await test.json();
            this.file = resFile;
        } else {
            this.file = this.item;
        }
        let temp = await this.getFileExtension(this.file.mimetype);
        this.fileExtension = temp;
        this.isItemLibreFile = this.allowedMimeTypesForLibre.includes(this.file.mimetype);
        this.isItemEditorFile = this.allowedMimeTypesForEditor.includes(this.file.mimetype);
        this.isItemAudioFile = this.allowedMimeTypesForAudioPlayer.includes(this.file.mimetype);
        if (this.file.mimetype) {
            this.isItemVideoFile = this.allowedExtensionsForVideoPlayer.includes(this.file.mimetype.split('/').pop().toLowerCase());
        }

        if (this.type === 'folder' && this.item.name) {
          this.editedNameValue = this.item.name;
        } else if (this.type === 'file' && this.item.uploadedName) {
          this.editedNameValue = this.item.uploadedName;
        }
        this.ghostElement = this.item.ghostElement,
        await this.initThumbnail();
        this.refreshReceivers();
        this.evaluateSharing();
        if (this.item.ruleSet === 'teacherFolder') {
            this.teacherFolder = true;
        }
        this.refreshKey++;
    },
    beforeDestroy() {
        EventBus.$off('new-thumbnail')
    },
    methods: {
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        ...mapMutations('util', ['setDontOpenAppointment']),
        ...mapActions('subjects', ['getThumbnail']),
        ...mapActions('parentalLetter', ['getParentalLetterList']),
        ...mapActions('teacherFolder', ['getVFSSharing']),
        ...mapActions('util', ['insertLastOpenedInEditor', 'toggleOpenAppointment', 'toggleCurrentUploadGroup', 'toggleOpenTeacherUploadId']),
        ...mapActions('mimeTypes', ['isCollaboraCompatible', 'getFileExtension', 'isEditorCompatible']),
        ...mapActions('pupils', ['getMePupil']),
        ...mapActions('appointments', ['getAppointment']),
        ...mapActions('virtualFileSystem', ['getVFSUploadThumbnail']),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),

        async ToggleAppointmentInRouter(event) {
            event.stopPropagation();
            if (this.$route.query.aId !== this.item.appointmentId) { // Don't open same route repeatedly to avoid errors
                await this.$router.replace({ query: { ...this.$route.query, aId: this.item.appointmentId } });
            } else {
                await this.$router.replace({query: { appointment: undefined }});
            }
        },

        /**
         * startMultiselect Methods
         * @returns {Promise<void>}
         */
        startMultiselect() {
            if (this.$refs[`fileTileMenu${this.item._id}`].openMenu) {
                this.$refs[`fileTileMenu${this.item._id}`].toggleMenu();
            }
            // if (!this.timeoutForMultiselectActive) {
            // }
            // emit to vfs
            this.$emit('startMultiselect', this.item._id)
        },

        dontLongPress(event) {
            this.timeoutForMultiselectActive = true;
            setTimeout(() => {
                this.timeoutForMultiselectActive = false;
            }, 2200)
        },

        multiselectUpdate(event) {
            this.$emit('updateMultiselect', this.item._id);
        },

        resizeMenu(value) {
            if (this.vfsGridWidthPct === 100 || !(this.appointment || this.vfs.appointment || this.vfs.isVfsTask)) {
                this.openMenu = value;
            }
        },

        evaluateSharing() {
            if (this.type === 'file') {
                if(!this.vfs) {
                    return;
                }
                if (this.vfs.sharing_Tasks) {
                    let foundTask = this.vfs.sharing_Tasks.find((el) => (el.file._id == this.item._id) || (el.file == this.item._id));
                    this.activeTask = foundTask ? foundTask.activeTask : false;
                    this.isTask = !!foundTask;
                    let foundSubmission = false;
                    if (foundTask) {
                        if (foundTask.submissions.length > 0) {
                            foundSubmission = foundTask.submissions.find((sub) => sub.account === this.accountId);
                            // pull submission file and replace current file with it (danger)
                            // TODO Update in future, currently ugly as fuck
                            // jan.. why.. I need to debug this more, currently causes the FSTE to not recognize it as a submission anymore
                            if (foundSubmission) {
                                this.item.name = foundSubmission.file.uploadedName;
                                this.item.uploadedName = foundSubmission.file.uploadedName;
                                this.item._id = foundSubmission.file._id;
                            }
                        }
                    }
                    this.alreadySubmitted = foundSubmission ? true : false;
                } else {
                    this.activeTask = false;
                    this.isTask = false;
                    this.alreadySubmitted = false;
                }
            }
        },

        async initThumbnail(update = false) {
            let thumbnailAvailable = ''; // Item needs to either be a pdf or an image to prevent sending unnecessary thumbnail requests
            if (this.item.title) {
                thumbnailAvailable = this.isFileThumbnailCompatibleExtension(this.item.title.split('.').pop().toLowerCase());
            } else if (this.item.originalname) {
                thumbnailAvailable = this.isFileThumbnailCompatibleExtension(this.item.originalname.split('.').pop().toLowerCase());
            }
            // TODO: Deactivated thumbnail requests for drafts for the moment, because drafts at all are pretty buggy right now. Come back here when drafts are fixed.
            if (this.type === 'folder' || this.ghostElement || this.type === 'draft') {
                return;
            }
            if (this.isItemAudioFile) {
              this.preview = musikIcon;
              return;
            }
            if (this.indexLoadingPermission) {
                if (this.indexLoadingPermission[this.index] !== 'proceed') {
                    return;
                } else {
                    this.thumbnailLoaded = true;
                }
            }
            // request thumbnail identified by fileId
            let thumb = null;
            if (this.item.itemType && this.item.itemType === 'Document') {
                const thumbnailResponse = await backend.getDocumentRegistryDocumentFile(
                    this.item.value,
                    this.item.thumbnail);
                thumb = await thumbnailResponse.blob();
            } else if(!this.appointment) {
                if (this.item.type === 'draft' || this.type === 'draft') {
                    if (this.item.uploadMessage && this.item.uploadMessage.file) {
                        // eslint-disable-next-line vue/no-async-in-computed-properties
                        // thumb = await this.getThumbnail(this.item.uploadMessage.file);
                        thumb = await this.getVFSUploadThumbnail({
                            fileId: this.item.uploadMessage.file,
                            fileAccessToken: this.file.accessToken,
                            update
                        });
                    } else if (this.item.file) {
                        // eslint-disable-next-line vue/no-async-in-computed-properties
                        // thumb = await this.getThumbnail(this.item.file._id || this.item.file);
                        thumb = await this.getVFSUploadThumbnail({
                            fileId: this.file._id,
                            update,
                            fileAccessToken: this.file.accessToken,
                        });
                    }
                } else {
                    if (thumbnailAvailable) {
                        // thumb = await this.getThumbnail(this.item._id);
                        thumb = await this.getVFSUploadThumbnail({
                            fileId: this.file ? this.file._id : this.item._id,
                            update,
                            fileAccessToken: this.file.accessToken,
                        });
                    }
                }
            } else {
                if (thumbnailAvailable) {
                    if (this.item.previewData) {
                        // thumb = await this.getThumbnail(this.item.previewData.fileId);
                        thumb = await this.getVFSUploadThumbnail({
                            fileId: this.item.previewData.fileId,
                            update,
                            fileAccessToken: this.item.fileAccessToken,
                        });
                    } else {
                        // thumb = await this.getThumbnail(this.item.file);
                        thumb = await this.getVFSUploadThumbnail({
                            fileId: this.item.file,
                            update,
                            fileAccessToken: this.item.fileAccessToken,
                        });
                    }
                }
            }
            this.previewPDF = false;
            if(thumb && typeof thumb !== 'number') {
                this.preview = thumb;
                this.$emit('objectURL',this.preview);
            } else {
                this.noThumbnail = true;
            }
            this.$emit('finishedLoading', this.index);
            // bandaid: pictures will not load in this category unless they are an embed <object>
        },

        badgeOfItemClicked(event) {
            event.stopPropagation();
            // todo: Fix for locally constructed 'Faecher' Folder
            if (this.item.rulesetAppointment) {
                return;
            }
            if (this.type === 'folder') {
                const sortedBadgeContent = this.item.seenByBadge.sort((a, b) => a.path > b.path);
                const pathIds = sortedBadgeContent[0].path.split('/');
                this.$emit('loadFolder', pathIds[pathIds.length-2], pathIds.slice(1, -2));
            }
        },

        getSrc(icon) {
            const foundItem = this.fachIcons.find(item => item.name === icon);
            return foundItem.icon;
        },

        // doesnt seem to work on my device, can someone confirm?
        async readExerciseName(item) {
            await textToSpeech(item.title, 'de');
        },

        openEdit(item) {
            const title = item.title;
            let fileId;
            const id = item._id;
            const group = item.groupName;
            const color = item.fachColor;
            const appointmentId = item.appointmentId;
            const userGroup = this.accountsById[item.uploader].role;

            fileId = item.file;
            this.insertLastOpenedInEditor(id);

            let mode = 'pupil';
            if (this.accountRole === 'teacher') {
                mode = 'creator';
            }
            if (this.category) {
                if (this.category !== 'toDo') {
                    mode = 'viewer';
                }
            }
            if (item.displayPupilFile) {
                if (this.accountRole === 'teacher') {
                    mode = 'teacher';
                } else
                if (this.accountRole === 'pupil') {
                    mode = 'viewer';
                }
            }
            if (item.displayCorrectedFile) {
                mode = 'viewer';
            }
            this.$router.replace({'draft': null});
            this.$router.push({
                name: 'editor',
                params: { mode },
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: title,
                        fId: fileId,
                        uploadid: id,
                        ug: userGroup,
                    }
            });
        },

        async handleClickElement(iconTypeClicked) {
            if (this.ghostElement) {
                return;
            }
            if (this.isFileDeleted) {
                this.showSnackbar({ message: 'Aufgrund 30 Tagen Inaktivität ist die Datei nicht mehr verfügbar.', color: 'error' });
                return;
            }
            if (!(this.isEditing || this.item.isEditing)) {
                if (this.isItemAudioFile) {
                    this.$emit('playAudio', this.item);
                    return;
                } else if (this.isItemVideoFile) {
                    this.$emit('playVideo', this.item);
                    return;
                } else if ((this.type === 'file' || this.type === 'draft') && !this.appointment ) {
                    // if iconTypeClicked (for example: clicked on the pencil icon in the bottom left to edit the file)
                    // directly opens the editor, skipping the preview dialog
                    this.$emit('loadFullscreenPreview', this.itemForMenu, this.preview, iconTypeClicked);
                    return;
                } else if (this.appointment) {
                    if (this.isItemLibreFile) {
                        if (this.accountRole === 'pupil') {
                            this.openLibre();
                        } else {
                            this.$emit('loadFullscreenPreview', this.item, this.preview);
                        }
                    } else if (this.isItemEditorFile){
                        // if (this.accountRole === 'pupil') {
                            this.setDontOpenAppointment(true);
                            this.openEdit(this.item);
                        // } else {
                        //     //this.$emit('loadFullscreenPreview', this.item, this.preview);
                        // }
                    }
                    return;
                }
                if (this.item.rulesetAppointment) {
                    this.$emit('openAppointment', this.item);
                } else {
                    this.$emit('openFolder');
                }
            }
        },
        async openLibre() {
            let res;
            let uploadFile;
            if (!this.item.isTeamwork) {
                res = await backend.getCopyOfFile(this.file._id, this.item.fileAccessToken);
                uploadFile = await res.json();
            } else {
                res = await backend.getFileInformation(this.file._id, this.item.fileAccessToken);
                uploadFile = await res.json();
            }
            res = await backend.getLibreDocFromFile(uploadFile._id);
            const doc = await res.json();
            const libreAccessToken = doc.accessToken;
            const libreDocId = doc.libreDoc._id;

            const file = doc.file;
            const fileType = await this.getFileExtension(file.mimetype);
            const query = {
            ... this.$route.query,

            fileId: file._id,
            libreDocId: libreDocId,
            accessToken: libreAccessToken,
            aId: this.item.appointmentId,
            fileAccessToken: !this.item.isTeamwork ? uploadFile.accessToken : this.item.fileAccessToken,
            fileType: fileType,
            mode: 'material',
            };

            await this.$router.push({
            name: 'libreoffice',
            query,
            });
        },
        handleDeleteButtonPress() {
            this.$emit('openDeleteDialog');
        },
        handleEditButtonPress() {
            // initialize sharing_Accounts
            if (this.accountRole === 'teacher') {
                this.getPossibleReceivers();
            }
            this.item.isEditing = true;
            this.editedNameValue = this.item.name || this.item.uploadedName;
            this.isEditing = true;
        },
        handleMoveButtonPress() {
            this.isMoving = true;
            this.$emit('startedEditing');
        },
        handleOpenSubmissionsButtonPress() {
            this.$emit('openSubmissions');
        },
        async handleIconSharingClicked() {
            try {
                if ((this.getIconSharing.icon === 'mdi-account' && this.accountRole === 'pupil') || this.ghostElement) {
                    // maybe show a pupil version of the LearningPathDialog, but for now -> no action
                    return;
                }
                const validIcons = ['mdi-folder-account', 'mdi-folder-lock'];
                const currentIcon = this.getIconSharing.icon;
                if (currentIcon === 'mdi-account') {
                    this.$emit('editLearningPathSettings', this.item);
                }
                if (validIcons.some((icon) => currentIcon === icon)) {
                    if (this.vfs.rulesetTask) {
                        return;
                    }
                    this.$emit('editSharing', this.item);
                }
                if (currentIcon === 'mdi-camera') {
                    if (!this.isDesktopSize) {
                        await this.uploadFile(true);
                    } else {
                        this.toggleImageCapture = true;
                    }
                }
            } catch (e) {
                console.warn(e);
            }
        },
        async setIsTask() {
            // check if the fileType is compatible with Editor
            const allowedTaskExtensions = this.allowedExtensionsForEditor.concat(this.allowedExtensionsForLibre);
            if (!allowedTaskExtensions.includes(this.item.uploadedName.split('.').pop().toLowerCase())) {
                this.showSnackbar({ message: 'Die Datei hat kein passendes Format, um im Editor angezeigt zu werden. Gerne können Sie Aufgaben als PDF / PNG / JPG / TIFF / BMP oder Office Datei hochladen.', color: 'error' });
                return;
            }
            let body = {
                activeTask: !this.activeTask,
            };
            const res = await backend.updateVFSSetActiveTaskV2(this.item._id, body);
            if (res.status === 200) {
                this.activeTask = !this.activeTask;
                this.showSnackbar({ message: `${this.activeTask === true ? 'Die Aufgabe kann jetzt von SuS bearbeitet werden.' : 'Es können keine weiteren Abgaben eingereicht werden.'}` });
            } else {
                this.showSnackbar({ message: 'Es gab ein Problem bei der Aufgabe', color: 'error' });
            }
            this.$emit('updateVFS');
        },
        cancelFolderEdit() {
            this.isEditing = false;
            this.isMoving = false;
            this.item.isEditing = false;
            this.item.isMoving = false;
            this.$emit('canceledEditing');
        },
        acceptFolderEdit() {
            const newFolder = !this.item._id;
            this.item.name = this.editedNameValue;
            this.isEditing = false;
            this.isMoving = false;
            this.item.isEditing = false;
            this.item.isMoving = false;
            this.item.receiverAccounts = this.receiverAccounts;
            this.$emit('folderEdited', newFolder);
        },
        getStyle() {
            let str = '';
            if (this.vfsGridWidthPct === 100 && !(this.appointment || this.vfs.appointment || this.vfs.isVfsTask)) {
                str += 'justify-content: flex-start !important; flex-direction: row; border-top-style: outset; border-top-width: 2px; padding-top: 5px; margin-bottom: 2px !important; aspect-ratio: unset; border-radius: 0px';
                if (this.openMenu) {
                    str += 'height: 150px';
                }
            }
            if (this.appointment) {
                // todo: fix styling in homework mode
            }
            return str+'';
        },
        getStyleList() {
            if (this.vfsGridWidthPct === 100 && !(this.appointment || this.vfs.appointment || this.vfs.isVfsTask)) {
                return 'display: flex; align-items: flex-start; border-radius: 0px';
            }
            return '';
        },
        async getPossibleReceivers() {
            try {
                let addItemText = await this.getVFSSharing(this.folderId);
                for (let i = 0; i < addItemText.length; i++) {
                    // grab the name of the Group
                    addItemText[i].displayName = addItemText[i].name;
                    // visually preselect possible recievers with currently set receivers
                    // for every possible receiver account
                    for (let j = 0; j < addItemText[i].participants.length; j++) {
                        // for every currently Shared AccountId
                        if (this.item.sharing_Accounts) {
                            for (let k = 0; k < this.item.sharing_Accounts.length; k++) {
                                // add to selected Model if matching (user sees the menu with current setting)
                                if (this.item.sharing_Accounts[k] == addItemText[i].participants[j].account) {
                                    this.receiverAccounts.push(addItemText[i].participants[j]);
                                }
                            }
                        }
                    }
                }
                this.possibleReceivers = addItemText;
            } catch (e) {
                console.error(e);
            }
        },
        async refreshReceivers() {
            if (this.folderType === 'groupFolder' && (this.isEditing || this.item.isEditing || this.isMoving || this.item.isMoving || this.item.isCreating)) {
                this.getPossibleReceivers();
            }
        },
        createDate(date) {
            let readableDate = '';
            if (date !== undefined) {
                readableDate = new Date(date).toLocaleDateString('de-DE', { day: 'numeric', month: 'numeric', year: '2-digit' });
            }
            return readableDate;
        },
        uploadFile(clickEvent) {
            // To make sure seen by me is also set in backend
            backend.getTeacherUpload(this.item.appointmentId, this.item.file);

            // Normal upload stuff
            const appointment = this.appointmentsById[this.item.appointmentId];
            this.toggleOpenAppointment(appointment);
            this.toggleCurrentUploadGroup('baby');
            this.toggleOpenTeacherUploadId(this.item.file);
            if (clickEvent) { // If anything but ImageCapture.vue was used for uploading
                // IMPORTANT: Triggering the upload input with a click event needs to be in a NON-ASYNC method.
                // Otherwise, it won't work on Safari.
                document.getElementById('uploadInput').click();
            }
        },

        activateTranslationBtn(){
            const element = this.$refs.speaker;
            if (element) {
                element.classList.remove('showSpeaker');

                setTimeout(() => {
                    element.classList.add('showSpeaker');
                }, 3000);
            }
        },


        async readText(item, event) {
            let txt;
            let text;
            if (!this.appointment) {
                text = this.sanitizeHtml(this.urlify(item.name || item.uploadedName));
            } else {
                text = this.sanitizeHtml(this.urlify(item.title));
            }
            if (text) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = text;
                txt = tmp.textContent || tmp.innerText || "";
            } else {
                txt = 'Dieser Eintrag hat keinen Inhalt.';
            }

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                event.target.src = lautsprecherAusIcon;
            }, () => {
                event.target.src = lautsprecherIcon;
            });
        },
    }
}
</script>

<style scoped lang="scss">
.showSpeaker{
    display: none;
    z-index: 100 !important;
    position: absolut;
}

.badgeEventVisualize {
    animation: badgeAppointmentRipple 1000ms ease-in-out;
}

@keyframes badgeAppointmentRipple {
    0% {
        background-color: rgba(255, 255, 255, 0.0);
    }
    10% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    75% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    100% {
        background-color: rgba(255, 255, 255, 0.0);
    }
}
.fileSystemTile {
    display: flex;
    border-radius: 4px;
    color: var(--v-schwarz-base);
    width: 90%; white-space: nowrap;
    // aspect-ratio: 1;
    background-color: white;
}

.previewDiv50 {
    min-height:12vh;
    min-width:12vw;
    max-height: 12vh;
    max-width: 12vw;
    cursor: pointer;
}

.thumbnailContainer {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: var(--v-dunkelgrau-base);
    width: 100%;
    max-height: 100%;
}

.thumbnailOverlayHausis {
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.4) inset;
    width: inherit;
    min-width: inherit;
    min-height: calc(100% - 26px);
    height: calc(100% - 26px);
    max-height: calc(100% - 26px);
    border-radius: 8px;
    position: absolute;
    aspect-ratio: auto;
    display: flex;
}

.thumbnailOverlay {
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.4) inset;
    width: inherit;
    min-width: inherit;
    min-height: calc(100% - 32px);
    height: calc(100% - 32px);
    max-height: calc(100% - 32px);
    border-radius: 8px;
    position: absolute;
    aspect-ratio: auto;
    display: flex;
}

.thumbnailOverlay33 {
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.4) inset;
    width: inherit;
    min-width: inherit;
    min-height: calc(100% - 27.5px);
    max-height: calc(100% - 27.5px);
    border-radius: 8px;
    position: absolute;
    aspect-ratio: auto;
    display: flex;
}

// Needs to be square
.thumbnailDisplayNormal {
    width: 100%;
    // min-width: 110px;
    // max-width: 10vmax;
    height: 100%;
    min-height: 150px;
    max-height: 10vmax;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 8px;
}

@media only screen and (min-width: 0px) and (max-width: 750px) {
    .thumbnailDisplayNormal {
        max-height: 20vmax;
    }
    .thumbnailOverlay {
        max-height: calc(100% - 32px);
    }
    .thumbnailOverlay33 {
        max-height: calc(100% - 27.5px);
        height: calc(100% - 27.5px);
    }
}

@media only screen and (min-width: 751px) and (max-width: 900px) {
    .thumbnailDisplayNormal {
        min-height: 20vmax;
    }
    .thumbnailOverlay {
        min-height: 20vmax;
    }
    .thumbnailOverlay33 {
        min-height: 20vmax;
    }
}

.previewDiv50_SingleWidgetView {
    min-height:14vh;
    min-width: 32vw;
    max-height: 14vh;
    max-width: 32vw;
    cursor: pointer;
}

.previewDiv33 {
    min-height:10vh;
    min-width:8vw;
    max-height: 10vh;
    max-width: 8vw;
    cursor: pointer;
}

.previewDiv33_SingleWidgetView {
    min-height: 12vh;
    min-width: 24vw;
    max-height: 12vh;
    max-width: 24vw;
    cursor: pointer;
}

.previewFolderDiv50 {
    min-height:12vh;
    max-height: 12vh;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
}

.previewFolderDiv50_SingleWidgetView {
    min-height:14vh;
    max-height: 14vh;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
}

.previewFolderDiv33 {
    min-height:10vh;
    max-height: 10vh;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
}

.previewFolderDiv33_SingleWidgetView {
    min-height:12vh;
    max-height: 12vh;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
}

.fileTitle {
    font-size: auto;
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.smallTopicActionBtn {
    height: 24px !important;
    min-width: 24px !important;
    width: 24px !important;
    max-width: 5%;
    border-radius: 6px;
    box-shadow: 1px 2px 3px silver;
}

.smallTopicActionBtnImg {
    width: 18px !important;
    height: 18px !important;
    filter: brightness(1000%);
}

.iOS_ResponsiveHack {
    // i dont know how this works but ios devs recommend this to create responsive fit for embed pdfs
    height: 1px;
    min-height: 100%;
    *height: 100%;
}

.fileSystemElementName {
    cursor: pointer;
    width: 90%;
    align-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transform: translate3d(0,0,0);
}

.icon {
    height: 20px;
}

.iconToFemale {
    filter: brightness(0) saturate(100%) invert(40%) sepia(81%) saturate(2866%) hue-rotate(326deg) brightness(92%) contrast(97%);
}

.iconToMale {
    filter: brightness(0) saturate(100%) invert(36%) sepia(17%) saturate(1992%) hue-rotate(192deg) brightness(93%) contrast(80%);
}

.iconToDiverse {
    filter: brightness(0) saturate(100%) invert(15%) sepia(52%) saturate(5688%) hue-rotate(281deg) brightness(93%) contrast(89%);
}

.iconInfo {
    position: absolute;
    color: white;
    background: #80808085;
    border-radius: 6px;
}
@media only screen and (min-width: 901px) and (max-width: 1100px) {
    .iconInfo {
        font-size: 200% !important;
    }
}
// @media only screen and (min-width: 0px) and (max-width: 450px) {
//     .iconInfo {
//         bottom: 10% !important;
//     }
// }

// @media only screen and (min-width: 451px) and (min-width: 900px) {
//     .iconInfo {
//         bottom: 15% !important;
//     }
// }


.fileSystemElementNameCentered {
    cursor: pointer;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.fileSystemElementOptionsMenuButton {
    display: inline-flex;
    justify-content: flex-start;
    padding-left: 5px !important;
    border-radius: 0;
}

.editorOptionsFade {
    opacity: 0;
    transition: 1s ease-out 3s;
}

.v-menu__content {
    position: fixed;
    overflow: visible;
    top: auto !important;
    left: auto !important;
    contain: initial;
    transform-origin: inherit !important;
    box-shadow: none !important;
}

.folderDisplay {
    display: flex;
    justify-content: center;
    align-content: space-around;
    font-size: 150px !important;
    color: white;
}
@media only screen and (min-width: 901px) and (max-width: 1280px) {
    .folderDisplay {
        font-size: 100px !important;
    }
}
@media only screen and (max-width: 400px) {
    .folderDisplay {
        font-size: 75px !important;
    }
}

.folderDisplay33 {
    display: flex;
    justify-content: center;
    align-content: space-around;
    font-size: 75px !important;
    color: white;
}

.internFileElementBadge {
    position: absolute;
    width: 25px;
    height: 25px;
    top: -10px;
    border-radius: 50%;
    background-color: red;
    color: white;
    z-index: 5;
}
.draftFileWidgetElement {
    background-color: var(--v-orange-base);
}

.multiselectCheckbox {
    left: 4%;
    top: 4%;
    background-color: rgba(175, 175, 175, 0.5);
    padding: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 900px) and (max-width: 1050px)
    and (max-width: 380px){
    .datePosition {
        margin-right: -40% !important;
    }
}

.scroll-area-treeview {
    position: relative;
    margin: auto;
    max-height: calc(50vh - 205px);
}
</style>
